import {Modal, ModalHeader, ModalBody, Col, Row, Table, Badge} from "reactstrap";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {formatCurrency} from "helpers/utilities";
import {memo, useCallback, useEffect, useState} from "react";
import {UserSearchProductFBASeller} from "models/user_search_product_fba_seller";
import {createTypedModal} from "helpers/modal_helpers";
import {UserSearchProductCompetitorAnalysis} from "models/user_search_product_competitor_analysis";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import {AllMarketplaces, AmazonFBAMarketplaces, AmazonMarketplace} from "helpers/marketplace_helper";
import {useSelector} from "react-redux";
import {CommonSliceSelector} from "slices/common/selector";
import {UserStore} from "models/user_stores";
import {UserSearchProductProfitAnalysis} from "models/user_search_product_profit_analysis";

interface ModalData {
  asin: string;
  marketplaceTarget: string;
  domainTarget: string;
  currencyCodeTarget: string;
  competitorAnalysis: UserSearchProductCompetitorAnalysis;
  profitAnalysis: UserSearchProductProfitAnalysis;
  fbaSellers: UserSearchProductFBASeller[];
}

export const FBASellersModal = createTypedModal<ModalData>("fba_sellers");

const CheckboxItem = ({status}: any) => {
  return status === false ? (
    <i className="ri-close-circle-fill fs-18 text-danger"></i>
  ) : status === null || status === undefined ? (
    <i className="ri-donut-chart-line fs-18 text-muted"></i>
  ) : (
    <i className="ri-checkbox-circle-fill fs-18 text-success"></i>
  );
};
const FBASellers = () => {
  const {open, data} = FBASellersModal.useModal();

  if (!open) return null;
  return <FBASellersContent data={data} />;
};

const FBASellersContent = ({data}: {data: ModalData | undefined}) => {
  const {t} = useTranslation();
  const {userStores} = useSelector(CommonSliceSelector);
  const [sellerList, setSellerList] = useState<UserSearchProductFBASeller[]>();
  const [isLandingPrice, setIsLandingPrice] = useState<boolean>(false);

  const toggle = () => {
    FBASellersModal.close();
  };

  const onLoad = useCallback(() => {
    if (data?.profitAnalysis?.currentShippingPrice !== 0 && data?.profitAnalysis?.currentPrice) {
      setIsLandingPrice(true);
    } else {
      setIsLandingPrice(false);
    }

    if (data?.fbaSellers) {
      const list = [...data.fbaSellers].sort((a, b) => (a.price ?? 0) - (b.price ?? 0));
      setSellerList(list);
    }
  }, []);// eslint-disable-line

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const userStoreExistInSellerList = data?.fbaSellers?.some((seller) => userStores.some((store) => store.sellerId === seller.sellerID && store.marketplace === data?.marketplaceTarget));
  console.log(userStoreExistInSellerList)
  return (
    <Modal id="showFBASellersModal" isOpen={true} toggle={toggle} centered={true} fade={true} className="modal-lg">
      <ModalHeader className="p-3" toggle={toggle}>
        {t("SearchResults.Dialog.FBASellers.Title")}
      </ModalHeader>
      <ModalBody>
        <Row className="mb-3">
          <Col>
            <div className="text-center fs-18">
              {t("SearchResults.Dialog.FBASellers.Competition")}
              {data?.competitorAnalysis?.competitionLevel ? (
                <Badge
                  className="rounded-pill top--2 ms-1"
                  color={data.competitorAnalysis.competitionLevel === "Weak" ? "success" : data.competitorAnalysis.competitionLevel === "Medium" ? "warning" : "danger"}
                >
                  {t(data.competitorAnalysis.competitionLevel)}
                </Badge>
              ) : (
                <span>{t("NA")}</span>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="table-responsive">
              <Table className="align-middle table-nowrap mb-4 mt-1 table-hover table-bordered">
                <tbody>
                  <tr>
                    <td width="50%">
                      <div className="d-flex justify-content-between align-items-center">
                        <span>{t("SearchResults.Dialog.FBASellers.FBASellerCount")}</span>
                        <span>{data?.competitorAnalysis?.fbaSellerCount ?? t("NA")}</span>
                      </div>
                    </td>
                    <td width="50%">
                      <div className="d-flex justify-content-between align-items-center">
                        <span>{t("SearchResults.Dialog.FBASellers.RemoteFBASellerCount")}</span>
                        <span>{data?.competitorAnalysis?.fbaSellerCountRemote ?? t("NA")}</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex justify-content-between align-items-center">
                        <span>{t("SearchResults.Dialog.FBASellers.FBMSellerCount")}</span>
                        <span>{data?.competitorAnalysis?.fbmSellerCount ?? t("NA")}</span>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex justify-content-between align-items-center">
                        <span>
                          {t("SearchResults.Dialog.FBASellers.AverageFBASellerCount", {day: 30})}
                          <div className="d-block fs-11 d-flex align-items-center">
                            {userStoreExistInSellerList && (
                              <>
                                <i className="mdi mdi-information text-secondary me-1"></i>
                                <span className="me-2 text-muted">{t("SearchResults.Dialog.FBASellers.AverageFBASellerCountInfo")}</span>
                              </>
                            )}
                          </div>
                        </span>
                        <span>{data?.competitorAnalysis?.avgFBASellerCount ?? t("NA")}</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex justify-content-between align-items-center">
                        <span>{t("SearchResults.Dialog.FBASellers.IsAmazonExist")}</span>
                        <CheckboxItem status={data?.competitorAnalysis?.isAmazonSell ? data.competitorAnalysis.isAmazonSell : false} />
                      </div>
                    </td>
                    <td>
                      <div className="d-flex justify-content-between align-items-center">
                        <span>{t("SearchResults.Dialog.FBASellers.AmazonLastSeen")}</span>
                        <span>
                          {data?.competitorAnalysis?.lastAmazonSeenText
                            ? data.competitorAnalysis.lastAmazonSeenText
                                .replace("years", t("years"))
                                .replace("year", t("year"))
                                .replace("months", t("months"))
                                .replace("month", t("month"))
                                .replace("days", t("days"))
                                .replace("day", t("day"))
                                .replace("and", t("and"))
                                .replace("ago", t("ago"))
                            : t("NA")}
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
        {sellerList && sellerList.length > 0 ? (
          <Row>
            <Col>
              <div className="table-responsive">
                <table className="table align-middle table-nowrap mb-0 table-hover table-bordered">
                  <thead className="table-light">
                    <tr>
                      <th>{t("Seller Id")}</th>
                      <th>{t("Rating")}</th>
                      <th>{isLandingPrice ? t("Price + Shipping") : t("Price")}</th>
                      <th>
                        <div className="d-flex align-items-center">
                          {t("SearchResults.Dialog.FBASellers.IsRemoteFBA")}
                          <i id="RemoteFBADefinition" className="ms-1 bx bxs-info-circle text-secondary"></i>
                          <DefaultUncontrolledTooltip target="RemoteFBADefinition">{t("Definitions.RemoteFBA")}</DefaultUncontrolledTooltip>
                        </div>
                      </th>
                      <th>{t("SearchResults.Dialog.FBASellers.IsAmazon")}</th>
                      {data?.marketplaceTarget === "AU" && <th>{t("SearchResults.Dialog.FBASellers.IsLocalAmazon")}</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {sellerList.map((item: UserSearchProductFBASeller, index: number) => {
                      let sellerMarketplace = {} as AmazonMarketplace | undefined;
                      if (item.isAmazon) {
                        sellerMarketplace = AmazonFBAMarketplaces.find((amazonMarketplaceInfo) => amazonMarketplaceInfo.id === item.sellerID);
                      } else {
                        sellerMarketplace = AllMarketplaces.find((amazonMarketplaceInfo) => amazonMarketplaceInfo.domain === data?.domainTarget);
                      }

                      const renderSellerName = () => {
                        if (item.isAmazon) {
                          return "Amazon " + sellerMarketplace?.marketplace;
                        } else {
                          const customerStore = userStores.find((userStore: UserStore) => userStore.sellerId === item.sellerID && userStore.marketplace === data?.marketplaceTarget);
                          let customerStoreName = "";
                          if (customerStore) {
                            customerStoreName = customerStore.name;
                          } else {
                            customerStoreName = item.sellerID ?? "";
                          }
                          return (
                            <>
                              <Link
                                to={`https://www.amazon.${sellerMarketplace?.domain}/sp?seller=${item.sellerID}`}
                                target="_blank"
                                className="link-secondary link-offset-2 text-decoration-underline"
                              >
                                {customerStoreName}
                                <i className="ri-arrow-right-up-line"></i>
                              </Link>
                              {customerStore && <Badge className="rounded-pill">{t("You")}</Badge>}
                            </>
                          );
                        }
                      };
                      return (
                        <tr key={index}>
                          <td>
                            <>
                              <span className="hstack gap-2">
                                <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                                  <img src={sellerMarketplace?.flag} alt="Country Flag" className=" rounded-circle" />
                                </div>
                                {renderSellerName()}
                              </span>
                            </>
                          </td>
                          <td>{item.reviewCount} </td>
                          <td>{`${formatCurrency("$", 2, "abbreviated", item.price, data?.currencyCodeTarget)}`}</td>
                          <td>
                            <CheckboxItem status={item.isRemote} />
                          </td>
                          <td>
                            <CheckboxItem status={item.isAmazon} />
                          </td>
                          {data?.marketplaceTarget === "AU" && (
                            <td>
                              <CheckboxItem status={!!AmazonFBAMarketplaces.find((amazonMarketplaceInfo) => amazonMarketplaceInfo.id === item.sellerID && amazonMarketplaceInfo.isLocal)} />
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </ModalBody>
    </Modal>
  );
};

export default memo(FBASellers);
