import {APIClient} from "helpers/api_helper";
import {AddToUserFavoriteListCommand, DeleteUserFavoriteListCommand, RemoveFromUserFavoriteListCommand, EditUserFavoriteListCommand, SaveUserFavoriteListCommand, ChangeUserFavoriteListVisibilityCommand} from "models/user_favorites";
import {UserSearchesQuery} from "models/user_search";
import {GetUserFavoriteListQuery, GetProductDetailsQuery, SearchQuery, SingleSearchQuery, GetUserSearchNotesQuery} from "api/query";
import {
  DeleteSearchResultsFilterCommand,
  SubmitForAnalysisCommand,
  SetUserShowCheckListOptionChoiceCommand,
  ReAnalyzeCommand,
  DeleteSearchCommand,
  AnalyzeShipmentCommand,
  SaveOrUpdateSearchResultsFilterCommand,
  MarkSearchProductReviewedCommand,
  SaveUserSearchCommand,
  ExportSearchResultsCommand,
  MarkSearchReviewedCommand,
  SaveNoteCommand,
  DeleteNoteCommand,
  ChangeSearchVisibilityCommand,
} from "api/command";
import {SearchResultQuery} from "models/search_result";
import * as url from "../helpers/url_helper";

const api = new APIClient();

export const postGetUserFavorites = (data: GetUserFavoriteListQuery): Promise<any> => api.create(url.POST_GET_USER_FAVORITES, data);

export const postSaveUserFavorite = (data: SaveUserFavoriteListCommand): Promise<any> => api.create(url.POST_SAVE_USER_FAVORITE, data);

export const postDeleteUserFavoriteList = (data: DeleteUserFavoriteListCommand): Promise<any> => api.create(url.POST_DELETE_USER_FAVORITE_LIST, data);

export const postEditUserFavoriteList = (data: EditUserFavoriteListCommand): Promise<any> => api.create(url.POST_EDIT_USER_FAVORITE_LIST, data);

export const postChangeUserFavoriteListVisibility = (data: ChangeUserFavoriteListVisibilityCommand): Promise<any> => api.create(url.POST_CHANGE_USER_FAVORITE_LIST_VISIBILITY, data);

export const postAddToUserFavoriteList = (data: AddToUserFavoriteListCommand): Promise<any> => api.create(url.POST_ADD_TO_USER_FAVORITE_LIST, data);

export const postRemoveFromUserFavoriteList = (data: RemoveFromUserFavoriteListCommand): Promise<any> => api.create(url.POST_REMOVE_FROM_USER_FAVORITE_LIST, data);

export const postGetUserSearches = (data: UserSearchesQuery) => api.create(url.POST_GET_USER_SEARCHES, data);

export const postSaveNewSearch = (data: SaveUserSearchCommand) => api.create(url.POST_SAVE_NEW_SEARCH, data);

export const postReAnalyze = (data: ReAnalyzeCommand) => api.create(url.POST_REANALYZE, data);

export const postDeleteSearch = (data: DeleteSearchCommand) => api.create(url.POST_DELETE_SEARCH, data);

export const postMarkSearchReviewed = (data: MarkSearchReviewedCommand) => api.create(url.POST_MARK_SEARCH_REVIEWED, data);

export const postChangeSearchVisibility = (data: ChangeSearchVisibilityCommand) => api.create(url.POST_CHANGE_SEARCH_VISIBILITY, data);

export const postSubmitForAnalysis = (data: SubmitForAnalysisCommand) => api.create(url.POST_SUBMIT_FOR_ANALYSIS, data);

export const postGetFilters = () => api.create(url.POST_GET_SEARCH_RESULTS_FILTERS, []);

export const postGetSearchResults = (data: SearchResultQuery) => api.create(url.POST_GET_SEARCH_RESULTS, data);

export const postSaveFilter = (data: SaveOrUpdateSearchResultsFilterCommand) => api.create(url.POST_SAVE_SEARCH_RESULTS_FILTER, data);

export const postDeleteFilter = (data: DeleteSearchResultsFilterCommand) => api.create(url.POST_DELETE_SEARCH_RESULTS_FILTER, data);

export const postSetUserShowCheckListOptionChoice = (data: SetUserShowCheckListOptionChoiceCommand) => api.create(url.POST_SET_USER_SHOW_CHECKLIST_OPTION_CHOICE, data);

export const postAnalyzeShipment = (data: AnalyzeShipmentCommand) => api.create(url.POST_ANALYZE_SHIPMENT, data);

export const postGetProductDetails = (data: GetProductDetailsQuery) => api.create(url.POST_GET_PRODUCT_DETAILS, data);

export const postGetProductDetailsForAnonymUsers = (data: SingleSearchQuery) : Promise<any> => api.create(url.POST_GET_SINGLE_SEARCH, data);

export const getSearch = (data: SearchQuery) => api.get(url.GET_SEARCH, data);

export const postMarkSearchProductReviewed = (data: MarkSearchProductReviewedCommand) => api.create(url.POST_SEARCH_PRODUCT_MARK_REVIEWED, data);

export const postSaveNote = (data: SaveNoteCommand) => api.create(url.POST_SAVE_SEARCH_NOTE, data);

export const postDeleteNote = (data: DeleteNoteCommand) => api.create(url.POST_DELETE_SEARCH_NOTE, data);

export const postExportSearchResults = (data: ExportSearchResultsCommand) => api.create(url.POST_EXPORT_SEARCH_RESULTS, data);

export const postGetUserNotes = (data: GetUserSearchNotesQuery) => api.create(url.POST_GET_USER_SEARCH_NOTES, data);
