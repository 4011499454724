import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Link, useLocation, useParams} from "react-router-dom";
import {Button, Dropdown, DropdownMenu, DropdownToggle, Form, Input, InputGroup} from "reactstrap";
import {changeSidebarVisibility} from "slices/thunks";
import {useSelector, useDispatch} from "react-redux";
import {createSelector} from "reselect";
import {useProfile} from "Components/Hooks/useProfile";
import {SelectOptionsType} from "Components/Hooks/useSelectOptions";
import {resetSearchProductState} from "slices/product-details/thunk";
import {t} from "i18next";
import LanguageDropdown from "Components/Common/LanguageDropdown";

import logoDark from "assets/images/logo-dark.png";
import logoLight from "assets/images/logo-light.png";

import Select, {SingleValue} from "react-select";
import _ from "lodash";
import {countryOptionContainer, countryValueContainer} from "Components/Common/Select/SelectStyle";
import {ActiveMarketplaceOptions} from "helpers/marketplace_helper";

declare global {
  interface Window {
    hj: any;
  }
}

const Header = ({onChangeLanguage, onChangeLayoutMode, layoutModeType, headerClass}: any) => {
  const dispatch: any = useDispatch();
  const location = useLocation();
  const {userProfile} = useProfile();
  const [searchValue, setSearchValue] = useState("");
  const [selectedMarketplace, setSelectedMarketplace] = useState<SelectOptionsType>();
  const {asin} = useParams();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const selectDashboardData = createSelector(
    (state) => state.Layout.sidebarVisibilitytype,
    (sidebarVisibilitytype) => sidebarVisibilitytype,
  );
  // Inside your component
  const sidebarVisibilitytype = useSelector(selectDashboardData);

  const [search, setSearch] = useState(false);
  const toogleSearch = () => {
    setSearch(!search);
  };

  const hotjarIdentify = ({userId, customAttributes}: {userId: string | undefined; customAttributes: Record<string, any>}) => {
    if (window.hj) {
      window.hj("identify", userId || null, customAttributes || {});
    }
  };
  hotjarIdentify({userId: userProfile?.userId, customAttributes: {email: userProfile?.email, name: userProfile?.fullName}});

  const toogleMenuBtn = () => {
    var windowSize = document.documentElement.clientWidth;
    const humberIcon = document.querySelector(".hamburger-icon") as HTMLElement;
    dispatch(changeSidebarVisibility("show"));

    if (windowSize > 767) humberIcon.classList.toggle("open");

    //For collapse horizontal menu
    if (document.documentElement.getAttribute("data-layout") === "horizontal") {
      document.body.classList.contains("menu") ? document.body.classList.remove("menu") : document.body.classList.add("menu");
    }

    //For collapse vertical and semibox menu
    if (sidebarVisibilitytype === "show" && (document.documentElement.getAttribute("data-layout") === "vertical" || document.documentElement.getAttribute("data-layout") === "semibox")) {
      if (windowSize < 1025 && windowSize > 767) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.getAttribute("data-sidebar-size") === "sm"
          ? document.documentElement.setAttribute("data-sidebar-size", "")
          : document.documentElement.setAttribute("data-sidebar-size", "sm");
      } else if (windowSize > 1025) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.getAttribute("data-sidebar-size") === "lg"
          ? document.documentElement.setAttribute("data-sidebar-size", "sm")
          : document.documentElement.setAttribute("data-sidebar-size", "lg");
      } else if (windowSize <= 767) {
        document.body.classList.add("vertical-sidebar-enable");
        document.documentElement.setAttribute("data-sidebar-size", "lg");
      }
    }

    //Two column menu
    if (document.documentElement.getAttribute("data-layout") === "twocolumn") {
      document.body.classList.contains("twocolumn-panel") ? document.body.classList.remove("twocolumn-panel") : document.body.classList.add("twocolumn-panel");
    }
  };

  const onUrlChange = useCallback(() => {
    let marketplaceExist = ActiveMarketplaceOptions.find((marketplaceOption) => marketplaceOption.value === searchParams.get("marketplace"));
    if (asin && marketplaceExist) {
      setSearchValue(asin);
      setSelectedMarketplace(ActiveMarketplaceOptions.find((marketplaceOption) => marketplaceOption.value === searchParams.get("marketplace")));
    }
  }, [searchParams]); // eslint-disable-line

  useEffect(() => {
    onUrlChange();
  }, [onUrlChange]);

  return (
    <React.Fragment>
      <header id="page-topbar" className={headerClass}>
        <div className="layout-width">
          <div className="navbar-header">
            <div className="d-flex align-items-center">
              <div className="navbar-brand-box horizontal-logo">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logoLight} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLight} alt="" height="28" />
                  </span>
                </Link>

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoDark} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoDark} alt="" height="28" />
                  </span>
                </Link>
              </div>

              <button onClick={toogleMenuBtn} type="button" className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger" id="topnav-hamburger-icon">
                <span className="hamburger-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>

              <div style={{width: "250px"}} className="me-3">
                <Select
                  className="d-none d-md-block"
                  placeholder="Select Marketplace"
                  value={selectedMarketplace}
                  options={ActiveMarketplaceOptions}
                  onChange={(e: SingleValue<SelectOptionsType>) => {
                    setSelectedMarketplace(e as SelectOptionsType);
                  }}
                  components={{Option: countryOptionContainer, SingleValue: countryValueContainer}}
                />
              </div>

              <div className="app-search d-none d-md-block">
                <div className="position-relative">
                  <InputGroup>
                    <Input
                      type="text"
                      className="form-control"
                      placeholder={t("SearchPlaceholder")}
                      id="search-options"
                      value={searchValue}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setSearchValue(_.toUpper(e.target.value));
                      }}
                      maxLength={10}
                    />
                    <Button
                      className="btn-soft-secondary waves-effect border-0"
                      disabled={searchValue.length !== 10}
                      onClick={() => {
                        resetSearchProductState()(dispatch);
                        window.location.href = `/dp/${searchValue}?marketplace=${selectedMarketplace?.value}`;
                      }}
                    >
                      <i className=" ri-arrow-right-line"></i>
                    </Button>
                  </InputGroup>
                  <span className="mdi mdi-magnify search-widget-icon"></span>
                  <span className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none" id="search-close-options"></span>
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center">
              <Dropdown isOpen={search} toggle={toogleSearch} className="d-md-none topbar-head-dropdown header-item">
                <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                  <i className="bx bx-search fs-22"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                  <Form className="p-3">
                    <div className="form-group m-0">
                      <div className="input-group">
                        <input type="text" className="form-control" placeholder="Search…" aria-label="Search" />
                        <Button color="secondary" className="btn-icon" type="submit">
                          <i className="mdi mdi-magnify fs-18"></i>
                        </Button>
                      </div>
                      <Select className="" options={ActiveMarketplaceOptions} />
                      <div></div>
                    </div>
                  </Form>
                </DropdownMenu>
              </Dropdown>

              <LanguageDropdown />

              <Link to="/account/login" className="btn btn-link fw-medium text-decoration-none text-body">
                {t("Login.SignIn")}
              </Link>
              <Link to="/account/register" className="ms-2 btn btn-secondary waves-effect waves-light">
                {t("Register.SignUp")}
              </Link>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
