import {Alert, Button, Col, Form, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {useProfile} from "Components/Hooks/useProfile";
import {useFormik} from "formik";
import {createTypedModal} from "helpers/modal_helpers";
import {useTranslation} from "react-i18next";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import ValidatedInput from "Components/Common/ValidatedInput";
import * as Yup from "yup";
import {useCallback, useEffect, useState} from "react";
import {getTwoFactorSetup, enableTwoFactor} from "slices/account/thunk";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {TwoFactorEnableCommand} from "api/command";
import CopyWidget from "Components/Common/CopyWidget";

export const Enable2FAModal = createTypedModal<any>("enable_2fa");
const Enable2FA = () => {
  const {t} = useTranslation();
  const {open} = Enable2FAModal.useModal();
  const {userProfile} = useProfile();
  const dispatch = useDispatch();
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const accountSelector = createSelector([(state: RootState) => state.Account], (account) => ({
    loading: account.loading,
    twoFactorSetup: account.twoFactorSetup,
  }));

  const {loading, twoFactorSetup} = useSelector(accountSelector);
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      currentPassword: "",
      verificationCode: "",
    } as TwoFactorEnableCommand,
    validationSchema: Yup.object<TwoFactorEnableCommand>({
      currentPassword: Yup.string().required(t("Account.Security.Dialog.Enable2FA.Validation.CurrentPassword")),
      verificationCode: Yup.string().required(t("Account.Security.Dialog.Enable2FA.Validation.VerificationCode")),
    }),
    onSubmit: (values: TwoFactorEnableCommand) => {
      const verifyPromise = enableTwoFactor(values)(dispatch);
      verifyPromise.then((isSuccess) => {
        if (isSuccess) toggle();
      });
    },
  });

  const onLoad = useCallback(() => {
    if (open) {
      getTwoFactorSetup()(dispatch);
      const interval = setInterval(() => {
        getTwoFactorSetup()(dispatch);
      }, 300000); // 5 minutes

      setIntervalId(interval);
    } else {
      if (intervalId) {
        clearInterval(intervalId);
      }
    }
  }, [open]); // eslint-disable-line

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const toggle = () => {
    Enable2FAModal.close();
    validation.resetForm();
  };
  return (
    <>
      <Modal backdrop="static" isOpen={open} toggle={toggle} fade={true} centered={true} size="md">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <PlaceholderGlow busy={loading.twoFactorSetup}>
            <ModalHeader className="p-3" toggle={toggle}>
              {t("Account.Security.Dialog.Enable2FA.Title")}
            </ModalHeader>
            <ModalBody>
              <h6 className="fw-bold">{t("Account.Security.Dialog.Enable2FA.ScanQR.Title")}</h6>
              <p className="text-muted">{t("Account.Security.Dialog.Enable2FA.ScanQR.Description")}</p>
              {!loading.twoFactorSetup && <img src={twoFactorSetup?.qrCodeUri} alt="QR Code" className="img-fluid ms-n2" width={150} height={150} />}
              <Alert color="info" className="mt-3">
                <h6 className="fw-bold">{t("Account.Security.Dialog.Enable2FA.CantScan.Title")}</h6>
                <p className="mb-0 text-muted">
                  <span className="d-block">{t("Account.Security.Dialog.Enable2FA.CantScan.Description")}</span>
                  <span className="d-block mt-2">
                    <span className="fw-medium">{t("Account.Security.Dialog.Enable2FA.Label.Account")}:</span> sellthis.com:{userProfile?.email}
                  </span>
                  <span className="d-flex align-items-center gap-1">
                    <span className="fw-medium">{t("Account.Security.Dialog.Enable2FA.Label.Secret")}:</span>
                    <span className="text-muted">
                      <CopyWidget text={twoFactorSetup?.manualEntryKey} index={twoFactorSetup?.manualEntryKey} />
                    </span>
                  </span>
                </p>
              </Alert>
              <Row className="flex-column gap-3">
                <Col xs={12}>
                  <Label>{t("Account.Security.Dialog.Enable2FA.Label.CurrentPassword")}</Label>
                  <ValidatedInput validation={validation} field={"currentPassword"} placeholder={t("Account.Security.Dialog.Enable2FA.Label.CurrentPassword")} type="password" disableValidationUI />
                </Col>

                <Col xs={12}>
                  <Label>{t("Account.Security.Dialog.Enable2FA.Label.VerificationCode")}</Label>
                  <ValidatedInput validation={validation} field={"verificationCode"} placeholder={t("Account.Security.Dialog.Enable2FA.Label.6DigitCode")} maxLength={6} disableValidationUI />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <div className="hstack gap-2 justify-content-end">
                <Button type="button" className="btn btn-light" onClick={toggle} disabled={loading.twoFactorEnable}>
                  {t("Close")}
                </Button>
                <Button
                  type="submit"
                  disabled={loading.twoFactorEnable}
                  onClick={() => {
                    validation.setFieldTouched("currentPassword");
                    validation.setFieldTouched("verificationCode");

                    if (validation.isValid) {
                      validation.handleSubmit();
                    }
                  }}
                >
                  {t("Account.Security.Dialog.Enable2FA.Button.Submit")}
                </Button>
              </div>
            </ModalFooter>
          </PlaceholderGlow>
        </Form>
      </Modal>
    </>
  );
};

export default Enable2FA;
