import {APIClient} from "helpers/api_helper";
import * as url from "../helpers/url_helper";
import {
  SaveNewUserShipmentCommand,
  EditUserShipmentCommand,
  AddUserShipmentItemCommand,
  DeleteUserShipmentItemCommand,
  RefreshUserShipmentItemCommand,
  AddToAmazonInventoryCommand,
  ChangeUserShipmentStatusCommand,
  ImplementUserShipmentSettingsCommand,
  UpdateUserShipmentItemCommand,
  UpdateUserShipmentFinanceCommand,
} from "api/command";
import {ChangeShipmentVisibilityCommand, DeleteUserShipmentCommand} from "api/command";
import {GetUserDraftShipmentsQuery, GetUserShipmentDetailsQuery, UserShipmentsQuery} from "api/query";

const api = new APIClient();

export const postGetUserShipments = (data: UserShipmentsQuery) => api.create(url.POST_GET_USER_SHIPMENTS, data);

export const postGetUserDraftShipments = (data: GetUserDraftShipmentsQuery) => api.create(url.POST_GET_USER_DRAFT_SHIPMENTS, data);

export const postSaveNewUserShipment = (data: SaveNewUserShipmentCommand) => api.create(url.POST_SAVE_NEW_USER_SHIPMENT, data);

export const postEditUserShipment = (data: EditUserShipmentCommand) => api.create(url.POST_EDIT_USER_SHIPMENT, data);

export const postDeleteUserShipment = (data: DeleteUserShipmentCommand) => api.create(url.POST_DELETE_USER_SHIPMENT, data);

export const postGetUserShipmentDetails = (data: GetUserShipmentDetailsQuery) => api.create(url.POST_GET_USER_SHIPMENT_DETAILS, data);

export const postChangeUserShipmentStatus = (data: ChangeUserShipmentStatusCommand) => api.create(url.POST_CHANGE_USER_SHIPMENT_STATUS, data);

export const postAddUserShipmentItem = (data: AddUserShipmentItemCommand) => api.create(url.POST_ADD_USER_SHIPMENT_ITEM, data);

export const postDeleteUserShipmentItem = (data: DeleteUserShipmentItemCommand) => api.create(url.POST_DELETE_USER_SHIPMENT_ITEM, data);

export const postRefreshUserShipmentItem = (data: RefreshUserShipmentItemCommand) => api.create(url.POST_REFRESH_USER_SHIPMENT_ITEM, data);

export const postAddToAmazonInventory = (data: AddToAmazonInventoryCommand) => api.create(url.POST_ADD_TO_AMAZON_INVENTORY, data);

export const postImplementUserShipmentSettings = (data: ImplementUserShipmentSettingsCommand) => api.create(url.POST_IMPLEMENT_USER_SHIPMENT_SETTINGS, data);

export const postUpdateUserShipmentItem = (data: UpdateUserShipmentItemCommand) => api.create(url.POST_UPDATE_USER_SHIPMENT_ITEM, data);

export const postUpdateUserShipmentFinance = (data: UpdateUserShipmentFinanceCommand) => api.create(url.POST_UPDATE_USER_SHIPMENT_FINANCE, data);

export const postChangeShipmentVisibility = (data: ChangeShipmentVisibilityCommand) => api.create(url.POST_CHANGE_SHIPMENT_VISIBILITY, data);
