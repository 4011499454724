import React, {useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Card, CardBody, CardHeader, CardTitle, Col, Row} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import CopyWidget from "Components/Common/CopyWidget";
import {ColumnDef} from "@tanstack/react-table";
import DataTable, {DataTableRef} from "Components/Common/DataTable";
import DisplayPrice from "Components/Common/DisplayPrice";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import {UserOrderItem} from "models/user_order_item";
import {OrderStatus} from "models/enums/order_status";
import {FinanceStatus} from "models/user_order";
import {AllMarketplaces} from "helpers/marketplace_helper";

export type OrderItemListRef = {
  reload: VoidFunction;
};
const Content = () => {
  const {t} = useTranslation();
  const tableRef = useRef<DataTableRef>(null);

  const ordersData = createSelector(
    (state: RootState) => state,
    (state) => ({
      order: state.OrderDetails.order,
      loading: state.OrderDetails.loading,
    }),
  );
  const {loading, order} = useSelector(ordersData);

  const columns = useMemo<ColumnDef<UserOrderItem>[]>(
    () => [
      {
        header: t("OrderDetails.Card.Content.Product"),
        size: 400,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserOrderItem;
          const imagePath = `https://m.media-amazon.com/images/I/${row.userInventory.imageBig}`;
          const [image, setImage] = useState<any>(imagePath); // eslint-disable-line
          return (
            <>
              <Row>
                <Col xs="auto">
                  <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center avatar-lg rounded flex-shrink-0 overflow-hidden">
                      {image ? (
                        <img className="rounded-circle w-100" src={image} alt="" onError={() => setImage(null)} />
                      ) : (
                        <div className="img-thumbnail flex-shrink-0 h-100 avatar-title text-uppercase border bg-light text-primary">
                          <i className=" ri-image-line fs-18"></i>
                        </div>
                      )}
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <div title={row.userInventory.productName} className="ellipsis-two-lines">
                        {row.userInventory.productName}
                      </div>
                      <div className="mt-2 text-nowrap">
                        <span className="fw-medium">{t("OrderDetails.Card.Content.OrderItemId")}:</span> {row.orderItemId}
                      </div>
                      <div className="d-flex flex-row align-items-center">
                        <span className="fw-medium me-1">{t("OrderDetails.Card.Content.Asin")}:</span>
                        <CopyWidget text={row.asin} index={row.asin} asLink={true} marketplace={order.userStore.marketplace} />
                      </div>
                      <div className="d-flex flex-row align-items-center">
                        <span className="fw-medium me-2">{t("OrderDetails.Card.Content.SKU")}:</span>
                        <CopyWidget text={row.sellerSKU} index={row.sellerSKU} />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          );
        },
      },
      {
        header: t("OrderDetails.Card.Content.TableColumn.Quantity"),
        size: 80,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserOrderItem;
          return (
            <div className="my-1 align-items-center d-flex fw-medium">
              {row.quantityOrdered}
              <i className="ri-bar-chart-fill text-success ms-1"></i>
            </div>
          );
        },
      },
      {
        header: t("OrderDetails.Card.Content.TableColumn.UnitPrice"),
        size: 80,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserOrderItem;
          const currency = AllMarketplaces.find((info) => info.marketplace === order.userStore.marketplace)?.currency;
          return order.financeStatus !== FinanceStatus.Completed && (order.orderStatus === OrderStatus.SHIPPED || order.orderStatus === OrderStatus.PENDING) ? (
            <>
              <div className="bg-body-tertiary p-2 me-3" id={`MissingFinancialDataTooltip-Order-${row.orderItemId}`}></div>
              <DefaultUncontrolledTooltip target={`MissingFinancialDataTooltip-Order-${row.orderItemId}`}>{t("Orders.Warnings.MissingFinancialData.Description")}</DefaultUncontrolledTooltip>
            </>
          ) : (
            <DisplayPrice source={currency} value={row.salesProceedsPerUnit} decimals={2} notation="decimal" />
          );
        },
      },
      {
        header: t("OrderDetails.Card.Content.TableColumn.Total"),
        size: 80,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserOrderItem;
          const currency = AllMarketplaces.find((info) => info.marketplace === order.userStore.marketplace)?.currency;
          return order.financeStatus !== FinanceStatus.Completed && (order.orderStatus === OrderStatus.SHIPPED || order.orderStatus === OrderStatus.PENDING) ? (
            <>
              <div className="bg-body-tertiary p-2 me-3" id={`MissingFinancialDataTooltip-Order-${row.orderItemId}`}></div>
              <DefaultUncontrolledTooltip target={`MissingFinancialDataTooltip-Order-${row.orderItemId}`}>{t("Orders.Warnings.MissingFinancialData.Description")}</DefaultUncontrolledTooltip>
            </>
          ) : (
            <DisplayPrice source={currency} value={row.totalSalesProceeds} decimals={2} notation="decimal" />
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, order],
  );

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <CardTitle className="fw-medium mb-0">{t("OrderDetails.Card.Content.Title")}</CardTitle>
        </CardHeader>
        <CardBody>
          <DataTable ref={tableRef} busy={loading.list} columns={columns} data={order.userOrderItems || []} totalDataLength={order.userOrderItems?.length} />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Content;
