import {Card, CardBody} from "reactstrap";
import {useTranslation} from "react-i18next";
import {UserSearchProduct} from "models/user_search_product";
import {AmazonBusinessModel} from "models/enums/user_search_type";
import CheckListItem from "./Components/CheckListItem";

enum SameProductCheckResult {
  YES = 0,
  NO = 1,
}

enum CustomsCheckResult {
  NO = 0,
  YES = 1,
}

export type CheckListStatus = "success" | "danger" | "warning" | "muted";

interface CheckListProps {
  item: UserSearchProduct;
}

const CheckListItems = ({item}: CheckListProps) => {
  const {t} = useTranslation();

  let sameProductTooltip: string = "";
  let customsCheckTooltip: string = "";
  let productSizeEligibleToFbaTooltip: string = "";
  let isOldProductTooltip: string = "";
  let isTheRatingAverageGoodTooltip: string = "";

  let sameProductStatus: CheckListStatus = "muted";
  let customsCheckStatus: CheckListStatus = "muted";
  let productSizeEligibleToFbaStatus: CheckListStatus = "muted";
  let isOldProductStatus: CheckListStatus = "muted";
  let isTheRatingAverageGoodStatus: CheckListStatus = "muted";

  switch (item.checkList.sameProductCheckResult) {
    case SameProductCheckResult.YES:
      sameProductStatus = "success";
      sameProductTooltip = t("SearchResults.Analyzes.CheckList.NoProblem");
      break;

    case SameProductCheckResult.NO:
      sameProductStatus = "warning";
      sameProductTooltip = t("SearchResults.Analyzes.CheckList.SameProduct.Tooltip");
      break;

    default:
      sameProductStatus = "muted";
      sameProductTooltip = t("NA");
      break;
  }

  switch (item.checkList.customsCheckCategoryCheckResult) {
    case CustomsCheckResult.YES:
      customsCheckStatus = "warning";
      customsCheckTooltip = t("SearchResults.Analyzes.CheckList.CustomsCheckCategory.Tooltip", {value: item.checkList.customsCheckCategoryCheckReason});

      break;

    case CustomsCheckResult.NO:
      // Only set status to success if both category and keyword checks are NO
      if (item.checkList.customsCheckKeywordCheckResult === CustomsCheckResult.NO) {
        customsCheckStatus = "success";
        customsCheckTooltip = t("SearchResults.Analyzes.CheckList.NoProblem");
      }
      break;

    default:
      customsCheckStatus = "muted";
      customsCheckTooltip = t("NA");
      break;
  }

  // If keyword check is YES, override the status
  if (item.checkList.customsCheckKeywordCheckResult === CustomsCheckResult.YES) {
    customsCheckStatus = "warning";
    customsCheckTooltip = t("SearchResults.Analyzes.CheckList.CustomsCheckKeyword.Tooltip", {value: extractKeywords(item.checkList.customsCheckKeywordCheckReason).join(", ")});
  }

  switch (item.checkList.productSizeEligibleToFBA) {
    case true:
      productSizeEligibleToFbaStatus = "success";
      productSizeEligibleToFbaTooltip = t("SearchResults.Analyzes.CheckList.NoProblem");
      break;

    case false:
      productSizeEligibleToFbaStatus = "warning";
      productSizeEligibleToFbaTooltip = t("SearchResults.Analyzes.CheckList.ProductSizeEligibleToFba.Tooltip");
      break;

    default:
      productSizeEligibleToFbaStatus = "muted";
      productSizeEligibleToFbaTooltip = t("NA");
      break;
  }

  switch (item.checkList.isOldProduct) {
    case true:
      isOldProductStatus = "success";
      isOldProductTooltip = t("SearchResults.Analyzes.CheckList.NoProblem");
      break;

    case false:
      isOldProductStatus = "warning";
      isOldProductTooltip = t("SearchResults.Analyzes.CheckList.IsOldProductTooltip");
      break;

    default:
      isOldProductStatus = "muted";
      isOldProductTooltip = t("NA");
      break;
  }

  switch (item.checkList.isTheRatingAverageGood) {
    case true:
      isTheRatingAverageGoodStatus = "success";
      isTheRatingAverageGoodTooltip = t("SearchResults.Analyzes.CheckList.NoProblem");
      break;

    case false: 
      isTheRatingAverageGoodStatus = "warning";
      isTheRatingAverageGoodTooltip = t("SearchResults.Analyzes.CheckList.IsTheRatingAverageGood.Tooltip", {value: item.summary.reviewRating});
      break;

    default:
      isTheRatingAverageGoodStatus = "muted";
      isTheRatingAverageGoodTooltip = t("NA");
      break;
  }

  // Create old product label with time information
  const oldProductLabel =
    t("SearchResults.Analyzes.CheckList.IsOldProduct") +
    (item.checkList?.productExistSince
      ? " (" +
        item.checkList.productExistSince
          .replace("years", t("years"))
          .replace("year", t("year"))
          .replace("months", t("months"))
          .replace("month", t("month"))
          .replace("days", t("days"))
          .replace("day", t("day"))
          .replace("and", t("and"))
          .replace("ago", t("ago")) +
        ")"
      : "");

  return (
    <Card className="border card-animate mb-3">
      <CardBody>
        <ul className="list-unstyled vstack gap-1 mb-0">
          <h5>{t("SearchResults.Analyzes.CheckList.Title")}</h5>
          <CheckListItem
            labelName={t("SearchResults.Analyzes.CheckList.SameProduct.Title")}
            status={sameProductStatus}
            hide={item.amazonBusinessModel === AmazonBusinessModel.WHOLESALE}
            tooltipMessage={sameProductTooltip}
          />
          <CheckListItem
            labelName={t("SearchResults.Analyzes.CheckList.CustomsCheck.Title")}
            status={customsCheckStatus}
            hide={item.amazonBusinessModel === AmazonBusinessModel.WHOLESALE}
            tooltipMessage={customsCheckTooltip}
          />
          <CheckListItem
            labelName={t("SearchResults.Analyzes.CheckList.ProductSizeEligibleToFba.Title")}
            status={productSizeEligibleToFbaStatus}
            hide={item.amazonBusinessModel === AmazonBusinessModel.DROPSHIPPING}
            tooltipMessage={productSizeEligibleToFbaTooltip}
          />
          <CheckListItem labelName={oldProductLabel} status={isOldProductStatus} hide={item.amazonBusinessModel === AmazonBusinessModel.DROPSHIPPING} tooltipMessage={isOldProductTooltip} />
          <CheckListItem labelName={t("SearchResults.Analyzes.CheckList.IsTheRatingAverageGood.Title")} status={isTheRatingAverageGoodStatus} tooltipMessage={isTheRatingAverageGoodTooltip} />
        </ul>
      </CardBody>
    </Card>
  );
};

export default CheckListItems;

export function extractKeywords(input?: string): string[] {
  if (!input) return [];

  const values: string[] = [];
  const regex = /:\s*([^=>\n]+)/g;

  let match;
  while ((match = regex.exec(input)) !== null) {
    values.push(match[1].trim());
  }

  return values;
}
