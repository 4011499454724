import {useEffect} from "react";
import {Navigate} from "react-router-dom";
import {logoutUser, resetDashboardState, resetLoginState} from "../../slices/thunks";
import {useSelector, useDispatch} from "react-redux";
import {resetExistSubscription} from "slices/account/thunk";
import {resetSettings} from "slices/settings/thunk";
import {resetCommonState} from "slices/common/thunk";
import {LoginSlice} from "slices/auth/login/selector";

const Logout = () => {
  const dispatch = useDispatch();
  //const authChannel = new BroadcastChannel(BroadcastChannels.AUTH_CHANNEL);

  // Inside your component
  const {status} = useSelector(LoginSlice);

  useEffect(() => {
    logoutUser()(dispatch);
    resetExistSubscription()(dispatch);
    resetSettings()(dispatch);
    resetDashboardState()(dispatch);
    resetCommonState()(dispatch);
    resetLoginState()(dispatch);
    //authChannel.postMessage(null);
  }, [dispatch]); // eslint-disable-line

  if (status) {
    return <Navigate to="/account/login" />;
  }

  return <></>;
};

export default Logout;
