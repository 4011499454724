import {Navigate} from "react-router-dom";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Stores from "pages/Stores";
import Shipments from "pages/Shipments/Shipments";
import Register from "pages/Authentication/Register";
import RegistrationSuccessful from "pages/Authentication/RegistrationSuccessful";
import ForgotPassword from "pages/Authentication/ForgotPassword";
import ResetPassword from "pages/Authentication/ResetPassword";
import EmailVerification from "pages/Authentication/EmailVerification";
import NewSubscription from "pages/Account/NewSubscription";
import ChangeSubscription from "pages/Account/ChangeSubscription";
import LimitUsageHistory from "pages/Account/LimitUsageHistory";
import ExchangeRates from "pages/Settings/ExchangeRates";
import CostAndFees from "pages/Settings/CostAndFees";
import AnalyzeOptions from "pages/Settings/AnalyzeOptions";
import PaymentHistory from "pages/Account/PaymentHistory";
import ProfileInformation from "pages/Account/ProfileInformation";
import Subscription from "pages/Account/Subscription";
import Inventory from "pages/Inventory";
import Orders from "pages/Orders/Orders";
import OrderDetails from "pages/Orders/OrderDetails";
import NotificationSettings from "pages/Account/Notifications";
import Team from "pages/Account/Team";
import Searches from "pages/Searches/Searches";
import ScanAndSave from "pages/ScanAndSave";
import ShipmentDetails from "pages/Shipments/ShipmentDetails";
import Notifications from "pages/Notifications";
import Support from "pages/Account/Support";
import Dashboard from "pages/Dashboard";
import Users from "pages/Admin/Users";
import AdminScanAndSavePage from "pages/Admin/ScanAndSave";
import AdminSearchesPage from "pages/Admin/Searches";
import AdminSearchLogs from "pages/Admin/SearchLogs";
import GettingStarted from "pages/Account/NewSubscription/Page/GettingStarted";
import AdminSystemMonitoring from "pages/Admin/SystemMonitoring";
import SearchResults from "pages/Searches/SearchResults";
import ProductDetails from "pages/ProductDetails";
import SubscriptionRedirect from "pages/Account/NewSubscription/Page/SubscriptionRedirect";
import NotesPage from "pages/Notes";
import Favorites from "pages/Favorites";
import Verify2FA from "pages/Authentication/Verify2FA";
import Security from "pages/Account/Security";
import VerticalLayout from "layouts/VerticalLayout";
import NonAuthLayout from "layouts/NonAuthLayout";
import CancelSubscriptionPage from "pages/Account/CancelSubscription";

export interface RouteConfig {
  path: string;
  component: React.ReactNode;
  layout?: React.ComponentType<any>;
}

const userRoutes: RouteConfig[] = [
  {path: "/account/subscription/new", component: <NewSubscription />},
  {path: "/account/profile", component: <ProfileInformation />, layout: VerticalLayout},
  {path: "/account/security", component: <Security />, layout: VerticalLayout},
  {path: "/account/subscription", component: <Subscription />, layout: VerticalLayout},
  {path: "/account/subscription/change", component: <ChangeSubscription />, layout: VerticalLayout},
  {path: "/account/subscription/cancel", component: <CancelSubscriptionPage />, layout: VerticalLayout},
  {path: "/account/team", component: <Team />, layout: VerticalLayout},
  {path: "/account/payment-history", component: <PaymentHistory />, layout: VerticalLayout},
  {path: "/account/notifications", component: <NotificationSettings />, layout: VerticalLayout},
  {path: "/account/limit-usage-history", component: <LimitUsageHistory />, layout: VerticalLayout},
  {path: "/account/settings", component: <CostAndFees />, layout: VerticalLayout},
  {path: "/account/settings/cost-and-fees", component: <CostAndFees />, layout: VerticalLayout},
  {path: "/account/settings/cost-and-fees/edit/:userSettingCostAndFeeOptionId", component: <CostAndFees edit />, layout: VerticalLayout},
  {path: "/account/settings/analyze-options", component: <AnalyzeOptions />, layout: VerticalLayout},
  {path: "/account/settings/analyze-options/edit/:userSettingAnalyzeOptionId", component: <AnalyzeOptions edit />, layout: VerticalLayout},
  {path: "/account/settings/exchange-rates", component: <ExchangeRates />, layout: VerticalLayout},
  {path: "/account/support", component: <Support />, layout: VerticalLayout},
  {path: "/stores/", component: <Stores />, layout: VerticalLayout},
  {path: "/stores/connect", component: <Stores connect />, layout: VerticalLayout},
  {path: "/stores/:status", component: <Stores />, layout: VerticalLayout},
  {path: "/searches", component: <Searches />, layout: VerticalLayout},
  {path: "/searches/new/bulk", component: <Searches bulkSearch />, layout: VerticalLayout},
  {path: "/searches/search-results/:searchId", component: <SearchResults />, layout: VerticalLayout},
  {path: "/favorites", component: <Favorites />, layout: VerticalLayout},
  {path: "/favorites/:favoriteId", component: <SearchResults />, layout: VerticalLayout},
  {path: "/notes", component: <NotesPage />, layout: VerticalLayout},
  {path: "/scan-and-save", component: <ScanAndSave />, layout: VerticalLayout},
  {path: "/scan-and-save/new", component: <ScanAndSave new />, layout: VerticalLayout},
  {path: "/shipments", component: <Shipments />, layout: VerticalLayout},
  {path: "/shipments/shipment-details/:userShipmentId", component: <ShipmentDetails />, layout: VerticalLayout},
  {path: "/inventory", component: <Inventory />, layout: VerticalLayout},
  {path: "/inventory/updateInventory", component: <Inventory updateInventory />, layout: VerticalLayout},
  {path: "/orders", component: <Orders />, layout: VerticalLayout},
  {path: "/orders/:amazonOrderId", component: <OrderDetails />, layout: VerticalLayout},
  {path: "/notifications", component: <Notifications />, layout: VerticalLayout},
  {path: "/getting-started", component: <GettingStarted />, layout: VerticalLayout},
  {path: "/subscription-redirect", component: <SubscriptionRedirect />, layout: VerticalLayout},
  {path: "/product-details/", component: <ProductDetails />, layout: VerticalLayout},
  {path: "/dp/:asin/:searchId", component: <ProductDetails />, layout: VerticalLayout},
  {path: "/index", component: <Dashboard />, layout: VerticalLayout},
  {path: "/dashboard", component: <Dashboard />, layout: VerticalLayout},
  {path: "*", component: <Navigate to="/dashboard" />, layout: VerticalLayout},
];

const adminRoutes: RouteConfig[] = [
  {path: "/admin/users", component: <Users />, layout: VerticalLayout},
  {path: "/admin/searches", component: <AdminSearchesPage />, layout: VerticalLayout},
  {path: "/admin/search-logs/:searchId", component: <AdminSearchLogs />, layout: VerticalLayout},
  {path: "/admin/scan-and-save", component: <AdminScanAndSavePage />, layout: VerticalLayout},
  {path: "/admin/system-monitoring", component: <AdminSystemMonitoring />, layout: VerticalLayout},
  {path: "/admin/system-monitoring/service-details/:serviceId", component: <AdminSystemMonitoring details />, layout: VerticalLayout},
  {path: "*", component: <Navigate to="/admin/system-monitoring" />, layout: VerticalLayout},
];
const publicRoutes: RouteConfig[] = [
  // Authentication Page
  {path: "/account/email-verification", component: <EmailVerification />, layout: NonAuthLayout},
  {path: "/account/reset-password", component: <ResetPassword />, layout: NonAuthLayout},
  {path: "/account/forgot-password", component: <ForgotPassword />, layout: NonAuthLayout},
  {path: "/account/register", component: <Register />, layout: NonAuthLayout},
  {path: "/account/registration-successful", component: <RegistrationSuccessful />, layout: NonAuthLayout},
  {path: "/account/logout", component: <Logout />, layout: NonAuthLayout},
  {path: "/account/login", component: <Login />, layout: NonAuthLayout},
  {path: "/account/verify-2fa", component: <Verify2FA />},
  {path: "/fast-search", component: <ProductDetails />, layout: NonAuthLayout},
];

const singleSearchRoutes = [{path: "/dp/:asin", component: <ProductDetails />}];

export {userRoutes, adminRoutes, publicRoutes, singleSearchRoutes};
