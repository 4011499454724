import DataTable, {DataTableRef} from "Components/Common/DataTable";
import EditNote, {EditNoteModal} from "pages/Notes/Modal/EditNote";
import {useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {Button, Card, CardBody, Container} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {useTranslation} from "react-i18next";
import {useProfile} from "Components/Hooks/useProfile";
import {ColumnDef} from "@tanstack/react-table";
import {Link} from "react-router-dom";
import {RootState} from "slices";
import {useImmer} from "use-immer";
import {useDebounce} from "Components/Hooks/useDebounce";
import {FieldConfig, useUrlQuery} from "Components/Hooks/useUrlQuery";
import {GetUserSearchNotesQuery} from "api/query";
import {UserSearchNote} from "models/user_search_note";
import {SingleSearchModal} from "Components/Common/Modals/SingleSearch";
import {getUserNotes, deleteNote} from "slices/notes/thunk";
import {ActiveMarketplaces} from "helpers/marketplace_helper";
import Loader from "Components/Common/Loader";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";
import BreadCrumb from "Components/Common/BreadCrumb";
import NoResult from "Components/Common/NoResult";
import DisplayDate from "Components/Common/DisplayDate";
import Filters from "./Filters";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";

export type NotesListRef = {
  reload: VoidFunction;
};
const NotesPage = () => {
  const {t} = useTranslation();
  const {userProfile, hasPermission} = useProfile();
  const [localQuery, updateLocalQuery] = useImmer<GetUserSearchNotesQuery>({
    action: "filtering",
    page: 1,
    pageSize: 10,
  });

  const tableRef = useRef<DataTableRef>(null);
  const listRef = useRef<NotesListRef>(null);
  const dispatch: any = useDispatch();

  let filterFields: FieldConfig<GetUserSearchNotesQuery>[] = [
    {field: "page", queryParam: "page", type: "number", defaultValue: localQuery.page},
    {field: "pageSize", queryParam: "pageSize", type: "number", defaultValue: localQuery.pageSize},
    {field: "sortBy", queryParam: "sortBy", type: "string", defaultValue: localQuery.sortBy},
    {field: "sortingOrder", queryParam: "sortingOrder", type: "number", defaultValue: localQuery.sortingOrder},
    {field: "action", queryParam: "action", type: "string", defaultValue: "filtering"},
  ];

  const debouncedLoadList = useDebounce(() => {
    getUserNotes(localQuery)(dispatch).then(() => {
      tableRef.current?.resetSelection();
    });
  }, 200);
  const {readQueryParams, updateQuery, searchParams} = useUrlQuery<GetUserSearchNotesQuery>(filterFields);

  useImperativeHandle(
    listRef,
    () => {
      return {
        reload: () => {
          debouncedLoadList();
        },
      };
    },
    [debouncedLoadList],
  );
  useEffect(() => {
    if (hasPermission("notes") && searchParams.has("action")) {
      const params = readQueryParams();
      updateLocalQuery(params as GetUserSearchNotesQuery);
      debouncedLoadList();
    }
  }, [debouncedLoadList, searchParams]); // eslint-disable-line

  const notesData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Notes.loading,
      list: state.Notes.list,
    }),
  );
  const {loading, list} = useSelector(notesData);

  const columns = useMemo<ColumnDef<UserSearchNote, any>[]>(
    () => [
      {
        header: t("Actions"),
        enableSorting: false,
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearchNote;
          const [isDeletingOrRestoring, setIsDeletingOrRestoring] = useState<boolean>(false); // eslint-disable-line

          const handleDeleteOrRestore = async () => {
            setIsDeletingOrRestoring(true);
            await deleteNote({userSearchId: row.userSearchId, asin: row.asin, deleted: !row.deleted}, row.asin)(dispatch);
            setIsDeletingOrRestoring(false);
          };

          return (
            <div className="d-flex align-items-center">
              <Button
                id={`ReAnalyze-${row.userSearchId}`}
                color="link"
                className="btn btn-ghost-secondary px-1 py-0 fs-18"
                onClick={() => {
                  SingleSearchModal.open({asin: row.asin});
                }}
              >
                <i className="mdi mdi-chart-timeline-variant-shimmer align-middle"></i>
              </Button>
              <DefaultUncontrolledTooltip target={`ReAnalyze-${row.userSearchId}`}>{t("Notes.Tooltip.Reanalyze")}</DefaultUncontrolledTooltip>

              <Button
                id={`EditNote-${row.userSearchId}`}
                color="link"
                className="btn btn-ghost-secondary px-1 py-0 fs-18"
                onClick={() => {
                  EditNoteModal.open({asin: row.asin, userSearchId: row.userSearchId, marketplaceTarget: row.marketplace, note: row});
                }}
              >
                <i className="ri-pencil-fill align-middle"></i>
              </Button>
              <DefaultUncontrolledTooltip target={`EditNote-${row.userSearchId}`}>{t("Notes.Tooltip.Edit")}</DefaultUncontrolledTooltip>

              <Button
                id={`DeleteNote-${row.userSearchNoteId}`}
                color={row.deleted ? "ghost-secondary" : "ghost-danger"}
                className="btn-link px-1 py-0 fs-18"
                onClick={handleDeleteOrRestore}
                disabled={isDeletingOrRestoring}
              >
                {row.deleted ? <i className="ri-arrow-go-back-line align-middle" /> : <i className="ri-delete-bin-fill align-middle"></i>}
              </Button>
              <DefaultUncontrolledTooltip target={`DeleteNote-${row.userSearchNoteId}`}>{row.deleted ? t("Notes.Tooltip.Restore") : t("Notes.Tooltip.Delete")}</DefaultUncontrolledTooltip>
            </div>
          );
        },
      },
      {
        header: t("Notes.TableColumn.ASIN"),
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearchNote;
          const marketplaceInfo = ActiveMarketplaces.find((mp) => mp.marketplace === row.marketplace);
          return (
            <>
              <Link to={`/searches/search-results/${row.userSearchId}?asin=${row.asin}`} target="_blank" className="link-secondary link-offset-2 text-decoration-underline me-1" role="button">
                {row.asin}
                <i className="ri-arrow-right-up-line"></i>
              </Link>
              <Link to={`https://www.amazon.${marketplaceInfo?.domain}/dp/${row.asin}?th=1&psc=1`} target="_blank">
                <i className="bx bxl-amazon label-icon align-middle fs-18"></i>
              </Link>
            </>
          );
        },
      },
      {
        header: t("Notes.TableColumn.Description"),
        size: 300,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearchNote;
          return <span className={`fw-medium ellipsis-two-lines`}>{row.note}</span>;
        },
      },
      {
        header: t("Notes.TableColumn.Marketplace"),
        enableSorting: true,
        accessorKey: "marketplace",
        size: 200,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearchNote;
          const marketplaceInfo = ActiveMarketplaces.find((mp) => mp.marketplace === row.marketplace);
          return (
            <>
              {marketplaceInfo && (
                <>
                  <span>
                    <span className="hstack gap-2">
                      <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                        <img src={marketplaceInfo.flag} alt="Country Flag" className="rounded-circle" />
                      </div>
                      {`${marketplaceInfo.countryName} (${marketplaceInfo.marketplace})`}
                    </span>
                  </span>
                </>
              )}
            </>
          );
        },
      },
      {
        header: t("Notes.TableColumn.CreateDate"),
        enableSorting: true,
        accessorKey: "createDate",
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearchNote;
          return (
            <>
              <DisplayDate id={`DisplayDateCreateDate-${row.userSearchNoteId}`} value={row.createDate} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} />
            </>
          );
        },
      },
      {
        header: t("Notes.TableColumn.UpdateDate"),
        enableSorting: true,
        accessorKey: "updateDate",
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserSearchNote;
          return (
            <>
              <DisplayDate id={`DisplayDateUpdateDate-${row.userSearchNoteId}`} value={row.updateDate} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} />
            </>
          );
        },
      },
    ],
    [t], // eslint-disable-line
  );

  document.title = t("PageTitles.Notes");

  return (
    <Restricted require={"notes"} read fallback={() => <Unauthorized />}>
      <div className="page-content">
        <Container fluid>
          <Restricted require="notes" update>
            <EditNote />
          </Restricted>

          <BreadCrumb title={t("Notes.Title")} menus={[{label: t("Notes.Title")}]} />
          <Filters fields={filterFields} />
          <Card>
            <CardBody>
              {loading.filter ? (
                <>
                  <Loader />
                </>
              ) : list.items && list.items.length > 0 ? (
                <>
                  <DataTable
                    ref={tableRef}
                    busy={loading.list}
                    columns={columns}
                    data={list?.items || []}
                    totalDataLength={list?.totalCount}
                    pagination={{
                      pageIndex: localQuery.page - 1,
                      pageSize: localQuery.pageSize,
                    }}
                    onPaginationChanged={(pagination) => {
                      updateQuery({...localQuery, page: pagination.pageIndex + 1, pageSize: pagination.pageSize, action: "paginating"});
                    }}
                    onSortingChanged={(sorting) => {
                      updateQuery({...localQuery, sortBy: sorting?.sortBy, sortingOrder: sorting?.sortingOrder, action: "sorting"});
                    }}
                    hovered
                  />
                </>
              ) : (
                <NoResult icon="mdi mdi-note-edit-outline" title={t("Notes.EmptyList.Title")} description={t("Notes.EmptyList.Description")} />
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </Restricted>
  );
};

export default NotesPage;
