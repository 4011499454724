import {UserSearchProduct} from "models/user_search_product";
import {Card, CardBody} from "reactstrap";
import PriceHistoryChart from "./Charts/PriceHistoryChart";
import {useCallback, useEffect, useState} from "react";
import {AllMarketplaces} from "helpers/marketplace_helper";

interface PriceHistoryProps {
  item: UserSearchProduct;
}
const PriceHistory = ({item}: PriceHistoryProps) => {
  const [currencyCode, setCurrencyCode] = useState<string | undefined>(undefined);
  const [currencySign, setCurrencySign] = useState<string | undefined>(undefined);

  const onLoad = useCallback(() => {
    if (item.priceAnalysis) {
      const mp = AllMarketplaces.find((m) => m.currency === item.currencyCodeTarget);
      setCurrencyCode(mp?.currency);
      setCurrencySign(mp?.priceSign);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <Card className="border card-animate mb-3">
      <CardBody>
        <PriceHistoryChart priceHistoryJSON={item.priceAnalysis?.priceHistoryJSON} currencyCode={currencyCode} currencySign={currencySign} />
      </CardBody>
    </Card>
  );
};

export default PriceHistory;
