import {ResponseModel} from "models/response_model";
import {apiError, deleteOrRestoreItem, loading, setList, updateItem} from "./reducer";
import {postDeleteUserFavoriteList, postGetUserFavorites, postEditUserFavoriteList, postChangeUserFavoriteListVisibility} from "services/search_service";
import {ChangeUserFavoriteListVisibilityCommand, DeleteUserFavoriteListCommand, EditUserFavoriteListCommand, UserFavorite} from "models/user_favorites";
import {generateError, renderSuccessToast} from "helpers/utilities";
import {PagedList} from "helpers/types";
import {GetUserFavoriteListQuery} from "api/query";
import i18n from "i18n";

export const getUserFavorites = (query: GetUserFavoriteListQuery) => async (dispatch: any) => {
  const loadingType = query.action === "filtering" ? "filter" : "list";
  try {
    dispatch(loading([loadingType, true]));
    const result: ResponseModel = await postGetUserFavorites(query);
    const pagedResult: PagedList<UserFavorite> = result.data;
    dispatch(setList(pagedResult));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading([loadingType, false]));
  }
};

export const deleteUserFavoriteList = (command: DeleteUserFavoriteListCommand, name: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["delete", true]));
    await postDeleteUserFavoriteList(command);
    dispatch(deleteOrRestoreItem(command));
    if (command.deleted) {
      renderSuccessToast(i18n.t("Favorites.Toast.FavoriteListDeleteSuccess", {value: name}));
    }
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["delete", false]));
  }
};

export const updateFavoriteList = (command: EditUserFavoriteListCommand) => async (dispatch: any) => {
  try {
    dispatch(loading(["update", true]));
    const response:ResponseModel = await postEditUserFavoriteList(command);
    const updatedItem: UserFavorite = response.data;
    dispatch(updateItem(updatedItem));
    renderSuccessToast(i18n.t("Favorites.Toast.FavoriteListUpdateSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["update", false]));
  }
};


export const changeFavoriteItemVisibility = (command: ChangeUserFavoriteListVisibilityCommand) => async (dispatch: any) => {
  try {
    const response:ResponseModel = await postChangeUserFavoriteListVisibility(command);
    const updatedItem: UserFavorite = response.data;
    dispatch(updateItem(updatedItem));
    renderSuccessToast(i18n.t("Common.Dialog.SelectVisibility.Toast.AccessSettingsUpdated"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    return false;
  }
};