import DisplayPrice from "Components/Common/DisplayPrice";
import _ from "lodash";
import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Col, Row} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";

interface StatsProps {
  busy: boolean;
}

const Stats = (props: StatsProps) => {
  const {t} = useTranslation();
  const orderStatsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      stats: state.Orders.stats,
    }),
  );
  const {stats} = useSelector(orderStatsData);

  return (
    <React.Fragment>
      <Row className="g-4 mb-4">
        <Col xs={12} sm={6} lg>
          <div className={`stats-card success-card ${props.busy ? "placeholder w-100" : ""}`}>
            <div className="card-body">
              <h6 className="stats-title">{t("Orders.Stats.TotalSales")}</h6>
              <h3 className="stats-value">
                <DisplayPrice source="USD" value={stats.totalSales} decimals={2} notation="abbreviated" />
              </h3>
              <div className="stats-icon">
                <i className="ri-money-dollar-circle-line"></i>
              </div>
            </div>
          </div>
        </Col>

        <Col xs={12} sm={6} lg>
          <div className={`stats-card primary-card ${props.busy ? "placeholder w-100" : ""}`}>
            <div className="card-body">
              <h6 className="stats-title">{t("Orders.Stats.TotalProfit")}</h6>
              <h3 className="stats-value">
                <DisplayPrice source="USD" value={stats.totalProfit} decimals={2} notation="abbreviated" />
              </h3>
              <div className="stats-icon">
                <i className="ri-line-chart-line"></i>
              </div>
            </div>
          </div>
        </Col>

        <Col xs={12} sm={6} lg>
          <div className={`stats-card info-card ${props.busy ? "placeholder w-100" : ""}`}>
            <div className="card-body">
              <h6 className="stats-title">{t("Orders.Stats.AvgMargin")}</h6>
              <h3 className="stats-value">{t("PercentSign", {value: _.toInteger(stats.avgMargin)})}</h3>
              <div className="stats-icon">
                <i className="ri-bar-chart-grouped-fill"></i>
              </div>
            </div>
          </div>
        </Col>

        <Col xs={12} sm={6} lg>
          <div className={`stats-card secondary-card ${props.busy ? "placeholder w-100" : ""}`}>
            <div className="card-body">
              <h6 className="stats-title">{t("Orders.Stats.AvgRoi")}</h6>
              <h3 className="stats-value">{t("PercentSign", {value: _.toInteger(stats.avgRoi)})}</h3>
              <div className="stats-icon">
                <i className="ri-bar-chart-grouped-fill"></i>
              </div>
            </div>
          </div>
        </Col>

        <Col xs={12} sm={6} lg>
          <div className={`stats-card info-card ${props.busy ? "placeholder w-100" : ""}`}>
            <div className="card-body">
              <h6 className="stats-title">{t("Orders.Stats.TotalOrder")}</h6>
              <h3 className="stats-value">{_.toInteger(stats.totalOrder)}</h3>
              <div className="stats-icon">
                <i className="ri-file-list-3-line"></i>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Stats;
