import React, {useEffect, useImperativeHandle, useRef, useState} from "react";
import {Button, Col, Dropdown, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useImmer} from "use-immer";
import {useDebounce} from "Components/Hooks/useDebounce";
import {getUserWebNotificationsForDropdown, markAllAsRead, readUserWebNotification} from "slices/notifications/thunk";
import {DataTableRef} from "./DataTable";
import {WebNotificationFilter, WebNotificationQuery, WebNotification} from "models/web_notification";
import {NotificationsSlice} from "slices/notifications/selector";
import Moment from "react-moment";
import PlaceholderGlow from "./PlaceholderGlow";
import SimpleBar from "simplebar-react";
import bell from "assets/images/svg/bell.svg";
import classnames from "classnames";
import i18n from "i18n";

const TAB_ALL: WebNotificationFilter = "all";
const TAB_UNREAD: WebNotificationFilter = "unread";
export type NotificationDropdownListRef = {
  reload: VoidFunction;
};

const NotificationDropdown = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState<WebNotificationFilter>(TAB_UNREAD);
  const tableRef = useRef<DataTableRef>(null);
  const listRef = useRef<NotificationDropdownListRef>(null);
  const dispatch = useDispatch();
  const {loading, unreadCount, userWebNotificationsForDropdown} = useSelector(NotificationsSlice);

  useEffect(() => {
    updateQuery((q) => {
      q.filter = selectedTab;
      q.page = 1;
      q.pageSize = 8;
    });
  }, [selectedTab]); // eslint-disable-line

  const [query, updateQuery] = useImmer<WebNotificationQuery>({
    page: 1,
    pageSize: 8,
    filter: TAB_UNREAD,
  });

  const debouncedLoadList = useDebounce(() => {
    getUserWebNotificationsForDropdown(query)(dispatch).then(() => {
      tableRef.current?.resetSelection();
    });
  }, 200);

  useEffect(() => {
    debouncedLoadList();
  }, [debouncedLoadList, query, t]);

  useImperativeHandle(
    listRef,
    () => {
      return {
        reload: () => {
          debouncedLoadList();
        },
      };
    },
    [debouncedLoadList],
  );

  const toggleTab = (tab: any) => {
    if (selectedTab !== tab) {
      setSelectedTab(tab);
    }
  };

  const handleReadAndRedirect = (row: WebNotification) => {
    if (!row.isRead) {
      readUserWebNotification(row.notificationHistoryId)(dispatch);
    }

    if (row.link) {
      navigate(row.link);
    }
    setIsDropdownOpen(false);
  };

  return (
    <React.Fragment>
      <Dropdown isOpen={isDropdownOpen} toggle={() => setIsDropdownOpen(!isDropdownOpen)} className="topbar-head-dropdown ms-1 header-item">
        <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
          <i className="bx bx-bell fs-22"></i>
          {unreadCount > 0 && (
            <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
              {unreadCount > 99 ? "99+" : unreadCount}
              <span className="visually-hidden">{t("Notifications.Dropdown.Label.UnreadMessages")}</span>
            </span>
          )}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="dropdown-head bg-info-subtle bg-pattern rounded-top">
            <div className="px-3 pt-2">
              <Row className="align-items-center mb-2">
                <Col className="d-flex justify-content-between">
                  <h6 className="m-0 mt-1 fs-16 fw-semibold">{t("Notifications.Dropdown.Title")}</h6>
                  {unreadCount > 0 && (
                    <Button
                      color="secondary"
                      className="btn-sm rounded-pill"
                      disabled={loading.markAllRead}
                      onClick={() => {
                        markAllAsRead()(dispatch);
                      }}
                    >
                      {t("Notifications.Label.MarkAllAsRead", {count: unreadCount})}
                    </Button>
                  )}
                  <Link to="/account/notifications" className="link-dark fs-18 " onClick={() => setIsDropdownOpen(false)}>
                    <i className="ri-settings-2-line align-middle"></i>
                  </Link>
                </Col>
              </Row>
            </div>

            <div className="px-2 pt-2">
              <Nav className="nav-tabs dropdown-tabs nav-border-top nav-border-top-secondary nav-tabs-notification-popup">
                <NavItem>
                  <NavLink
                    href="#"
                    className={`d-flex align-items-center ${classnames({active: selectedTab === TAB_UNREAD, "text-dark": true})}`}
                    onClick={() => {
                      toggleTab(TAB_UNREAD);
                    }}
                  >
                    {t("Notifications.Dropdown.Tab.Unread")}
                    <span className="badge rounded-pill bg-secondary ms-1">{unreadCount}</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({active: selectedTab === TAB_ALL, "text-dark": true})}
                    onClick={() => {
                      toggleTab(TAB_ALL);
                    }}
                  >
                    {t("Notifications.Dropdown.Tab.All")}
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>

          <TabContent activeTab={selectedTab}>
            <TabPane tabId={TAB_ALL} className="py-2 ps-2">
              <SimpleBar style={{maxHeight: "300px"}} className="pe-2">
                {userWebNotificationsForDropdown?.items?.map((item, key) => (
                  <div
                    key={key}
                    className={`text-reset notification-item d-block dropdown-item position-relative ${item.link ? "cursor-pointer" : ""} border-bottom mb-2 ${item.isRead ? "" : "active"}`}
                    onClick={() => {
                      handleReadAndRedirect(item);
                    }}
                  >
                    <PlaceholderGlow busy={loading.dropdown}>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <h6 className="mt-0 mb-1 fs-13 fw-semibold">{item.subject}</h6>
                          <div className="fs-13 text-muted">
                            <p className="mb-1">{item.body}</p>
                          </div>
                          <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                            <span>
                              <i className="mdi mdi-clock-outline"></i>{" "}
                              <Moment className="text-muted" locale={i18n.language} fromNow>
                                {item.sendDate}
                              </Moment>
                            </span>
                          </p>
                        </div>
                      </div>
                    </PlaceholderGlow>
                  </div>
                ))}
              </SimpleBar>
            </TabPane>
            <TabPane tabId={TAB_UNREAD} className="p-2">
              {unreadCount === 0 ? (
                <>
                  <div className="w-25 pt-3 mx-auto">
                    <img src={bell} className="" alt="bell" />
                  </div>
                  <div className="text-center pb-4 mt-2">
                    <h6 className="fs-14 lh-base">{t("Notifications.Dropdown.Label.NoNotification")}</h6>
                  </div>
                </>
              ) : (
                <>
                  <SimpleBar style={{maxHeight: "300px"}} className="pe-2">
                    {userWebNotificationsForDropdown &&
                      userWebNotificationsForDropdown.items?.map((item, key) => (
                        <div
                          key={key}
                          className={`text-reset notification-item d-block dropdown-item position-relative ${item.link ? "cursor-pointer" : ""} border-bottom mb-2 ${!item.isRead ? "active" : ""}`}
                          onClick={() => {
                            handleReadAndRedirect(item);
                          }}
                        >
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <Link to="#" className="stretched-link">
                                <h6 className="mt-0 mb-1 fs-13 fw-semibold">{item.subject}</h6>
                              </Link>
                              <div className="fs-13 text-muted">
                                <p className="mb-1">{item.body}</p>
                              </div>
                              <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                <span>
                                  <i className="mdi mdi-clock-outline me-1"></i>
                                  <Moment className="text-muted" locale={i18n.language} fromNow>
                                    {item.sendDate}
                                  </Moment>
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </SimpleBar>
                </>
              )}
            </TabPane>
            <div className="mb-2 text-center">
              <Button
                type="button"
                color="soft-success"
                className="rounded-pill btn-sm"
                onClick={() => {
                  navigate("/notifications?tab=all");
                  setIsDropdownOpen(!isDropdownOpen);
                }}
              >
                {t("Notifications.Dropdown.Label.ViewAll")}
                <i className="ri-arrow-right-line align-middle"></i>
              </Button>
            </div>
          </TabContent>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default NotificationDropdown;
