import {useProfile} from "Components/Hooks/useProfile";
import {ShareType} from "models/enums/share_type";
import {User} from "models/user";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ShareTypeBadge} from "./Badges/ShareTypeBadge";
import {HTMLAttributeAnchorTarget, useCallback, useEffect, useState} from "react";
import DefaultUncontrolledTooltip from "./DefaultUncontrolledTooltip";
import defaultAvatar from "assets/images/user-dummy-img.jpg";
import _ from "lodash";

interface ResourceLinkProps {
  userId: string;
  name: string;
  tooltipKey: string;
  maxLength: number;
  shareType: ShareType;
  id: string;
  href: string;
  target: HTMLAttributeAnchorTarget;
}

const ResourceLink = (props: ResourceLinkProps) => {
  const {userProfile} = useProfile();
  const {t} = useTranslation();
  const [teamMember, setTeamMember] = useState<User>();

  const showAvatar = (userProfile?.teamUsers != null && userProfile?.teamUsers?.length > 1) || userProfile?.subscription === "Premium";
  const truncatedName = _.truncate(props.name, {length: props.maxLength, omission: "..."});
  const tooltipId = `CreatedUserAvatarTooltip-${props.id}-${teamMember?.userId}`;
  const tooltipText = t(props.tooltipKey, {
    value: teamMember?.userId === userProfile?.userId ? t("You") : `${teamMember?.fullName} (${teamMember?.email})`,
  });

  const avatar = showAvatar && (
    <>
      <img id={tooltipId} src={teamMember?.userUiPreferences?.avatar || defaultAvatar} alt={`User Profile - ${teamMember?.fullName}`} className="rounded-circle me-1 border align-middle" style={{width: "16px", height: "16px"}} />
      <DefaultUncontrolledTooltip target={tooltipId}>{tooltipText}</DefaultUncontrolledTooltip>
    </>
  );

  const content = (
    <>
      {avatar}
      <span>{truncatedName}</span>
      {props.target === "_blank" && props.href && <i className="ri-arrow-right-up-line align-middle"></i>}
    </>
  );

  const onLoad = useCallback(() => {
    if (userProfile?.teamUsers != null && userProfile?.teamUsers?.length > 1) {
      const member = userProfile.teamUsers.find((user: User) => user.userId === props.userId);
      setTeamMember(member);
    }
  }, [userProfile, props.userId]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  if (props.href === "") {
    return (
      <>
        <span className="me-1">{content}</span>
        <ShareTypeBadge status={props.shareType} size="md" id={props.id} ownerUserId={props.userId} />
      </>
    );
  }

  return (
    <>
      <Link className="link-secondary link-offset-2 text-decoration-underline me-1" role="button" to={props.href} target={props.target}>
        {content}
      </Link>
      <ShareTypeBadge status={props.shareType} size="md" id={props.id} ownerUserId={props.userId} />
    </>
  );
};

export default ResourceLink;
