import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";

export type CheckListStatus = "success" | "danger" | "warning" | "muted";
interface CheckListItemProps {
  labelName: string;
  status: CheckListStatus;
  hide?: boolean;
  tooltipMessage?: string;
}
const CheckListItem = ({labelName, status, hide, tooltipMessage}: CheckListItemProps) => {
  if (hide) return null;

  const uniqueId = `Tooltip-${Math.random().toString(36).substring(2, 6)}`;

  const getStatusIcon = () => {
    switch (status) {
      case "success":
        return <i id={uniqueId} className="ri-checkbox-circle-fill fs-18 text-success"></i>;
      case "danger":
        return <i id={uniqueId} className="ri-close-circle-fill fs-18 text-danger"></i>;
      case "warning":
        return <i id={uniqueId} className="ri-error-warning-fill fs-18 text-warning"></i>;
      default:
        return <i id={uniqueId} className="ri-donut-chart-line fs-18 text-muted"></i>;
    }
  };

  return (
    <li>
      <div className="d-flex align-items-center">
        <div className="flex-shrink-0 me-1">{getStatusIcon()}</div>
        <div className="flex-grow-1">
          <h6 className={"mb-0" + (status === "muted" ? " text-muted " : "")}>{labelName}</h6>
        </div>
        {tooltipMessage && (
          <DefaultUncontrolledTooltip target={uniqueId} placement="top">
            {tooltipMessage}
          </DefaultUncontrolledTooltip>
        )}
      </div>
    </li>
  );
}

export default CheckListItem;
