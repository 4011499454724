import React, {useEffect, useState} from "react";
import OrdersSummaryChart from "./Charts/OrdersSummaryChart";
import {Card, CardBody, Col} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {DashboardSlice} from "slices/dashboard/selector";

const OrdersSummary = () => {
  const {t} = useTranslation();

  const [pending, setPending] = useState<number>(0);
  const [shipped, setShipped] = useState<number>(0);
  const [canceled, setCanceled] = useState<number>(0);

  const {statistics} = useSelector(DashboardSlice);

  useEffect(() => {
    if (statistics.orderSummary) {
      const pendingOrders = statistics.orderSummary?.pendingCount ?? 0;
      const canceledOrders = statistics.orderSummary?.canceledCount ?? 0;
      const shippedOrders = statistics.orderSummary?.shippedCount ?? 0;

      setPending(pendingOrders);
      setShipped(shippedOrders);
      setCanceled(canceledOrders);
    }
  }, [statistics]);

  const total = pending + shipped + canceled;

  const orderStats = [
    {
      title: t("OrderStatus.PENDING"),
      count: pending,
      percentage: (pending / total) * 100,
      icon: "mdi mdi-clock",
      color: "warning",
      bgColor: "warning-subtle",
    },
    {
      title: t("OrderStatus.SHIPPED"),
      count: shipped,
      percentage: (shipped / total) * 100,
      icon: "mdi mdi-truck-fast",
      color: "success",
      bgColor: "success-subtle",
    },
    {
      title: t("OrderStatus.CANCELED"),
      count: canceled,
      percentage: (canceled / total) * 100,
      icon: "mdi mdi-basket-remove",
      color: "danger",
      bgColor: "danger-subtle",
    },
  ];

  return (
    <React.Fragment>
      <Col xl={3} className="same-height">
        <Card className="card-animate">
          <div className="card-header align-items-center d-flex border-bottom-dashed">
            <h4 className="card-title mb-0 flex-grow-1">{t("Dashboard.OrdersSummary.Title")}</h4>
          </div>
          <CardBody>
            <div id="portfolio_donut_charts" dir="ltr">
              <OrdersSummaryChart pendingCount={pending} shippedCount={shipped} canceledCount={canceled} dataColors={["#f7b84b", "#0ab39c", "#f06548"]} />
            </div>

            <div className="pt-3">
              {orderStats.map((stat, index) => (
                <div key={index} className={`d-flex ${index !== orderStats.length - 1 ? "mb-4" : ""}`}>
                  <div className={`flex-shrink-0 avatar-sm bg-${stat.bgColor} rounded`}>
                    <div className="rounded text-center h-100 d-flex align-items-center justify-content-center">
                      <i className={`${stat.icon} text-${stat.color} fs-4`}></i>
                    </div>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <div className="d-flex align-items-center mb-2">
                      <h6 className="mb-0 flex-grow-1">{stat.title}</h6>
                      <span className="flex-shrink-0 fs-5 fw-semibold">{stat.count}</span>
                    </div>
                    <div className="progress progress-sm">
                      <div
                        className={`progress-bar bg-${stat.color}`}
                        role="progressbar"
                        style={{width: `${stat.percentage}%`}}
                        aria-valuenow={stat.percentage}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      ></div>
                    </div>
                    <p className="text-muted fs-12 mb-0 mt-2">{t("Dashboard.OrdersSummary.OfTotal", {value: stat.percentage.toFixed(0)})}</p>
                  </div>
                </div>
              ))}
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default OrdersSummary;
