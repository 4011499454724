import BreadCrumb, {BreadcrumbMenuItem} from "Components/Common/BreadCrumb";
import {useEffect} from "react";
import {useFormik} from "formik";
import {ExchangeOptionItem} from "models/user_setting_exchange_options";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Button, Card, CardBody, CardHeader, CardTitle, Col, Container, Form, FormFeedback, Input, InputGroup, InputGroupText, Label, Row, Spinner} from "reactstrap";
import {getExchangeRates, updateExchangeRates} from "slices/settings/thunk";
import {useProfile} from "Components/Hooks/useProfile";
import {ConstantPage} from "helpers/permission_helper";
import {generateRandomGuid} from "helpers/utilities";
import {SettingsSlice} from "slices/settings/selector";
import SettingsLayout from "Components/Common/Layout/SettingsLayout";
import Loader from "Components/Common/Loader";
import ErrorPopover from "Components/Common/ErrorPopover";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";
import * as Yup from "yup";

const PAGE_IDENTIFIER: ConstantPage = "settings.exchangeRates";

const ExchangeRates = () => {
  const {t} = useTranslation();
  const {hasPermission} = useProfile();
  const dispatch: any = useDispatch();

  useEffect(() => {
    if (hasPermission(PAGE_IDENTIFIER)) {
      dispatch(getExchangeRates());
    }
  }, [dispatch]); //eslint-disable-line

  const {loading, exchangeRates, defaultCurrencies} = useSelector(SettingsSlice);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      cad: exchangeRates?.find((x) => x.currencyCode === "CAD")?.rate || "",
      gbp: exchangeRates?.find((x) => x.currencyCode === "GBP")?.rate || "",
      aud: exchangeRates?.find((x) => x.currencyCode === "AUD")?.rate || "",
      mxn: exchangeRates?.find((x) => x.currencyCode === "MXN")?.rate || "",
      cadAuto: exchangeRates?.find((x) => x.currencyCode === "CAD")?.auto || false,
      gbpAuto: exchangeRates?.find((x) => x.currencyCode === "GBP")?.auto || false,
      audAuto: exchangeRates?.find((x) => x.currencyCode === "AUD")?.auto || false,
      mxnAuto: exchangeRates?.find((x) => x.currencyCode === "MXN")?.auto || false,
    },
    validationSchema: Yup.object({
      cad: Yup.number().when("cadAuto", ([cadAuto], schema) => {
        if (cadAuto) return schema.notRequired();
        else return schema.required(t("Settings.ExchangeRates.Validation.Currency", {value: "CAD"}));
      }),
      gbp: Yup.number().when("gbpAuto", ([gbpAuto], schema) => {
        if (gbpAuto) return schema.notRequired();
        else return schema.required(t("Settings.ExchangeRates.Validation.Currency", {value: "GBP"}));
      }),
      aud: Yup.number().when("audAuto", ([audAuto], schema) => {
        if (audAuto) return schema.notRequired();
        else return schema.required(t("Settings.ExchangeRates.Validation.Currency", {value: "AUD"}));
      }),
      mxn: Yup.number().when("mxnAuto", ([audAuto], schema) => {
        if (audAuto) return schema.notRequired();
        else return schema.required(t("Settings.ExchangeRates.Validation.Currency", {value: "MXN"}));
      }),
      cadAuto: Yup.boolean(),
      gbpAuto: Yup.boolean(),
      audAuto: Yup.boolean(),
      mxnAuto: Yup.boolean(),
    }),
    onSubmit: (values: any) => {
      var exchanges: ExchangeOptionItem[] = [];
      exchanges.push({currency: "CAD", marketplace: "CA", rate: values.cad, auto: values.cadAuto});
      exchanges.push({currency: "GBP", marketplace: "UK", rate: values.gbp, auto: values.gbpAuto});
      exchanges.push({currency: "AUD", marketplace: "AU", rate: values.aud, auto: values.audAuto});
      exchanges.push({currency: "MXN", marketplace: "MX", rate: values.mxn, auto: values.mxnAuto});

      if (validation.dirty) {
        const updatePromise = updateExchangeRates(exchanges)(dispatch);
        updatePromise.then((success) => {
          if (success) {
            validation.resetForm();
          }
        });
      }
    },
  });

  document.title = t("PageTitles.ExchangeRates");

  const breadcrumbMenus: BreadcrumbMenuItem[] = [
    {
      label: t("Settings.Title"),
      url: "#",
    },
    {
      label: t("Settings.ExchangeRates.Title"),
      url: "",
    },
  ];

  const currencies = [
    {code: "CAD", country: "Canada", flag: "🇨🇦"},
    {code: "GBP", country: "United Kingdom", flag: "🇬🇧"},
    {code: "AUD", country: "Australia", flag: "🇦🇺"},
    {code: "MXN", country: "Mexico", flag: "🇲🇽"},
  ];

  return (
    <Restricted require={PAGE_IDENTIFIER} read fallback={() => <Unauthorized />}>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Settings.Title")} menus={breadcrumbMenus} />
          <SettingsLayout hasAnyChanges={validation.dirty} navigateTo="">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Card>
                <CardHeader>
                  <CardTitle className="fw-medium mb-0 align-items-center d-flex">
                    <i className="bx bx-dollar fs-18 me-1"></i>
                    {t("Settings.ExchangeRates.Title")}
                  </CardTitle>
                </CardHeader>
              </Card>
              <Card>
                <CardBody>
                  {loading.list ? (
                    <Loader />
                  ) : (
                    <Row className="g-4">
                      {currencies.map((currency) => (
                        <Col xs={12} md={6} key={currency.code.toLowerCase()}>
                          <div className="exchange-rate-card p-3 rounded-3 border">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                              <div className="d-flex align-items-center">
                                <span className="fs-4 me-2">{currency.flag}</span>
                                <div>
                                  <h6 className="mb-0">
                                    {currency.country} <i className="text-muted small">{currency.code}</i>
                                  </h6>
                                </div>
                              </div>
                              <div className="form-check form-switch form-switch-right d-flex align-items-center">
                                <Label for={`${currency.code.toLowerCase()}Auto`} className="form-check-label user-select-none me-2">
                                  {t("Settings.ExchangeRates.Automatic")}
                                </Label>
                                <Input
                                  id={`${currency.code.toLowerCase()}Auto`}
                                  type="checkbox"
                                  role="switch"
                                  className="form-check-input"
                                  onChange={(e) => {
                                    validation.handleChange(e);
                                    validation.setFieldValue(currency.code.toLowerCase(), defaultCurrencies.find((x) => x.currencyCode === currency.code)?.rate);
                                  }}
                                  checked={validation.values[`${currency.code.toLowerCase()}Auto`]}
                                />
                              </div>
                            </div>
                            <InputGroup>
                              <InputGroupText className="bg-light">1 USD</InputGroupText>
                              <Input
                                id={currency.code.toLowerCase()}
                                type="number"
                                step="any"
                                className="form-control-lg"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values[currency.code.toLowerCase()]}
                                invalid={validation.touched[currency.code.toLowerCase()] && validation.errors[currency.code.toLowerCase()] ? true : false}
                                disabled={validation.values[`${currency.code.toLowerCase()}Auto`]}
                              />
                              <InputGroupText className="bg-light">{currency.code}</InputGroupText>
                            </InputGroup>
                            <FormFeedback>{validation.values[`${currency.code.toLowerCase()}Auto`] && validation.errors[currency.code.toLowerCase()]}</FormFeedback>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  )}
                </CardBody>
              </Card>
              <Row className="justify-content-end mt-4">
                <Col xs="auto">{Object.keys(validation.errors).length > 0 && <ErrorPopover key={generateRandomGuid()} validationErrors={validation.errors} />}</Col>
                <Col xs="auto">
                  <Button color="success" type="submit" disabled={loading.update || Object.keys(validation.errors).length > 0}>
                    {loading.update && <Spinner size="sm" className="me-2 align-middle"></Spinner>}
                    {t("Settings.ExchangeRates.Button.SaveChanges")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </SettingsLayout>
        </Container>
      </div>
    </Restricted>
  );
};

export default ExchangeRates;
