import {Routes, Route} from "react-router-dom";
import {userRoutes, adminRoutes, publicRoutes, singleSearchRoutes, RouteConfig} from "./allRoutes";
import {useProfile} from "Components/Hooks/useProfile";
import React, { memo, useMemo } from "react";
import ProtectedRoutes from "./_ProtectedRoutes";
import PublicRoutes from "./_PublicRoutes";
import SingleSearchRoutes from "./_SingleSearchRoutes";
import RouteWrapper from "./_RouterWrapper";

const RoutesComponent = () => {
  const {userProfile} = useProfile();
  const isTokenValid = useMemo(() => {
    return (userProfile && new Date(userProfile.tokenInfo.expiresAt) > new Date()) ?? false;
  }, [userProfile]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <React.Fragment>
      <Routes>
        <Route element={<PublicRoutes user={userProfile} isTokenValid={isTokenValid} />}>
          {publicRoutes.map((route: RouteConfig, idx: number) => (
            <Route key={`${route.path}-${idx}`} path={route.path} element={<RouteWrapper route={route} />} />
          ))}
        </Route>
          
        <Route element={<ProtectedRoutes/>}>
          {userRoutes.map((route: RouteConfig, idx: number) => (
            <Route key={`${route.path}-${idx}`} path={route.path} element={<RouteWrapper route={route} />} />
          ))}
          {adminRoutes.map((route: RouteConfig, idx: number) => (
            <Route key={`${route.path}-${idx}`} path={route.path} element={<RouteWrapper route={route} />} />
          ))}
        </Route>

        <Route element={<SingleSearchRoutes user={userProfile} />}>
          {singleSearchRoutes.map((route: RouteConfig, idx: number) => (
            <Route key={`${route.path}-${idx}`} path={route.path} element={<RouteWrapper route={route} />} />
          ))}
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default memo(RoutesComponent);
