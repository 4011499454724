import {getToday, preventScrollUp} from "helpers/utilities";
import {useCallback, useEffect, useMemo, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {CardHeader, Row, Col, Button, Badge} from "reactstrap";
import {UserSearchProduct} from "models/user_search_product";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useProfile} from "Components/Hooks/useProfile";
import {SummaryCard} from "./Components/SummaryCard";
import {addFavoriteItem, markSearchProductReviewed, removeFavoriteItem} from "slices/search-result/thunk";
import {AmazonBusinessModel} from "models/enums/user_search_type";
import {AnalyzeType, ReAnalyzeModal} from "Components/Common/Modals/ReAnalyze";
import {DecisionReasonsModal} from "../Modals/DecisionReasons";
import {AddToFavoritesModal} from "../Modals/AddToFavorites";
import {IneligibilityReasonListModal} from "../Modals/IneligibilityReasonList";
import {CheckListModal} from "../Modals/CheckList";
import {DecisionReasonsLink} from "./Components/DecisionReasonsLink";
import {SellThisModal} from "Components/Common/SellThis";
import {BusinessModelBadge} from "Components/Common/Badges/BusinessModelBadge";
import {NotesModal} from "../Modals/Notes";
import {AmazonMarketplace} from "helpers/marketplace_helper";
import {ReportErrorModal} from "../Modals/ReportError";
import {SearchResultSlice} from "slices/search-result/selector";
import {AddToUserFavoriteListCommand, RemoveFromUserFavoriteListCommand, UserFavorite} from "models/user_favorites";
import {ProductDetailsSlice} from "slices/product-details/selector";
import {ShareType} from "models/enums/share_type";
import CopyWidget from "Components/Common/CopyWidget";
import DomainToFlag from "Components/Common/DomainToFlag";
import Rating from "react-rating";
import moment from "moment";
import logoDarkTransparent from "assets/images/logo-dark-transparent.png";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import DisplayPrice from "Components/Common/DisplayPrice";
import classNames from "classnames";
import DisplayDate from "Components/Common/DisplayDate";
import DisplayNumber from "Components/Common/DisplayNumber";
import _ from "lodash";
import ImageLoader from "Components/Common/ImageLoader";

interface SummaryProps {
  item: UserSearchProduct;
  sourceMarketplace?: AmazonMarketplace;
}
const Summary = ({item, sourceMarketplace}: SummaryProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {userProfile} = useProfile();
  const {favoriteId} = useParams();
  const [addingOrRemovingFromFavorites, setAddingOrRemovingFromFavorites] = useState<boolean>(false);
  const [markReviewLoading, setMarkReviewLoading] = useState<boolean>(false);
  const [isInUserShipment, setIsInUserShipment] = useState<boolean>(false);
  const [decision, setDecision] = useState<string | undefined>();
  const [decisionReasons, setDecisionReasons] = useState<string | undefined>();
  const [variationLevel, setVariationLevel] = useState<null | "Low" | "High">();
  const [itemFavoriteList, setItemFavoriteList] = useState<UserFavorite>();

  const {loading: productDetailsLoading} = useSelector(ProductDetailsSlice);
  const {result, currentFavoriteList} = useSelector(SearchResultSlice);

  const {showCheckList, search, notes} = result;
  const itemNote = useMemo(() => notes?.find((note) => note.asin === item.asin), [notes, item.asin]);

  const handleAddToFavoriteList = async () => {
    preventScrollUp();
    const {asin, marketplaceTarget} = item;
    const favoriteListId = favoriteId || currentFavoriteList?.userFavoriteId;

    if (itemFavoriteList) {
      setAddingOrRemovingFromFavorites(true);
      const removePayload: RemoveFromUserFavoriteListCommand = {
        userFavoriteId: itemFavoriteList.userFavoriteId,
        asin: asin,
        marketplace: marketplaceTarget,
        userSearchProductId: item.userSearchProductId,
      };
      await removeFavoriteItem(removePayload, true, "SearchResult")(dispatch);
      setAddingOrRemovingFromFavorites(false);
    } else if (favoriteListId) {
      setAddingOrRemovingFromFavorites(true);
      const addPayload: AddToUserFavoriteListCommand = {userFavoriteId: favoriteListId, asin: asin, marketplace: marketplaceTarget, userSearchProductId: item.userSearchProductId};
      await addFavoriteItem(addPayload, true, "SearchResult")(dispatch);
      setAddingOrRemovingFromFavorites(false);
    } else {
      AddToFavoritesModal.open({userSearchProductId: item.userSearchProductId, asin, marketplaceTarget});
    }
  };

  const handleNotes = () => {
    NotesModal.open({asin: item.asin, userSearchId: item.userSearchId, marketplaceTarget: item.marketplaceTarget, note: itemNote, from: "SearchResult"});
  };

  const handleMarkAsReviewed = async () => {
    setMarkReviewLoading(true);
    if (item.isReviewed === true) {
      await markSearchProductReviewed({userSearchId: item.userSearchId, userSearchProductId: item.userSearchProductId, isReviewed: false}, "SearchResult")(dispatch);
    } else {
      await markSearchProductReviewed({userSearchId: item.userSearchId, userSearchProductId: item.userSearchProductId, isReviewed: true}, "SearchResult")(dispatch);
    }
    setMarkReviewLoading(false);
  };

  const onLoad = useCallback(() => {
    if (item.summary) {
      if (item.amazonBusinessModel === AmazonBusinessModel.DROPSHIPPING) {
        setDecision(item.summary.decisionDS);
        setDecisionReasons(item.summary.decisionReasonsDS ? item.summary.decisionReasonsDS : undefined);
        setVariationLevel(item.variationAnalysis.variationLevelDS);
      } else {
        setDecision(item.summary.decision);
        setDecisionReasons(item.summary.decisionReasons ? item.summary.decisionReasons : undefined);
        setVariationLevel(item.variationAnalysis.variationLevel);
      }
    }
  }, [item.summary]); // eslint-disable-line

  const onShipmentStatusChange = useCallback(
    () => {
      if (!_.isEmpty(result.shipmentInfo)) {
        let isExistOnAnyShipment = result?.shipmentInfo?.find((x) => x.asin === item.asin);
        if (isExistOnAnyShipment) {
          setIsInUserShipment(true);
        } else {
          setIsInUserShipment(false);
        }
      }
    },
    [result.shipmentInfo], // eslint-disable-line
  );

  const onFavoriteStatusChange = useCallback(() => {
    if (result) {
      const fav = result.favorites?.find((x) => x.userFavoriteItems?.find((y) => y.asin === item.asin));
      setItemFavoriteList(fav);
    }
  }, [result.favorites]); // eslint-disable-line

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  useEffect(() => {
    onShipmentStatusChange();
  }, [onShipmentStatusChange]);

  useEffect(() => {
    onFavoriteStatusChange();
  }, [onFavoriteStatusChange]);

  return (
    <>
      <CardHeader className="common-css padding-bottom-0 border-bottom-0">
        <Row>
          <Col xl={1} lg={2} sm={3} className="mx-auto mb-4">
            {item.summary?.imageBig && (
              <div className="pimage-container">
                <Link to={`/dp/${item.asin}/${item.userSearchId}`} target="_blank">
                <ImageLoader src={`https://m.media-amazon.com/images/I/${item.summary.imageBig}`} alt="" className="rounded w-100"
                  />
                </Link>
              </div>
            )}

            <div className="w-auto mt-2 text-center">
              <div className="d-flex gap-1 align-items-center justify-content-center">
                {item.amazonBusinessModel !== AmazonBusinessModel.DROPSHIPPING && (
                  <>
                    <Button
                      className={"rounded-pill p-0 " + (isInUserShipment ? "btn-success" : "btn-secondary")}
                      id={`SellThisButton-${item.userSearchProductId}`}
                      style={{width: "80%"}}
                      onClick={() => {
                        if (showCheckList) {
                          CheckListModal.open({
                            asin: item.asin,
                            amazonBusinessModel: item.amazonBusinessModel,
                            checkList: item.checkList,
                            summary: item.summary,
                            marketplaceTarget: item.marketplaceTarget,
                            purchasePrice: item.amazonBusinessModel === AmazonBusinessModel.WHOLESALE ? item.purchasePriceSource : undefined,
                            exchangeRateSource: item.amazonBusinessModel === AmazonBusinessModel.WHOLESALE ? item.profitAnalysis.exchangeRateSource : undefined,
                          });
                        } else {
                          SellThisModal.open({
                            asin: item.asin,
                            marketplace: item.marketplaceTarget,
                            purchasePrice: item.amazonBusinessModel === AmazonBusinessModel.WHOLESALE ? item.purchasePriceSource : undefined,
                            exchangeRateSource: item.amazonBusinessModel === AmazonBusinessModel.WHOLESALE ? item.profitAnalysis.exchangeRateSource : undefined,
                          });
                        }
                      }}
                    >
                      <img src={logoDarkTransparent} alt="" width="100%" className="px-2 py-1" />
                    </Button>
                    <DefaultUncontrolledTooltip target={`SellThisButton-${item.userSearchProductId}`}>
                      {isInUserShipment ? t("SearchResults.SellThisButtonReverse") : t("SearchResults.SellThisButton")}
                    </DefaultUncontrolledTooltip>
                  </>
                )}

                <Link
                  id={`ProductDetailsButton-${item.userSearchProductId}`}
                  to={`/dp/${item.asin}/${item.userSearchId}`}
                  target="_blank"
                  className="btn btn-soft-secondary px-1 py-0 rounded-pill btn btn-secondary d-flex align-items-center"
                >
                  {item.amazonBusinessModel === AmazonBusinessModel.DROPSHIPPING && <span className="fs-12 px-1">{t("Details")}</span>} <i className="ri-arrow-right-up-line fs-14"></i>
                </Link>
                <DefaultUncontrolledTooltip target={`ProductDetailsButton-${item.userSearchProductId}`}>{t("SearchResults.ProductDetailsButton")}</DefaultUncontrolledTooltip>
              </div>
            </div>
          </Col>

          <Col xl={11} lg={10} sm={9}>
            <div className="mt-xl-0">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <h4 className="ellipsis-single-line mb-0">{item.summary?.title}</h4>
                  <div className="text-muted mb-2">
                    <Row>
                      <Col>
                        {item.summary?.categoryPath ? item.summary.categoryPath.replaceAll("|", " > ") : item.summary?.categoryName ? item.summary.categoryName : t("Category") + ": " + t("NA")}
                      </Col>
                      {/* Only show while Favorite page is loaded */
                      favoriteId && (
                        <Col className="col-auto d-flex justify-content-end mt-1">
                          <BusinessModelBadge key={item.userSearchProductId} model={item.amazonBusinessModel} size="sm" />
                        </Col>
                      )}
                    </Row>
                  </div>

                  <div className="hstack column-gap-4 row-gap-1 flex-wrap">
                    <CopyWidget text={item.asin} index={`CopyAsin-${item.userSearchProductId}`} bold={true} />

                    <div className="d-flex align-items-center">
                      {item.asin ? (
                        <>
                          {item.amazonBusinessModel !== AmazonBusinessModel.WHOLESALE && (
                            <>
                              <DomainToFlag marketplace={sourceMarketplace?.marketplace} />
                              <Link
                                to={`https://www.amazon.${sourceMarketplace?.domain}/dp/${item.asin}?th=1&psc=1`}
                                target="_blank"
                                className="link-secondary link-offset-2 text-decoration-underline common-css"
                              >
                                .{sourceMarketplace?.domain}
                                <i className="ri-arrow-right-up-line"></i>
                              </Link>
                            &nbsp;&nbsp;
                            </>
                          )}
                          {item.domainTarget && (
                            <>
                              <DomainToFlag marketplace={item.marketplaceTarget} />
                              <Link
                                to={`https://www.amazon.${item.domainTarget}/dp/${item.asin}?th=1&psc=1`}
                                target="_blank"
                                className="link-secondary link-offset-2 text-decoration-underline common-css"
                              >
                                .{item.domainTarget}
                                <i className="ri-arrow-right-up-line"></i>
                              </Link>
                            </>
                          )}
                        </>
                      ) : (
                        t("NA")
                      )}
                    </div>

                    {item.summary?.reviewCount && (
                      <>
                        <div className="text-muted">
                          <Rating
                            initialRating={item.summary?.reviewRating ?? 0}
                            fractions={2}
                            emptySymbol="mdi mdi-star-outline fs-16 text-muted "
                            fullSymbol="mdi mdi-star fs-16 text-warning "
                            readonly={true}
                          />{" "}
                          <DisplayNumber
                            value={item.summary?.reviewCount}
                            className="text-muted"
                            prefix="("
                            suffix={` ${item.summary.reviewCount > 0 ? t("SearchResults.Summary.ProductReviewPlural") : t("SearchResults.Summary.ProductReview")})`}
                            renderOnEmpty={<></>}
                          />
                        </div>
                      </>
                    )}

                    {item.amazonBusinessModel !== AmazonBusinessModel.DROPSHIPPING && (
                      <div className="d-flex align-items-center">
                        {item.summary?.isEligible != null ? (
                          <>
                            <i className={item.summary.isEligible ? "mdi mdi-check fs-20" : "bx bx-x fs-20"}></i>
                            <span className={"fw-bold " + (item.summary.isEligible ? "text-secondary" : "text-danger")}>
                              {item.summary.isEligible ? t("SearchResults.Summary.EligibleToSell") : t("SearchResults.Summary.NotEligibleToSell")}
                            </span>
                            {item.summary?.ineligibilityReasonList && !item.summary.isEligible && (
                              <>
                                <Button
                                  color="link"
                                  id={`PopoverIneligibilityReasonList-${item.userSearchProductId}`}
                                  className="p-0 ms-1 fs-12 link-secondary link-offset-1 text-decoration-underline common-css"
                                  onClick={async () => IneligibilityReasonListModal.open({asin: item.asin, ineligibilityReasonList: item.summary.ineligibilityReasonList})}
                                >
                                  <DecisionReasonsLink items={item.summary?.ineligibilityReasonList?.split(",") ?? []} />
                                </Button>
                                <DefaultUncontrolledTooltip target={`PopoverIneligibilityReasonList-${item.userSearchProductId}`}>{t("Click for details")}</DefaultUncontrolledTooltip>
                              </>
                            )}
                          </>
                        ) : (
                          <span className="text-muted">{t("SearchResults.Summary.Eligibility") + t("NA")}</span>
                        )}
                      </div>
                    )}

                    <div className="d-flex align-items-center">
                      {getToday().diff(moment.tz(item.statusDate, userProfile?.timezone!), "hours") > 48 ? (
                        <>
                          <i
                            className={`ri-error-warning-fill fs-18 ${getToday().diff(moment.tz(item.statusDate, userProfile?.timezone!), "days") > 7 ? "text-danger" : "text-warning"} me-1`}
                            id={`AnalysisWarningMessage-${item.userSearchProductId}`}
                          ></i>
                          <DefaultUncontrolledTooltip target={`AnalysisWarningMessage-${item.userSearchProductId}`}>
                            {t("SearchResults.Summary.AnalysisWarningMessage", {hours: 48})}
                          </DefaultUncontrolledTooltip>
                        </>
                      ) : (
                        <i className="bx bx-calendar-check fs-18 me-1"></i>
                      )}

                      <DisplayDate
                        id={`DisplayDateAnalysisStatusDate-${item.userSearchProductId}`}
                        className="text-muted"
                        value={item.statusDate}
                        tz={userProfile?.timezone}
                        format="D MMM YYYY HH:mm"
                      />
                    </div>
                  </div>

                  <div className="mt-4 hstack d-flex flex-wrap justify-content-start gap-3">
                    <SummaryCard
                      icon="mdi mdi-chart-timeline-variant-shimmer"
                      label={t("SearchResults.Summary.Decision")}
                      content={
                        <div className="d-flex align-items-center">
                          {!_.isNil(decision) ? (
                            <Badge color={decision === "Sellable" ? "success" : decision === "Recheck" ? "warning" : "danger"} className="rounded-pill fs-11">
                              {t(decision)}
                              {decision === "Sellable" && <i className="mdi mdi-check ms-1"></i>}
                              {}
                            </Badge>
                          ) : (
                            " " + t("NA")
                          )}
                          {!_.isNil(decisionReasons) ? (
                            <>
                              <Button
                                color="link"
                                id={`PopoverDecisionReasons-${item.userSearchProductId}`}
                                className="p-0 ms-1 fs-12 link-secondary link-offset-1 text-decoration-underline common-css"
                                onClick={() => {
                                  DecisionReasonsModal.open({
                                    asin: item.asin,
                                    variationAnalysis: item.variationAnalysis,
                                    amazonBusinessModel: item.amazonBusinessModel,
                                    checkList: item.checkList,
                                    summary: item.summary,
                                  });
                                }}
                              >
                                {item.summary?.decision === "Sellable" ? (
                                  <>
                                    <i className="mdi mdi-alert align-middle fs-18 text-warning"></i>
                                    <DefaultUncontrolledTooltip target={`PopoverDecisionReasons-${item.userSearchProductId}`}>
                                      {t("SearchResults.Summary.DecisionSellableWarning")}
                                    </DefaultUncontrolledTooltip>
                                  </>
                                ) : (
                                  <>
                                    <DecisionReasonsLink items={decisionReasons.split(",")} isWarning={decision === "Sellable"} />
                                    <DefaultUncontrolledTooltip target={`PopoverDecisionReasons-${item.userSearchProductId}`}>{t("Click for details")}</DefaultUncontrolledTooltip>
                                  </>
                                )}
                              </Button>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      }
                    />
                    <SummaryCard
                      icon="mdi mdi-finance"
                      label={t("SearchResults.Summary.SaleCount.Title")}
                      content={
                        <div className="d-flex align-items-center">
                          {item.saleAnalysis?.saleCount != null ? (
                            <>
                              <span>{item.saleAnalysis.saleCount.toLocaleString()}</span>
                              <small className="fs-13 text-muted">
                                <i className="mdi mdi-slash-forward"></i>
                                {t("month")}
                              </small>
                            </>
                          ) : (
                            t("NA")
                          )}{" "}
                          {item.saleAnalysis?.hasAnomalies && (
                            <>
                              <i id={`PopoverHasAnomaliesSummary-${item.userSearchProductId}`} className="ms-1 mdi mdi-alert fs-20 text-warning"></i>
                              <DefaultUncontrolledTooltip target={`PopoverHasAnomaliesSummary-${item.userSearchProductId}`}>
                                {t("SearchResults.Summary.SaleCount.Anomalies")}
                              </DefaultUncontrolledTooltip>
                            </>
                          )}
                          {item.saleAnalysis?.precision && !item.saleAnalysis.hasAnomalies && (
                            <>
                              <i
                                id={`PopoverPrecisionSummary-${item.userSearchProductId}`}
                                className={`ms-1 mdi ${
                                  item.saleAnalysis.precision === "VeryClose"
                                    ? "mdi-check-circle text-secondary"
                                    : item.saleAnalysis.precision === "Medium"
                                    ? "mdi-progress-check text-secondary fs-18"
                                    : item.saleAnalysis.precision === "Prediction"
                                    ? "mdi-progress-clock text-danger fs-18"
                                    : ""
                                }`}
                              ></i>
                              <DefaultUncontrolledTooltip target={`PopoverPrecisionSummary-${item.userSearchProductId}`}>
                                [{t("SearchResults.Summary.SaleCount.Precision")}: {item.saleAnalysis.precision}] {t(`SearchResults.Summary.SaleCount.Precisions.${item.saleAnalysis.precision}`)}
                              </DefaultUncontrolledTooltip>
                            </>
                          )}
                        </div>
                      }
                    />

                    <SummaryCard
                      icon="mdi mdi-currency-usd"
                      label={t("SearchResults.Summary.ProfitAndROI")}
                      content={
                        item.amazonBusinessModel === AmazonBusinessModel.DROPSHIPPING ? (
                          <>
                            <DisplayPrice
                              source={item.currencyCodeTarget}
                              className={classNames({"text-danger": item.profitAnalysis.profitDS != null && item.profitAnalysis.profitDS < 0}, "float-start")}
                              value={item.profitAnalysis.profitDS}
                              decimals={2}
                              notation="abbreviated"
                              renderOnEmpty={<span>{t("NA")}</span>}
                            />
                            <DisplayNumber
                              value={item.profitAnalysis.roids}
                              className={`badge rounded-pill fs-15 ms-2 ${
                                item.profitAnalysis.roids != null && item.profitAnalysis.roids < 0 ? "bg-danger-subtle text-danger" : "bg-success-subtle text-success"
                              }`}
                              suffix=" %"
                              decimals={2}
                              renderOnEmpty={<></>}
                            />
                          </>
                        ) : (
                          <>
                            <DisplayPrice
                              className={classNames({"text-danger": item.profitAnalysis.profit != null && item.profitAnalysis.profit < 0 && "text-danger"}, "float-start")}
                              source={item.currencyCodeTarget}
                              value={item.profitAnalysis.profit}
                              decimals={2}
                              notation="abbreviated"
                              renderOnEmpty={<span>{t("NA")}</span>}
                            />
                            <DisplayNumber
                              value={item.profitAnalysis.roi}
                              className={`badge rounded-pill fs-15 ms-2 ${
                                item.profitAnalysis.roi != null && item.profitAnalysis.roi < 0 ? "bg-danger-subtle text-danger" : "bg-success-subtle text-success"
                              }`}
                              suffix=" %"
                              decimals={2}
                              renderOnEmpty={<></>}
                            />
                          </>
                        )
                      }
                    />
                    <SummaryCard
                      icon="mdi mdi-layers-triple-outline"
                      label={t("SearchResults.Summary.Variation")}
                      content={
                        <>
                          <DisplayNumber
                            value={item.variationAnalysis?.variationCount}
                            className={`${
                              variationLevel === "Low"
                                ? "badge rounded-pill fs-14 bg-success-subtle text-success"
                                : variationLevel === "High"
                                ? "badge rounded-pill fs-14 bg-warning-subtle text-warning"
                                : ""
                            }`}
                            renderOnEmpty={<>{t("NA")}</>}
                          />
                        </>
                      }
                    />
                    <SummaryCard
                      icon={
                        item.competitorAnalysis.competitionLevel === "Weak"
                          ? "mdi mdi-speedometer-slow text-success"
                          : item.competitorAnalysis.competitionLevel === "Medium"
                          ? "mdi mdi-speedometer-medium text-warning"
                          : "mdi mdi-speedometer text-danger"
                      }
                      label={t("SearchResults.Summary.Competition")}
                      content={
                        <>
                          {item.competitorAnalysis?.competitionLevel != null ? (
                            <Badge
                              color={item.competitorAnalysis.competitionLevel === "Weak" ? "success" : item.competitorAnalysis.competitionLevel === "Medium" ? "warning" : "danger"}
                              className="rounded-pill fs-14"
                            >
                              {t(item.competitorAnalysis.competitionLevel)}
                              {item.competitorAnalysis.competitionLevel === "Weak" && <i className="mdi mdi-check ms-1"></i>}
                            </Badge>
                          ) : (
                            t("NA")
                          )}
                        </>
                      }
                    />
                    <SummaryCard
                      icon="mdi mdi-trophy-outline text-secondary"
                      label={`BSR ${item.domainTarget ? " - " + item.domainTarget.replace("com.", "").toUpperCase() : ""}`}
                      content={<>{item.summary?.bsr ? item.summary.bsr.toLocaleString() : t("NA")}</>}
                    />
                  </div>
                </div>
                <div className="flex-shrink-0 ms-2">
                  <Row>
                    <div className="vstack gap-1 flex-wrap justify-content-end">
                      <Button
                        type="button"
                        id={`MarkReviewButton-${item.userSearchProductId}`}
                        className="p-0 px-1"
                        color={item.isReviewed ? "light" : "ghost-dark"}
                        onClick={handleMarkAsReviewed}
                        disabled={markReviewLoading}
                      >
                        <i className={`ri-check-double-fill fs-20  ${item.isReviewed ? "text-secondary" : "text-primary-emphasis"}`}></i>
                      </Button>
                      <DefaultUncontrolledTooltip target={`MarkReviewButton-${item.userSearchProductId}`}>
                        {item.isReviewed ? t("SearchResults.Summary.Tooltip.NotReviewed") : t("SearchResults.Summary.Tooltip.IsReviewed")}
                      </DefaultUncontrolledTooltip>

                      <Button
                        id={`ReAnalyzeProductButton-${item.userSearchProductId}`}
                        type="button"
                        color="ghost-secondary"
                        className="p-0 px-1"
                        onClick={() => {
                          ReAnalyzeModal.open({
                            asin: item.asin,
                            store: search?.userStoreId ?? item.marketplaceTarget,
                            businessModel: item.amazonBusinessModel,
                            currency: item.currencyCodeTarget,
                            price: item.purchasePriceSource?.toFixed(2).replace(",", ".") || "",
                            type: AnalyzeType.SEARCH_PRODUCT,
                            searchName: item.asin,
                          });
                        }}
                        disabled={productDetailsLoading.save}
                      >
                        <i className="mdi mdi-chart-timeline-variant-shimmer fs-20 text-info"></i>
                      </Button>
                      <DefaultUncontrolledTooltip target={`ReAnalyzeProductButton-${item.userSearchProductId}`}>{t("SearchResults.Summary.Tooltip.ReanalyzeProduct")}</DefaultUncontrolledTooltip>

                      <Button
                        type="button"
                        id={`AddToFavoriteButton-${item.userSearchProductId}`}
                        color={itemFavoriteList ? "light" : "ghost-dark"}
                        className="p-0 px-1"
                        onClick={handleAddToFavoriteList}
                        disabled={addingOrRemovingFromFavorites}
                      >
                        <i className={`mdi ${itemFavoriteList?.shareType === ShareType.SHARED ? "mdi-account-star" : "mdi-star"}  fs-20 ${itemFavoriteList && "text-warning"}`}></i>
                      </Button>
                      <DefaultUncontrolledTooltip target={`AddToFavoriteButton-${item.userSearchProductId}`}>
                        {itemFavoriteList ? t("SearchResults.Summary.Tooltip.RemoveFromFavorites") : t("SearchResults.Summary.Tooltip.AddToFavorites")}
                      </DefaultUncontrolledTooltip>

                      <Button type="button" id={`NotesButton-${item.userSearchProductId}`} color={itemNote?.deleted === false ? "light" : "ghost-primary"} className="p-0 px-1" onClick={handleNotes}>
                        <i className={`mdi mdi-note-edit-outline fs-20 ${itemNote?.deleted === false && "text-info"}`}></i>
                      </Button>
                      <DefaultUncontrolledTooltip target={`NotesButton-${item.userSearchProductId}`}>
                        {itemNote?.deleted === false ? t("SearchResults.Summary.Tooltip.YouHaveNote") : t("SearchResults.Summary.Tooltip.CreateNote")}
                      </DefaultUncontrolledTooltip>

                      <Button
                        type="button"
                        id={`ReportABugButton-${item.userSearchProductId}`}
                        color="ghost-dark"
                        className="p-0 px-1"
                        onClick={async () => ReportErrorModal.open({asin: item.asin, marketplaceTarget: item.marketplaceTarget})}
                      >
                        <i className="ri-bug-fill fs-18 text-primary-emphasis"></i>
                      </Button>
                      <DefaultUncontrolledTooltip target={`ReportABugButton-${item.userSearchProductId}`}>{t("SearchResults.Summary.Tooltip.ReportError")}</DefaultUncontrolledTooltip>
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </CardHeader>
    </>
  );
};

export default Summary;
