import DataTable, {DataTableRef} from "Components/Common/DataTable";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Button, Card, CardBody, CardHeader, Col, Row, UncontrolledAlert} from "reactstrap";
import {CheckboxItem} from "./Components/CheckboxItem";
import {useSelector} from "react-redux";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {ColumnDef, FilterFn} from "@tanstack/react-table";
import {SellerList, SellerType} from "models/product_details";
import {AmazonMarketplace} from "helpers/marketplace_helper";
import {getRatingPowerToolTip, getSellerName} from "./Helpers";
import {ProductDetailsSlice} from "slices/product-details/selector";
import DomainToFlag from "Components/Common/DomainToFlag";
import DisplayNumber from "Components/Common/DisplayNumber";
import _ from "lodash";
import {AmazonBusinessModel} from "models/enums/user_search_type";

interface SellersAndListingAnalysisProps {
  destinationMarketplace?: AmazonMarketplace;
  sourceMarketplace?: AmazonMarketplace;
}
const SellersAndListingAnalysis = ({destinationMarketplace, sourceMarketplace}: SellersAndListingAnalysisProps) => {
  const FILTER_COLUMN_ID = "Seller";
  const {t} = useTranslation();
  const sourceSellerTableRef = useRef<DataTableRef>(null);
  const destinationSellerTableRef = useRef<DataTableRef>(null);
  const [sourceSellerTableFilter, setSourceSellerTableFilter] = useState<"FBA" | "FBM" | "AMAZON" | "ALL">("ALL");
  const [destinationSellerTableFilter, setDestinationSellerTableFilter] = useState<"FBA" | "FBM" | "AMAZON" | "ALL">("ALL");
  const [fbaSellerCountSource, setFbaSellerCountSource] = useState<number>(0);
  const [fbmSellerCountSource, setFbmSellerCountSource] = useState<number>(0);
  const [amazonSellerCountSource, setAmazonSellerCountSource] = useState<number>(0);

  const [fbaSellerCountDestination, setFbaSellerCountDestination] = useState<number>(0);
  const [fbmSellerCountDestination, setFbmSellerCountDestination] = useState<number>(0);
  const [amazonSellerCountDestination, setAmazonSellerCountDestination] = useState<number>(0);

  const {loading, details} = useSelector(ProductDetailsSlice);

  const sellerTypeFilter: FilterFn<SellerList> = (row, _columnId, filterValue) => {
    if (filterValue === "FBA") return row.original.sellerType === SellerType.FBA;
    else if (filterValue === "FBM") return row.original.sellerType === SellerType.FBM;
    else if (filterValue === "AMAZON") return row.original.sellerType === SellerType.AMAZON;
    else return true;
  };

  const sourceSellerColumns = useMemo<ColumnDef<SellerList, any>[]>(
    () => [
      {
        id: FILTER_COLUMN_ID,
        filterFn: sellerTypeFilter,
        cell: (cellProps) => {
          const row = cellProps.row.original as SellerList;
          return (
            <>
              <div className="align-items-center d-flex">
                <span className="flex-grow-1 hstack gap-2">
                  <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                    <img src={sourceMarketplace?.flag} alt="Country Flag" className="rounded-circle" />
                  </div>
                  <div>
                    <div className="hstack">
                      {getSellerName(row, "dark", destinationMarketplace)}
                      {getRatingPowerToolTip(row)}
                      {row.sellerType === SellerType.FBA ? (
                        <span className="badge rounded-pill bg-warning-subtle text-warning fs-12 ms-2">FBA</span>
                      ) : row.sellerType === SellerType.FBM ? (
                        <span className="badge rounded-pill bg-success-subtle text-success fs-12 ms-2">FBM</span>
                      ) : row.sellerType === SellerType.AMAZON ? (
                        <span className="badge rounded-pill bg-danger-subtle text-danger fs-12 ms-2">Amazon</span>
                      ) : null}
                      {row.isBuyBoxWinner && (
                        <span className="ms-2">
                          <i className="text-secondary mdi mdi-basket me-1 align-middle "></i>
                          <span className="text-secondary text-nowrap small fw-medium">Buybox</span>
                        </span>
                      )}
                    </div>
                  </div>
                </span>
                <span className="d-flex align-items-center flex-shrink-0">
                  <i className="ri-star-fill text-warning fs-16 me-1"></i> <span className="me-1">Rating:</span>{" "}
                  <DisplayNumber value={row.ratingCount} decimals={0} notation="abbreviated" renderOnEmpty={<span className="text-muted">{t("NA")}</span>} />
                </span>
              </div>
              <div className="hstack gap-2 flex-wrap mt-1 ms-1">
                <div className="d-flex align-items-center">
                  <DomainToFlag marketplace={sourceMarketplace?.marketplace || ""} />
                  <Link to={`https://www.amazon.${sourceMarketplace?.domain}/sp?seller=${row.sellerId}`} target="_blank" className="link-dark text-muted">
                    {t("Store")}
                    <i className="ri-arrow-right-up-line align-middle"></i>
                  </Link>
                </div>
                <div className="d-flex align-items-center">
                  <DomainToFlag marketplace={sourceMarketplace?.marketplace || ""} />
                  <Link to={`https://www.amazon.${sourceMarketplace?.domain}/s?me=${row.sellerId}`} target="_blank" className="link-dark text-muted">
                    {t("Products")}
                    <i className="ri-arrow-right-up-line align-middle"></i>
                  </Link>
                </div>
                <div className="vr mx-1"></div>
                <div className="d-flex align-items-center">
                  <DomainToFlag marketplace={destinationMarketplace?.marketplace || ""} />
                  <Link to={`https://www.amazon.${destinationMarketplace?.domain}/sp?seller=${row.sellerId}`} target="_blank" className="link-dark text-muted">
                    {t("Store")}
                    <i className="ri-arrow-right-up-line align-middle"></i>
                  </Link>
                </div>
                <div className="d-flex align-items-center">
                  <DomainToFlag marketplace={destinationMarketplace?.marketplace || ""} />
                  <Link to={`https://www.amazon.${destinationMarketplace?.domain}/s?me=${row.sellerId}`} target="_blank" className="link-dark text-muted">
                    {t("Products")}
                    <i className="ri-arrow-right-up-line align-middle"></i>
                  </Link>
                </div>
              </div>
              <div className="hstack gap-3 flex-wrap mt-1 ms-1">
                {row.isCommonFbaSeller && (
                  <span>
                    <i className="text-warning mdi mdi-account-multiple me-1 align-middle"></i>
                    <span className="text-warning text-nowrap small fw-medium">{t("ProductDetails.SellersAndListingAnalysis.Label.CommonFbaSeller")}</span>
                  </span>
                )}
                {row.isRemoteFBA && (
                  <span>
                    <i className="text-warning mdi mdi-web me-1 align-middle"></i>
                    <span className="text-warning text-nowrap small fw-medium">{t("ProductDetails.SellersAndListingAnalysis.Label.RemoteFba")}</span>
                  </span>
                )}
                {row.potentialBrandOwner && (
                  <span>
                    <i className="text-danger mdi mdi-trademark me-1 align-middle"></i>
                    <span className="text-danger text-nowrap small fw-medium">{t("ProductDetails.SellersAndListingAnalysis.Label.PotentialBrandOwner")}</span>
                  </span>
                )}
              </div>
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, details, destinationMarketplace],
  );

  const destinationSellerColumns = useMemo<ColumnDef<SellerList, any>[]>(
    () => [
      {
        id: FILTER_COLUMN_ID,
        filterFn: sellerTypeFilter,
        cell: (cellProps) => {
          const row = cellProps.row.original as SellerList;
          return (
            <>
              <div className="align-items-center d-flex">
                <span className="flex-grow-1 hstack gap-2">
                  <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                    <img src={destinationMarketplace?.flag} alt="Country Flag" className="rounded-circle" />
                  </div>
                  <div>
                    <div className="hstack">
                      {getSellerName(row, "dark", destinationMarketplace)}
                      {getRatingPowerToolTip(row)}
                      {row.sellerType === SellerType.FBA ? (
                        <span className="badge rounded-pill bg-warning-subtle text-warning fs-12 ms-2">FBA</span>
                      ) : row.sellerType === SellerType.FBM ? (
                        <span className="badge rounded-pill bg-success-subtle text-success fs-12 ms-2">FBM</span>
                      ) : row.sellerType === SellerType.AMAZON ? (
                        <span className="badge rounded-pill bg-danger-subtle text-danger fs-12 ms-2">Amazon</span>
                      ) : null}
                      {row.isBuyBoxWinner && (
                        <span className="ms-2">
                          <i className="text-secondary mdi mdi-basket me-1 align-middle "></i>
                          <span className="text-secondary text-nowrap small fw-medium">Buybox</span>
                        </span>
                      )}
                    </div>
                  </div>
                </span>
                <span className="d-flex align-items-center flex-shrink-0">
                  <i className="ri-star-fill text-warning fs-16 me-1"></i> <span className="me-1">Rating:</span>{" "}
                  <DisplayNumber value={row.ratingCount} decimals={0} renderOnEmpty={<span className="text-muted">{t("NA")}</span>} />
                </span>
              </div>
              <div className="hstack gap-2 flex-wrap mt-1 ms-1">
                <div className="d-flex align-items-center">
                  <Link to={`https://www.amazon.${destinationMarketplace?.domain}/sp?seller=${row.sellerId}`} target="_blank" className="link-dark text-muted">
                    {t("Store")}
                    <i className="ri-arrow-right-up-line align-middle"></i>
                  </Link>
                </div>
                <div className="d-flex align-items-center">
                  <Link to={`https://www.amazon.${destinationMarketplace?.domain}/s?me=${row.sellerId}`} target="_blank" className="link-dark text-muted">
                    {t("Products")}
                    <i className="ri-arrow-right-up-line align-middle"></i>
                  </Link>
                </div>
              </div>
              <div className="hstack gap-3 flex-wrap mt-1 ms-1">
                {row.isCommonFbaSeller && (
                  <span>
                    <i className="text-warning mdi mdi-account-multiple me-1 align-middle"></i>
                    <span className="text-warning text-nowrap small fw-medium">{t("ProductDetails.SellersAndListingAnalysis.Label.CommonFbaSeller")}</span>
                  </span>
                )}
                {row.isRemoteFBA && (
                  <span>
                    <i className="text-warning mdi mdi-web me-1 align-middle"></i>
                    <span className="text-warning text-nowrap small fw-medium">{t("ProductDetails.SellersAndListingAnalysis.Label.RemoteFba")}</span>
                  </span>
                )}
                {row.potentialBrandOwner && (
                  <span>
                    <i className="text-danger mdi mdi-trademark me-1 align-middle"></i>
                    <span className="text-danger text-nowrap small fw-medium">{t("ProductDetails.SellersAndListingAnalysis.Label.PotentialBrandOwner")}</span>
                  </span>
                )}
              </div>
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, details, destinationMarketplace],
  );

  const handleSourceSellerListFilterChange = useCallback(() => {
    if (sourceSellerTableRef.current) {
      if (sourceSellerTableFilter === "ALL") {
        sourceSellerTableRef.current.clearFilters();
      } else {
        sourceSellerTableRef.current.setFilter("Seller", sourceSellerTableFilter);
      }
    }
  }, [sourceSellerTableFilter]);

  const handleDestinationSellerListFilterChange = useCallback(() => {
    if (destinationSellerTableRef.current) {
      if (destinationSellerTableFilter === "ALL") {
        destinationSellerTableRef.current.clearFilters();
      } else {
        destinationSellerTableRef.current.setFilter("Seller", destinationSellerTableFilter);
      }
    }
  }, [destinationSellerTableFilter]);

  const handleTableSourceSellerCounts = useCallback(() => {
    if (details?.sellerAndListingAnalysis?.sourceSellerList) {
      const amazon = details?.sellerAndListingAnalysis?.sourceSellerList?.filter((seller) => seller.sellerType === SellerType.AMAZON);
      const fba = details?.sellerAndListingAnalysis?.sourceSellerList?.filter((seller) => seller.sellerType === SellerType.FBA);
      const fbm = details?.sellerAndListingAnalysis?.sourceSellerList?.filter((seller) => seller.sellerType === SellerType.FBM);
      setAmazonSellerCountSource(amazon.length);
      setFbaSellerCountSource(fba.length);
      setFbmSellerCountSource(fbm.length);
    }
  }, [details]);

  const handleTableDestinationSellerCounts = useCallback(() => {
    if (details?.sellerAndListingAnalysis?.targetSellerList) {
      const amazon = details?.sellerAndListingAnalysis?.targetSellerList?.filter((seller) => seller.sellerType === SellerType.AMAZON);
      const fba = details?.sellerAndListingAnalysis?.targetSellerList?.filter((seller) => seller.sellerType === SellerType.FBA);
      const fbm = details?.sellerAndListingAnalysis?.targetSellerList?.filter((seller) => seller.sellerType === SellerType.FBM);
      setAmazonSellerCountDestination(amazon.length);
      setFbaSellerCountDestination(fba.length);
      setFbmSellerCountDestination(fbm.length);
    }
  }, [details]);

  useEffect(() => {
    handleTableSourceSellerCounts();
  }, [handleTableSourceSellerCounts]);

  useEffect(() => {
    handleTableDestinationSellerCounts();
  }, [handleTableDestinationSellerCounts]);

  useEffect(() => {
    handleSourceSellerListFilterChange();
  }, [handleSourceSellerListFilterChange]);

  useEffect(() => {
    handleDestinationSellerListFilterChange();
  }, [handleDestinationSellerListFilterChange]);

  const getBuyBoxType = (type: number) => {
    switch (type) {
      case 0:
        return <span className={`badge rounded-pill fs-12 bg-danger-subtle text-danger`}>Local Amazon</span>;
      case 1:
        return <span className={`badge rounded-pill fs-12 bg-danger-subtle text-danger`}>Amazon</span>;
      case 2:
        return <span className={`badge rounded-pill fs-12 bg-warning-subtle text-warning`}>FBA</span>;
      case 3:
        return <span className={`badge rounded-pill fs-12 bg-success-subtle text-success`}>FBM</span>;
    }
  };
  return (
    <Card className="card-animate">
      <CardBody>
        <h5 className="fs-15">{t("ProductDetails.SellersAndListingAnalysis.Title")}</h5>
        <Row className="mt-3">
          {details?.sellerAndListingAnalysis.potentialBrandOwner && (
            <Col className="col-12 mb-4">
              <UncontrolledAlert color="danger" className="alert-additional mb-xl-0" toggle={null}>
                <div className="alert-body">
                  <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                      <i className="ri-alert-line fs-16 align-middle"></i>
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="alert-heading">{t("ProductDetails.SellersAndListingAnalysis.Alert.Title")}</h5>
                      <p className="mb-0">{t("ProductDetails.SellersAndListingAnalysis.Alert.Description")}</p>
                    </div>
                  </div>
                </div>
              </UncontrolledAlert>
            </Col>
          )}

          <Col xxl={4} xl={6} sm={12}>
            {details.productInfo.amazonBusinessModel !== AmazonBusinessModel.WHOLESALE && (
              <>
                <Card>
                  <CardHeader className="bg-light py-2">
                    <span className="hstack gap-2">
                      <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                        <img src={sourceMarketplace?.flag} alt="Country Flag" className="rounded-circle" />
                      </div>
                      <h5 className="mb-0">{sourceMarketplace?.countryName}</h5>
                    </span>
                  </CardHeader>
                  <CardBody>
                    <div className="fs-15">
                      <div className="d-flex pb-2">
                        <div className="flex-grow-1">
                          <p className="text-truncate mb-0">
                            <i className="ri-amazon-fill align-middle me-2"></i>
                            {t("ProductDetails.SellersAndListingAnalysis.Label.ListedOnAmazon")}
                          </p>
                        </div>
                        <div className="flex-shrink-0">
                          <span className="d-flex align-items-center">
                            <CheckboxItem status={(details?.sellerAndListingAnalysis.offerCountSource ?? 0) > 0} />
                          </span>
                        </div>
                      </div>
                      <div className="d-flex py-2">
                        <div className="flex-grow-1">
                          <p className="text-truncate mb-0">
                            <i className="mdi mdi-store align-middle text-primary me-2"></i>
                            {t("ProductDetails.SellersAndListingAnalysis.Label.CurrentSeller")}
                          </p>
                        </div>
                        <div className="flex-shrink-0">
                          {!_.isNil(details?.sellerAndListingAnalysis.buyBoxTypeSource) ? (
                            getBuyBoxType(details?.sellerAndListingAnalysis.buyBoxTypeSource)
                          ) : (
                            <span className="text-muted">{t("NA")}</span>
                          )}
                        </div>
                      </div>
                      <div className="d-flex pt-2">
                        <div className="flex-grow-1">
                          <p className="text-truncate mb-0">
                            <i className="mdi mdi-store-edit align-middle text-primary me-2"></i>
                            {t("ProductDetails.SellersAndListingAnalysis.Label.SellerCount")}
                          </p>
                        </div>
                        <div className="flex-shrink-0">
                          <p className="mb-0 d-flex align-items-center">
                            <i className="ri-bar-chart-fill text-success me-1"></i>
                            {details?.sellerAndListingAnalysis.offerCountSource}
                          </p>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </>
            )}

            <Card>
              <CardHeader className="bg-light py-2">
                <span className="hstack gap-2">
                  <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                    <img src={destinationMarketplace?.flag} alt="Country Flag" className="rounded-circle" />
                  </div>
                  <h5 className="mb-0">{destinationMarketplace?.countryName}</h5>
                </span>
              </CardHeader>
              <CardBody>
                <div className="fs-15">
                  <div className="d-flex pb-2">
                    <div className="flex-grow-1">
                      <p className="text-truncate mb-0">
                        <i className="ri-amazon-fill align-middle me-2"></i>
                        {t("ProductDetails.SellersAndListingAnalysis.Label.ListedOnAmazon")}
                      </p>
                    </div>
                    <div className="flex-shrink-0">
                      <span className="d-flex align-items-center">
                        <CheckboxItem status={(details?.sellerAndListingAnalysis.offerCount ?? 0) > 0} />
                      </span>
                    </div>
                  </div>
                  <div className="d-flex py-2">
                    <div className="flex-grow-1">
                      <p className="text-truncate mb-0">
                        <i className="mdi mdi-store-check align-middle text-primary me-2"></i>
                        {t("ProductDetails.SellersAndListingAnalysis.Label.CurrentSeller")}
                      </p>
                    </div>
                    <div className="flex-shrink-0">
                      {!_.isNil(details?.sellerAndListingAnalysis.buyBoxType) ? getBuyBoxType(details?.sellerAndListingAnalysis.buyBoxType) : <span className="text-muted">{t("NA")}</span>}
                    </div>
                  </div>
                  <div className="d-flex pt-2">
                    <div className="flex-grow-1">
                      <p className="text-truncate mb-0">
                        <i className="mdi mdi-store-edit align-middle text-primary me-2"></i>
                        {t("ProductDetails.SellersAndListingAnalysis.Label.SellerCount")}
                      </p>
                    </div>
                    <div className="flex-shrink-0">
                      <p className="mb-0 d-flex align-items-center">
                        <i className="ri-bar-chart-fill text-success me-1"></i>
                        {details?.sellerAndListingAnalysis.offerCount}
                      </p>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>

            {details.productInfo.amazonBusinessModel !== AmazonBusinessModel.WHOLESALE && (
              <Card>
                <CardHeader className="bg-light">
                  <span className="hstack gap-2">
                    <h5 className="mb-0">{t("ProductDetails.SellersAndListingAnalysis.Card.CrossMarketSellerStatistics.Title")}</h5>
                  </span>
                </CardHeader>
                <CardBody>
                  <div className="fs-15">
                    <div className="d-flex pb-2">
                      <div className="flex-grow-1">
                        <p className="text-truncate mb-0">
                          <i className="mdi mdi-store-edit align-middle text-primary me-2"></i>
                          {t("ProductDetails.SellersAndListingAnalysis.Label.CommonFbaSellerCount")}
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <p className="mb-0 d-flex align-items-center">
                          <i className="ri-bar-chart-fill text-success me-1"></i>
                          <DisplayNumber value={details?.sellerAndListingAnalysis.commonFBASellerCount} renderOnEmpty={<>0</>} />
                        </p>
                      </div>
                    </div>
                    <div className="d-flex py-2">
                      <div className="flex-grow-1">
                        <p className="text-truncate mb-0">
                          <i className="mdi mdi-store-edit align-middle text-primary me-2"></i>
                          {t("ProductDetails.SellersAndListingAnalysis.Label.CommonRemoteFbaSellerCount")}
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <p className="mb-0 d-flex align-items-center">
                          <i className="ri-bar-chart-fill text-success me-1"></i>
                          <DisplayNumber value={details?.sellerAndListingAnalysis.commonFBARemoteSellerCount} renderOnEmpty={<>0</>} />
                        </p>
                      </div>
                    </div>
                    <div className="d-flex pt-2">
                      <div className="flex-grow-1">
                        <p className="mb-0">
                          <i className="mdi mdi-trademark align-middle text-primary me-2"></i>
                          {t("ProductDetails.SellersAndListingAnalysis.Label.PotentialBrandOwner")}
                          {details?.sellerAndListingAnalysis.potentialBrandOwner && <i className="mdi mdi-alert align-middle text-warning fs-18 ms-1"></i>}
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <span className={`badge rounded-pill fs-12 ${details?.sellerAndListingAnalysis.potentialBrandOwner ? "bg-danger-subtle text-danger " : "bg-success-subtle text-success"}`}>
                          {details?.sellerAndListingAnalysis.potentialBrandOwner ? t("Yes") : t("No")}
                        </span>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            )}
          </Col>
          {details.productInfo.amazonBusinessModel !== AmazonBusinessModel.WHOLESALE && (
            <Col xxl={4} xl={6} sm={12} className="same-height">
              <Card>
                <CardHeader className="bg-light py-2">
                  <div className="d-flex align-items-center">
                    <span className="flex-grow-1 hstack gap-2">
                      <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                        <img src={sourceMarketplace?.flag} alt="Country Flag" className="rounded-circle" />
                      </div>
                      <h5 className="fs-15 mb-0">{t("ProductDetails.SellersAndListingAnalysis.Card.SellerList.Title")}</h5>
                    </span>
                    <div className="d-flex gap-1">
                      <Button
                        color="secondary"
                        size="sm"
                        className={sourceSellerTableFilter === "FBA" ? "btn-soft-secondary" : "btn-ghost-secondary"}
                        onClick={() => setSourceSellerTableFilter("FBA")}
                      >
                        FBA <span className="badge bg-secondary-subtle text-secondary align-middle rounded-pill">{fbaSellerCountSource}</span>
                      </Button>
                      <Button
                        color="secondary"
                        size="sm"
                        className={sourceSellerTableFilter === "FBM" ? "btn-soft-secondary" : "btn-ghost-secondary"}
                        onClick={() => setSourceSellerTableFilter("FBM")}
                      >
                        FBM <span className="badge bg-secondary-subtle text-secondary align-middle rounded-pill">{fbmSellerCountSource}</span>
                      </Button>
                      <Button
                        color="secondary"
                        size="sm"
                        className={sourceSellerTableFilter === "AMAZON" ? "btn-soft-secondary" : "btn-ghost-secondary"}
                        onClick={() => setSourceSellerTableFilter("AMAZON")}
                      >
                        Amazon <span className="badge bg-secondary-subtle text-secondary align-middle rounded-pill">{amazonSellerCountSource}</span>
                      </Button>
                      <Button
                        color="secondary"
                        size="sm"
                        className={sourceSellerTableFilter === "ALL" ? "btn-soft-secondary" : "btn-ghost-secondary"}
                        onClick={() => setSourceSellerTableFilter("ALL")}
                      >
                        {t("ProductDetails.SellersAndListingAnalysis.Button.All")}{" "}
                        <span className="badge bg-secondary-subtle text-secondary align-middle rounded-pill">{fbaSellerCountSource + fbmSellerCountSource + amazonSellerCountSource}</span>
                      </Button>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <DataTable
                    ref={sourceSellerTableRef}
                    theadClass="d-none border-0"
                    tbodyClass="border-0"
                    tdClass="border-0"
                    columns={sourceSellerColumns}
                    data={details?.sellerAndListingAnalysis?.sourceSellerList || []}
                    totalDataLength={details?.sellerAndListingAnalysis?.sourceSellerList?.length || 0}
                    thClass=""
                    busy={loading.list}
                    hovered
                  />
                </CardBody>
              </Card>
            </Col>
          )}

          <Col xxl={4} xl={6} sm={12} className="same-height">
            <Card>
              <CardHeader className="bg-light py-2">
                <div className="d-flex align-items-center">
                  <span className="flex-grow-1 hstack gap-2">
                    <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                      <img src={destinationMarketplace?.flag} alt="Country Flag" className="rounded-circle" />
                    </div>
                    <h5 className="fs-15 mb-0">{t("ProductDetails.SellersAndListingAnalysis.Card.SellerList.Title")}</h5>
                  </span>
                  <div className="d-flex gap-1">
                    <Button
                      color="secondary"
                      size="sm"
                      className={destinationSellerTableFilter === "FBA" ? "btn-soft-secondary" : "btn-ghost-secondary"}
                      onClick={() => setDestinationSellerTableFilter("FBA")}
                    >
                      FBA <span className="badge bg-secondary-subtle text-secondary align-middle rounded-pill">{fbaSellerCountDestination}</span>
                    </Button>
                    <Button
                      color="secondary"
                      size="sm"
                      className={destinationSellerTableFilter === "FBM" ? "btn-soft-secondary" : "btn-ghost-secondary"}
                      onClick={() => setDestinationSellerTableFilter("FBM")}
                    >
                      FBM <span className="badge bg-secondary-subtle text-secondary align-middle rounded-pill">{fbmSellerCountDestination}</span>
                    </Button>
                    <Button
                      color="secondary"
                      size="sm"
                      className={destinationSellerTableFilter === "AMAZON" ? "btn-soft-secondary" : "btn-ghost-secondary"}
                      onClick={() => setDestinationSellerTableFilter("AMAZON")}
                    >
                      Amazon <span className="badge bg-secondary-subtle text-secondary align-middle rounded-pill">{amazonSellerCountDestination}</span>
                    </Button>
                    <Button
                      color="secondary"
                      size="sm"
                      className={destinationSellerTableFilter === "ALL" ? "btn-soft-secondary" : "btn-ghost-secondary"}
                      onClick={() => setDestinationSellerTableFilter("ALL")}
                    >
                      {t("ProductDetails.SellersAndListingAnalysis.Button.All")}{" "}
                      <span className="badge bg-secondary-subtle text-secondary align-middle rounded-pill">{fbaSellerCountDestination + fbmSellerCountDestination + amazonSellerCountDestination}</span>
                    </Button>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <DataTable
                  ref={destinationSellerTableRef}
                  theadClass="d-none border-0"
                  tbodyClass="border-0"
                  tdClass="border-0"
                  columns={destinationSellerColumns}
                  data={details?.sellerAndListingAnalysis?.targetSellerList || []}
                  totalDataLength={details?.sellerAndListingAnalysis?.targetSellerList?.length || 0}
                  thClass=""
                  busy={loading.list}
                  hovered
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default SellersAndListingAnalysis;
