import BreadCrumb, {BreadcrumbMenuItem} from "Components/Common/BreadCrumb";
import DataTable, {DataTableRef} from "Components/Common/DataTable";
import Dialog, {DialogRef} from "Components/Common/Dialog";
import {useEffect, useMemo, useRef, useState} from "react";
import {Badge, Button, Card, CardBody, Col, Container, Row, Spinner} from "reactstrap";
import {renderFailedToast, renderSuccessToast} from "helpers/utilities";
import {getUserStores, disconnectUserStore, deleteUserStore} from "slices/thunks";
import {useTranslation} from "react-i18next";
import {Link, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {ColumnDef} from "@tanstack/react-table";
import {UserStore} from "models/user_stores";
import {setCurrentUserStore} from "slices/store/reducer";
import {useProfile} from "Components/Hooks/useProfile";
import {ConstantPage} from "helpers/permission_helper";
import {getConnectRequestUrl} from "helpers/store_helper";
import {ActiveMarketplaces} from "helpers/marketplace_helper";
import {StoresSlice} from "slices/store/selector";
import ConnectUserStore from "./Modals/ConnectUserStore";
import RenameUserStore from "./Modals/RenameUserStore";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";
import NoResult from "Components/Common/NoResult";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import DisplayDate from "Components/Common/DisplayDate";
import DisconnectedStoreAlert from "pages/Alerts/DisconnectedStoreAlert";
import Loader from "Components/Common/Loader";

const PAGE_IDENTIFIER: ConstantPage = "stores";

interface StoresProps {
  connect?: boolean;
}
const Stores = (props: StoresProps) => {
  const {t} = useTranslation();
  const {userProfile, hasPermission} = useProfile();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const connectUserStoreStatus = params.get("status");
  const tableRef = useRef<DataTableRef>(null);
  const dispatch: any = useDispatch();

  const [isRenaming, setIsRenaming] = useState(false);
  const disconnectUserStoreDialogRef = useRef<DialogRef>(null);
  const reconnectUserStoreDialogRef = useRef<DialogRef>(null);

  const {loading, list, currentUserStore: currentStore} = useSelector(StoresSlice);

  useEffect(() => {
    if (connectUserStoreStatus !== undefined && connectUserStoreStatus !== null) {
      if (connectUserStoreStatus === "0") {
        renderSuccessToast(t("Stores.ConnectSuccess"));
      } else if (parseInt(connectUserStoreStatus) >= 1 && parseInt(connectUserStoreStatus) <= 7) {
        renderFailedToast(t("Stores.ConnectError"));
      } else if (parseInt(connectUserStoreStatus) === 8) {
        renderFailedToast(t("Stores.ConnectStoreAlreadyConnected"));
      } else if (parseInt(connectUserStoreStatus) >= 9 && parseInt(connectUserStoreStatus) <= 12) {
        renderFailedToast(t("Stores.ConnectError"));
      } else if (connectUserStoreStatus === "13") {
        renderFailedToast(t("Stores.ConnectStoreAlreadyInUse"));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectUserStoreStatus]);

  useEffect(() => {
    if (hasPermission(PAGE_IDENTIFIER)) {
      getUserStores()(dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReconnectUserStore = (store: UserStore) => {
    window.location.href = getConnectRequestUrl(userProfile?.userId!, ActiveMarketplaces, store.name, store.marketplace, "true");
  };

  const columns = useMemo<ColumnDef<UserStore, any>[]>(
    () => [
      {
        header: t("Actions"),
        size: 10,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserStore;
          const [isDeletingOrRestoring, setIsDeletingOrRestoring] = useState<boolean>(false); // eslint-disable-line

          const handleDeleteOrRestore = async () => {
            setIsDeletingOrRestoring(true);
            await deleteUserStore({userStoreId: row.userStoreId, deleted: !row.deleted}, row.name)(dispatch);
            setIsDeletingOrRestoring(false);
          };

          return (
            <Restricted require="stores" update>
              <div className="d-flex align-items-center">
                <Button
                  id={`ReconnectUserStore-${row.userStoreId}`}
                  className="btn btn-ghost-success px-1 py-0 fs-18"
                  onClick={() => {
                    dispatch(setCurrentUserStore(row));
                    reconnectUserStoreDialogRef.current?.show();
                  }}
                >
                  <i className="mdi mdi-connection align-middle"></i>
                </Button>
                <DefaultUncontrolledTooltip target={`ReconnectUserStore-${row.userStoreId}`}>{t("Reconnect")}</DefaultUncontrolledTooltip>

                {row.status && (
                  <>
                    <Button
                      id={`DisconnectUserStore-${row.userStoreId}`}
                      className={`btn btn-ghost-danger px-1 py-0 fs-18 ${row.status ? "" : "cursor-text"}`}
                      onClick={() => {
                        dispatch(setCurrentUserStore(row));
                        disconnectUserStoreDialogRef.current?.show();
                      }}
                    >
                      <i className={`mdi align-middle ${row.status ? "mdi-power-plug-off" : "mdi-power-plug-off text-muted"}`}></i>
                    </Button>
                    <DefaultUncontrolledTooltip target={`DisconnectUserStore-${row.userStoreId}`}>{t("Disconnect")}</DefaultUncontrolledTooltip>
                  </>
                )}

                <Button
                  id={`DeleteStore-${row.userStoreId}`}
                  color={row.deleted ? "ghost-secondary" : "ghost-danger"}
                  className="btn-link px-1 py-0 fs-18"
                  onClick={handleDeleteOrRestore}
                  disabled={isDeletingOrRestoring}
                >
                  {row.deleted ? <i className="ri-arrow-go-back-line align-middle" /> : <i className="ri-delete-bin-fill align-middle"></i>}
                </Button>
                <DefaultUncontrolledTooltip target={`DeleteStore-${row.userStoreId}`}>{row.deleted ? t("Stores.Tooltip.Restore") : t("Stores.Tooltip.Delete")}</DefaultUncontrolledTooltip>

              </div>
            </Restricted>
          );
        },
      },
      {
        accessorKey: "name",
        size: 150,
        header: t("Stores.TableColumn.StoreName"),
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserStore;
          return (
            <>
              <h6 className="d-flex align-items-center my-1 nowrap">
                {row.name}
                <Link
                  to=""
                  id={`EditUserStore-${row.userStoreId}`}
                  className="btn btn-ghost-info px-1 py-0 fs-18 ms-1"
                  onClick={() => {
                    dispatch(setCurrentUserStore(row));
                    setIsRenaming(true);
                  }}
                >
                  <i className="ri-pencil-fill"></i>
                </Link>
                <DefaultUncontrolledTooltip target={`EditUserStore-${row.userStoreId}`}>{t("Edit")}</DefaultUncontrolledTooltip>
              </h6>
            </>
          );
        },
      },
      {
        accessorKey: "sellerId",
        size: 50,
        header: t("Stores.TableColumn.SellerId"),
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserStore;
          const domain = ActiveMarketplaces.find((amazonMarketplaceInfo) => amazonMarketplaceInfo.marketplace === row.marketplace)?.domain;
          return (
            <>
              <h6 className="d-flex align-items-center my-1">
                {row.sellerId}
                <Link
                  to={`https://www.amazon.${domain}/sp?seller=${row.sellerId}`}
                  id={`UserStorefrontLink-${row.sellerId}`}
                  target="_blank"
                  className="link-primary link-offset-2 text-decoration-underline ms-1"
                >
                  <i className="mdi mdi-storefront fs-18"></i>
                </Link>
                <DefaultUncontrolledTooltip target={`UserStorefrontLink-${row.sellerId}`}>{t("Stores.TableColumn.ViewYourStorefront")}</DefaultUncontrolledTooltip>
              </h6>
            </>
          );
        },
      },
      {
        accessorKey: "marketplace",
        size: 50,
        sortingFn: "alphanumeric",
        header: t("Stores.TableColumn.Marketplace"),
        cell: (cellProps) => {
          const row = cellProps.row.original as UserStore;
          const marketplace = ActiveMarketplaces.find((amazonMarketplaceInfo) => amazonMarketplaceInfo.marketplace === row.marketplace);
          const flag = marketplace?.flag;
          const countryName = marketplace?.countryName || "";
          return (
            <>
              <span className="hstack gap-2 nowrap">
                <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                  <img src={flag} alt="Country Flag" className="img-fluid d-block rounded-circle" />{" "}
                </div>
                {t(countryName) + " (" + row.marketplace + ")"}
              </span>
            </>
          );
        },
      },
      {
        accessorKey: "status",
        header: t("Stores.TableColumn.Status"),
        size: 50,
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserStore;
          return (
            <>
              <Badge color={row.status ? "success" : "danger"} className="badge rounded-pill fs-12">
                {row.status ? t("Connected") : t("Disconnected")}
              </Badge>
            </>
          );
        },
      },
      {
        accessorKey: "createDate",
        size: 100,
        header: t("Stores.TableColumn.CreateDate"),
        sortingFn: "datetime",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserStore;
          return (
            <>
              <DisplayDate id={`DisplayDateUserStoreCreateDate-${row.userStoreId}`} value={row.createDate} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} />
            </>
          );
        },
      },
    ],
    [t], // eslint-disable-line react-hooks/exhaustive-deps
  );

  document.title = t("PageTitles.Stores");

  const breadcrumbMenus: BreadcrumbMenuItem[] = [
    {
      label: t("Stores.Title"),
    },
  ];
  return (
    <Restricted require={PAGE_IDENTIFIER} read fallback={() => <Unauthorized />}>
      <ConnectUserStore isOpen={props.connect ?? false} />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Stores.Title")} menus={breadcrumbMenus} />
          <DisconnectedStoreAlert />
          <Card>
            <CardBody>
              <Row className="align-items-end mb-3">
                <Restricted require="stores" create>
                  <Col xs align="right" className={loading.list ? "d-none" : ""}>
                    <Link to="/stores/connect" color="info" className="btn btn-secondary fw-semibold btn-sm mb-3" style={loading.list ? {pointerEvents: "none", color: "#dedede"} : {}}>
                      {loading.list ? <Spinner size="sm" className="me-2 align-middle"></Spinner> : <i className="mdi mdi-lan-connect align-bottom me-1"></i>}
                      {t("Stores.Connect")}
                    </Link>
                  </Col>
                </Restricted>
              </Row>
              {loading.list ? (
                <>
                  <Loader />
                </>
              ) : list && list.length > 0 ? (
                <DataTable ref={tableRef} columns={columns} data={list || []} totalDataLength={list.length} busy={loading.list} hovered />
              ) : (
                <NoResult title={t("Stores.NoResult.Title")} description={t("Stores.NoResult.Description")} />
              )}
            </CardBody>
          </Card>

          <Dialog
            ref={disconnectUserStoreDialogRef}
            color="danger"
            buttons={["yes", "no"]}
            busy={loading.update}
            iconClass="mdi mdi-power-plug-off"
            message={t("Stores.Dialog.DisconnectUserStore.Description", {storeName: currentStore.name})}
            title={t("Stores.Dialog.DisconnectUserStore.Title")}
            onButtonClick={async (button) => {
              if (button === "yes") {
                await dispatch(disconnectUserStore(currentStore.userStoreId));
              }
              disconnectUserStoreDialogRef.current?.hide();
            }}
          />

          <Dialog
            ref={reconnectUserStoreDialogRef}
            color="success"
            buttons={["yes", "no"]}
            busy={loading.update}
            iconClass="mdi mdi-connection"
            message={t("Stores.Dialog.ReconnectUserStore.Description", {storeName: currentStore.name})}
            title={t("Stores.Dialog.ReconnectUserStore.Title")}
            onButtonClick={async (button) => {
              if (button === "yes") {
                handleReconnectUserStore(currentStore);
              }
              reconnectUserStoreDialogRef.current?.hide();
            }}
          />

          <RenameUserStore isOpen={isRenaming} toggle={() => setIsRenaming(false)} store={currentStore} />
        </Container>
      </div>
    </Restricted>
  );
};

export default Stores;
