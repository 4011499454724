import {ResponseModel} from "models/response_model";
import {apiError, deleteOrRestoreItem, loading, setList, updateNoteAction} from "./reducer";
import {postGetUserNotes, postSaveNote, postDeleteNote} from "services/search_service";
import {generateError, renderSuccessToast} from "helpers/utilities";
import {PagedList} from "helpers/types";
import {GetUserSearchNotesQuery} from "api/query";
import {UserSearchNote} from "models/user_search_note";
import {DeleteNoteCommand, SaveNoteCommand} from "api/command";
import i18n from "i18n";

export const getUserNotes = (query: GetUserSearchNotesQuery) => async (dispatch: any) => {
  const loadingType = query.action === "filtering" ? "filter" : "list";
  try {
    dispatch(loading([loadingType, true]));
    const result: ResponseModel = await postGetUserNotes(query);
    const pagedResult: PagedList<UserSearchNote> = result.data;
    dispatch(setList(pagedResult));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading([loadingType, false]));
  }
};

export const updateNote = (command: SaveNoteCommand) => async (dispatch: any) => {
  try {
    dispatch(loading(["update", true]));
    const response: ResponseModel = await postSaveNote(command);
    const createdNote: UserSearchNote = response.data;
    dispatch(updateNoteAction(createdNote));
    renderSuccessToast(i18n.t("Notes.Dialog.Edit.Toast.Success"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["update", false]));
  }
};

export const deleteNote = (command: DeleteNoteCommand, name: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["delete", true]));
    await postDeleteNote(command);
    dispatch(deleteOrRestoreItem(command));
    if (command.deleted) {
      renderSuccessToast(i18n.t("Notes.Toast.DeleteSuccess", {value: name}));
    }
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["delete", false]));
  }
};
