import ShipmentInfo from "./_ShipmentInfo";
import Stats from "./_Stats";
import ProjectedSalesAndProfits from "./_ProjectedSalesAndProfits";
import CostAndProfitAnalysis from "./_CostAndProfitAnalysis";
import SalesHistory from "./_SalesHistory";
import ProductList from "./_ProductList";
import SoldOutProducts from "./_SoldOutProducts";
import Orders from "./_Orders";
import {Row} from "reactstrap";
import RemainingProducts from "./_RemainingProducts";
import FinanceStats from "./_FinanceStats";
import { useCallback, useEffect, useState } from "react";
import { ActiveMarketplaces, AmazonMarketplace } from "helpers/marketplace_helper";
import { useSelector } from "react-redux";
import { ShipmentSlice } from "slices/shipment/selector";

const ShippedOrCompleted = () => {
  const [sourceMarketplace, setSourceMarketplace] = useState<AmazonMarketplace>()
  const [destinationMarketplace, setDestinationMarketplace] = useState<AmazonMarketplace>()

  const {currentUserShipment} = useSelector(ShipmentSlice)
  const onLoad = useCallback(
    () => {
      if(currentUserShipment)
      {
          let source = ActiveMarketplaces.find((amazonMarketplaceInfo) => amazonMarketplaceInfo.marketplace === "US");
          let destination = ActiveMarketplaces.find((amazonMarketplaceInfo) => amazonMarketplaceInfo.marketplace === currentUserShipment.userStore.marketplace);
          setDestinationMarketplace(destination);
          setSourceMarketplace(source);
      }
    },
    [currentUserShipment],
  )

  useEffect(() => {
    onLoad()
  }, [onLoad])
  
  return (
    <>
      <ShipmentInfo sourceMarketplace={sourceMarketplace} destinationMarketplace={destinationMarketplace} />

      <Stats />

      <Row>
        <FinanceStats destinationMarketplace={destinationMarketplace}/>
        <ProjectedSalesAndProfits destinationMarketplace={destinationMarketplace} />
      </Row>

      <CostAndProfitAnalysis destinationMarketplace={destinationMarketplace} sourceMarketplace={sourceMarketplace} />

      <SalesHistory destinationMarketplace={destinationMarketplace} />

      <ProductList destinationMarketplace={destinationMarketplace} sourceMarketplace={sourceMarketplace} />

      <Row className="g-4">
        <RemainingProducts  />
        <SoldOutProducts  />
      </Row>
      <Orders destinationMarketplace={destinationMarketplace}/>
    </>
  );
};

export default ShippedOrCompleted;
