import {CancelUserSubscriptionCommand} from "api/command";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import ValidatedInput from "Components/Common/ValidatedInput";
import {useFormik} from "formik";
import {createTypedModal} from "helpers/modal_helpers";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {Button, Card, CardBody, Col, Form, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {cancelUserSubscription} from "slices/account/thunk";
import * as Yup from "yup";

interface FeedbackForm {
  pausingStoreForVacation: boolean;
  sellerAccountSuspended: boolean;
  tooExpensive: boolean;
  technicalIssues: boolean;
  switchingToAnotherProduct: boolean;
  notSureHowToUseTheTool: boolean;
  notEnoughResults: boolean;
  noLongerUsingProductOrSellingOnAmazon: boolean;
  noLongerUsingProductButStillSellingOnAmazon: boolean;
  missingFeatures: boolean;
  otherReason: boolean;
  otherReasonText?: string;
}
const REASON_MAPPING = {
  pausingStoreForVacation: "Pausing store for vacation",
  sellerAccountSuspended: "Seller account suspended",
  tooExpensive: "Too expensive",
  technicalIssues: "Technical issues",
  switchingToAnotherProduct: "Switching to another product",
  notSureHowToUseTheTool: "Not sure how to use the tool",
  notEnoughResults: "Not enough results",
  noLongerUsingProductOrSellingOnAmazon: "No longer using product or selling on Amazon",
  noLongerUsingProductButStillSellingOnAmazon: "No longer using product but still selling on Amazon",
  missingFeatures: "Missing features",
  otherReason: "Other reason",
} as const;
type ReasonKey = keyof typeof REASON_MAPPING;

interface ModalData {
  subscriptionId: string;
  cancelAtPeriodEnd: boolean;
}
export const CancelSubscriptionModal = createTypedModal<ModalData>("cancel_subscription");
const CancelSubscription = () => {
  const {t} = useTranslation();
  const {open, data} = CancelSubscriptionModal.useModal();
  const [hasError, setHasError] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accountData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Account.loading,
    }),
  );
  const {loading} = useSelector(accountData);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      pausingStoreForVacation: false,
      sellerAccountSuspended: false,
      tooExpensive: false,
      technicalIssues: false,
      switchingToAnotherProduct: false,
      notSureHowToUseTheTool: false,
      notEnoughResults: false,
      noLongerUsingProductOrSellingOnAmazon: false,
      noLongerUsingProductButStillSellingOnAmazon: false,
      missingFeatures: false,
      otherReason: false,
      otherReasonText: "",
    } as FeedbackForm,
    validationSchema: Yup.object<FeedbackForm>({
      pausingStoreForVacation: Yup.boolean(),
      sellerAccountSuspended: Yup.boolean(),
      tooExpensive: Yup.boolean(),
      technicalIssues: Yup.boolean(),
      switchingToAnotherProduct: Yup.boolean(),
      notSureHowToUseTheTool: Yup.boolean(),
      notEnoughResults: Yup.boolean(),
      noLongerUsingProductOrSellingOnAmazon: Yup.boolean(),
      noLongerUsingProductButStillSellingOnAmazon: Yup.boolean(),
      missingFeatures: Yup.boolean(),
      otherReason: Yup.boolean(),
      otherReasonText: Yup.string().when("otherReason", (otherReason, schema) => {
        if (otherReason.toString() === "true") {
          return schema.required(t("FieldRequired"));
        } else {
          return schema.notRequired();
        }
      }),
    }),
    onSubmit: (values: FeedbackForm) => {
      const {otherReasonText, ...booleanFields} = values;
      const selectedReason = Object.entries(booleanFields).find(([_, value]) => value === true)?.[0] as ReasonKey | undefined;

      if (!selectedReason) {
        setHasError(true);
        return;
      }

      setHasError(false);
      const payload: CancelUserSubscriptionCommand = {
        subscriptionId: data!.subscriptionId,
        cancelAtPeriodEnd: data!.cancelAtPeriodEnd,
        reason: values.otherReasonText || REASON_MAPPING[selectedReason],
      };

      const cancelSubscriptionPromise = cancelUserSubscription(payload)(dispatch);
      cancelSubscriptionPromise.then((success) => {
        if (success) {
          navigate("/account/subscription");
        }
      });
    },
  });

  const handleReasonClick = (fieldName: keyof FeedbackForm) => {
    const resetValues = {
      pausingStoreForVacation: false,
      sellerAccountSuspended: false,
      tooExpensive: false,
      technicalIssues: false,
      switchingToAnotherProduct: false,
      notSureHowToUseTheTool: false,
      notEnoughResults: false,
      noLongerUsingProductOrSellingOnAmazon: false,
      noLongerUsingProductButStillSellingOnAmazon: false,
      missingFeatures: false,
      otherReason: false,
      otherReasonText: "",
    };

    setHasError(false);
    validation.setValues({
      ...resetValues,
      [fieldName]: true,
    });
  };

  const toggle = () => {
    validation.resetForm();
    CancelSubscriptionModal.close();
  };
  return (
    <Modal backdrop="static" isOpen={open} toggle={toggle} fade={true} centered={true} size="lg">
      <PlaceholderGlow busy={false}>
        <>
          <Form onSubmit={validation.handleSubmit}>
            <ModalHeader className="p-3" toggle={toggle}>
              {t("Account.Subscription.CancelSubscription.Dialog.Title")}
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col xs={12} md={7}>
                  <h5 className="fw-medium">{t("Account.Subscription.CancelSubscription.Dialog.Description1")}</h5>
                  <p className="text-muted">{t("Account.Subscription.CancelSubscription.Dialog.Description2")}</p>
                </Col>
              </Row>
              <Row className="mt-2 d-flex flex-column flex-md-row g-3">
                <Col xs={12} sm={6} className="same-height">
                  <Card
                    className={`mb-0 shadow-none cursor-pointer border border-2${validation.values.pausingStoreForVacation ? " card-item-checked" : ""}`}
                    onClick={() => handleReasonClick("pausingStoreForVacation")}
                  >
                    <CardBody className="p-3">
                      <span className="fw-medium user-select-none">{t("Account.Subscription.CancelSubscription.Dialog.Reasons.1")}</span>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} sm={6} className="same-height">
                  <Card
                    className={`mb-0 shadow-none cursor-pointer border border-2${validation.values.sellerAccountSuspended ? " card-item-checked" : ""}`}
                    onClick={() => handleReasonClick("sellerAccountSuspended")}
                  >
                    <CardBody className="p-3">
                      <span className="fw-medium user-select-none">{t("Account.Subscription.CancelSubscription.Dialog.Reasons.2")}</span>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} sm={6} className="same-height">
                  <Card className={`mb-0 shadow-none cursor-pointer border border-2${validation.values.tooExpensive ? " card-item-checked" : ""}`} onClick={() => handleReasonClick("tooExpensive")}>
                    <CardBody className="p-3">
                      <span className="fw-medium user-select-none">{t("Account.Subscription.CancelSubscription.Dialog.Reasons.3")}</span>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} sm={6} className="same-height">
                  <Card
                    className={`mb-0 shadow-none cursor-pointer border border-2${validation.values.technicalIssues ? " card-item-checked" : ""}`}
                    onClick={() => handleReasonClick("technicalIssues")}
                  >
                    <CardBody className="p-3">
                      <span className="fw-medium user-select-none">{t("Account.Subscription.CancelSubscription.Dialog.Reasons.4")}</span>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} sm={6} className="same-height">
                  <Card
                    className={`mb-0 shadow-none cursor-pointer border border-2${validation.values.switchingToAnotherProduct ? " card-item-checked" : ""}`}
                    onClick={() => handleReasonClick("switchingToAnotherProduct")}
                  >
                    <CardBody className="p-3">
                      <span className="fw-medium user-select-none">{t("Account.Subscription.CancelSubscription.Dialog.Reasons.5")}</span>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} sm={6} className="same-height">
                  <Card
                    className={`mb-0 shadow-none cursor-pointer border border-2${validation.values.notSureHowToUseTheTool ? " card-item-checked" : ""}`}
                    onClick={() => handleReasonClick("notSureHowToUseTheTool")}
                  >
                    <CardBody className="p-3">
                      <span className="fw-medium user-select-none">{t("Account.Subscription.CancelSubscription.Dialog.Reasons.6")}</span>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} sm={6} className="same-height">
                  <Card
                    className={`mb-0 shadow-none cursor-pointer border border-2${validation.values.notEnoughResults ? " card-item-checked" : ""}`}
                    onClick={() => handleReasonClick("notEnoughResults")}
                  >
                    <CardBody className="p-3">
                      <span className="fw-medium user-select-none">{t("Account.Subscription.CancelSubscription.Dialog.Reasons.7")}</span>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} sm={6} className="same-height">
                  <Card
                    className={`mb-0 shadow-none cursor-pointer border border-2${validation.values.noLongerUsingProductOrSellingOnAmazon ? " card-item-checked" : ""}`}
                    onClick={() => handleReasonClick("noLongerUsingProductOrSellingOnAmazon")}
                  >
                    <CardBody className="p-3">
                      <span className="fw-medium user-select-none">{t("Account.Subscription.CancelSubscription.Dialog.Reasons.8")}</span>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} sm={6} className="same-height">
                  <Card
                    className={`mb-0 shadow-none cursor-pointer border border-2${validation.values.noLongerUsingProductButStillSellingOnAmazon ? " card-item-checked" : ""}`}
                    onClick={() => handleReasonClick("noLongerUsingProductButStillSellingOnAmazon")}
                  >
                    <CardBody className="p-3">
                      <span className="fw-medium user-select-none">{t("Account.Subscription.CancelSubscription.Dialog.Reasons.9")}</span>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} sm={6} className="same-height">
                  <Card
                    className={`mb-0 shadow-none cursor-pointer border border-2${validation.values.missingFeatures ? " card-item-checked" : ""}`}
                    onClick={() => handleReasonClick("missingFeatures")}
                  >
                    <CardBody className="p-3">
                      <span className="fw-medium user-select-none">{t("Account.Subscription.CancelSubscription.Dialog.Reasons.10")}</span>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} sm={6} className="same-height">
                  <Card className={`mb-0 shadow-none cursor-pointer border border-2${validation.values.otherReason ? " card-item-checked" : ""}`} onClick={() => handleReasonClick("otherReason")}>
                    <CardBody className="p-3">
                      <span className="fw-medium user-select-none">{t("Account.Subscription.CancelSubscription.Dialog.Reasons.11")}</span>
                    </CardBody>
                  </Card>
                </Col>
                {validation.values.otherReason && (
                  <Col xs={12}>
                    <Label htmlFor="otherReasonText">{t("Other")}</Label>
                    <ValidatedInput type="textarea" validation={validation} field="otherReasonText" />
                  </Col>
                )}
                {hasError && <div className="text-danger mt-3">{t("Account.Subscription.CancelSubscription.Dialog.Validation.SelectMinimumOneOption")}</div>}
              </Row>
            </ModalBody>
            <ModalFooter>
              <div className="hstack gap-3 justify-content-end">
                <Link to="/account/subscription" className={`btn btn-secondary${loading.delete ? " disabled" : ""}`} onClick={toggle}>
                  <i className="mdi mdi-arrow-left me-1 align-middle"></i>
                  <span>{t("Account.Subscription.CancelSubscription.Label.KeepActive")}</span>
                </Link>
                <Button type="submit" color="danger" disabled={loading.delete}>
                  <span>{t("Account.Subscription.CancelSubscription.Title")}</span>
                  {loading.delete ? <Spinner size="sm" className="ms-2 align-middle" /> : <i className="mdi mdi-arrow-right ms-1 align-middle"></i>}
                </Button>
              </div>
            </ModalFooter>
          </Form>
        </>
      </PlaceholderGlow>
    </Modal>
  );
};

export default CancelSubscription;
