import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {Modal, ModalHeader, ModalBody, Col, Row, Button, ModalFooter, Form, Label, Spinner} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {SelectOptionsType} from "Components/Hooks/useSelectOptions";
import {createAnalyzeOptionSettings} from "slices/settings/thunk";
import {useNavigate} from "react-router-dom";
import {SettingsSlice} from "slices/settings/selector";
import ValidatedSelect from "Components/Common/ValidatedSelect";
import ValidatedInput from "Components/Common/ValidatedInput";
import * as Yup from "yup";

interface CreateAnalyzeOptionProps {
  isOpen: boolean;
  toggle: () => void;
}
const CreateAnalyzeOption = (props: CreateAnalyzeOptionProps) => {
  const {t} = useTranslation();
  const [analyzeOptions, setAnalyzeOptions] = useState<any>();
  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const {loading, analyzeList: analyzeOptionsList, currentAnalyzeOption} = useSelector(SettingsSlice);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {name: "", marketplace: null, copyFrom: currentAnalyzeOption.userSettingAnalyzeOptionId},
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t("Settings.AnalyzeOptions.Validation.Name")),
      copyFrom: Yup.string().required(t("Settings.AnalyzeOptions.Validation.CopyFrom")),
    }),
    onSubmit: (values: any) => {
      const createPromise = createAnalyzeOptionSettings(values.name, values.copyFrom, navigate)(dispatch);
      createPromise.then((success) => {
        if (success) {
          props.toggle();
          validation.resetForm();
          navigate("/account/settings/analyze-options");
        }
      });
    },
  });

  useEffect(() => {
    if (analyzeOptionsList.length > 0) {
      const optionArray: SelectOptionsType[] = [];
      analyzeOptionsList.forEach((item) => {
        optionArray.push({value: item.userSettingAnalyzeOptionId, label: item.name});
      });
      setAnalyzeOptions(optionArray);
    }
  }, [analyzeOptionsList]);

  useEffect(() => {
    if (props.isOpen) {
      if (currentAnalyzeOption.userSettingAnalyzeOptionId) validation.setFieldValue("copyFrom", currentAnalyzeOption.userSettingAnalyzeOptionId);
    } else {
      validation.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  return (
    <>
      <Modal backdrop="static" id="showCreateAnalyzeOptionModal" isOpen={props.isOpen} toggle={props.toggle} fade={true} centered={true}>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <ModalHeader className="p-3" toggle={props.toggle}>
            {t("Settings.AnalyzeOptions.Dialog.Create.Title")}
          </ModalHeader>
          <ModalBody>
            <Row className="mb-3">
              <Col xs={12} md={6}>
                <Label className="form-label" htmlFor="name">
                  {t("Settings.AnalyzeOptions.Name")}
                </Label>
                <ValidatedInput validation={validation} type="text" field="name" maxLength={100} placeholder={t("Settings.AnalyzeOptions.NamePlaceholder")} disableValidationUI />
              </Col>
              <Col xs={12} md={6} className="mt-3 mt-md-0">
                <Label className="form-label" htmlFor="copyFrom">
                  {t("Settings.AnalyzeOptions.CopyFrom")}
                </Label>
                <ValidatedSelect
                  className={`new-search user-input w-100 ${validation.errors.copyFrom && validation.touched.copyFrom && "form-control is-invalid form-select-invalid"}`}
                  options={analyzeOptions}
                  validation={validation}
                  field="copyFrom"
                  value={validation.values.copyFrom}
                  placeholder={t("Settings.CostAndFees.CopyFromPlaceholder")}
                  errorStyle="container"
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <Button type="button" className="btn btn-light" onClick={props.toggle}>
                {t("Settings.AnalyzeOptions.Dialog.Create.CancelButton")}
              </Button>
              <Button type="submit" color="success" disabled={loading.create}>
                {loading.create && <Spinner size="sm" className="me-2 align-middle"></Spinner>}
                {t("Settings.AnalyzeOptions.Dialog.Create.SubmitButton")}
              </Button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default CreateAnalyzeOption;
