import React from "react";
import {Spinner} from "reactstrap";
import {t} from "i18next";

interface UpdateLoaderProps {
  isVisible: boolean;
}

const UpdateLoader: React.FC<UpdateLoaderProps> = ({isVisible}) => {
  if (!isVisible) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        backdropFilter: "blur(4px)",
        zIndex: 9999,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        className="bg-light p-4 rounded-3 text-center"
        style={{
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          maxWidth: "90%",
          width: "300px",
        }}
      >
        <Spinner color="secondary" style={{width: "3rem", height: "3rem"}} />
        <p className="mt-3 mb-0 fw-medium">{t("UpdateApplication.UpdatingApplication")}</p>
      </div>
    </div>
  );
};

export default UpdateLoader;
