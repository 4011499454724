import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Button, Card, CardBody, CardHeader, Col} from "reactstrap";
import {UserInventory} from "models/user_inventory";
import {AnalyzeType, ReAnalyzeModal} from "Components/Common/Modals/ReAnalyze";
import {DashboardSlice} from "slices/dashboard/selector";
import CopyWidget from "Components/Common/CopyWidget";
import DomainToFlag from "Components/Common/DomainToFlag";
import errorImage from "assets/images/svg/product.svg";
import SimpleBar from "simplebar-react";
import NoData from "Components/Common/NoData";
import CountUp from "react-countup";
import DisplayPrice from "Components/Common/DisplayPrice";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";

const RemainingInventories = () => {
  const {t} = useTranslation();
  const [remainingInventoryList, setRemainingInventoryList] = useState<UserInventory[]>([]);

  const {filter, statistics} = useSelector(DashboardSlice);

  useEffect(() => {
    if (statistics && statistics.remainingInventories) {
      const list = statistics.remainingInventories.filter((inventory) => inventory.afnFulfillableQuantity !== undefined && inventory.afnFulfillableQuantity > 0);
      setRemainingInventoryList(list);
    }
  }, [statistics]);

  const handleReanalyze = () => {
    if (remainingInventoryList != null && remainingInventoryList.length === 1) {
      ReAnalyzeModal.open({
        searchName: `${t("Dashboard.RemainingInventory.Title")} ${t("Reanalyze")}`,
        store: "",
        type: AnalyzeType.SEARCH_PRODUCT,
        asin: remainingInventoryList[0].asin,
      });
    } else {
      ReAnalyzeModal.open({
        searchName: `${t("Dashboard.RemainingInventory.Title")} ${t("Reanalyze")}`,
        store: "",
        type: AnalyzeType.IDENTIFIER_LIST,
        identifierList: remainingInventoryList.map((item) => item.asin),
      });
    }
  };

  return (
    <React.Fragment>
      <Col xl={4} className="same-height">
        <Card className="card-animate">
          <CardHeader className="align-items-center d-flex p-3 fixed-card-height">
            <div className="d-flex align-items-center flex-grow-1">
              <h4 className="card-title mb-0">{t("Dashboard.RemainingInventory.Title")}</h4>
              {remainingInventoryList != null && remainingInventoryList.length > 0 && (
                <>
                  <Button id={`ReAnalyze-RemainingInventory`} className="btn-link px-1 py-0 fs-18 ms-2" type="button" color="ghost-secondary" onClick={handleReanalyze}>
                    <i className="mdi mdi-chart-timeline-variant-shimmer align-middle"></i>
                  </Button>
                  <DefaultUncontrolledTooltip target={`ReAnalyze-RemainingInventory`}>{t("Dashboard.RemainingInventory.Tooltip.Reanalyze")}</DefaultUncontrolledTooltip>
                </>
              )}
            </div>
            <div className="flex-shrink-0">
              <span className="fw-semibold fs-13">
                <CountUp start={0} decimals={0} duration={2} end={remainingInventoryList.length} /> {remainingInventoryList.length > 1 ? t("SKUs") : t("SKU")}
              </span>
            </div>
          </CardHeader>

          <CardBody>
            {remainingInventoryList && remainingInventoryList.length > 0 ? (
              <div className="table-responsive table-card">
                <SimpleBar style={{height: "430px"}}>
                  <table className="table-hover table table-centered align-middle mb-0">
                    <tbody>
                      {remainingInventoryList &&
                        remainingInventoryList.map((inventory: UserInventory, index) => {
                          const imagePath = `https://m.media-amazon.com/images/I/${inventory.imageSmall}`;
                          return (
                            <tr key={index}>
                              <td>
                                <div className="d-flex">
                                  <div className="flex-shrink-0">
                                    <div className="avatar-md rounded flex-shrink-0 overflow-hidden">
                                      <img
                                        className="img-fluid d-block rounded-circle"
                                        src={imagePath}
                                        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                          e.currentTarget.onerror = null;
                                          e.currentTarget.src = errorImage;
                                        }}
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                  <div className="flex-grow-1 ms-3">
                                    <div className="text-muted d-flex align-items-center text-nowrap mb-1">
                                      <DomainToFlag marketplace={inventory.userStore.marketplace} />
                                      <span>
                                        {inventory.userStore.marketplace} - {inventory.userStore.name}
                                      </span>
                                    </div>
                                    <p className="ellipsis-two-lines mb-0" title={inventory.productName}>
                                      {inventory.productName}
                                    </p>
                                    <div className="d-flex flex-wrap gap-2 align-items-center">
                                      <div className="d-flex">
                                        <CopyWidget text={inventory.asin} index={inventory.asin} asLink={true} marketplace={inventory.userStore.marketplace} />
                                      </div>
                                      <div className="d-flex">
                                        <CopyWidget text={inventory.sku} index={inventory.sku} asLink={false} />
                                      </div>
                                    </div>
                                    <div className="d-flex gap-1 mt-2">
                                      <span className="badge rounded-pill bg-warning-subtle text-warning fs-12">{inventory.fbaSellerCount} FBA</span>
                                      <span className="badge rounded-pill bg-success-subtle text-success fs-12">{inventory.fbmSellerCount} FBM</span>
                                      <span className="badge rounded-pill bg-primary-subtle text-primary fs-12">
                                        {t("Stock")}: {inventory.afnFulfillableQuantity}
                                      </span>
                                    </div>
                                    <div className="d-flex mt-3">
                                      <span className="ellipsis-single-line">
                                        <i className="text-primary mdi mdi-trophy-variant-outline align-middle me-1"></i>
                                        <span title={inventory.categoryName}>
                                          <strong>BSR:</strong> {inventory.bsr} - {inventory.categoryName}
                                        </span>
                                      </span>
                                    </div>
                                    <div className="d-flex">
                                      <span>
                                        <i className="text-success mdi mdi-bank-plus align-middle me-1"></i>
                                        <strong>{t("Dashboard.RemainingInventory.Label.EstimatedRevenue")}: </strong>
                                        <DisplayPrice source={filter?.currencyCode} value={inventory.salesProceeds} decimals={2} notation="abbreviated" />
                                      </span>
                                    </div>
                                    <div className="d-flex">
                                      <span>
                                        <i className="text-secondary mdi mdi-bank-check align-middle me-1"></i>
                                        <strong>{t("Dashboard.RemainingInventory.Label.EstimatedProfit")}: </strong>
                                        <DisplayPrice source={filter?.currencyCode} value={inventory.profit} decimals={2} notation="abbreviated" />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </SimpleBar>
              </div>
            ) : (
              <NoData icon="mdi mdi-note-search-outline" />
            )}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default RemainingInventories;
