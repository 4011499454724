import {useEffect, useImperativeHandle, useMemo, useRef} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Card, CardBody, Container} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {getUserOrders} from "slices/orders/thunk";
import {FinanceStatus, UserOrderWithShipments} from "models/user_order";
import {Link} from "react-router-dom";
import {getRelativeDateByTimezone, getToday} from "helpers/utilities";
import {useImmer} from "use-immer";
import {ColumnDef} from "@tanstack/react-table";
import {useDebounce} from "Components/Hooks/useDebounce";
import {DataTableRef} from "Components/Common/DataTable";
import {useProfile} from "Components/Hooks/useProfile";
import {ConstantPage} from "helpers/permission_helper";
import {FilterOrderQuery} from "api/query";
import BreadCrumb from "Components/Common/BreadCrumb";
import Filters from "./Filters";
import Stats from "./Stats";
import DomainToFlag from "Components/Common/DomainToFlag";
import Loader from "Components/Common/Loader";
import DataTable from "Components/Common/DataTable";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";
import errorImage from "assets/images/svg/product.svg";
import CopyWidget from "Components/Common/CopyWidget";
import DisplayPrice from "Components/Common/DisplayPrice";
import DisplayNumber from "Components/Common/DisplayNumber";
import NoResult from "Components/Common/NoResult";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import {UserShipment} from "models/user_shipment";
import DisplayDate from "Components/Common/DisplayDate";
import {OrderStatus} from "models/enums/order_status";
import {UserOrderItem} from "models/user_order_item";
import {OrderStatusBadge} from "Components/Common/Badges/OrderStatusBadge";
import {FieldConfig, useUrlQuery} from "Components/Hooks/useUrlQuery";
import {AllMarketplaces} from "helpers/marketplace_helper";

export type OrderListRef = {
  reload: VoidFunction;
};

const PAGE_IDENTIFIER: ConstantPage = "orders";
const Orders = () => {
  const {t} = useTranslation();
  const {userProfile, hasPermission} = useProfile();
  const dispatch: any = useDispatch();
  const tableRef = useRef<DataTableRef>(null);
  const listRef = useRef<OrderListRef>(null);

  const [localQuery, updateLocalQuery] = useImmer<FilterOrderQuery>({
    action: "filtering",
    startDate: undefined,
    endDate: undefined,
    page: 1,
    pageSize: 10,
  });

  let filterFields: FieldConfig<FilterOrderQuery>[] = [
    {field: "status", queryParam: "status", type: "array"},
    {field: "search", queryParam: "search", type: "string"},
    {field: "stores", queryParam: "stores", type: "array"},
    {field: "shipments", queryParam: "shipments", type: "array"},
    {field: "marginRange", queryParam: "marginRange", type: "numberRange"},
    {field: "roiRange", queryParam: "roiRange", type: "numberRange"},
    {field: "profitRange", queryParam: "profitRange", type: "numberRange"},
    {field: "startDate", queryParam: "start", type: "date", defaultValue: getRelativeDateByTimezone(30, userProfile?.timezone)},
    {field: "endDate", queryParam: "end", type: "date", defaultValue: getToday().toDate()},
    {field: "page", queryParam: "page", type: "number", defaultValue: localQuery.page},
    {field: "pageSize", queryParam: "pageSize", type: "number", defaultValue: localQuery.pageSize},
    {field: "sortBy", queryParam: "sortBy", type: "string", defaultValue: localQuery.sortBy},
    {field: "sortingOrder", queryParam: "sortingOrder", type: "number", defaultValue: localQuery.sortingOrder},
    {field: "action", queryParam: "action", type: "string", defaultValue: "filtering"},
  ];

  const {readQueryParams, updateQuery, searchParams} = useUrlQuery<FilterOrderQuery>(filterFields);

  const debouncedLoadList = useDebounce(() => {
    getUserOrders(localQuery)(dispatch).then(() => {
      tableRef.current?.resetSelection();
    });
  }, 200);

  useEffect(() => {
    if (hasPermission("inventory") && searchParams.has("action")) {
      const params = readQueryParams();
      updateLocalQuery(params as FilterOrderQuery);
      debouncedLoadList();
    }
  }, [debouncedLoadList, searchParams]); // eslint-disable-line

  useImperativeHandle(
    listRef,
    () => {
      return {
        reload: () => {
          debouncedLoadList();
        },
      };
    },
    [debouncedLoadList],
  );

  const ordersData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Orders.loading,
      userOrders: state.Orders.userOrders,
    }),
  );
  const {loading, userOrders} = useSelector(ordersData);

  const columns = useMemo<ColumnDef<UserOrderWithShipments, any>[]>(
    () => [
      {
        header: t("Orders.TableColumn.OrderId"),
        enableSorting: false,
        size: 50,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserOrderWithShipments;
          return (
            <>
              <div className="mb-1 d-flex">
                <Link className="link-secondary link-offset-2 text-decoration-underline me-1 nowrap" target="_blank" to={`/orders/${row.amazonOrderId}`}>
                  {row.amazonOrderId}
                  <i className="ri-arrow-right-up-line"></i>
                </Link>
              </div>
              <div className="d-flex align-items-center text-nowrap text-muted">
                <DomainToFlag marketplace={row.userStore.marketplace} />
                <span>{`${row.userStore.marketplace} - ${row.userStore.name}`}</span>
              </div>
            </>
          );
        },
      },
      {
        header: t("Orders.TableColumn.Products"),
        enableSorting: false,
        size: 50,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserOrderWithShipments;
          return (
            <>
              {row.userOrderItems && row.userOrderItems.length <= 1 ? (
                <div className="d-flex align-items-center">
                  <div className="d-flex align-items-center avatar-sm rounded flex-shrink-0 overflow-hidden">
                    <img
                      className="rounded-circle w-100"
                      src={`https://m.media-amazon.com/images/I/${row.userOrderItems[0].userInventory?.imageSmall}`}
                      onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                        e.currentTarget.onerror = null;
                        e.currentTarget.src = errorImage;
                      }}
                      alt=""
                    />
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <div className="d-flex align-items-center">
                      <CopyWidget text={row.userOrderItems[0].asin} index={`${row.amazonOrderId}-${row.userOrderItems[0].asin}`} asLink={true} marketplace={row.userStore.marketplace} />
                    </div>
                    <div className="d-flex align-items-center">
                      <CopyWidget text={row.userOrderItems[0].sellerSKU} index={`${row.amazonOrderId}-${row.userOrderItems[0].sellerSKU}`} asLink={false} marketplace={row.userStore.marketplace} />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <div className="avatar-group">
                      {row.userOrderItems &&
                        row.userOrderItems.map((item: UserOrderItem, itemIndex: number) => {
                          const itemImage = `https://m.media-amazon.com/images/I/${item.userInventory.imageSmall}`;
                          return (
                            <div key={itemIndex}>
                              <Link to={`/inventory?search=${item.asin}`} target="_blank" className="avatar-group-item">
                                <img
                                  src={itemImage}
                                  id={`multiple-order-item--${item.asin}`}
                                  alt=""
                                  className="rounded-circle avatar-sm"
                                  onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                    e.currentTarget.onerror = null;
                                    e.currentTarget.src = errorImage;
                                  }}
                                />
                              </Link>
                              <DefaultUncontrolledTooltip target={`multiple-order-item--${item.asin}`}>
                                {item.asin} x {item.quantityOrdered}
                              </DefaultUncontrolledTooltip>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <Link className="link-body-emphasis link-offset-1 text-decoration-underline link-underline-opacity-75-hover " target="_blank" to={`/orders/${row.amazonOrderId}`}>
                      {t("Dashboard.LatestOrders.Link.ClickForDetails")}
                      <i className="ri-arrow-right-up-line"></i>
                    </Link>
                  </div>
                </div>
              )}
            </>
          );
        },
      },
      {
        header: t("Orders.TableColumn.Shipments"),
        enableSorting: false,
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserOrderWithShipments;
          return (
            <>
              {row.userShipments != null && row.userShipments?.length > 0 ? (
                row.userShipments?.map((shipment: UserShipment, index: number) => {
                  return (
                    <div key={`${row.amazonOrderId}-${index}`}>
                      <Link to={`/shipments/shipment-details/${shipment.userShipmentId}`} target="_blank" className="link-secondary link-offset-2 text-decoration-underline me-1 nowrap">
                        {shipment.name}
                        <i className="ri-arrow-right-up-line"></i>
                      </Link>
                      <br />
                    </div>
                  );
                })
              ) : (
                <span className="text-muted">—</span>
              )}
            </>
          );
        },
      },
      {
        header: t("Orders.TableColumn.Status"),
        enableSorting: true,
        accessorKey: "orderStatus",
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserOrderWithShipments;
          return (
            <>
              <OrderStatusBadge key={row.userOrderId} status={row.orderStatus} size="sm" />
            </>
          );
        },
      },
      {
        header: t("Orders.TableColumn.Quantity"),
        enableSorting: true,
        accessorKey: "quantityOrdered",
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserOrderWithShipments;
          return (
            <>
              <h5 className="fs-14 my-1 align-items-center d-flex">
                {row.quantityOrdered} <i className="ri-bar-chart-fill text-success fs-16 ms-1"></i>
              </h5>
              <span className="text-muted text-nowrap"></span>
            </>
          );
        },
      },
      {
        header: t("Orders.TableColumn.TotalAndProfit"),
        enableSorting: true,
        accessorKey: "totalSellPrice",
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserOrderWithShipments;
          const rowCurrency = AllMarketplaces.find((amazonMarketplaceInfo) => amazonMarketplaceInfo.marketplace === row.userStore.marketplace)?.currency;
          return (
            <>
              {row.orderStatus !== undefined && row.orderStatus <= OrderStatus.SHIPPED ? (
                <>
                  {row.financeStatus !== FinanceStatus.Completed && row.totalFBAFee !== null ? (
                    <>
                      <span className="text-muted">
                        <DisplayPrice source={rowCurrency} value={row.totalSellPrice} decimals={2} notation="abbreviated" />
                      </span>
                      <h5 className="fs-14 my-1 d-flex align-items-center">
                        <DisplayPrice
                          id={`EstimatedFinancialDataTooltip-TotalAndProfit-${row.amazonOrderId}`}
                          className="estimated-text"
                          source={rowCurrency}
                          value={row.profit!}
                          decimals={2}
                          notation="abbreviated"
                        />
                        <DefaultUncontrolledTooltip target={`EstimatedFinancialDataTooltip-TotalAndProfit-${row.amazonOrderId}`}>
                          {t("Orders.Warnings.EstimatedFinancialData.Description")}
                        </DefaultUncontrolledTooltip>
                      </h5>
                    </>
                  ) : row.financeStatus !== FinanceStatus.Completed ? (
                    <>
                      <div className="bg-body-tertiary p-2" id={`MissingFinancialDataTooltip-TotalAndProfit-${row.amazonOrderId}`}></div>
                      <DefaultUncontrolledTooltip target={`MissingFinancialDataTooltip-TotalAndProfit-${row.amazonOrderId}`}>
                        {t("Orders.Warnings.MissingFinancialData.Description")}
                      </DefaultUncontrolledTooltip>
                    </>
                  ) : (
                    <>
                      <span className="text-muted">
                        <DisplayPrice source={rowCurrency} value={row.totalSellPrice} decimals={2} notation="abbreviated" />
                      </span>
                      <h5 className="fs-14 my-1 d-flex align-items-center">
                        <DisplayPrice source={rowCurrency} value={row.profit!} decimals={2} notation="abbreviated" />
                        {row.costIsPredicted && (
                          <>
                            <i className="ri-question-fill text-warning fs-14 ms-1" id={`CostIsPredictedTooltip-${row.amazonOrderId}`} />
                            <DefaultUncontrolledTooltip target={`CostIsPredictedTooltip-${row.amazonOrderId}`}>{t("CostIsPredicted")}</DefaultUncontrolledTooltip>
                          </>
                        )}
                      </h5>
                    </>
                  )}
                </>
              ) : (
                <span className="text-muted">—</span>
              )}
            </>
          );
        },
      },
      {
        header: t("Orders.TableColumn.RoiMargin"),
        enableSorting: true,
        accessorKey: "roi",
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserOrderWithShipments;
          return (
            <>
              {row.orderStatus !== undefined && row.orderStatus <= OrderStatus.SHIPPED ? (
                row.financeStatus !== FinanceStatus.Completed && row.totalFBAFee !== null ? (
                  <>
                    <span className="text-muted">
                      <DisplayNumber value={row.roi} decimals={0} suffix="%" />
                    </span>
                    <h5 className="fs-14 my-1 d-flex align-items-center">
                      <DisplayNumber id={`EstimatedFinancialDataTooltip-RoiMargin-${row.amazonOrderId}`} className="estimated-text" value={row.margin} decimals={0} suffix="%" />
                      <DefaultUncontrolledTooltip target={`EstimatedFinancialDataTooltip-RoiMargin-${row.amazonOrderId}`}>
                        {t("Orders.Warnings.EstimatedFinancialData.Description")}
                      </DefaultUncontrolledTooltip>
                    </h5>
                  </>
                ) : row.financeStatus !== FinanceStatus.Completed ? (
                  <>
                    <div className="bg-body-tertiary p-2" id={`MissingFinancialDataTooltip-RoiMargin-${row.amazonOrderId}`}></div>
                    <DefaultUncontrolledTooltip target={`MissingFinancialDataTooltip-RoiMargin-${row.amazonOrderId}`}>
                      {t("Orders.Warnings.MissingFinancialData.Description")}
                    </DefaultUncontrolledTooltip>
                  </>
                ) : (
                  <>
                    <span className="text-muted">
                      <DisplayNumber value={row.roi} decimals={0} suffix="%" />
                    </span>
                    <h5 className="fs-14 my-1">
                      <DisplayNumber value={row.margin} decimals={0} suffix="%" />
                    </h5>
                  </>
                )
              ) : (
                <span className="text-muted">—</span>
              )}
            </>
          );
        },
      },

      {
        header: t("Orders.TableColumn.FeesAndTaxes"),
        enableSorting: true,
        accessorKey: "totalFees",
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserOrderWithShipments;
          const rowCurrency = AllMarketplaces.find((amazonMarketplaceInfo) => amazonMarketplaceInfo.marketplace === row.userStore.marketplace)?.currency;
          return (
            <>
              {row.orderStatus !== undefined && row.orderStatus <= OrderStatus.SHIPPED ? (
                row.financeStatus !== FinanceStatus.Completed && row.totalFBAFee !== null ? (
                  <>
                    <span className="text-muted">
                      <DisplayPrice
                        id={`EstimatedFinancialDataTooltip-FeesAndTaxes-${row.amazonOrderId}`}
                        className="estimated-text"
                        source={rowCurrency}
                        value={row.totalFees!}
                        decimals={2}
                        notation="abbreviated"
                      />
                      <DefaultUncontrolledTooltip target={`EstimatedFinancialDataTooltip-FeesAndTaxes-${row.amazonOrderId}`}>
                        {t("Orders.Warnings.EstimatedFinancialData.Description")}
                      </DefaultUncontrolledTooltip>
                    </span>
                    <h5 className="fs-14 my-1 text-nowrap">
                      <div className="bg-body-tertiary p-2 me-3" id={`MissingFinancialDataTooltip-FeesAndTaxes-${row.amazonOrderId}`}></div>
                      <DefaultUncontrolledTooltip target={`MissingFinancialDataTooltip-FeesAndTaxes-${row.amazonOrderId}`}>
                        {t("Orders.Warnings.MissingFinancialData.Description")}
                      </DefaultUncontrolledTooltip>
                    </h5>
                  </>
                ) : row.financeStatus !== FinanceStatus.Completed ? (
                  <>
                    <div className="bg-body-tertiary p-2 me-3" id={`MissingFinancialDataTooltip-FeesAndTaxes-${row.amazonOrderId}`}></div>
                    <DefaultUncontrolledTooltip target={`MissingFinancialDataTooltip-FeesAndTaxes-${row.amazonOrderId}`}>
                      {t("Orders.Warnings.MissingFinancialData.Description")}
                    </DefaultUncontrolledTooltip>
                  </>
                ) : (
                  <>
                    <span className="text-muted text-nowrap">
                      <DisplayPrice source={rowCurrency} value={row.totalFees!} decimals={2} notation="abbreviated" />
                    </span>
                    <h5 className="fs-14 my-1 text-nowrap">
                      <DisplayPrice source={rowCurrency} value={row.totalTax!} decimals={2} notation="abbreviated" />
                    </h5>
                  </>
                )
              ) : (
                <span className="text-muted">—</span>
              )}
            </>
          );
        },
      },
      {
        header: t("Orders.TableColumn.OrderDate"),
        enableSorting: true,
        accessorKey: "purchaseDate",
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserOrderWithShipments;
          return (
            <>
              <DisplayDate id={`DisplayDateOrder-${row.userOrderId}`} value={row.purchaseDate} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} showDateAndTimeSeparate={true} />
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, userOrders],
  );

  document.title = t("PageTitles.Orders");

  return (
    <Restricted require={PAGE_IDENTIFIER} read fallback={() => <Unauthorized />}>
      <>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title={t("Orders.Title")} menus={[{label: t("Orders.Title")}]} />
            <>
              <Filters busy={loading.filter} fields={filterFields} />
            </>
            {loading.filter ? <Loader /> : userOrders.items && userOrders.items.length > 0 ? <Stats busy={loading.list} /> : ""}
            <Card>
              <CardBody>
                {loading.filter ? (
                  <Loader />
                ) : userOrders.items && userOrders.items.length > 0 ? (
                  <>
                    <DataTable
                      ref={tableRef}
                      busy={loading.list || loading.updateShipment}
                      columns={columns}
                      data={userOrders.items || []}
                      totalDataLength={userOrders.totalCount}
                      pagination={{
                        pageIndex: localQuery.page - 1,
                        pageSize: localQuery.pageSize,
                      }}
                      onPaginationChanged={(pagination) => {
                        updateQuery({...localQuery, page: pagination.pageIndex + 1, pageSize: pagination.pageSize, action: "paginating"});
                      }}
                      onSortingChanged={(sorting) => {
                        updateQuery({...localQuery, sortBy: sorting?.sortBy, sortingOrder: sorting?.sortingOrder, action: "sorting"});
                      }}
                      hovered
                    />
                  </>
                ) : (
                  <NoResult title={t("Orders.NoResult.Title")} description={t("Orders.NoResult.Description")} />
                )}
              </CardBody>
            </Card>
          </Container>
        </div>
      </>
    </Restricted>
  );
};

export default Orders;
