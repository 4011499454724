import {createSelector} from "reselect";
import {RootState} from "slices";

export const ShipmentSlice = createSelector(
  (state: RootState) => state,
  (state) => ({
    loading: state.Shipment.loading,
    userShipmentList: state.Shipment.userShipmentList,
    currentUserShipment: state.Shipment.currentUserShipment,
    currentUserShipmentOrders: state.Shipment.currentUserShipmentOrders,
    error: state.Shipment.error,
  }),
);
