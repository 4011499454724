import {createListenerMiddleware} from "@reduxjs/toolkit";
import {RootState} from "slices";
import {setLoginUser, setUserPreferences} from "./reducer";
import {fetchUserStores} from "services/store_service";

const loginListener = createListenerMiddleware<RootState>();

loginListener.startListening({
  actionCreator: setLoginUser, // action creator
  effect: async (action, {dispatch, getState}) => {
    const state = getState();
    if (state.Login.user && !state.Login.user.tokenInfo.isTempToken) {
      await dispatch(fetchUserStores());
    }
  },
});

loginListener.startListening({
  actionCreator: setUserPreferences, // action creator
  effect: async (action, {dispatch, getState}) => {
    const state = getState();
    if (state.Login.user && !state.Login.user.tokenInfo.isTempToken) {
      await dispatch(fetchUserStores());
    }
  },
});

export default loginListener;
