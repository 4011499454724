import { useCallback, useEffect, useMemo, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DateRange, FormikValidation, NumberRange } from "helpers/types";
import { useProfile } from "./useProfile";
import moment from "moment";

export type FieldType = "number" | "numberRange" | "string" | "array" | "date" | "dateRange" | "bool";

export type FieldConfig<T> = {
  field: keyof T;
  queryParam: string;
  type: FieldType;
  defaultValue?: T[keyof T];
  hideQuery?: boolean;
  hideChip?: boolean;
  chipName?: string;
  disabled?: boolean;
};

export const useUrlQuery = <T>(fields: FieldConfig<T>[], validation?: FormikValidation<T>) => {
  const { search: LocationSearch, pathname: LocationPathname } = useLocation();
  const navigate = useNavigate();
  const { userProfile } = useProfile();
  const initialized = useRef(false);
  const searchParams = useMemo(() => new URLSearchParams(LocationSearch), [LocationSearch]);

  // Read field from the URL
  const readQueryParams = useCallback(() => {
    const params: Partial<T> = {};
    fields.forEach(({ field, queryParam, type, disabled }) => {
      const value = searchParams.get(queryParam);
      if (!value || disabled) return;

      try {
        switch (type) {
          case "array":
            params[field] = value.split(",") as T[keyof T];
            break;
          case "bool":
            params[field] = (value === "true" as unknown) as T[keyof T];
            break;
          case "date":
            if (!userProfile) break;

            const dateStr = value;
            
            if (queryParam.includes("end")) {
              const date = moment.tz(dateStr, "YYYY-MM-DD", userProfile.timezone).endOf('day').toDate();
              params[field] = date as T[keyof T];
            } else if (queryParam.includes("start")) {
              const date = moment.tz(dateStr, "YYYY-MM-DD", userProfile.timezone).startOf('day').toDate();
              params[field] = date as T[keyof T];
            } else {
              const date = moment.tz(dateStr, "YYYY-MM-DD", userProfile.timezone).toDate();
              params[field] = date as T[keyof T];
            }
            break;
          case "dateRange":
            if (!userProfile) break;
            const delimiter = ";";
            const [start, end] = value.split(delimiter);
            const dateRange: DateRange = {
              start: start
                ? moment(start, "YYYY-MM-DD")
                    .tz(userProfile.timezone)
                    .startOf("day")
                    .tz("Etc/GMT")
                    .toDate()
                : undefined,
              end: end
                ? moment(end, "YYYY-MM-DD")
                    .tz(userProfile.timezone)
                    .endOf("day")
                    .tz("Etc/GMT")
                    .toDate()
                : undefined,
            };
            params[field] = dateRange as T[keyof T];
            break;
          case "numberRange":
            const [min, max] = value.split(";").map((v) => (v ? Number(v) : undefined));
            const range: NumberRange = {
              min: Number(min) !== undefined ? Number(min) : undefined,
              max: Number(max) !== undefined ? Number(max) : undefined,
            };
            params[field] = range as T[keyof T];
            break;
          default:
            params[field] = value as T[keyof T];
        }
      } catch (error) {
        console.warn(`Error parsing parameter ${queryParam}:`, error);
      }
    });
    return params;
  }, [searchParams, fields, userProfile]);

  const updateQuery = useCallback(
    (values: Partial<T>) => {
      const newSearchParams = new URLSearchParams(searchParams);

      fields.forEach(({ field, queryParam, hideQuery: hide, type, disabled }) => {
        const value = values[field];
        if (
          value === undefined ||
          value === null ||
          (Array.isArray(value) && value.length === 0) ||
          (typeof value === "string" && value.trim() === "") ||
          disabled
        ) {
          newSearchParams.delete(queryParam);
          return;
        }

        if (!hide) {
          if (type === "array" && Array.isArray(value)) {
            newSearchParams.set(queryParam, value.join(","));
          } else if (type === "date" && value instanceof Date) {
            const dateStr = moment(value).tz(userProfile?.timezone!).format("YYYY-MM-DD");
            newSearchParams.set(queryParam, dateStr);
          } else if (type === "dateRange") {
            const dateRange = value as DateRange;
            if (dateRange.start || dateRange.end) {
              const startDate = dateRange.start ? moment(dateRange.start).format("YYYY-MM-DD") : "";
              const endDate = dateRange.end ? moment(dateRange.end).format("YYYY-MM-DD") : "";
              newSearchParams.set(queryParam, `${startDate};${endDate}`);
            } else {
              newSearchParams.delete(queryParam);
            }
          } else if (type === "numberRange") {
            const range = value as NumberRange;
            if (range.min === undefined && range.max === undefined) {
              newSearchParams.delete(queryParam);
            } else {
              const minVal = range.min !== undefined && range.min !== null ? range.min.toString() : "";
              const maxVal = range.max !== undefined && range.max !== null ? range.max.toString() : "";
              if (!minVal && !maxVal) {
                newSearchParams.delete(queryParam);
              } else {
                newSearchParams.set(queryParam, `${minVal};${maxVal}`);
              }
            }
          } else {
            newSearchParams.set(queryParam, value as string);
          }
        }
      });

      newSearchParams.set("_t", Date.now().toString());
      const decodedSearch = decodeURIComponent(newSearchParams.toString());
      
      navigate(
        {
          pathname: LocationPathname,
          search: decodedSearch,
        },
        {
          replace: !initialized.current
        }
      );

      initialized.current = true;
    },
    [fields, navigate, LocationPathname, searchParams] // eslint-disable-line
  );

  const updateFormik = useCallback(() => {
    if (validation) {
      const urlParams = readQueryParams();
      const fieldsWithDefaults = fields.reduce((acc, { field, defaultValue, disabled }) => {
        if (defaultValue !== undefined && !disabled && !(field in urlParams)) {
          acc[field] = defaultValue;
        }
        return acc;
      }, {} as Partial<T>);

      validation.setValues({ ...fieldsWithDefaults, ...urlParams } as T);
    }
  }, [fields, readQueryParams]);// eslint-disable-line

  useEffect(() => {
    if (!initialized.current) {
      updateFormik();
    }
  }, [updateFormik]);

  return {
    updateQuery,
    readQueryParams,
    searchParams,
  };
};