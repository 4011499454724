import {Modal, ModalHeader, ModalBody} from "reactstrap";
import {UserSearchProductCheckList} from "models/user_search_product_check_list";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {AmazonBusinessModel} from "models/enums/user_search_type";
import {createTypedModal} from "helpers/modal_helpers";
import {UserSearchProductVariationAnalysis} from "models/user_search_product_variation_analysis";
import {UserSearchProductSummary} from "models/user_search_product_summary";
import DecisionsExtendedInfo from "./Components/DecisionsExtendedInfo";

interface DecisionReasonsModalData {
  asin: string;
  amazonBusinessModel: AmazonBusinessModel;
  variationAnalysis?: UserSearchProductVariationAnalysis;
  checkList: UserSearchProductCheckList;
  summary: UserSearchProductSummary;
}
export const DecisionReasonsModal = createTypedModal<DecisionReasonsModalData>("decision_reason");

const DecisionReasons = () => {
  const {open, data} = DecisionReasonsModal.useModal();

  if (!open) return null;
  return <DecisionReasonsContent data={data} />;
};

const DecisionReasonsContent = ({data}: {data: DecisionReasonsModalData | undefined}) => {
  const {t} = useTranslation();

  const [decisionReasonsList, setDecisionReasonsList] = useState<string[]>();
  const decisionsExtendedInfoItems = ["SameProductCheckRecheck", "CustomsCheckKeywordCheckRecheck", "CustomsCheckCategoryCheckRecheck"];

  useEffect(() => {
    if (data?.amazonBusinessModel === AmazonBusinessModel.DROPSHIPPING) {
      setDecisionReasonsList(data.summary.decisionReasonsDS?.split(","));
    } else {
      setDecisionReasonsList(data!.summary.decisionReasons?.split(","));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const toggle = () => {
    DecisionReasonsModal.close();
  };

  return (
    <Modal id="showDecisionReasonsModal" isOpen={true} toggle={toggle} fade={true} centered={true}>
      <ModalHeader className="p-3" toggle={toggle}>
        {t("SearchResults.Dialog.DecisionReasons.Title")}
      </ModalHeader>
      <ModalBody>
        {decisionReasonsList ? (
          decisionReasonsList?.map((reason: string, index: number) => {
            // By default, use the normal name
            let reasonName = t(`DecisionReasons.${reason}.Name`);
            let reasonDescription = t(`DecisionReasons.${reason}.Description`);

            // If reason is "VariationCountRecheck" and variationCount is between 2 and 5, use the special name
            if (reason === "VariationCountRecheck" && data?.variationAnalysis?.variationCount != null && data.variationAnalysis.variationCount >= 2 && data.variationAnalysis.variationCount <= 5) {
              reasonName = t(`DecisionReasons.${reason}.Low.Name`);
              reasonDescription = t(`DecisionReasons.${reason}.Low.Description`);
            }

            return (
              <div key={index} className="d-flex">
                <div className="flex-shrink-0 me-2">
                  <i className={`bx ${reason.endsWith("Recheck") || reason.endsWith("Warning") ? "bxs-error text-warning" : "bxs-x-circle text-danger"} fs-20`}></i>
                </div>
                <div className="flex-grow-1">
                  <h5>{reasonName}</h5>
                  <p className="text-muted">{reasonDescription}</p>
                  {decisionsExtendedInfoItems.includes(reason) && <DecisionsExtendedInfo reason={reason} checkList={data?.checkList!} />}
                </div>
              </div>
            );
          })
        ) : (
          <div>{t("No reason")}</div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default DecisionReasons;
