import {createSelector} from "reselect";
import {RootState} from "slices";

export const StoresSlice = createSelector(
  (state: RootState) => state,
  (state) => ({
    list: state.Stores.list,
    currentUserStore: state.Stores.currentUserStore,
    loading: state.Stores.loading,
    error: state.Stores.error,
  }),
);
