import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ApiError, LoadingStates, PagedList} from "helpers/types";
import {TeamUser} from "models/user";
import {UserLimitUsageHistory} from "models/user_limit_usage_history";
import {UserPayment} from "models/user_payment";
import {UserPaymentMethod} from "models/user_payment_methods";
import {UserSettingNotificationOptions} from "models/user_setting_notification_options";
import {UserSubscription} from "models/user_subscription";

export type TwoFactorSetup = {
  qrCodeUri: string;
  manualEntryKey: string;
}
export type AccountLoadingStates = LoadingStates<
  "list" | 
  "save" | 
  "update" | 
  "download" | 
  "upload" | 
  "paymentMethodList" | 
  "userSubscription" | 
  "paymentMethod" | 
  "delete" | 
  "resumeSubscription" | 
  "filter" | 
  "limit" | 
  "retryPayment" |
  "twoFactorSetup" | 
  "twoFactorEnable" |
  "twoFactorDisable"
>;

export type AccountState = {
  loading: AccountLoadingStates;
  twoFactorSetup: TwoFactorSetup;                                   // Security
  paymentHistoryList: Partial<PagedList<UserPayment>>;              // PaymentHistory
  paymentMethods: UserPaymentMethod[];                              // PaymentMethods
  userSubscription: UserSubscription;                               // Subscription
  userBalance: number;                                              // Subscription
  team: TeamUser[];                                                 // Team
  notificationOptions: UserSettingNotificationOptions;              // Notifications
  limitUsageHistoryList: Partial<PagedList<UserLimitUsageHistory>>; // Limit Usage History
  error?: ApiError;
};

const initialState: AccountState = {
  loading: {
    list: false,
    save: false,
    download: false,
    update: false,
    upload: false,
    delete: false,
    paymentMethodList: false,
    userSubscription: false,
    paymentMethod: false,
    resumeSubscription: false,
    filter: false,
    limit: false,
    retryPayment: false,
    twoFactorSetup: false,
    twoFactorEnable: false,
    twoFactorDisable: false,
  },
  twoFactorSetup: {} as TwoFactorSetup,
  paymentHistoryList: {items: []},
  paymentMethods: [] as UserPaymentMethod[],
  userSubscription: {} as UserSubscription,
  userBalance: 0,
  team: [] as TeamUser[],
  notificationOptions: {
    emailAnalysisCompleted: false,
    webAnalysisCompleted: false,
    emailASINFetchCompleted: false,
    webASINFetchCompleted: false,
    emailShipmentCompleted: false,
    webShipmentCompleted: false,
    emailNewOrder: false,
    webNewOrder: false,
    emailPaymentReceived: false,
    webPaymentReceived: false,
    emailPaymentFailed: false,
    webPaymentFailed: false,
    emailDataDeletion: false,
    webDataDeletion: false,
    emailSubscriptionChange: false,
    webSubscriptionChange: false,
    emailSubscriptionCancellation: false,
    webSubscriptionCancellation: false,
    emailSubscriptionReactivation: false,
    webSubscriptionReactivation: false,
  } as UserSettingNotificationOptions,
  limitUsageHistoryList: {items: []},
  error: {} as ApiError,
};

const AccountSlice = createSlice({
  name: "Account",
  initialState,
  reducers: {
    setList(state, action: PayloadAction<PagedList<UserPayment>>) {
      state.paymentHistoryList = action.payload;
    },
    loading(state, action: PayloadAction<[keyof AccountLoadingStates, boolean]>) {
      const [operation, loadingState] = action.payload;
      state.loading[operation] = loadingState;
    },
    setPaymentMethods(state, action: PayloadAction<UserPaymentMethod[]>) {
      state.paymentMethods = action.payload;
    },
    setUserSubscription(state, action: PayloadAction<UserSubscription>) {
      state.userSubscription = action.payload;
    },
    setUserBalance(state, action: PayloadAction<number>) {
      state.userBalance = action.payload;
    },
    setTeam(state, action: PayloadAction<TeamUser[]>) {
      state.team = action.payload;
    },
    setNotificationOption(state, action: PayloadAction<UserSettingNotificationOptions>) {
      state.notificationOptions = action.payload;
    },
    setUserLimitUsageHistory(state, action: PayloadAction<Partial<PagedList<UserLimitUsageHistory>>>) {
      state.limitUsageHistoryList = action.payload;
    },
    setTwoFactorSetup(state, action: PayloadAction<TwoFactorSetup>) {
      state.twoFactorSetup = action.payload;
    },
    apiError(state, action: PayloadAction<ApiError>) {
      state.error = action.payload;
    },
  },
});

export const {
  setList,
  loading,
  setPaymentMethods,
  setUserSubscription,
  setUserBalance,
  setTeam,
  setNotificationOption,
  setUserLimitUsageHistory,
  setTwoFactorSetup,
  apiError,
} = AccountSlice.actions;

export default AccountSlice.reducer;
