import i18n from "i18n";
import {ShipmentStatus} from "models/enums/shipment_status";
import { UserShipment } from "models/user_shipment";
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown } from "reactstrap";

const getAvailableStatusTransitions = (currentStatus: ShipmentStatus): {status: ShipmentStatus; label: string; color: string}[] => {
  const transitions: {status: ShipmentStatus; label: string; color: string}[] = [];

  if (currentStatus > ShipmentStatus.DRAFT) {
    const previousStatus = currentStatus - 1;
    transitions.push({
      status: previousStatus,
      label: i18n.t("Shipments.ShipmentDetails.BackToStatus", {value: getUserShipmentStatus(previousStatus)}),
      color: getStatusColor(previousStatus),
    });
  }

  // Allow one step forward
  if (currentStatus < ShipmentStatus.COMPLETED) {
    const nextStatus = currentStatus + 1;
    transitions.push({
      status: nextStatus,
      label: i18n.t("Shipments.ShipmentDetails.UpdateToStatus", {value: getUserShipmentStatus(nextStatus)}),
      color: getStatusColor(nextStatus),
    });
  }

  return transitions;
};

const getUserShipmentStatus = (status: ShipmentStatus): string => {
  switch (status) {
    case ShipmentStatus.DRAFT:
      return i18n.t("ShipmentStatus.DRAFT");
    case ShipmentStatus.ORDERED:
      return i18n.t("ShipmentStatus.ORDERED");
    case ShipmentStatus.SHIPPED:
      return i18n.t("ShipmentStatus.SHIPPED");
    case ShipmentStatus.DELIVERED:
      return i18n.t("ShipmentStatus.DELIVERED");
    case ShipmentStatus.ACTIVE:
      return i18n.t("ShipmentStatus.ACTIVE");
    case ShipmentStatus.COMPLETED:
      return i18n.t("ShipmentStatus.COMPLETED");
    default:
      return i18n.t("ShipmentStatus.DRAFT");
  }
};

const getStatusColor = (status: ShipmentStatus): string => {
  switch (status) {
    case ShipmentStatus.DRAFT:
      return "warning";
    case ShipmentStatus.ORDERED:
      return "info";
    case ShipmentStatus.SHIPPED:
      return "primary";
    case ShipmentStatus.DELIVERED:
      return "secondary";
    case ShipmentStatus.ACTIVE:
      return "success";
    case ShipmentStatus.COMPLETED:
      return "dark";
    default:
      return "warning";
  }
};


const renderStatusDropdown = (currentUserShipment: UserShipment, busy: boolean, onStatusChange: (status: ShipmentStatus) => void) => {
  if (currentUserShipment == null) return null;
  const status: ShipmentStatus = currentUserShipment.status;
  const availableTransitions = getAvailableStatusTransitions(currentUserShipment.status);
  const hasAnyOrder = currentUserShipment.soldItems > 0;
  return (
    <UncontrolledDropdown size="sm" disabled={busy || hasAnyOrder}>
      <DropdownToggle color={getStatusColor(status)} caret={!busy && !hasAnyOrder}>
        <span>
          {getUserShipmentStatus(status)}
          {busy && <Spinner size="sm" className="ms-2 align-middle" />}
        </span>
      </DropdownToggle>
      <DropdownMenu>
        {availableTransitions.map((transition, index) => (
          <DropdownItem key={index} onClick={() => onStatusChange(transition.status)}>
            <div className="d-flex justify-content-between">
              <span className="d-flex align-items-center">{transition.label}</span>
              <span>
                <i className="mdi mdi-arrow-right fs-18 ms-4 me-1 align-middle" />
                <Badge color={transition.color}>{getUserShipmentStatus(transition.status).toUpperCase()}</Badge>
              </span>
            </div>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export {getUserShipmentStatus, getStatusColor, renderStatusDropdown};
