import {createSelector} from "reselect";
import {RootState} from "slices";

export const SystemMonitoringSlice = createSelector(
  (state: RootState) => state,
  (state) => ({
    loading: state.AdminSystemMonitoring.loading,
    systemMonitoring: state.AdminSystemMonitoring.systemMonitoring,
    details: state.AdminSystemMonitoring.details,
    error: state.AdminSystemMonitoring.error,
  }),
);
