import {UserSearchProduct} from "models/user_search_product";
import {Card, CardBody} from "reactstrap";
import BSRHistoryChart from "./Charts/BsrHistoryChart";

interface BsrHistoryProps {
  item: UserSearchProduct;
}
const BsrHistory = ({item}: BsrHistoryProps) => {
  return (
    <Card className="border card-animate mb-3">
      <CardBody>
        <BSRHistoryChart bsrHistoryJSON={item.summary.bsrHistoryJSON} />
      </CardBody>
    </Card>
  );
};

export default BsrHistory;
