import {useTranslation} from "react-i18next";
import {Card, CardBody} from "reactstrap";
import {UserSearchProduct} from "models/user_search_product";

interface NoAvailableOfferProps {
  item: UserSearchProduct;
}
const NoAvailableOffer = ({item}: NoAvailableOfferProps) => {
  const {t} = useTranslation();

  return (
    <Card className="border border-danger border-opacity-25 bg-opacity-10 card-animate mb-3 bg-danger">
      <CardBody>
        <h5 className="mb-2">{t("SearchResults.Analyzes.ProfitAnalysis.Title")}</h5>
        <p className="mb-0 text-muted">
          {item.summary?.decisionReasons?.includes("NoAvailableOfferAtSource") && item.summary?.decisionReasons?.includes("NoAvailableOfferAtTarget") ? (
            t("DecisionReasons.NoAvailableOfferAtBoth.Description")
          ) : (
            <>
              {item.summary?.decisionReasons?.includes("NoAvailableOfferAtSource") && t("DecisionReasons.NoAvailableOfferAtSource.Description")}
              {item.summary?.decisionReasons?.includes("NoAvailableOfferAtTarget") && t("DecisionReasons.NoAvailableOfferAtTarget.Description")}
            </>
          )}
        </p>
      </CardBody>
    </Card>
  );
};

export default NoAvailableOffer;
