import {UserSearchesQuery, UserSearch} from "models/user_search";
import {apiError, deleteOrRestoreItem, loading, markReviewedItem, reset, setSearchesList, updateSearchItem} from "./reducer";
import {ResponseModel} from "models/response_model";
import {postChangeSearchVisibility, postDeleteSearch, postGetUserSearches, postMarkSearchReviewed, postSaveNewSearch} from "services/search_service";
import {PagedList} from "helpers/types";
import {generateError, renderSuccessToast} from "helpers/utilities";
import {refreshRemainingLimits} from "slices/thunks";
import {ChangeSearchVisibilityCommand, DeleteSearchCommand, MarkSearchReviewedCommand, SaveUserSearchCommand} from "api/command";
import i18n from "i18n";

export const getUserSearchesList = (query: UserSearchesQuery) => async (dispatch: any) => {
  const loadingType = query.action === "filtering" ? "filter" : "list";
  try {
    dispatch(loading([loadingType, true]));
    const result: ResponseModel = await postGetUserSearches(query);
    const pagedResult: PagedList<UserSearch> = result.data;
    dispatch(setSearchesList(pagedResult));
    return true;
  } catch (error:any) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading([loadingType, false]));
  }
};

export const saveNewSearch = (request: SaveUserSearchCommand, isReAnalyzing?:boolean) => async (dispatch: any) => {
  try {
    if(isReAnalyzing){
      dispatch(loading(["reanalyze", true]));
    }
    else{
      dispatch(loading(["save", true]));
    }

    await postSaveNewSearch(request);
    dispatch(refreshRemainingLimits());
    renderSuccessToast(i18n.t("Searches.SaveSuccess"));
    return true;
  } catch (error:any) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    if(isReAnalyzing)
      dispatch(loading(["reanalyze", false]));
    else{
      dispatch(loading(["save", false]));
    }
  }
};

export const deleteSearch = (command: DeleteSearchCommand, searchName:string) => async (dispatch: any) => {
  try {
    dispatch(loading(["delete", true]));
    await postDeleteSearch(command);
    dispatch(deleteOrRestoreItem(command));
    if(command.deleted)
    {
      renderSuccessToast(i18n.t("Searches.Toast.DeleteSuccess", {value: searchName}));
    }
    return true;
  } catch (error:any) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["delete", false]));
  }
};

export const markSearchReviewed = (command: MarkSearchReviewedCommand) => async (dispatch: any) => {
  try {
    dispatch(loading(["markReviewed", true]));
    await postMarkSearchReviewed(command);
    dispatch(markReviewedItem(command));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["markReviewed", false]));
  }
};

export const changeSearchVisibility = (command: ChangeSearchVisibilityCommand) => async (dispatch: any) => {
  try {
    const response:ResponseModel = await postChangeSearchVisibility(command);
    const updatedItem: UserSearch = response.data;
    dispatch(updateSearchItem(updatedItem));
    renderSuccessToast(i18n.t("Common.Dialog.SelectVisibility.Toast.AccessSettingsUpdated"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    return false;
  }
};

export const resetSearchesState = () => async (dispatch: any) => {
  dispatch(reset());
};
