import axios, { AxiosRequestConfig } from "axios";
import { ResponseModel } from "models/response_model";
import { ApplicationUser } from "models/application_user";
import { LOCAL_STORAGE } from "./local_storage";
import { refreshAccessTokenAsync } from "./utilities";
import { HEADER } from "common/headers";
import Cookies from "js-cookie";
import { COOKIE_STORAGE } from "./cookie_helper";

// Default headers
axios.defaults.headers.post[HEADER.CONTENT_TYPE] = "application/json";

// Auth token setup
const authUserStr = localStorage.getItem(LOCAL_STORAGE.LOGGED_USER);
const authUser: ApplicationUser | null = authUserStr ? JSON.parse(authUserStr) as ApplicationUser : null;
const token = authUser?.tokenInfo?.signature ?? null;

if (token) {
  axios.defaults.headers.common[HEADER.AUTHORIZATION] = `Bearer ${token}`;
}

// Language setup
const language = localStorage.getItem(LOCAL_STORAGE.I18N_LANGUAGE) || navigator.language || "en";
axios.defaults.headers.common[HEADER.LANGUAGE] = language;

// Request interceptor
axios.interceptors.request.use((config) => {
  const trustedBrowserId = Cookies.get(COOKIE_STORAGE.TRUSTED_BROWSER_ID);
  if (trustedBrowserId) {
    config.headers[HEADER.TRUSTED_BROWSER_ID] = trustedBrowserId;
  }
  return config;
});

// Response interceptor
axios.interceptors.response.use(
  (response) => response.data ?? response,
  async (error) => {
    if (axios.isCancel(error)) return Promise.reject(error);

    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const newAccessToken = await refreshAccessTokenAsync();
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return axios(originalRequest);
      } catch (refreshError) {
        throw refreshError;
      }
    }
    return Promise.reject(error);
  }
);

class APIClient {
  get = (url: string, params?: Record<string, any>, config?: AxiosRequestConfig): Promise<ResponseModel> => {
    const queryString = params ? new URLSearchParams(params).toString() : '';
    const finalUrl = queryString ? `${url}?${queryString}` : url;
    return axios.get(finalUrl, config);
  };

  create = (url: string, data: any, config?: AxiosRequestConfig): Promise<ResponseModel> => {
    return axios.post(url, data, config);
  };

  update = (url: string, data: any, config?: AxiosRequestConfig): Promise<ResponseModel> => {
    return axios.patch(url, data, config);
  };

  put = (url: string, data: any, config?: AxiosRequestConfig): Promise<ResponseModel> => {
    return axios.put(url, data, config);
  };

  delete = (url: string, config?: AxiosRequestConfig): Promise<ResponseModel> => {
    return axios.delete(url, config);
  };
}

const setAuthorization = (token: string) => {
  axios.defaults.headers.common[HEADER.AUTHORIZATION] = `Bearer ${token}`;
};

export { APIClient, setAuthorization };