import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {ButtonDropdown, Card, CardBody, CardHeader, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, Row, UncontrolledAlert} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {updateOrderCost} from "slices/order-details/thunk";
import Loader from "Components/Common/Loader";
import DisplayPrice from "Components/Common/DisplayPrice";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import {OrderStatus} from "models/enums/order_status";
import {FinanceStatus} from "models/user_order";
import {ActiveMarketplaces} from "helpers/marketplace_helper";

const CostAndFees = () => {
  const {t} = useTranslation();
  const [currency, setCurrency] = useState<string>();
  const dispatch: any = useDispatch();

  const ordersData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.OrderDetails.loading,
      order: state.OrderDetails.order,
    }),
  );
  const {loading, order} = useSelector(ordersData);

  const [costValue, setCostValue] = useState<number>(order.totalCost || 0);
  const [previousValue, setPreviousValue] = useState<number>(order.totalCost || 0);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    if (order) {
      setCurrency(order.currencyCodeSource);
      setCostValue(Number(order.totalCost?.toFixed(2)));
      setPreviousValue(Number(order.totalCost?.toFixed(2)));
    }
    // eslint-disable-next-line
  }, [order]);

  const getBadgeWithValue = (value: number, prefix: string = "", postfix: string = ""): JSX.Element => {
    if (typeof value !== "number" || isNaN(value)) {
      return <span className={`badge bg-light text-dark rounded-pill fs-12 `}>{t("NA")}</span>;
    }
    const badgeClass = value < 0 ? "bg-danger-subtle text-danger" : "bg-success-subtle text-success";
    const formattedValue = `${prefix} ${value.toFixed(2)} ${postfix}`;
    return <span className={`badge rounded-pill fs-12 ${badgeClass}`}>{formattedValue}</span>;
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(e.target.value);
    setCostValue(newValue);
  };

  const handleBlur = (from: "dropdown" | "input", currencyValue: string) => {
    if ((from === "input" && costValue !== previousValue) || from === "dropdown") {
      const updatePromise = updateOrderCost(order.userOrderId, costValue, currencyValue)(dispatch);
      updatePromise.then((isSuccess) => {
        if (isSuccess) {
          setCostValue(Number(costValue.toFixed(2)));
          setPreviousValue(Number(costValue.toFixed(2)));
        } else {
          setCostValue(Number(previousValue.toFixed(2)));
        }
      });
    }
  };
  return (
    <React.Fragment>
      {order.orderStatus === OrderStatus.CANCELED ? (
        <Card>
          <UncontrolledAlert color="danger" className="alert-additional mb-0" toggle={null}>
            <div className="alert-body">
              <div className="d-flex">
                <div className="flex-shrink-0 me-3">
                  <i className="ri-alert-line fs-16 align-middle"></i>
                </div>
                <div className="flex-grow-1">
                  <h5 className="alert-heading">{t("Orders.Warnings.ExcludedFromCalculations.Title")}</h5>
                  <p className="mb-0">{t("Orders.Warnings.ExcludedFromCalculations.Description")}</p>
                </div>
              </div>
            </div>
          </UncontrolledAlert>
        </Card>
      ) : (
        <Card>
          <CardHeader>
            <CardTitle className="fw-medium mb-0">{t("OrderDetails.Card.CostAndFees.Title")}</CardTitle>
          </CardHeader>
          <div className="placeholder-glow">
            <div className={loading.updateCost ? "placeholder w-100" : ""}>
              <CardBody>
                {loading.list ? (
                  <Loader />
                ) : (
                  <div className="d-flex flex-column gap-3">
                    {order.financeStatus === FinanceStatus.Completed && (
                      <>
                        <Row>
                          <Col>{t("OrderDetails.Card.CostAndFees.Total")}</Col>
                          <Col>
                            <DisplayPrice source={order.currencyCodeTarget} value={order.totalSellPrice} decimals={2} notation="decimal" />
                          </Col>
                        </Row>
                        <Row>
                          <Col>{t("OrderDetails.Card.CostAndFees.Tax")}</Col>
                          <Col>
                            <DisplayPrice source={order.currencyCodeTarget} value={order.totalTax} decimals={2} notation="abbreviated" />{" "}
                          </Col>
                        </Row>
                      </>
                    )}
                    {order.shippingPriceFinance && order.shippingPriceFinance !== 0 ? (
                      <>
                        <Row>
                          <Col>{t("OrderDetails.Card.CostAndFees.ShippingPriceFinance")}</Col>
                          <Col>
                            <DisplayPrice source={order.currencyCodeTarget} value={order.shippingPriceFinance} decimals={2} notation="decimal" />
                          </Col>
                        </Row>
                      </>
                    ) : null}
                    {order.shippingTaxFinance && order.shippingTaxFinance !== 0 ? (
                      <>
                        <Row>
                          <Col>{t("OrderDetails.Card.CostAndFees.ShippingTaxFinance")}</Col>
                          <Col>{<DisplayPrice source={order.currencyCodeTarget} value={order.shippingTaxFinance} decimals={2} notation="decimal" />}</Col>
                        </Row>
                      </>
                    ) : null}
                    {order.orderTotal && (
                      <>
                        <Row>
                          <Col>{t("OrderDetails.Card.CostAndFees.SalesProceeds")}</Col>
                          <Col>
                            <DisplayPrice source={order.currencyCodeTarget} value={order.orderTotal} decimals={2} notation="decimal" />
                          </Col>
                        </Row>
                        <hr />
                      </>
                    )}
                    <Row>
                      <Col>{t("OrderDetails.Card.CostAndFees.Cost")}</Col>
                      <Col>
                        <InputGroup size="sm" style={{width: "130px"}} className="d-flex align-items-center">
                          {order.costIsPredicted && (
                            <>
                              <i className="ri-question-fill text-warning fs-14 me-2" id={`CostIsPredictedTooltip-${order.amazonOrderId}`} />
                              <DefaultUncontrolledTooltip target={`CostIsPredictedTooltip-${order.amazonOrderId}`}>{t("CostIsPredicted")}</DefaultUncontrolledTooltip>
                            </>
                          )}
                          <Input
                            id={"cost-" + order.userOrderId}
                            type="number"
                            className="form-control form-control-sm border-input-group border-end-0"
                            value={isNaN(costValue) ? "" : costValue}
                            onChange={handleValueChange}
                            onBlur={() => handleBlur("input", currency!)}
                            onKeyDown={(event: React.KeyboardEvent) => event.key === "Enter" && handleBlur("input", currency!)}
                            min={0}
                          />
                          <ButtonDropdown isOpen={isDropdownOpen} toggle={() => setIsDropdownOpen(!isDropdownOpen)} direction={"down"}>
                            <DropdownToggle caret>{currency}</DropdownToggle>
                            <DropdownMenu>
                              {ActiveMarketplaces.map((marketplaceInfo) => (
                                <DropdownItem
                                  key={marketplaceInfo.marketplace}
                                  onClick={() => {
                                    setCurrency(marketplaceInfo.currency);
                                    handleBlur("dropdown", marketplaceInfo.currency);
                                  }}
                                  className={order.currencyCodeSource === marketplaceInfo.currency ? "active" : ""}
                                >
                                  {marketplaceInfo.currency}
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </ButtonDropdown>
                        </InputGroup>
                        {costValue >= 0 && order.currencyCodeSource !== "USD" ? (
                          <>
                            <DisplayPrice
                              source={"USD"}
                              value={costValue / (order.exchangeRateSource ?? 1)}
                              decimals={2}
                              notation="abbreviated"
                              prefix="("
                              suffix=")"
                              className="text-muted text-nowrap small fw-medium d-flex mt-1"
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                    {order.financeStatus === FinanceStatus.Completed && (
                      <>
                        <Row>
                          <Col>{t("OrderDetails.Card.CostAndFees.FbaFee")}</Col>
                          <Col>
                            <DisplayPrice source={order.currencyCodeTarget} value={order.totalFBAFee} decimals={2} notation="abbreviated" />
                          </Col>
                        </Row>
                        <Row>
                          <Col>{t("OrderDetails.Card.CostAndFees.ReferralFee")}</Col>
                          <Col>
                            <DisplayPrice source={order.currencyCodeTarget} value={order.totalReferralFee} decimals={2} notation="abbreviated" />
                          </Col>
                        </Row>
                        {order.totalDigitalServicesFee != null && order.totalDigitalServicesFee !== 0 ? (
                          <Row>
                            <Col>{t("OrderDetails.Card.CostAndFees.DigitalServicesFee")}</Col>
                            <Col>
                              <DisplayPrice source={order.currencyCodeTarget} value={order.totalDigitalServicesFee} decimals={2} notation="abbreviated" />
                            </Col>
                          </Row>
                        ) : null}
                        {order.marketplaceFacilitatorTax != null && order.marketplaceFacilitatorTax !== 0 ? (
                          <>
                            <Row>
                              <Col>{t("OrderDetails.Card.CostAndFees.MarketplaceFacilitatorTax")}</Col>
                              <Col>
                                <DisplayPrice source={order.currencyCodeTarget} value={order.marketplaceFacilitatorTax} decimals={2} notation="abbreviated" />
                              </Col>
                            </Row>
                          </>
                        ) : null}
                        {order.marketplaceFacilitatorTaxShipping != null && order.marketplaceFacilitatorTaxShipping !== 0 ? (
                          <>
                            <Row>
                              <Col>{t("OrderDetails.Card.CostAndFees.MarketplaceFacilitatorTaxShipping")}</Col>
                              <Col>{<DisplayPrice source={order.currencyCodeTarget} value={order.marketplaceFacilitatorTaxShipping} decimals={2} notation="abbreviated" />}</Col>
                            </Row>
                          </>
                        ) : null}
                        {order.shippingChargeBack != null && order.shippingChargeBack !== 0 ? (
                          <>
                            <Row>
                              <Col>{t("OrderDetails.Card.CostAndFees.ShippingChargeBack")}</Col>
                              <Col>{<DisplayPrice source={order.currencyCodeTarget} value={order.shippingChargeBack} decimals={2} notation="decimal" />}</Col>
                            </Row>
                          </>
                        ) : null}
                        {order.totalOtherFees != null && order.totalOtherFees !== 0 ? (
                          <Row>
                            <Col>{t("OrderDetails.Card.CostAndFees.OtherFees")}</Col>
                            <Col>
                              <DisplayPrice source={order.currencyCodeTarget} value={order.totalOtherFees} decimals={2} notation="abbreviated" />
                            </Col>
                          </Row>
                        ) : null}
                        {order.promotions != null && order.promotions !== 0 ? (
                          <Row>
                            <Col>{t("OrderDetails.Card.CostAndFees.Promotions")}</Col>
                            <Col>
                              <DisplayPrice source={order.currencyCodeTarget} value={order.promotions} decimals={2} notation="abbreviated" />
                            </Col>
                          </Row>
                        ) : null}
                        <hr />
                        <Row>
                          <Col>{t("OrderDetails.Card.CostAndFees.Profit")}</Col>
                          <Col>{getBadgeWithValue(order.profit!, "", order.currencyCodeTarget)}</Col>
                        </Row>
                        <Row>
                          <Col>{t("OrderDetails.Card.CostAndFees.Margin")}</Col>
                          <Col>{getBadgeWithValue(order.margin!, "%")}</Col>
                        </Row>
                        <Row>
                          <Col>{t("OrderDetails.Card.CostAndFees.ROI")}</Col>
                          <Col>{getBadgeWithValue(order.roi!, "%")}</Col>
                        </Row>
                      </>
                    )}
                  </div>
                )}
              </CardBody>
            </div>
          </div>
        </Card>
      )}

      {order.financeStatus !== FinanceStatus.Completed && (order.orderStatus === OrderStatus.SHIPPED || order.orderStatus === OrderStatus.PENDING) && (
        <Card>
          <UncontrolledAlert color="warning" className="alert-additional mb-0" toggle={null}>
            <div className="alert-body">
              <div className="d-flex">
                <div className="flex-shrink-0 me-3">
                  <i className="ri-alert-line fs-16 align-middle"></i>
                </div>
                <div className="flex-grow-1">
                  <h5 className="alert-heading">{t("Orders.Warnings.MissingFinancialData.Title")}</h5>
                  <p className="mb-0">{t("Orders.Warnings.MissingFinancialData.Description")}</p>
                </div>
              </div>
            </div>
          </UncontrolledAlert>
        </Card>
      )}
    </React.Fragment>
  );
};

export default CostAndFees;
