import {DisableTwoFactorCommand} from "api/command";
import {useFormik} from "formik";
import {createTypedModal} from "helpers/modal_helpers";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Button, Col, Form, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {disableTwoFactor} from "slices/account/thunk";
import ValidatedInput from "Components/Common/ValidatedInput";
import * as Yup from "yup";

export const Disable2FAModal = createTypedModal<any>("disable_2fa");
const Disable2FA = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {open} = Disable2FAModal.useModal();

  const accountSelector = createSelector([(state: RootState) => state.Account], (account) => ({
    loading: account.loading,
  }));

  const {loading} = useSelector(accountSelector);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      currentPassword: "",
    } as DisableTwoFactorCommand,
    validationSchema: Yup.object<DisableTwoFactorCommand>({
      currentPassword: Yup.string().required(t("Account.Security.Dialog.Enable2FA.Validation.CurrentPassword")),
    }),
    onSubmit: (values: DisableTwoFactorCommand) => {
      const disablePromise = disableTwoFactor(values)(dispatch);
      disablePromise.then((isSuccess) => {
        if (isSuccess) toggle();
      });
    },
  });

  const toggle = () => {
    Disable2FAModal.close();
    validation.resetForm();
  };
  return (
    <Modal backdrop="static" isOpen={open} toggle={toggle} fade={true} centered={true} size="md">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <ModalHeader className="p-3" toggle={toggle}>
          {t("Account.Security.Dialog.Disable2FA.Title")}
        </ModalHeader>
        <ModalBody>
          <p className="text-muted">{t("Account.Security.Dialog.Disable2FA.Description")}</p>
          <Row className="g-2">
            <Col xs={12}>
              <Label>{t("Account.Security.Dialog.Disable2FA.Label.CurrentPassword")}</Label>
              <ValidatedInput
                validation={validation}
                field={"currentPassword"}
                placeholder={t("Account.Security.Dialog.Disable2FA.Label.CurrentPasswordPlaceholder")}
                type="password"
                disableValidationUI
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <Button type="button" className="btn btn-light" onClick={toggle} disabled={loading.twoFactorDisable}>
              {t("Close")}
            </Button>
            <Button
              type="submit"
              color="danger"
              disabled={loading.twoFactorDisable}
              onClick={() => {
                validation.setFieldTouched("currentPassword");
                validation.setFieldTouched("verificationCode");

                if (validation.isValid) {
                  validation.handleSubmit();
                }
              }}
            >
              {t("Account.Security.Dialog.Disable2FA.Button.Disable")}
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default Disable2FA;
