import {Outlet} from "react-router-dom";
import NonAuthSingleSearchLayout from "layouts/NonAuthSingleSearchLayout";
import Unauthorized from "pages/Errors/_Unauthorized";
import VerticalLayout from "layouts/VerticalLayout";
import Restricted from "Components/Common/Restricted";
import { ApplicationUser } from "models/application_user";

interface SingleSearchRoutesProps {
  user?: ApplicationUser;
}
const SingleSearchRoutes = ({user}: SingleSearchRoutesProps) => {
  // If token is invalid
  if (user) {
    return (
      <VerticalLayout>
        <Restricted require="productDetails" read fallback={() => <Unauthorized />}>
          <Outlet />
        </Restricted>
      </VerticalLayout>
    );
  } else {
    return (
      <NonAuthSingleSearchLayout>
        <Outlet />
      </NonAuthSingleSearchLayout>
    );
  }
};

export default SingleSearchRoutes;
