import React, {useState, useEffect, useCallback, ReactNode} from "react";
import {toast, ToastContainer} from "react-toastify";
import {getAppVersion, updateAppVersion} from "helpers/local_storage";
import {t} from "i18next";
import UpdateLoader from "Components/Common/UpdateLoader";
import "react-toastify/dist/ReactToastify.css";

interface VersionResponse {
  version: string;
  timestamp: string;
}

interface UpdateWrapperProps {
  children: ReactNode;
  checkInterval?: number;
}

const UpdateWrapper: React.FC<UpdateWrapperProps> = ({
  children,
  checkInterval = 10 * 60 * 1000, // Default: 10 minutes
}) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [isToastActive, setIsToastActive] = useState(false);

  const handleUpdate = useCallback((newVersion: string) => {
    setIsUpdating(true);
    updateAppVersion(newVersion);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }, []);

  // Version check function
  const checkForUpdates = useCallback(async () => {
    try {
      const response = await fetch(`/version.json?t=${new Date().getTime()}`);
      const data: VersionResponse = await response.json();
      const currentVersion = getAppVersion();

      // if there is no version, set the version
      if (!currentVersion) {
        updateAppVersion(data.version);
        return;
      }

      // if the version has changed and no active toast
      if (currentVersion !== data.version && !isToastActive) {
        setIsToastActive(true);
        // If user is already on the page, show toast with update button
        toast.info(
          <div className="d-flex align-items-center">
            <div className="ms-1">
              <h6 className="mb-1">{t("UpdateApplication.NewUpdateAvailable")}</h6>
              <div className="mt-2">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleUpdate(data.version);
                  }}
                  className="btn btn-sm btn-secondary"
                >
                  <i className="ri-refresh-line align-middle me-1"></i>
                  {t("UpdateApplication.Update")}
                </button>
              </div>
            </div>
          </div>,
          {
            position: "bottom-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
            className: "bg-secondary-subtle",
            onClose: () => setIsToastActive(false),
          },
        );
      }
    } catch (error) {
      console.error("Version check failed:", error);
    }
  }, [handleUpdate, isToastActive]);

  // Start periodic version check
  useEffect(() => {
    // Initial check
    checkForUpdates();

    // Set up periodic check
    const intervalId = setInterval(checkForUpdates, checkInterval);

    // Cleanup on unmount
    return () => clearInterval(intervalId);
  }, [checkForUpdates, checkInterval]);

  return (
    <>
      <ToastContainer />
      {children}
      <UpdateLoader isVisible={isUpdating} />
    </>
  );
};

export default UpdateWrapper;
