import React, {useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import DataTable, {DataTableRef} from "Components/Common/DataTable";
import {UserInventory, UserInventoryWithSoldQuantity} from "models/user_inventory";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Button, ButtonDropdown, Card, CardBody, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, Label, Row, Spinner, Table, UncontrolledPopover} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {deselectItem, selectItem, setSelectAllUserInventories} from "slices/inventory/reducer";
import {archiveUserInventories, getUserInventories, resetInventoryState, unarchiveUserInventories, updateUserInventoryCost} from "slices/inventory/thunk";
import {useImmer} from "use-immer";
import {ColumnDef} from "@tanstack/react-table";
import {useDebounce} from "Components/Hooks/useDebounce";
import {ConstantPage} from "helpers/permission_helper";
import {useProfile} from "Components/Hooks/useProfile";
import {Link} from "react-router-dom";
import {UserInventoryQuery} from "api/query";
import {FieldConfig, useUrlQuery} from "Components/Hooks/useUrlQuery";
import {Maybe} from "yup";
import errorImage from "assets/images/svg/product.svg";
import BreadCrumb from "Components/Common/BreadCrumb";
import CopyWidget from "Components/Common/CopyWidget";
import DomainToFlag from "Components/Common/DomainToFlag";
import Filters from "./Filters";
import Loader from "Components/Common/Loader";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";
import DisplayPrice from "Components/Common/DisplayPrice";
import NoResult from "Components/Common/NoResult";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import UpdateInventory from "./Modals/UpdateInventory";
import DisplayNumber from "Components/Common/DisplayNumber";
import {AllMarketplaces} from "helpers/marketplace_helper";

export type InventoryListRef = {
  reload: VoidFunction;
};
interface InventoryProps {
  updateInventory?: boolean;
}
const PAGE_IDENTIFIER: ConstantPage = "inventory";
const Inventory = (props: InventoryProps) => {
  const {t} = useTranslation();
  const {hasPermission} = useProfile();
  const [selectedItemsCount, setSelectedItemsCount] = useState<number>(0);
  const [archiveButtonDisabled, setArchiveButtonDisabled] = useState<boolean>(true);
  const activeCurrencyList = AllMarketplaces.filter((info) => info.active || info.marketplace === "US");

  const dispatch: any = useDispatch();
  const tableRef = useRef<DataTableRef>(null);
  const listRef = useRef<InventoryListRef>(null);

  const [localQuery, updateLocalQuery] = useImmer<UserInventoryQuery>({
    action: "filtering",
    page: 1,
    pageSize: 10,
  });

  let filterFields: FieldConfig<UserInventoryQuery>[] = [
    {field: "search", queryParam: "search", type: "string"},
    {field: "stores", queryParam: "stores", type: "array"},
    {field: "marginRange", queryParam: "marginRange", type: "numberRange"},
    {field: "roiRange", queryParam: "roiRange", type: "numberRange"},
    {field: "profitRange", queryParam: "profitRange", type: "numberRange"},
    {field: "shipments", queryParam: "shipments", type: "array"},
    {field: "inStock", queryParam: "inStock", type: "bool"},
    {field: "showArchived", queryParam: "showArchived", type: "bool"},
    {field: "productsWithoutCostInformation", queryParam: "productsWithoutCostInformation", type: "bool"},
    {field: "availableInMyStore", queryParam: "availableInMyStore", type: "bool"},
    {field: "page", queryParam: "page", type: "number", defaultValue: localQuery.page},
    {field: "pageSize", queryParam: "pageSize", type: "number", defaultValue: localQuery.pageSize},
    {field: "sortBy", queryParam: "sortBy", type: "string", defaultValue: localQuery.sortBy},
    {field: "sortingOrder", queryParam: "sortingOrder", type: "number", defaultValue: localQuery.sortingOrder},
    {field: "action", queryParam: "action", type: "string", defaultValue: "filtering"},
  ];

  const {readQueryParams, updateQuery, searchParams} = useUrlQuery<UserInventoryQuery>(filterFields);

  const debouncedLoadList = useDebounce(() => {
    getUserInventories(localQuery)(dispatch).then(() => {
      tableRef.current?.resetSelection();
    });
  }, 200);

  useEffect(() => {
    if (hasPermission("inventory") && searchParams.has("action")) {
      const params = readQueryParams();
      updateLocalQuery(params as UserInventoryQuery);
      debouncedLoadList();
    }
  }, [debouncedLoadList, searchParams]); // eslint-disable-line

  useImperativeHandle(
    listRef,
    () => {
      return {
        reload: () => {
          debouncedLoadList();
        },
      };
    },
    [debouncedLoadList],
  );

  useEffect(() => {
    return () => {
      dispatch(resetInventoryState());
    };
  }, []); // eslint-disable-line

  const inventoryData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Inventory.loading,
      userInventories: state.Inventory.userInventories,
      selectedAll: state.Inventory.selectedAll,
      excludedItems: state.Inventory.excludedItems,
      selectedItems: state.Inventory.selectedItems,
    }),
  );
  const {loading, userInventories, selectedAll, selectedItems, excludedItems} = useSelector(inventoryData);

  const columns = useMemo<ColumnDef<UserInventory, any>[]>(
    () => [
      {
        id: "#",
        enableHiding: false,
        enableResizing: false,
        enableSorting: false,
        size: 50,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserInventoryWithSoldQuantity;
          return (
            <Restricted require="inventory" update>
              <div className="d-flex align-items-center">
                <Input
                  className="form-check-input mt-0 me-2"
                  type="checkbox"
                  name="formCheckboxRight"
                  id="formCheckboxRight1"
                  checked={checkIsSelected(row)}
                  onChange={(e) => {
                    handleSelectOrDeselectItem(e, row);
                  }}
                />
                {row.archived ? (
                  <>
                    <Button
                      id={`UnarchiveItem-${row.userInventoryId}`}
                      className="btn btn-ghost-success px-1 py-0 fs-18"
                      onClick={() => {
                        const ids: string[] = [row.userInventoryId];
                        unarchiveUserInventories(ids)(dispatch);
                      }}
                    >
                      <i className="mdi mdi-archive-arrow-up align-middle"></i>
                    </Button>
                    <DefaultUncontrolledTooltip target={`UnarchiveItem-${row.userInventoryId}`}>{t("Inventory.Tooltip.Unarchive")}</DefaultUncontrolledTooltip>
                  </>
                ) : (
                  <>
                    <Button
                      id={`ArchiveItem-${row.userInventoryId}`}
                      className="btn btn-ghost-warning px-1 py-0 fs-18"
                      onClick={() => {
                        const ids: string[] = [row.userInventoryId];
                        archiveUserInventories(ids)(dispatch);
                      }}
                    >
                      <i className="mdi mdi-archive-arrow-down align-middle"></i>
                    </Button>
                    <DefaultUncontrolledTooltip target={`ArchiveItem-${row.userInventoryId}`}>{t("Inventory.Tooltip.Archive")}</DefaultUncontrolledTooltip>
                  </>
                )}
              </div>
            </Restricted>
          );
        },
      },
      {
        header: t("Inventory.TableColumn.SKU"),
        enableSorting: false,
        size: 300,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserInventoryWithSoldQuantity;
          const imagePath = `https://m.media-amazon.com/images/I/${row.imageSmall}`;

          return (
            <div className="overflow-hidden">
              <Row className="align-items-center" style={{minWidth: "280px"}}>
                <Col xs={"auto"}>
                  <div className="avatar-sm rounded-circle flex-shrink-0 overflow-hidden">
                    <img
                      className="rounded w-100"
                      src={imagePath}
                      onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                        e.currentTarget.onerror = null;
                        e.currentTarget.src = errorImage;
                      }}
                      alt=""
                    />
                  </div>
                </Col>
                <Col xs={"auto"}>
                  <div className="d-flex align-items-center text-nowrap">
                    <DomainToFlag marketplace={row.userStore.marketplace} />
                    <span>
                      {row.userStore.marketplace} - {row.userStore.name}
                    </span>
                  </div>
                  <div className="d-flex align-items-center">
                    <span className="me-1">ASIN:</span>
                    <CopyWidget text={row.asin} index={row.asin} asLink={true} marketplace={row.userStore.marketplace} />
                  </div>
                  {row.sku && (
                    <div className="d-flex align-items-center">
                      <span className="me-2">SKU:</span>
                      <CopyWidget text={row.sku} index={row.sku} />
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          );
        },
      },
      {
        header: t("Inventory.TableColumn.ProductName"),
        enableSorting: false,
        size: 350,
        accessorFn: (item) => item.productName,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserInventoryWithSoldQuantity;
          return (
            <>
              <p style={{maxWidth: "350px"}} className="ellipsis-two-lines mb-0">
                {row.productName}
              </p>
              <span className="badge rounded-pill bg-warning-subtle text-warning me-2">
                FBA: <DisplayNumber value={row.fbaSellerCount} renderOnEmpty={<span className="text-muted">—</span>} />
              </span>
              <span className="badge rounded-pill bg-success-subtle text-success me-2">
                FBM: <DisplayNumber value={row.fbmSellerCount} renderOnEmpty={<span className="text-muted">—</span>} />
              </span>
              <span className="badge rounded-pill bg-primary-subtle text-primary me-2">
                BSR: <DisplayNumber value={row.bsr} renderOnEmpty={<span className="text-muted">—</span>} />
              </span>
              <span className="badge rounded-pill bg-info-subtle text-info">{row.saleCountLastMonth != null && <DisplayNumber value={row.saleCountLastMonth} prefix={`${t("Sale Count")}: `} />}</span>
            </>
          );
        },
      },
      {
        header: t("Inventory.TableColumn.Stock.Title"),
        enableSorting: true,
        accessorKey: "afnFulfillableQuantity",
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserInventoryWithSoldQuantity;
          return (
            <>
              <Button id={`Stock-${row.userInventoryId}`} color="link" className="p-0 link-body-emphasis link-opacity-75-hover fw-medium d-flex align-items-center custom-width" to={""}>
                <span>{row.afnFulfillableQuantity || 0}</span>
                <i className="ri-arrow-down-s-line fs-16 ms-1 text-info"></i>
              </Button>
              <UncontrolledPopover trigger="legacy" placement="right" target={`Stock-${row.userInventoryId}`}>
                <Table className="align-middle table table-hover mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>{t("Inventory.TableColumn.Stock.Tooltip.QuantityType")}</th>
                      <th>{t("Inventory.TableColumn.Stock.Tooltip.Units")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{t("Inventory.TableColumn.Stock.Tooltip.Available")}</td>
                      <td>{row.afnFulfillableQuantity || 0}</td>
                    </tr>
                    <tr>
                      <td>{t("Inventory.TableColumn.Stock.Tooltip.Inbound")}</td>
                      <td>{(row.afnInboundWorkingQuantity ?? 0) + (row.afnInboundShippedQuantity ?? 0) + (row.afnInboundReceivingQuantity ?? 0)}</td>
                    </tr>
                    <tr>
                      <td>{t("Inventory.TableColumn.Stock.Tooltip.Reserved")}</td>
                      <td>{row.afnReservedQuantity || 0}</td>
                    </tr>
                    <tr>
                      <td>{t("Inventory.TableColumn.Stock.Tooltip.Unfulfillable")}</td>
                      <td>{row.afnUnsellableQuantity || 0}</td>
                    </tr>
                    <tr>
                      <td>{t("Inventory.TableColumn.Stock.Tooltip.Researching")}</td>
                      <td>{row.afnResearchingQuantity || 0}</td>
                    </tr>
                    <tr>
                      <th scope="row">{t("Inventory.TableColumn.Stock.Tooltip.Total")}</th>
                      <th scope="row">{row.afnTotalQuantity || 0}</th>
                    </tr>
                  </tbody>
                </Table>
              </UncontrolledPopover>
            </>
          );
        },
      },
      {
        header: t("Inventory.TableColumn.Sold"),
        enableSorting: true,
        accessorKey: "sold",
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserInventoryWithSoldQuantity;
          return (
            <>
              <DisplayNumber value={row.sold} renderOnEmpty={<span className="text-muted">—</span>} />
            </>
          );
        },
      },
      {
        header: t("Inventory.TableColumn.SalesProceeds"),
        enableSorting: true,
        accessorKey: "salesProceeds",
        size: 200,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserInventoryWithSoldQuantity;
          const currency = AllMarketplaces.find((info) => info.marketplace === row.userStore.marketplace)?.currency || "";
          return (
            <>
              {row.salesProceeds ? (
                row.marketplaceTaxExceptionRate != null && row.marketplaceTaxExceptionRate > 0 ? (
                  <>
                    <Link id={`SalesProceeds-${row.userInventoryId}`} className="link-body-emphasis link-opacity-75-hover fw-medium d-flex align-items-center custom-width" to={""}>
                      <DisplayPrice source={currency} value={row.salesProceeds} decimals={2} notation="decimal" />
                      <i className="ri-arrow-down-s-line fs-16 ms-1 text-info"></i>
                    </Link>
                    <UncontrolledPopover trigger="legacy" placement="right" target={`SalesProceeds-${row.userInventoryId}`}>
                      <Table className="align-middle table table-hover mb-0">
                        <thead className="table-light">
                          <tr>
                            <th>{t("Inventory.TableColumn.Type")}</th>
                            <th>{t("Inventory.TableColumn.Price")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{t("Inventory.TableColumn.SellPrice")}</td>
                            <td>
                              <DisplayPrice source={currency} value={row.yourPrice} decimals={2} notation="decimal" />
                            </td>
                          </tr>
                          {row.marketplaceTaxExceptionRate > 0 && (
                            <tr>
                              <td>{t("Inventory.TableColumn.MarketplaceTaxException")}</td>
                              <td>
                                <DisplayPrice source={currency} value={row.marketplaceTaxException} decimals={2} notation="decimal" />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </UncontrolledPopover>
                  </>
                ) : (
                  <span className="fw-medium">
                    <DisplayPrice source={currency} value={row.salesProceeds} decimals={2} notation="decimal" />
                  </span>
                )
              ) : (
                <span className="text-muted">—</span>
              )}
              {row.buyBoxPrice && (
                <span className="d-flex align-items-center">
                  {row.isBuyBoxWinner === true ? (
                    <span className="d-flex align-items-center">
                      <i className="text-secondary mdi mdi-basket me-1 align-middle"></i>
                      <span className="text-secondary text-nowrap small fw-medium">BuyBox</span>
                    </span>
                  ) : (
                    <span className="d-flex align-items-center fs-12">
                      <i className="text-danger mdi mdi-close-circle me-1 align-middle"></i>
                      <span className="text-muted me-1">BuyBox:</span>
                      <DisplayPrice source={currency} value={row.buyBoxPrice} decimals={2} notation="decimal" className="text-primary" />
                    </span>
                  )}
                </span>
              )}
            </>
          );
        },
      },
      {
        header: t("Inventory.TableColumn.Cost"),
        accessorKey: "totalCost",
        enableSorting: true,
        size: 50,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserInventoryWithSoldQuantity;
          const [costValue, setCostValue] = useState<Maybe<number>>(row.totalCost); // eslint-disable-line
          const [currency, setCurrency] = useState<Maybe<string>>(row.currencyCodeSource); // eslint-disable-line
          const [previousValue, setPreviousValue] = useState<Maybe<number>>(row.totalCost); // eslint-disable-line

          const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = parseFloat(e.target.value);
            setCostValue(newValue);
          };

          const handleBlur = (from: "dropdown" | "input", currencyValue: Maybe<string>) => {
            if ((from === "input" && costValue !== previousValue) || from === "dropdown") {
              const updatePromise = updateUserInventoryCost(row.userInventoryId, currencyValue, costValue)(dispatch);
              updatePromise.then((isSuccess) => {
                if (isSuccess) {
                  setCostValue(Number(costValue?.toFixed(2)));
                  setPreviousValue(Number(costValue?.toFixed(2)));
                } else {
                  setCostValue(Number(previousValue?.toFixed(2)));
                }
              });
            }
          };

          const [isDropdownOpen, setIsDropdownOpen] = useState(false); // eslint-disable-line
          return (
            <div style={{width: "130px"}}>
              <InputGroup size="sm" className="d-flex">
                <Input
                  id={"cost-" + row.userInventoryId}
                  type="number"
                  className="form-control form-control-sm border-end-0 w-50"
                  value={costValue ?? undefined}
                  onChange={handleValueChange}
                  onBlur={() => handleBlur("input", currency)}
                  onKeyDown={(event: React.KeyboardEvent) => event.key === "Enter" && handleBlur("input", currency)}
                />
                <ButtonDropdown isOpen={isDropdownOpen} toggle={() => setIsDropdownOpen(!isDropdownOpen)} direction={"down"}>
                  <DropdownToggle caret>{currency}</DropdownToggle>
                  <DropdownMenu>
                    {activeCurrencyList.map((marketplaceInfo) => (
                      <DropdownItem
                        key={marketplaceInfo.marketplace}
                        onClick={() => {
                          setCurrency(marketplaceInfo.currency);
                          handleBlur("dropdown", marketplaceInfo.currency);
                        }}
                        className={row.currencyCodeSource === marketplaceInfo.currency ? "active" : ""}
                      >
                        {marketplaceInfo.currency}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </ButtonDropdown>
              </InputGroup>
            </div>
          );
        },
      },
      {
        header: t("Inventory.TableColumn.Fees"),
        enableSorting: true,
        accessorKey: "totalFees",
        size: 50,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserInventoryWithSoldQuantity;
          const currency = AllMarketplaces.find((info) => info.marketplace === row.userStore.marketplace)?.currency || "";
          return (
            <>
              {row.totalFees ? (
                <>
                  <Link id={`Fees-${row.userInventoryId}`} className="link-body-emphasis link-opacity-75-hover d-flex align-items-center custom-width" to={""}>
                    <span>
                      <DisplayPrice source={currency} value={-row.totalFees} decimals={2} notation="abbreviated" />
                    </span>
                    <i className="ri-arrow-down-s-line fs-16 ms-1 text-info"></i>
                  </Link>
                  <UncontrolledPopover trigger="legacy" placement="right" target={`Fees-${row.userInventoryId}`}>
                    <Table className="align-middle table table-hover mb-0">
                      <thead className="table-light">
                        <tr>
                          <th>{t("Inventory.TableColumn.Type")}</th>
                          <th>{t("Inventory.TableColumn.Fee")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{t("Referral Fee")}</td>
                          <td>
                            <DisplayPrice
                              source={currency}
                              value={row.referralFee !== undefined ? -row.referralFee : undefined}
                              decimals={2}
                              notation="abbreviated"
                              renderOnEmpty={<span className="text-muted">—</span>}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>{t("FBA Fee")}</td>
                          <td>
                            <DisplayPrice source={currency} value={row.fbaFee !== undefined ? -row.fbaFee : undefined} decimals={2} notation="abbreviated" />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </UncontrolledPopover>
                </>
              ) : (
                <span className="text-muted">—</span>
              )}
            </>
          );
        },
      },
      {
        header: t("Inventory.TableColumn.Profit"),
        enableSorting: true,
        accessorKey: "profit",
        size: 50,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserInventoryWithSoldQuantity;
          const currency = AllMarketplaces.find((info) => info.marketplace === row.userStore.marketplace)?.currency;
          const cssClass = row.profit && row.profit < 0 ? "bg-danger-subtle text-danger" : "bg-success-subtle text-success";
          return (
            <>
              {row.profit ? (
                <span className={`badge rounded-pill ${cssClass} fs-12`}>
                  <DisplayPrice source={currency} value={row.profit} decimals={2} notation="abbreviated" />
                </span>
              ) : (
                <span className="text-muted">—</span>
              )}
            </>
          );
        },
      },
      {
        header: t("Inventory.TableColumn.MarginROI"),
        enableSorting: true,
        accessorKey: "margin",
        size: 150,
        accessorFn: (row) => `${t("PercentSign", {value: row.margin})} - ${t("PercentSign", {value: row.roi})}`, // excel export
        cell: (cellProps) => {
          const row = cellProps.row.original as UserInventoryWithSoldQuantity;
          const marginCssClass = row.margin && row.margin < 0 ? "text-danger" : "text-dark";
          const roiCssClass = row.roi && row.roi < 0 ? "text-danger" : "text-dark";
          return (
            <div>
              {row.margin ? <span className={marginCssClass}>{t("PercentSign", {value: row.margin.toFixed(0)})}</span> : <span className="text-muted">—</span>}
              {row.roi && row.roi > 0 ? (
                <>
                  <i className="mdi mdi-slash-forward"></i>
                  <span className={`${roiCssClass}`}>{t("PercentSign", {value: row.roi.toFixed(0)})}</span>
                </>
              ) : (
                ""
              )}
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, selectedAll, excludedItems, selectedItems, userInventories.items],
  );

  const handleSelectOrDeselectItem = (e: React.ChangeEvent<HTMLInputElement>, inventory: UserInventory) => {
    const isChecked = e.currentTarget.checked;
    if (isChecked) {
      dispatch(selectItem(inventory));
    } else {
      dispatch(deselectItem(inventory));
    }
  };

  const checkIsSelected = (inventory: UserInventory) => {
    if (selectedAll) {
      if (excludedItems.find((item) => item.userInventoryId === inventory.userInventoryId)) return false;
      return true;
    } else {
      if (selectedItems.find((item) => item.userInventoryId === inventory.userInventoryId)) return true;
      return false;
    }
  };

  const handleArchiveOrUnarchiveUserInventory = () => {
    let ids: string[];
    if (selectedAll) {
      ids = userInventories.items?.filter((item) => !excludedItems.includes(item)).map((item) => item.userInventoryId) || [];
    } else {
      ids = selectedItems.map((item) => item.userInventoryId);
    }

    if (!localQuery.showArchived) {
      archiveUserInventories(ids)(dispatch);
    } else {
      unarchiveUserInventories(ids)(dispatch);
    }
  };

  useEffect(() => {
    if (selectedAll) {
      setArchiveButtonDisabled(false);
      const count = (userInventories?.totalCount ?? 0) - excludedItems.length;
      setSelectedItemsCount(count);
    } else {
      setSelectedItemsCount(selectedItems.length);
      setArchiveButtonDisabled(selectedItems.length === 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAll, excludedItems, selectedItems]);

  useEffect(() => {
    if (selectedItemsCount === 0) {
      dispatch(setSelectAllUserInventories(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItemsCount]);

  document.title = t("PageTitles.Inventory");

  return (
    <Restricted require="inventory" read fallback={() => <Unauthorized />}>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Inventory.Title")} menus={[{label: t("Inventory.Title")}]} />
          <>
            <Filters busy={loading.filter} fields={filterFields} />
            <UpdateInventory isOpen={props.updateInventory || false} listRef={listRef} />
          </>
          <Card>
            <CardBody>
              {loading.filter ? (
                <>
                  <Loader />
                </>
              ) : userInventories.items && userInventories.items.length > 0 ? (
                <Restricted require={PAGE_IDENTIFIER} read>
                  <Row className="mb-3 align-items-center ">
                    <Restricted require="inventory" update>
                      <Col xs="auto">
                        <Input
                          className="form-check-input me-2"
                          type="checkbox"
                          name="selectAllCheckbox"
                          id="selectAllCheckbox"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            dispatch(setSelectAllUserInventories(event.target.checked));
                          }}
                          checked={selectedAll}
                        />
                        <Label className="form-check-label user-select-none" for="selectAllCheckbox">
                          {t("Select all")}
                          {selectedItemsCount > 0 && <span className="badge rounded-pill bg-dark-subtle text-dark ms-1 fs-12">{selectedItemsCount}</span>}
                        </Label>
                      </Col>
                      <Col xs="auto">
                        <span id="archiveButton">
                          <Button
                            type="button"
                            className={`btn-sm ${localQuery.showArchived ? "btn-success" : "btn-warning"}`}
                            onClick={handleArchiveOrUnarchiveUserInventory}
                            disabled={loading.archive || archiveButtonDisabled}
                          >
                            {loading.archive && <Spinner size="sm" className="align-middle" />}
                            {
                              <>
                                {localQuery.showArchived ? (
                                  <>
                                    <i className="mdi mdi-archive-arrow-up align-middle me-1"></i>
                                    {t("Inventory.Button.Unarchive")}
                                  </>
                                ) : (
                                  <>
                                    <i className="mdi mdi-archive-arrow-down align-middle me-1"></i>
                                    {t("Inventory.Button.Archive")}
                                  </>
                                )}
                              </>
                            }
                          </Button>
                        </span>
                        {archiveButtonDisabled && <DefaultUncontrolledTooltip target="archiveButton">{t("Inventory.Tooltip.MinimumSelectItem")}</DefaultUncontrolledTooltip>}
                      </Col>
                    </Restricted>
                  </Row>
                  <DataTable
                    className="align-middle table-nowrap table"
                    ref={tableRef}
                    busy={loading.list || loading.update}
                    columns={columns}
                    data={userInventories.items || []}
                    totalDataLength={userInventories.totalCount}
                    pagination={{
                      pageIndex: localQuery.page - 1,
                      pageSize: localQuery.pageSize,
                    }}
                    onPaginationChanged={(pagination) => {
                      updateQuery({...localQuery, page: pagination.pageIndex + 1, pageSize: pagination.pageSize, action: "paginating"});
                    }}
                    onSortingChanged={(sorting) => {
                      updateQuery({...localQuery, sortBy: sorting?.sortBy, sortingOrder: sorting?.sortingOrder, action: "sorting"});
                    }}
                    hovered
                  />
                </Restricted>
              ) : (
                <NoResult title={t("Inventory.NoResult.Title")} description={t("Inventory.NoResult.Description")} />
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </Restricted>
  );
};

export default Inventory;
