import BreadCrumb, {BreadcrumbMenuItem} from "Components/Common/BreadCrumb";
import {useEffect} from "react";
import {Card, CardBody, Container} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {getUserShipmentDetails, resetShipmentState} from "slices/thunks";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {ConstantPage} from "helpers/permission_helper";
import {useProfile} from "Components/Hooks/useProfile";
import {ShipmentStatus} from "models/enums/shipment_status";
import {ShipmentSlice} from "slices/shipment/selector";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";
import Draft from "./Draft";
import ShippedOrCompleted from "./ShippedOrCompleted";
import SellThis from "Components/Common/SellThis";
import ReAnalyze from "Components/Common/Modals/ReAnalyze";
import MarkAsShipped from "./Draft/Modals/MarkAsShipped";

const PAGE_IDENTIFIER: ConstantPage = "shipmentDetails";

const ShipmentDetails = () => {
  const {t} = useTranslation();
  const {hasPermission} = useProfile();
  const dispatch: any = useDispatch();
  const {userShipmentId} = useParams();

  const {loading, currentUserShipment} = useSelector(ShipmentSlice);

  useEffect(() => {
    if (hasPermission(PAGE_IDENTIFIER)) {
      getUserShipmentDetails({userShipmentId: userShipmentId ?? ""})(dispatch);
    }
    return () => {
      resetShipmentState()(dispatch);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  document.title = t("PageTitles.ShipmentDetails") + " - " + currentUserShipment?.name;
  const breadcrumbMenus: BreadcrumbMenuItem[] = [
    {
      label: t("Shipments.Title"),
      url: "/shipments",
    },
    {
      label: `${t("Shipments.ShipmentDetails.Title")} - ${currentUserShipment?.name}`,
      url: "",
    },
  ];
  return (
    <Restricted require={PAGE_IDENTIFIER} read fallback={() => <Unauthorized />}>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Shipments.ShipmentDetails.Title")} menus={breadcrumbMenus} />
          <ReAnalyze />
          <SellThis />
          <MarkAsShipped />
          <PlaceholderGlow busy={loading.list || loading.implementSettings}>
            <>
              {currentUserShipment && currentUserShipment.userShipmentId ? (
                <>{currentUserShipment.status === ShipmentStatus.DRAFT || currentUserShipment.status === ShipmentStatus.ORDERED ? <Draft /> : <ShippedOrCompleted />}</>
              ) : loading.list ? (
                <Card>
                  <CardBody>
                    <div>{t("Loading...")}</div>
                  </CardBody>
                </Card>
              ) : (
                <Card>
                  <CardBody>
                    <div>{t("Shipments.ShipmentDetails.NoShipmentDetails")}</div>
                  </CardBody>
                </Card>
              )}
            </>
          </PlaceholderGlow>
        </Container>
      </div>
    </Restricted>
  );
};

export default ShipmentDetails;
