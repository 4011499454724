import React, {useState} from "react";
import {Link} from "react-router-dom";
import {t} from "i18next";
import DefaultUncontrolledTooltip from "./DefaultUncontrolledTooltip";
import _ from "lodash";
import {ActiveMarketplaces} from "helpers/marketplace_helper";
interface CopyWidgetProps {
  text?: string;
  index?: string;
  marketplace?: string;
  bold?: boolean;
  asLink?: boolean;
  customLink?: string;
  customClick?: (e: React.MouseEvent) => void;
  hideCopyIcon?: boolean;
  hideLinkIcon?: boolean;
  tooltipText?: string;
}

const CopyWidget = ({text, index, marketplace, bold, asLink, customLink, customClick, hideCopyIcon, hideLinkIcon, tooltipText}: CopyWidgetProps) => {
  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(text!);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  let domain = ActiveMarketplaces.find((amazonMarketplaceInfo) => amazonMarketplaceInfo.marketplace === marketplace)?.domain;

  if (text && index) {
    return (
      <React.Fragment>
        <div className="d-flex align-items-center nowrap">
          <span id={_.snakeCase(`CopyWidgetText-${index}`)} className="d-flex align-items-center">
            {asLink ? (
              customClick ? (
                <span
                  className={`link-secondary link-offset-2 text-decoration-underline me-1 cursor-pointer ${bold ? "fw-bold" : ""}`}
                  onMouseDown={customClick}
                  onTouchEnd={(e) => customClick(e as any)}
                >
                  {text}
                  {!hideLinkIcon && <i className="ri-arrow-right-up-line" />}
                </span>
              ) : customLink ? (
                <Link to={customLink} target="_blank" className={`link-secondary link-offset-2 text-decoration-underline me-1 ${bold ? "fw-bold" : ""}`}>
                  {text}
                  {!hideLinkIcon && <i className="ri-arrow-right-up-line" />}
                </Link>
              ) : (
                <Link to={`https://www.amazon.${domain}/dp/${text}?th=1&psc=1`} target="_blank" className={`link-secondary link-offset-2 text-decoration-underline me-1 ${bold ? "fw-bold" : ""}`}>
                  {text}
                  {!hideLinkIcon && <i className="ri-arrow-right-up-line" />}
                </Link>
              )
            ) : (
              <span className={`me-1 ${bold ? "fw-medium" : ""}`}>{text}</span>
            )}
          </span>
          {tooltipText !== undefined && tooltipText !== null && tooltipText !== "" && (
            <DefaultUncontrolledTooltip delay={{show: 0, hide: 0}} target={_.snakeCase(`CopyWidgetText-${index}`)}>
              {tooltipText}
            </DefaultUncontrolledTooltip>
          )}

          {!hideCopyIcon && (
            <>
              <i
                className={`cursor-pointer fs-14 ${copied ? "ri-checkbox-circle-fill text-success" : "mdi mdi-content-copy"} align-middle`}
                id={_.snakeCase(`CopyTooltip-${index}`)}
                onClick={handleCopyClick}
              />
              <DefaultUncontrolledTooltip delay={{show: 0, hide: 0}} target={_.snakeCase(`CopyTooltip-${index}`)}>
                {copied ? t("Copied") : t("Copy")}
              </DefaultUncontrolledTooltip>
            </>
          )}
        </div>
      </React.Fragment>
    );
  }
  return null;
};

export default CopyWidget;
