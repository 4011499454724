import { createSelector } from "reselect";
import { RootState } from "slices";

export const ScanAndSaveSlice = createSelector(
  (state: RootState) => state,
  (state) => ({
      list: state.ScanAndSave.list,
      loading: state.ScanAndSave.loading,
      error: state.ScanAndSave.error,
  }),
);
