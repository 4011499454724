import DataTable, {DataTableRef} from "Components/Common/DataTable";
import {ColumnDef} from "@tanstack/react-table";
import {useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Card, CardBody, CardHeader, Col} from "reactstrap";
import {AmazonMarketplace} from "helpers/marketplace_helper";
import {ShipmentSlice} from "slices/shipment/selector";
import FeatherIcon from "feather-icons-react";
import DisplayPrice from "Components/Common/DisplayPrice";
import SuccessRateCalculator from "Components/Common/Calculator/SuccessRateCalculator";
import React from "react";

type ProjectedSalesAndProfitsData = {
  label: string;
  count: number;
  revenue: number;
  estimatedPayout: number;
  expectedProfit: number;
  profit: number;
  totalExpectedROI: number;
  totalExpectedMargin: number;
  totalROI: number;
  totalMargin: number;
  expectedRoi: number;
  roi: number;
  expectedMargin: number;
  margin: number;
  projectedTotalRoi: number;
  projectedTotalMargin: number;
  successRate: number;
};
interface ProjectedSalesAndProfitsProps {
  destinationMarketplace?: AmazonMarketplace;
}
const ProjectedSalesAndProfits = ({destinationMarketplace}: ProjectedSalesAndProfitsProps) => {
  const {t} = useTranslation();
  const tableRef = useRef<DataTableRef>(null);
  const [tableData, setTableData] = useState<ProjectedSalesAndProfitsData[]>([]);

  const {currentUserShipment} = useSelector(ShipmentSlice);
  useEffect(() => {
    if (currentUserShipment) {
      const shipment = currentUserShipment;
      const soldItems: ProjectedSalesAndProfitsData = {
        label: t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.Label.SoldItems"),
        count: shipment?.soldItems,
        revenue: shipment?.userShipmentFinance.actualSoldRevenue,
        estimatedPayout: shipment?.userShipmentFinance.estimatedSoldPayout,
        expectedProfit: shipment?.userShipmentFinance.expectedSoldProfit,
        profit: shipment?.userShipmentFinance.actualSoldProfit,
        totalExpectedROI: shipment?.userShipmentFinance.expectedROI,
        totalExpectedMargin: shipment?.userShipmentFinance.expectedMargin,
        totalROI: shipment?.userShipmentFinance.actualROI,
        totalMargin: shipment?.userShipmentFinance.actualMargin,
        expectedRoi: shipment?.userShipmentFinance.expectedSoldROI,
        expectedMargin: shipment?.userShipmentFinance.expectedSoldMargin,
        roi: shipment?.userShipmentFinance.actualSoldROI,
        margin: shipment?.userShipmentFinance.actualSoldMargin,
        projectedTotalRoi: shipment?.userShipmentFinance.actualSoldROI,
        projectedTotalMargin: shipment?.userShipmentFinance.actualSoldMargin,
        successRate: SuccessRateCalculator(shipment?.userShipmentFinance.actualSoldProfit, shipment?.userShipmentFinance.expectedSoldProfit) || 0,
      };

      const leftItems: ProjectedSalesAndProfitsData = {
        label: t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.Label.LeftItems"),
        count: shipment?.totalItems - shipment?.soldItems,
        revenue: shipment?.userShipmentFinance.actualLeftRevenue,
        estimatedPayout: shipment?.userShipmentFinance.estimatedLeftPayout,
        expectedProfit: shipment?.userShipmentFinance.expectedLeftProfit,
        profit: shipment?.userShipmentFinance.actualLeftProfit,
        totalExpectedROI: shipment?.userShipmentFinance.expectedROI,
        totalExpectedMargin: shipment?.userShipmentFinance.expectedMargin,
        totalROI: shipment?.userShipmentFinance.actualROI,
        totalMargin: shipment?.userShipmentFinance.actualMargin,
        expectedRoi: shipment?.userShipmentFinance.expectedLeftROI,
        expectedMargin: shipment?.userShipmentFinance.expectedLeftMargin,
        roi: shipment?.userShipmentFinance.actualLeftROI,
        margin: shipment?.userShipmentFinance.actualLeftMargin,
        projectedTotalRoi: shipment?.userShipmentFinance.actualSoldROI,
        projectedTotalMargin: shipment?.userShipmentFinance.actualSoldMargin,
        successRate: SuccessRateCalculator(shipment?.userShipmentFinance.actualLeftProfit, shipment?.userShipmentFinance.expectedLeftProfit) || 0,
      };

      const joined: ProjectedSalesAndProfitsData[] = [soldItems, leftItems];
      setTableData(joined);
    }
  }, [t, currentUserShipment]); //eslint-disable-line

  const columns = useMemo<ColumnDef<ProjectedSalesAndProfitsData, any>[]>(
    () => [
      {
        header: " ",
        size: 100,
        accessorKey: "label",
        cell: (cellProps) => {
          return (
            <div className="d-flex align-items-center text-nowrap">
              <FeatherIcon
                icon={cellProps.getValue() === t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.Label.SoldItems") ? "check-circle" : "package"}
                className={`me-2 icon-dual-${cellProps.getValue() === t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.Label.SoldItems") ? "success" : "warning"}`}
                size={18}
              />
              <span className="fw-medium">{cellProps.getValue()}</span>
            </div>
          );
        },
        footer: () => {
          return (
            <div className="d-flex align-items-center">
              <FeatherIcon icon="bar-chart-2" className="me-2 icon-dual-primary" size={18} />
              <span className="fw-bold">{t("Total")}</span>
            </div>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.TableColumn.Count"),
        size: 50,
        accessorKey: "count",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: ProjectedSalesAndProfitsData = cellProps.row.original;
          const isLeftItems = row.label === t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.Label.LeftItems");
          return (
            <div className="d-flex align-items-center">
              <span className={`bg-soft-${isLeftItems ? "warning" : "success"} text-${isLeftItems ? "warning" : "success"} fs-12 fw-medium me-1`}>{cellProps.getValue()}</span>
              <i className={`ri-bar-chart-fill text-${isLeftItems ? "warning" : "success"} fs-16`}></i>
            </div>
          );
        },
        footer: ({table, column}) => {
          const totalCount = table.getPreFilteredRowModel().rows.reduce((sum, row) => (row.getValue(column.id) as number) + sum, 0);
          return (
            <div className="d-flex align-items-center">
              <span className="bg-soft-primary text-primary fs-12 fw-medium me-1">{totalCount}</span>
              <i className="ri-bar-chart-fill text-primary fs-16"></i>
            </div>
          );
        },
      },
      {
        header: `${t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.TableColumn.Revenue")} / ${t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.TableColumn.EstimatedPayout")}`,
        size: 150,
        cell: (cellProps) => {
          const row: ProjectedSalesAndProfitsData = cellProps.row.original;
          const isLeftItems = row.label === t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.Label.LeftItems");
          return (
            <div className="revenue-payout-container">
              <div className={`text-${isLeftItems ? "warning" : "success"} mb-1`}>
                <DisplayPrice source={destinationMarketplace?.currency} value={row.revenue} decimals={2} notation="decimal" />
              </div>
              <div className="fw-medium">
                <DisplayPrice source={destinationMarketplace?.currency} value={row.estimatedPayout} decimals={2} notation="decimal" />
              </div>
            </div>
          );
        },
        footer: ({table, column}) => {
          const totalRevenue = table.getPreFilteredRowModel().rows.reduce((sum, row) => sum + (row.original.revenue as number), 0);
          const totalEstimatedPayout = table.getPreFilteredRowModel().rows.reduce((sum, row) => sum + (row.original.estimatedPayout as number), 0);
          return (
            <div className="revenue-payout-container">
              <div className="text-primary mb-1">
                <DisplayPrice source={destinationMarketplace?.currency} value={totalRevenue} decimals={2} notation="decimal" />
              </div>
              <div className="fw-medium">
                <DisplayPrice source={destinationMarketplace?.currency} value={totalEstimatedPayout} decimals={2} notation="decimal" />
              </div>
            </div>
          );
        },
      },
      {
        header: `${t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.TableColumn.ExpectedProfit")} / ${t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.TableColumn.Profit")}`,
        size: 150,
        accessorKey: "expectedProfit",
        enableSorting: false,
        cell: (cellProps) => {
          const row: ProjectedSalesAndProfitsData = cellProps.row.original;
          const isLeftItems = row.label === t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.Label.LeftItems");
          return (
            <div className="profit-container">
              <div className={`text-${isLeftItems ? "warning" : "success"} mb-1`}>
                <DisplayPrice source={destinationMarketplace?.currency} value={row.expectedProfit} decimals={2} notation="decimal" />
              </div>
              <div className="fw-medium">
                <DisplayPrice source={destinationMarketplace?.currency} value={row.profit} decimals={2} notation="decimal" />
              </div>
            </div>
          );
        },
        footer: ({table, column}) => {
          const totalExpectedProfit = table.getPreFilteredRowModel().rows.reduce((sum, row) => sum + (row.original.expectedProfit as number), 0);
          const totalProfit = table.getPreFilteredRowModel().rows.reduce((sum, row) => sum + (row.original.profit as number), 0);
          return (
            <div className="profit-container">
              <div className="text-primary mb-1">
                <DisplayPrice source={destinationMarketplace?.currency} value={totalExpectedProfit} decimals={2} notation="decimal" />
              </div>
              <div className="fw-medium">
                <DisplayPrice source={destinationMarketplace?.currency} value={totalProfit} decimals={2} notation="decimal" />
              </div>
            </div>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.TableColumn.ExpectedRoiMargin"),
        size: 150,
        cell: (cellProps) => {
          const row: ProjectedSalesAndProfitsData = cellProps.row.original;
          const isLeftItems = row.label === t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.Label.LeftItems");
          return (
            <div className="roi-margin-container">
              <div className={`d-flex align-items-center text-${isLeftItems ? "warning" : "success"}`}>
                <span>{t("PercentSign", {value: row.expectedRoi.toFixed(0)})}</span>
                <i className="mdi mdi-slash-forward mx-2"></i>
                <span>{t("PercentSign", {value: row.expectedMargin.toFixed(0)})}</span>
              </div>
            </div>
          );
        },
        footer: ({table}) => {
          const expectedRoi = table.getPreFilteredRowModel().rows[0]?.original.totalExpectedROI.toFixed(0);
          const expectedMargin = table.getPreFilteredRowModel().rows[0]?.original.totalExpectedMargin.toFixed(0);
          return (
            <div className="roi-margin-container">
              <div className="d-flex align-items-center text-primary">
                <span>{t("PercentSign", {value: expectedRoi})}</span>
                <i className="mdi mdi-slash-forward mx-2"></i>
                <span>{t("PercentSign", {value: expectedMargin})}</span>
              </div>
            </div>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.TableColumn.RoiMargin"),
        size: 120,
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row: ProjectedSalesAndProfitsData = cellProps.row.original;
          const isLeftItems = row.label === t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.Label.LeftItems");
          return (
            <div className="roi-margin-container">
              <div className={`d-flex align-items-center text-${isLeftItems ? "warning" : "success"}`}>
                <span>{t("PercentSign", {value: row.roi.toFixed(0)})}</span>
                <i className="mdi mdi-slash-forward mx-2"></i>
                <span>{t("PercentSign", {value: row.margin.toFixed(0)})}</span>
              </div>
            </div>
          );
        },
        footer: ({table}) => {
          const totalRoi = table.getPreFilteredRowModel().rows[0]?.original.totalROI.toFixed(0);
          const totalMargin = table.getPreFilteredRowModel().rows[0]?.original.totalMargin.toFixed(0);
          return (
            <div className="roi-margin-container">
              <div className="d-flex align-items-center text-primary">
                <span>{t("PercentSign", {value: totalRoi})}</span>
                <i className="mdi mdi-slash-forward mx-2"></i>
                <span>{t("PercentSign", {value: totalMargin})}</span>
              </div>
            </div>
          );
        },
      },
      {
        header: t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.TableColumn.SuccessRate"),
        size: 120,
        cell: (cellProps) => {
          const row: ProjectedSalesAndProfitsData = cellProps.row.original;
          const value = row.successRate;
          let style = value >= 70 ? "success" : value >= 50 ? "warning" : "danger";

          return (
            <div className="success-rate-container">
              <div className="d-flex align-items-center">
                <span className={`badge bg-soft-${style} text-${style} fs-12 fw-medium`}>
                  <i className="mdi mdi-trophy me-1 fs-14"></i>
                  {t("PercentSign", {value: value.toFixed(0)})}
                </span>
              </div>
            </div>
          );
        },
        footer: ({table, column}) => {
          const totalExpectedProfit = table.getPreFilteredRowModel().rows.reduce((sum, row) => sum + (row.original.expectedProfit as number), 0);
          const totalProfit = table.getPreFilteredRowModel().rows.reduce((sum, row) => sum + (row.original.profit as number), 0);
          const totalRate = SuccessRateCalculator(totalProfit, totalExpectedProfit) ?? 0;
          let style = totalRate >= 70 ? "success" : totalRate >= 50 ? "warning" : "danger";

          return (
            <div className="success-rate-container">
              <div className="d-flex align-items-center">
                <span className={`badge bg-soft-${style} text-${style} fs-12 fw-medium`}>
                  <i className="mdi mdi-trophy me-1 fs-14"></i> {t("PercentSign", {value: totalRate.toFixed(0)})}
                </span>
              </div>
            </div>
          );
        },
      },
    ],
    [t, destinationMarketplace],
  );

  return (
    <React.Fragment>
      <Col xl={9} className="same-height">
        <Card className="card-animate">
          <CardHeader className="border-0 align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">{t("Shipments.ShippedOrCompleted.ProjectedSalesAndProfits.Title")}</h4>
          </CardHeader>
          <CardBody>
            <DataTable
              thClass="bg-light text-muted"
              trClass="align-middle"
              tdClass="align-middle"
              ref={tableRef}
              busy={false}
              columns={columns}
              data={tableData}
              totalDataLength={0}
              hovered
              className="projected-sales-table"
            />
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default ProjectedSalesAndProfits;
