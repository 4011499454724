import {ResponseModel} from "models/response_model";
import {apiError, loading, reset, setDetails, setIsLimitExceeded, setPageUrl, setShowCheckList, setStore} from "./reducer";
import {generateError, renderSuccessToast} from "helpers/utilities";
import {GetProductDetailsQuery} from "api/query";
import {postGetProductDetails, postGetProductDetailsForAnonymUsers, postSetUserShowCheckListOptionChoice} from "services/search_service";
import {ProductDetails} from "models/product_details";
import {setCurrencies} from "slices/common/reducer";
import {Currencies} from "models/currencies";
import {UserStore} from "models/user_stores";
import {refreshRemainingLimits} from "slices/thunks";
import {getLoggedUser} from "helpers/local_storage";
import i18n from "i18n";
import {AmazonBusinessModel} from "models/enums/user_search_type";
import {ApplicationUser} from "models/application_user";
import Cookies from "js-cookie";
import {COOKIE_STORAGE} from "helpers/cookie_helper";

export const getProductDetails = (query: GetProductDetailsQuery, loggedUser: ApplicationUser | undefined) => async (dispatch: any) => {
  try {
    dispatch(loading(["list", true]));
    let result: any = {} as any;

    if (loggedUser) {
      result = await postGetProductDetails(query);
    } else {
      const currentLimit: number = Number(Cookies.get(COOKIE_STORAGE.DAILY_SINGLE_SEARCH)) || 0;
      if (currentLimit < 5) {
        result = await postGetProductDetailsForAnonymUsers(query)
          .then(() => {
            const newLimit = currentLimit + 1;
            Cookies.set(COOKIE_STORAGE.DAILY_SINGLE_SEARCH, newLimit.toString(), {expires: 1});
          })
          .catch((error) => {
            if (error.response.status === 429) {
              dispatch(setIsLimitExceeded(true));
              return;
            }
          });
      } else {
        dispatch(setIsLimitExceeded(true));
        return;
      }
    }

    const details: ProductDetails = result.data.productDetails;
    const currencies: Currencies[] = result.data.currencies;
    const store: UserStore = result.data.store;

    if (getLoggedUser()?.userId) {
      dispatch(refreshRemainingLimits());
    }
    dispatch(setDetails(details));
    dispatch(setCurrencies(currencies));
    dispatch(setStore(store));
    return true;
  } catch (error) {
    /*if (error instanceof Error && error.message === "LIMIT_ERROR") {
      const limitErrorObject: ApiError = {
        message: "LIMIT_ERROR",
        status: false,
        errorCode: "429",
      };
      dispatch(apiError(limitErrorObject));
      return;
    }*/
    const errorObject = generateError(error, false);
    dispatch(apiError(errorObject));
  } finally {
    dispatch(loading(["list", false]));
  }
};

export const setUserShowCheckListOptionChoice = (choice: boolean, amazonBusinessModel: AmazonBusinessModel) => async (dispatch: any) => {
  try {
    dispatch(loading(["save", true]));
    const result: ResponseModel = await postSetUserShowCheckListOptionChoice({showCheckList: choice, amazonBusinessModel});
    dispatch(setShowCheckList(choice));
    renderSuccessToast(i18n.t(result.message));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["save", false]));
  }
};

export const setProductDetailsPageUrl = (pageUrl: string) => (dispatch: any) => {
  dispatch(setPageUrl(pageUrl));
};

export const resetSearchProductState = () => async (dispatch: any) => {
  dispatch(reset());
};
