import ReactDOM from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import {configureStore} from "@reduxjs/toolkit";
import {setupConsoleLogging} from "helpers/logging";
import {fetchUserStores} from "services/store_service";
import App from "./App";
import config from "config";
import rootReducer from "./slices";
import listeners from "slices/listeners";
import reportWebVitals from "./reportWebVitals";
import UpdateWrapper from "Components/Common/UpdateWrapper";
import "./wdyr";

setupConsoleLogging();

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <UpdateWrapper>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <App />
        </BrowserRouter>
      </UpdateWrapper>
    </Provider>
  );
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware();
    middlewares.push(...listeners);

    return middlewares;
  },
  devTools: config.env.isDevelopment,
});

store.dispatch(fetchUserStores());
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(<AppWrapper />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
