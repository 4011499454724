import { createSelector } from "reselect";
import { RootState } from "slices";

export const ProductDetailsSlice = createSelector(
  (state: RootState) => state,
  (state) => ({
    loading: state.ProductDetails.loading,
    details: state.ProductDetails.details,
    store: state.ProductDetails.store,
    isLimitExceeded: state.ProductDetails.isLimitExceeded,
    pageUrl: state.ProductDetails.pageUrl,
    error: state.ProductDetails.error,
  }),
);
