import {createSelector} from "reselect";
import {RootState} from "slices";

export const SettingsSlice = createSelector(
  (state: RootState) => state,
  (state) => ({
    loading:state.Settings.loading,
    costAndFeesList:state.Settings.costAndFeesList,
    analyzeList:state.Settings.analyzeList,
    exchangeRates:state.Settings.exchangeRates,
    defaultCurrencies:state.Settings.defaultCurrencies,
    currentCostAndFeeOption:state.Settings.currentCostAndFeeOption,
    currentAnalyzeOption:state.Settings.currentAnalyzeOption,
    defaultCarrierMinRates:state.Settings.defaultCarrierMinRates,
    error:state.Settings.error,
  }),
);
