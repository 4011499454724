import {useMemo} from "react";
import {Link, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useUrlQuery, FieldConfig} from "Components/Hooks/useUrlQuery";
import {FormikValidation} from "helpers/types";
import {Col, Row} from "reactstrap";
import DefaultUncontrolledTooltip from "./DefaultUncontrolledTooltip";

interface FilterChipsProps<T> {
  fields: FieldConfig<T>[];
  validation: FormikValidation<T>;
  onClear?: () => void;
  fieldSelectOptionsMap?: Record<string, {value: any; label: string}[]>;
}

const FilterChips = <T,>(props: FilterChipsProps<T>) => {
  const {t} = useTranslation();
  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const {updateQuery} = useUrlQuery(props.fields, props.validation);

  const getDisplayValue = (value: string, type: string, field: keyof T) => {
    const selectOptions = props.fieldSelectOptionsMap?.[field as string];

    if (selectOptions) {
      if (type === "array") {
        const selectedValues = value.split(",");
        const labels = selectedValues
          .map((val) => {
            const option = selectOptions.find((opt) => String(opt.value) === String(val.trim()));
            return option ? option.label : val;
          })
          .filter(Boolean);
        if (labels.length) {
          return labels.join(", ");
        }
      } else {
        const option = selectOptions.find((opt) => String(opt.value) === String(value));
        if (option) {
          return option.label;
        }
      }
    }

    if (selectOptions) {
      if (type === "array") {
        const selectedValues = value.split(",");
        const labels = selectedValues
          .map((val) => {
            const option = selectOptions.find((opt) => String(opt.value) === String(val.trim()));
            return option ? option.label : val;
          })
          .filter(Boolean);
        if (labels.length) {
          return labels.join(", ");
        }
      } else {
        const option = selectOptions.find((opt) => String(opt.value) === String(value));
        if (option) {
          return option.label;
        }
      }
    }

    switch (type) {
      case "array":
        return value.split(",").join(", ");
      case "bool":
        return value === "true" ? t("Yes") : t("No");
      case "dateRange":
        const [start, end] = value.split(";");
        if (start || end) {
          return `${start || ""} - ${end || ""}`;
        }
        return value;
      case "numberRange":
        const [min, max] = value.split(";");
        if (Number(min) && Number(max)) {
          return `${min} - ${max}`;
        } else if (Number(min)) {
          return `≥ ${min}`;
        } else if (Number(max)) {
          return `≤ ${max}`;
        }
        return value;
      default:
        return value;
    }
  };

  const activeFilters = useMemo(() => {
    const filters: {field: keyof T; label: string; value: any; displayValue: string}[] = [];

    props.fields.forEach(({field, queryParam, type, hideChip, chipName}) => {
      if (hideChip) return;

      const value = searchParams.get(queryParam);
      if (!value || value === "all") return;

      const displayValue = getDisplayValue(value, type, field);

      filters.push({
        field,
        label: chipName?.toString() || "",
        value,
        displayValue,
      });
    });
    return filters;
  }, [searchParams, t]); // eslint-disable-line

  const handleRemoveFilter = (field: keyof T) => {
    const newValues = {...props.validation.values};
    (newValues[field] as any) = undefined;
    updateQuery(newValues);
  };

  const handleClearFilter = () => {
    props.onClear?.();
  };

  if (activeFilters.length === 0) return null;

  return (
    <>
      <Row className="d-flex align-items-center mt-3 gx-5">
        <Col xl={9} lg={9} sm={12} className="d-flex align-items-center">
          <div className="d-flex flex-wrap gap-2 align-items-center">
            {activeFilters.map(({field, label, displayValue}) => (
              <div key={String(field)}>
                <label id={`ExportSearchResult-${String(field)}`} className="rounded-pill bg-filter-chips cursor-pointer" onClick={() => handleRemoveFilter(field)}>
                  <div className="px-2 fs-11 py-1 d-flex align-items-center">
                    <span className="fw-medium text-black-50">{label}:</span>
                    <span className="ms-1 text-muted">{displayValue}</span>
                    <i className="ri-close-line"></i>
                  </div>
                </label>
                <DefaultUncontrolledTooltip delay={{show: 150, hide: 0}} target={`ExportSearchResult-${String(field)}`}>
                  {t("RemoveFilter", {field: label})}
                </DefaultUncontrolledTooltip>
              </div>
            ))}
            <Link to={""} className="link-danger" onClick={handleClearFilter}>
              <i className="ri-close-line align-bottom"></i>
              {t("ClearAppliedFilters")}
            </Link>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default FilterChips;
