import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Card, CardBody, CardHeader, Col, Progress, Row} from "reactstrap";
import {AmazonMarketplace} from "helpers/marketplace_helper";
import {ShipmentSlice} from "slices/shipment/selector";
import {get} from "lodash";
import languages from "common/language";
import DisplayPrice from "Components/Common/DisplayPrice";
import SuccessRateCalculator from "Components/Common/Calculator/SuccessRateCalculator";

interface CostAndProfitAnalysisProps {
  sourceMarketplace?: AmazonMarketplace;
  destinationMarketplace?: AmazonMarketplace;
}
const CostAndProfitAnalysis = ({sourceMarketplace, destinationMarketplace}: CostAndProfitAnalysisProps) => {
  const {t} = useTranslation();
  const [successAnalysisRate, setSuccessAnalysisRate] = useState<number>(0);
  const [projectedProfit, setProjectedProfit] = useState<number>(0);
  const currentLanguage: any = localStorage.getItem("I18N_LANGUAGE");
  let currentLocale = get(languages, `${currentLanguage}.locale`);

  const {currentUserShipment} = useSelector(ShipmentSlice);
  useEffect(() => {
    if (currentUserShipment) {
      const projectedProfitValue =
        currentUserShipment.userShipmentFinance.actualSoldProfit > 0 ? currentUserShipment.userShipmentFinance.actualLeftProfit + currentUserShipment.userShipmentFinance.actualSoldProfit : 0;
      const successRateValue = SuccessRateCalculator(projectedProfitValue, currentUserShipment.userShipmentFinance.expectedProfit);
      setProjectedProfit(projectedProfitValue);
      setSuccessAnalysisRate(successRateValue ?? 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserShipment]);

  const returnNonNegativeValue = (value: number) => {
    if (value > 0) {
      return value.toLocaleString(currentLocale, {
        style: "currency",
        currency: destinationMarketplace?.currency,
      });
    } else {
      return "0.00 " + destinationMarketplace?.currency;
    }
  };
  return (
    <>
      <Row>
        <Col xxl={3} sm={6} className="same-height">
          <Card className="card-animate border shipment-finance-items">
            <CardHeader>
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="card-title mb-0">{t("Shipments.ShippedOrCompleted.CostAndProfitAnalysis.ActualPurchaseCost.Title")}</h6>
                <div className="avatar-xs flex-shrink-0 common-css">
                  <span className={"avatar-title rounded-circle fs-4 bg-danger-subtle text-danger"}>
                    <i className="mdi mdi-bank-check"></i>
                  </span>
                </div>
              </div>
            </CardHeader>
            <CardBody className="d-flex flex-column">
              <ul className="list-group">
                <li className="d-flex justify-content-between mb-1">
                  <span>{t("Shipments.ShippedOrCompleted.CostAndProfitAnalysis.ActualPurchaseCost.Label.BuyPrice")}</span>
                  <span className="fw-medium">
                    <DisplayPrice source={sourceMarketplace?.currency} value={currentUserShipment.userShipmentFinance.actualPurchasePrice} decimals={2} notation="decimal" />
                  </span>
                </li>

                <li className="d-flex justify-content-between mb-1">
                  <span>{t("Shipments.ShippedOrCompleted.CostAndProfitAnalysis.ActualPurchaseCost.Label.Tax")}</span>
                  <span className="fw-medium">
                    <DisplayPrice source={sourceMarketplace?.currency} value={currentUserShipment.userShipmentFinance.actualTax} decimals={2} notation="decimal" />
                  </span>
                </li>
                <li className="d-flex justify-content-between mb-1">
                  <span>{t("Shipments.ShippedOrCompleted.CostAndProfitAnalysis.ActualPurchaseCost.Label.PrepAndShip")}</span>
                  <span className="fw-medium">
                    <DisplayPrice source={sourceMarketplace?.currency} value={currentUserShipment.userShipmentFinance.actualShippingPrice} decimals={2} notation="decimal" />
                  </span>
                </li>
                <li className="d-flex justify-content-between">
                  <span>{t("Shipments.ShippedOrCompleted.CostAndProfitAnalysis.ActualPurchaseCost.Label.Discount")}</span>
                  <span className="fw-medium">
                    <DisplayPrice source={sourceMarketplace?.currency} value={currentUserShipment.userShipmentFinance.actualDiscount} decimals={2} notation="decimal" />
                  </span>
                </li>
              </ul>
              <ul className="list-group mt-auto">
                <hr></hr>
                <li className="d-flex justify-content-between">
                  <span>{t("Total")}</span>
                  <span className="fw-medium">
                    <DisplayPrice source={sourceMarketplace?.currency} value={currentUserShipment.userShipmentFinance.actualTotalCost} decimals={2} notation="decimal" />
                  </span>
                </li>
                <li className="d-flex justify-content-between">
                  <span></span>
                  <span className="fw-medium">
                    <DisplayPrice source={destinationMarketplace?.currency} value={currentUserShipment.userShipmentFinance.actualTotalCostTarget} decimals={2} notation="decimal" />
                  </span>
                </li>
                <li className="d-flex justify-content-between mt-3 fs-11">
                  <span></span>
                  <span className="text-muted">
                    <em>{t("Exchange Rate") + ": " + currentUserShipment.userShipmentFinance.exchangeRateTarget}</em>
                  </span>
                </li>
              </ul>
            </CardBody>
          </Card>
        </Col>

        <Col xxl={3} sm={6} className="same-height">
          <Card className="card-animate border">
            <CardHeader>
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="card-title mb-0">{t("Shipments.ShippedOrCompleted.CostAndProfitAnalysis.ExpectedProfit.Title")}</h6>
                <div className="avatar-xs flex-shrink-0 common-css">
                  <span className={"avatar-title rounded-circle fs-4 bg-success-subtle text-success"}>
                    <i className="ri-funds-line"></i>
                  </span>
                </div>
              </div>
            </CardHeader>
            <CardBody className="d-flex flex-column">
              <ul className="list-group">
                <li className="d-flex justify-content-between mb-1">
                  <span>{t("Shipments.ShippedOrCompleted.CostAndProfitAnalysis.ExpectedProfit.Label.Revenue")}</span>
                  <span className="fw-medium">
                    <DisplayPrice source={destinationMarketplace?.currency} value={currentUserShipment.userShipmentFinance.totalRevenue} decimals={2} notation="decimal" />
                  </span>
                </li>
                <li className="d-flex justify-content-between mb-1">
                  <span>{t("Shipments.ShippedOrCompleted.CostAndProfitAnalysis.ExpectedProfit.Label.PurchaseCost")}</span>
                  <span className="fw-medium">
                    <DisplayPrice source={destinationMarketplace?.currency} value={currentUserShipment.userShipmentFinance.actualTotalCostTarget} decimals={2} notation="decimal" />
                  </span>
                </li>
                <li className="d-flex justify-content-between mb-1">
                  <span>{t("Shipments.ShippedOrCompleted.CostAndProfitAnalysis.ExpectedProfit.Label.Fees")}</span>
                  <span className="fw-medium">
                    <DisplayPrice source={destinationMarketplace?.currency} value={currentUserShipment.userShipmentFinance.totalFees} decimals={2} notation="decimal" />
                  </span>
                </li>
              </ul>
              <ul className="list-group mt-auto">
                <hr></hr>
                <li className="d-flex justify-content-between mb-1">
                  <span>{t("Shipments.ShippedOrCompleted.CostAndProfitAnalysis.ExpectedProfit.Label.ExpectedProfit")}</span>
                  <span className="fw-medium">
                    <DisplayPrice source={destinationMarketplace?.currency} value={currentUserShipment.userShipmentFinance.expectedProfit} decimals={2} notation="decimal" />
                  </span>
                </li>
                <li className="d-flex justify-content-between">
                  <span>{t("Shipments.ShippedOrCompleted.CostAndProfitAnalysis.ExpectedProfit.Label.ExpectedRoiMargin")}</span>
                  <span className="fw-medium">
                    <span>{t("PercentSign", {value: currentUserShipment.userShipmentFinance.expectedROI.toFixed(0)})}</span>
                    <i className="mdi mdi-slash-forward"></i>
                    <span>{t("PercentSign", {value: currentUserShipment.userShipmentFinance.expectedMargin.toFixed(0)})}</span>
                  </span>
                </li>
              </ul>
            </CardBody>
          </Card>
        </Col>

        <Col xxl={3} sm={6} className="same-height">
          <Card className="card-animate border">
            <CardHeader>
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="card-title mb-0">{t("Shipments.ShippedOrCompleted.CostAndProfitAnalysis.CostRecoveryStatus.Title")}</h6>
                <div className="avatar-xs flex-shrink-0 common-css">
                  <span className={"avatar-title rounded-circle fs-4 bg-info-subtle text-info"}>
                    <i className="mdi mdi-cash-refund"></i>
                  </span>
                </div>
              </div>
            </CardHeader>
            <CardBody className="d-flex flex-column">
              <ul className="list-group">
                <li className="d-flex justify-content-between mb-1">
                  <span>{t("Shipments.ShippedOrCompleted.CostAndProfitAnalysis.CostRecoveryStatus.Label.PurchaseCost")}</span>
                  <span className="fw-medium">
                    <DisplayPrice source={destinationMarketplace?.currency} value={currentUserShipment.userShipmentFinance.actualTotalCostTarget} decimals={2} notation="decimal" />
                  </span>
                </li>
                <li className="d-flex justify-content-between mb-1">
                  <span>{t("Shipments.ShippedOrCompleted.CostAndProfitAnalysis.CostRecoveryStatus.Label.EstimatedPayout")}</span>
                  <span className="fw-medium">
                    <DisplayPrice source={destinationMarketplace?.currency} value={currentUserShipment.userShipmentFinance.estimatedSoldPayout} decimals={2} notation="decimal" />
                  </span>
                </li>
                <li className="list-group">
                  <div className="mt-3">
                    <span className="fw-medium me-1">{t("PercentSign", {value: currentUserShipment.userShipmentFinance.costRecoveryStatusRate.toFixed(0)})}</span>
                    {t("Shipments.ShippedOrCompleted.CostAndProfitAnalysis.CostRecoveryStatus.Label.Recovered")}
                  </div>
                  <div>
                    <Progress className="progress-lg avatar-md w-100 mt-1" color="success" value={currentUserShipment.userShipmentFinance.costRecoveryStatusRate} />
                  </div>
                </li>
              </ul>
              <ul className="list-group mt-auto">
                <hr></hr>
                <li className="d-flex justify-content-between">
                  <span>{t("Shipments.ShippedOrCompleted.CostAndProfitAnalysis.CostRecoveryStatus.Label.Remaining")}</span>
                  <span className="fw-medium">
                    {destinationMarketplace && returnNonNegativeValue(currentUserShipment.userShipmentFinance.actualTotalCostTarget - currentUserShipment.userShipmentFinance.estimatedSoldPayout)}
                  </span>
                </li>
              </ul>
            </CardBody>
          </Card>
        </Col>
        <Col xxl={3} sm={6} className="same-height">
          <Card className="card-animate border">
            <CardHeader>
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="card-title mb-0">{t("Shipments.ShippedOrCompleted.CostAndProfitAnalysis.SuccessAnalysis.Title")}</h6>
                <div className="avatar-xs flex-shrink-0 common-css">
                  <span className={"avatar-title rounded-circle fs-4 bg-secondary-subtle text-secondary"}>
                    <i className="mdi mdi-trophy"></i>
                  </span>
                </div>
              </div>
            </CardHeader>
            <div className="flex-grow-1">
              <div className="border-0 rounded-0 m-0 d-flex align-items-center alert alert-success fade show">
                <i className="mdi mdi-trophy label-icon"></i>
                <div className="flex-grow-1 ms-1 fw-medium">
                  {t("Shipments.ShippedOrCompleted.CostAndProfitAnalysis.SuccessAnalysis.SuccessRate")}: <b>{t("PercentSign", {value: successAnalysisRate.toFixed(0)})}</b>
                </div>
              </div>
            </div>
            <CardBody className="d-flex flex-column">
              <ul className="list-group mt-auto">
                <li className="d-flex justify-content-between mb-1">
                  <span>{t("Shipments.ShippedOrCompleted.CostAndProfitAnalysis.SuccessAnalysis.Label.ExpectedProfit")}</span>
                  <span className="fw-medium">
                    <DisplayPrice source={destinationMarketplace?.currency} value={currentUserShipment.userShipmentFinance.expectedProfit} decimals={2} notation="decimal" />
                  </span>
                </li>
                <li className="d-flex justify-content-between">
                  <span>{t("Shipments.ShippedOrCompleted.CostAndProfitAnalysis.SuccessAnalysis.Label.ExpectedRoiMargin")}</span>
                  <span className="fw-medium">
                    <span>{t("PercentSign", {value: currentUserShipment.userShipmentFinance.expectedROI.toFixed(0)})}</span>
                    <i className="mdi mdi-slash-forward"></i>
                    <span>${currentUserShipment.userShipmentFinance.expectedMargin.toFixed(0)}</span>
                  </span>
                </li>
                <hr></hr>
                <li className="d-flex justify-content-between mb-1">
                  <span>{t("Shipments.ShippedOrCompleted.CostAndProfitAnalysis.SuccessAnalysis.Label.ProjectedProfit")}</span>
                  <span className="fw-medium">
                    <DisplayPrice source={destinationMarketplace?.currency} value={projectedProfit} decimals={2} notation="decimal" />
                  </span>
                </li>
                <li className="d-flex justify-content-between">
                  <span>{t("Shipments.ShippedOrCompleted.CostAndProfitAnalysis.SuccessAnalysis.Label.ProjectedRoiMargin")}</span>
                  <span className="fw-medium">
                    <span>{t("PercentSign", {value: currentUserShipment.userShipmentFinance.actualROI.toFixed(0)})}</span>
                    <i className="mdi mdi-slash-forward"></i>
                    <span>${currentUserShipment.userShipmentFinance.actualMargin.toFixed(0)}</span>
                  </span>
                </li>
              </ul>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CostAndProfitAnalysis;
