import {createSelector} from "reselect";
import {RootState} from "slices";

export const CommonSliceSelector = createSelector(
  (state: RootState) => state,
  (state) => ({
    loading: state.Common.loading,
    userStores: state.Common.userStores,
    activeUserStoreOptions: state.Common.activeUserStoreOptions,
    disconnectedUserStoreOptions: state.Common.disconnectedUserStoreOptions,
    activeMarketplaceOptions: state.Common.activeMarketplaceOptions,
    shipmentInfo: state.Common.shipmentInfo,
    currencies: state.Common.currencies,
    searchResponse: state.Common.searchResponse,
    tags: state.Common.tags,
    error: state.Common.error,
  }),
);
