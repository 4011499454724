import {UserSearchProduct} from "models/user_search_product";
import {Card, CardBody} from "reactstrap";
import SellerHistoryChart from "./Charts/SellerHistoryChart";

interface SellerHistoryProps {
  item: UserSearchProduct;
}
const SellerHistory = ({item}: SellerHistoryProps) => {
  return (
    <Card className="border card-animate mb-3">
      <CardBody>
        <SellerHistoryChart sellerHistoryJSON={item.competitorAnalysis?.sellerHistoryJSON} />
      </CardBody>
    </Card>
  );
};

export default SellerHistory;
