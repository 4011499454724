import { createSelector } from "reselect";
import { RootState } from "slices";

export const AccountSlice = createSelector(
  (state: RootState) => state,
  (state) => ({
    loading:state.Account.loading,
    twoFactorSetup:state.Account.twoFactorSetup,
    paymentHistoryList:state.Account.paymentHistoryList,
    paymentMethods:state.Account.paymentMethods,
    userSubscription:state.Account.userSubscription,
    userBalance:state.Account.userBalance,
    team:state.Account.team,
    notificationOptions:state.Account.notificationOptions,
    limitUsageHistoryList:state.Account.limitUsageHistoryList,
    error:state.Account.error,
  }),
);
