import BreadCrumb, {BreadcrumbMenuItem} from "Components/Common/BreadCrumb";
import CancelSubscription, {CancelSubscriptionModal} from "./Modals/CancelSubscription";
import {useProfile} from "Components/Hooks/useProfile";
import {useTranslation} from "react-i18next";
import {Button, Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import {useCallback, useEffect} from "react";
import {getUserSubscriptionPackage} from "slices/account/thunk";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {UserSubscriptionStatus} from "models/enums/user_subscription_status";
import Moment from "react-moment";
import i18n from "i18n";
import DisplayPrice from "Components/Common/DisplayPrice";
import moment from "moment";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import ProfileLayout from "Components/Common/Layout/ProfileLayout";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";
import {AccountSlice} from "slices/account/selector";
import _ from "lodash";

const CancelSubscriptionPage = () => {
  const {userProfile} = useProfile();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {loading, userSubscription} = useSelector(AccountSlice);

  const onLoad = useCallback(() => {
    getUserSubscriptionPackage()(dispatch);
  }, []); // eslint-disable-line

  const onSubscriptionChange = useCallback(() => {
    if (_.isEmpty(userSubscription)) return;
    else if (!userSubscription || (userSubscription.status !== UserSubscriptionStatus.Active && userSubscription.status !== UserSubscriptionStatus.Trial)) {
      navigate("/account/subscription");
    } else {
      return;
    }
  }, [userSubscription]); // eslint-disable-line

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  useEffect(() => {
    onSubscriptionChange();
  }, [onSubscriptionChange]);

  document.title = t("PageTitles.CancelSubscription");
  const breadcrumbMenus: BreadcrumbMenuItem[] = [
    {
      label: t("Account.Title"),
      url: "/account/subscription",
    },
    {
      label: t("Account.Subscription.Title"),
      url: "/account/subscription",
    },
    {
      label: t("Account.Subscription.CancelSubscription.Title"),
      url: "",
    },
  ];

  if (!userSubscription) return null;
  else
    return (
      <Restricted require={"account.subscription"} read fallback={() => <Unauthorized />}>
        <PlaceholderGlow busy={loading.userSubscription}>
          <CancelSubscription />
          <div className="page-content">
            <Container fluid>
              <BreadCrumb title={t("Account.Title")} menus={breadcrumbMenus} />
              <ProfileLayout hasAnyChanges={false} navigateTo="">
                <Card>
                  <CardHeader className="border-0">
                    <h4 className="card-title mb-0">{t("Account.Subscription.CancelSubscription.Title")}</h4>
                    <p className="text-muted mt-2 mb-0">{t("Account.Subscription.CancelSubscription.Label.Description", {value: moment(userSubscription?.endDate).diff(moment(), "day")})}</p>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col xs={12} md={4}>
                        <Card className="card-animate border">
                          <CardBody>
                            <div className="d-flex justify-content-between">
                              <div>
                                <p className="text-uppercase fw-medium text-muted mb-2">{t("Account.Subscription.CancelSubscription.Label.CurrentPlan")}</p>
                                <h4 className="fs-16 mb-0">{userProfile?.subscription}</h4>
                              </div>
                              <div>
                                <div className="avatar-sm">
                                  <span className="avatar-title bg-secondary-subtle rounded-circle fs-2">
                                    <i className="mdi mdi-credit-card-outline text-secondary"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xs={12} md={4}>
                        <Card className="card-animate border">
                          <CardBody>
                            <div className="d-flex justify-content-between">
                              <div>
                                <p className="text-uppercase fw-medium text-muted mb-2">{t("Account.Subscription.CancelSubscription.Label.BillingSchedule")}</p>
                                <h4 className="fs-16 mb-0">
                                  {userSubscription.billingPeriodMonth === 1
                                    ? t("Account.Subscription.Monthly")
                                    : userSubscription.billingPeriodMonth === 6
                                    ? t("Account.Subscription.6-Month")
                                    : t("Account.Subscription.12-Month")}
                                </h4>
                              </div>
                              <div>
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-secondary-subtle rounded-circle fs-2">
                                    <i className="ri ri-time-line text-secondary"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xs={12} md={4}>
                        <Card className="card-animate border">
                          <CardBody>
                            <div className="d-flex justify-content-between">
                              <div>
                                <p className="text-uppercase fw-medium text-muted mb-2">{t("Account.Subscription.CancelSubscription.Label.NextBill")}</p>
                                <h4 className="fs-16 mb-0">
                                  <Moment locale={i18n.language} tz={userProfile?.timezone} format="DD MMM YYYY">
                                    {userSubscription?.endDate}
                                  </Moment>
                                  <span>
                                    <DisplayPrice value={userSubscription.nextBillingAmount} decimals={2} source="USD" notation="decimal" prefix=" - " />
                                  </span>
                                </h4>
                              </div>
                              <div>
                                <div className="avatar-sm flex-shrink-0">
                                  <span className="avatar-title bg-secondary-subtle rounded-circle fs-2">
                                    <i className="ri ri-calendar-event-line text-secondary"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <h4 className="mb-2 fs-16">{t("Account.Subscription.CancelSubscription.Label.NeedHelp")}</h4>
                        <Card className="border border-info-subtle mb-3 bg-info-subtle shadow-none">
                          <CardBody>
                            <div className="align-items-center d-flex p-2">
                              <div className="flex-grow-1">
                                <p className="mb-0 fs-14">{t("Account.Subscription.CancelSubscription.Label.NeedHelpText")}</p>
                              </div>
                              <div className="flex-shrink-0">
                                <Link to="/account/support" className="btn btn-secondary rounded-pill">
                                  {t("Account.Subscription.CancelSubscription.Button.Support")}
                                </Link>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <Card className="border mb-3 bg-light-subtle shadow-none">
                          <CardBody>
                            <h5 className="alert-heading">{t("Account.Subscription.CancelSubscription.Label.CancellationTerms")}</h5>
                            <p>{t("Account.Subscription.CancelSubscription.Label.CancellationTermsDescription")}</p>
                            <ul className="mb-0">
                              <li>{t("Account.Subscription.CancelSubscription.Label.Terms.1")}</li>
                              <li>
                                {t("Account.Subscription.CancelSubscription.Label.Terms.2", {
                                  value: moment(userSubscription?.endDate)
                                    .locale(i18n.language)
                                    .format("DD MMM YYYY"),
                                })}
                              </li>
                              <li>{t("Account.Subscription.CancelSubscription.Label.Terms.3")}</li>
                            </ul>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col className="hstack gap-3">
                        <Link to="/account/subscription" className="btn btn-secondary">
                          <i className="mdi mdi-arrow-left me-1 align-middle"></i>
                          <span>{t("Account.Subscription.CancelSubscription.Label.KeepActive")}</span>
                        </Link>
                        <Button
                          color="danger"
                          onClick={() =>
                            CancelSubscriptionModal.open({
                              subscriptionId: userSubscription!.subscriptionId,
                              cancelAtPeriodEnd: userSubscription.status === UserSubscriptionStatus.Active || userSubscription.status === UserSubscriptionStatus.Trial,
                            })
                          }
                        >
                          <span>{t("Account.Subscription.CancelSubscription.Title")}</span>
                          <i className="mdi mdi-arrow-right ms-1 align-middle"></i>
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </ProfileLayout>
            </Container>
          </div>
        </PlaceholderGlow>
      </Restricted>
    );
};

export default CancelSubscriptionPage;
