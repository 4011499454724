import { createSelector } from "reselect";
import { RootState } from "slices";

export const SearchResultSlice = createSelector(
  (state: RootState) => state,
  (state) => ({
      result: state.SearchResult.result,
      favoriteList: state.SearchResult.favoriteList,
      currentFavoriteList: state.SearchResult.currentFavoriteList,
      definedFilters: state.SearchResult.definedFilters,
      loading: state.SearchResult.loading,
      detailedModeVisibility: state.SearchResult.detailedModeVisibility,
      error: state.SearchResult.error,
  }),
);
