import {fetchUserStores, postDeleteUserStore, postDisconnectUserStore, postGetUserStores, postSetUserStoreName} from "services/store_service";
import {apiError, deleteOrRestoreItem, loading, setStoresList} from "./reducer";
import {generateError, renderSuccessToast} from "helpers/utilities";
import {ResponseModel} from "models/response_model";
import {UserStore} from "models/user_stores";
import i18n from "i18n";
import { DeleteUserStoreCommand } from "api/command";
import { nanoid } from "@reduxjs/toolkit";

export const getUserStores = () => async (dispatch: any) => {
  try {
    dispatch(loading(["list", true]));
    const response: ResponseModel = await postGetUserStores();
    const stores: UserStore[] = response.data;
    dispatch(setStoresList(stores));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["list", false]));
  }
};

export const deleteUserStore = (command: DeleteUserStoreCommand, name: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["delete", true]));
    await postDeleteUserStore(command);
    dispatch(deleteOrRestoreItem(command));
    if(command.deleted)
    {
      renderSuccessToast(i18n.t("Stores.Toast.StoreDeleteSuccess", { value: name}));
    }
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["delete", false]));
  }
};

export const disconnectUserStore = (userStoreId: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["update", true]));
    const response:ResponseModel = await postDisconnectUserStore({userStoreId});
    const stores: UserStore[] = response.data;
    dispatch(setStoresList(stores));
    renderSuccessToast(i18n.t("Stores.Toast.StoreDisconnectSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["update", false]));
  }
};

export const setUserStoreName = (userStoreId: string, name: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["update", true]));
    const response : ResponseModel = await postSetUserStoreName({userStoreId, name});
    const stores: UserStore[] = response.data
    dispatch(setStoresList(stores));
    const requestId = nanoid();
    dispatch(fetchUserStores.fulfilled(stores, requestId, undefined));
    renderSuccessToast(i18n.t("Stores.Toast.StoreNameUpdateSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["update", false]));
  }
};
