import Dialog, {DialogRef} from "../Dialog";
import {useTranslation} from "react-i18next";
import {Card, CardBody, Col, Nav, NavItem, NavLink, Row} from "reactstrap";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {SettingsSlice} from "slices/settings/selector";
import {refreshList} from "slices/settings/thunk";
import classnames from "classnames";
import Restricted from "../Restricted";

interface SettingsLayoutProps {
  hasAnyChanges: boolean;
  navigateTo: string;
  children: JSX.Element;
}
const SettingsLayout = (props: SettingsLayoutProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const unsavedChangesDialogRef = useRef<DialogRef>(null);
  const [redirectUrl, setRedirectUrl] = useState<string>("");

  const {loading} = useSelector(SettingsSlice);

  const handleNavigate = (path: string) => {
    refreshList()(dispatch);
    if (path === location.pathname) {
      return; // Prevent navigation to the same path
    }

    setRedirectUrl(path);
    if (props.hasAnyChanges) {
      unsavedChangesDialogRef.current?.show();
    } else {
      navigate(path);
    }
  };

  const navigationPathRef = useRef<string | null>(null);

  useEffect(() => {
    const path = props.navigateTo;

    // Only handle navigation if the path has changed and is different from current location
    if (path && path !== navigationPathRef.current && path !== location.pathname) {
      handleNavigate(path);
      navigationPathRef.current = path;
    }
  }, [props.navigateTo]); // eslint-disable-line

  return (
    <div>
      <Row>
        <Col xxl={3}>
          <Card>
            <CardBody className="p-4">
              <Nav vertical pills card>
                <Restricted require="settings.costAndFees" read>
                  <NavItem>
                    <NavLink
                      className={classnames({active: location.pathname.startsWith("/account/settings/cost-and-fees") || location.pathname === "/account/settings"}, "align-items-center d-flex")}
                      onClick={() => handleNavigate("/account/settings/cost-and-fees")}
                      role="button"
                    >
                      <i className="mdi mdi-currency-jpy fs-16 me-2"></i>
                      {t("Settings.CostAndFees.Title")}
                    </NavLink>
                  </NavItem>
                </Restricted>

                <Restricted require="settings.analyzeOptions" read>
                  <NavItem>
                    <NavLink
                      className={classnames({active: location.pathname.startsWith("/account/settings/analyze-options")}, "align-items-center d-flex")}
                      onClick={() => handleNavigate("/account/settings/analyze-options")}
                      role="button"
                    >
                      <i className="mdi mdi-chart-timeline-variant-shimmer fs-16 me-2"></i>
                      {t("Settings.AnalyzeOptions.Title")}
                    </NavLink>
                  </NavItem>
                </Restricted>

                <Restricted require="settings.exchangeRates" read>
                  <NavItem>
                    <NavLink
                      className={classnames({active: location.pathname.startsWith("/account/settings/exchange-rates")}, "align-items-center d-flex")}
                      onClick={() => handleNavigate("/account/settings/exchange-rates")}
                      role="button"
                    >
                      <i className="bx bx-dollar fs-16 me-2"></i>
                      {t("Settings.ExchangeRates.Title")}
                    </NavLink>
                  </NavItem>
                </Restricted>
              </Nav>
            </CardBody>
          </Card>
        </Col>
        <Col xxl={9}>{props.children}</Col>
      </Row>
      <Dialog
        ref={unsavedChangesDialogRef}
        color="info"
        buttons={["yes", "no"]}
        busy={loading.update}
        iconClass="ri-alert-fill"
        message={t("Settings.Dialog.UnsavedChanges.Description")}
        title={t("Settings.Dialog.UnsavedChanges.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            navigate(redirectUrl);
          }
          unsavedChangesDialogRef.current?.hide();
        }}
      />
    </div>
  );
};

export default SettingsLayout;
