import {createSelector} from "reselect";
import {RootState} from "slices";

export const LoginSlice = createSelector(
  (state: RootState) => state,
  (state) => ({
    loading: state.Login.loading,
    status: state.Login.status,
    error: state.Login.error,
    user: state.Login.user,
    demoMode: state.Login.demoMode,
  }),
);
