import React, {useEffect, useState} from "react";
import _, {get} from "lodash";
import {useTranslation} from "react-i18next";
import {Button, Card, CardBody, CardHeader, Col, FormFeedback, Input, InputGroup, InputGroupText, Row, Spinner} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {changeUserShipmentStatus, updateUserShipmentFinance} from "slices/thunks";
import {UserShipmentFinance} from "models/user_shipment_finance";
import {useFormik} from "formik";
import {MarkAsShippedModal} from "./Modals/MarkAsShipped";
import {ActiveMarketplaces, AmazonMarketplace} from "helpers/marketplace_helper";
import {UpdateUserShipmentFinanceCommand} from "api/command";
import {ShipmentStatus} from "models/enums/shipment_status";
import {ShipmentSlice} from "slices/shipment/selector";
import Products from "./_Products";
import languages from "common/language";
import DisplayPrice from "Components/Common/DisplayPrice";
import * as Yup from "yup";

const ShipmentFinance = () => {
  const {t} = useTranslation();
  const dispatch: any = useDispatch();
  const currentLanguage: any = localStorage.getItem("I18N_LANGUAGE");

  let currentLocale = get(languages, `${currentLanguage}.locale`);

  const [sourceMarketplace, setSourceMarketplace] = useState<AmazonMarketplace>();
  const [destinationMarketplace, setDestinationMarketplace] = useState<AmazonMarketplace>();

  const {currentUserShipment, loading} = useSelector(ShipmentSlice);
  useEffect(() => {
    if (currentUserShipment) {
      let source = ActiveMarketplaces.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.marketplace === "US");
      let destination = ActiveMarketplaces.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.marketplace === currentUserShipment.userStore.marketplace);
      setSourceMarketplace(source);
      setDestinationMarketplace(destination);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserShipment]);

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      actualPurchasePrice: currentUserShipment.userShipmentFinance.actualPurchasePrice.toFixed(2) || "",
      actualShippingPrice: currentUserShipment.userShipmentFinance.actualShippingPrice.toFixed(2) || "",
      actualTax: currentUserShipment.userShipmentFinance.actualTax.toFixed(2) || "",
      actualDiscount: currentUserShipment.userShipmentFinance.actualDiscount.toFixed(2) || "",
    },
    validationSchema: Yup.object().shape({
      actualPurchasePrice: Yup.number()
        .required(t("FieldRequired"))
        .min(0, t("FieldNonNegativeError"))
        .typeError(t("Must be number")),
      actualShippingPrice: Yup.number()
        .required(t("FieldRequired"))
        .min(0, t("FieldNonNegativeError"))
        .typeError(t("Must be number")),
      actualTax: Yup.number()
        .required(t("FieldRequired"))
        .min(0, t("FieldNonNegativeError"))
        .typeError(t("Must be number")),
      actualDiscount: Yup.number()
        .required(t("FieldRequired"))
        .min(0, t("FieldNonNegativeError"))
        .typeError(t("Must be number")),
    }),
    onSubmit: (values: any) => {},
  });

  useEffect(() => {
    validation.resetForm();

    validation.initialValues = {
      actualPurchasePrice: currentUserShipment.userShipmentFinance.actualPurchasePrice.toFixed(2),
      actualShippingPrice: currentUserShipment.userShipmentFinance.actualShippingPrice.toFixed(2),
      actualTax: currentUserShipment.userShipmentFinance.actualTax.toFixed(2),
      actualDiscount: currentUserShipment.userShipmentFinance.actualDiscount.toFixed(2),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserShipment]);

  const handleInputBlur = (input: any) => {
    validation.handleBlur(input);
    if (validation.isValid) {
      let inputVal = parseFloat(input.target.value);
      const name: keyof UserShipmentFinance = input.target.name;
      let existVal = parseFloat(currentUserShipment.userShipmentFinance[name] as string);
      if (!isNaN(inputVal) && !isNaN(existVal) && inputVal !== existVal) {
        let payload: UpdateUserShipmentFinanceCommand = {
          userShipmentId: currentUserShipment.userShipmentId,
          field: _.upperFirst(input.target.name),
          newValue: inputVal,
        };

        updateUserShipmentFinance(payload, "update")(dispatch);
      }
    }
  };

  return (
    sourceMarketplace &&
    destinationMarketplace && (
      <React.Fragment>
        <Row>
          <Col xxl={3} sm={6} className="same-height">
            <Card className="card-animate border">
              <CardHeader>
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="card-title mb-0">{t("Shipments.ShipmentDetails.ExpectedPurchaseCost")}</h6>
                  <div className="avatar-xs flex-shrink-0 common-css">
                    <span className={"avatar-title rounded-circle fs-4 bg-secondary-subtle text-secondary"}>
                      <i className="ri-funds-line"></i>
                    </span>
                  </div>
                </div>
              </CardHeader>
              <CardBody className="d-flex flex-column">
                <ul className="list-group">
                  <li className="d-flex justify-content-between mb-1">
                    <span>{t("Shipments.ShipmentDetails.BuyPrice")}</span>
                    <span>
                      <DisplayPrice source={sourceMarketplace?.currency} value={currentUserShipment.userShipmentFinance.expectedPurchasePrice} decimals={2} notation="decimal" />
                    </span>
                  </li>
                  <li className="d-flex justify-content-between mb-1">
                    <span>{t("Shipments.ShipmentDetails.Tax")}</span>
                    <span>
                      <DisplayPrice source={sourceMarketplace?.currency} value={currentUserShipment.userShipmentFinance.expectedTax} decimals={2} notation="decimal" />
                    </span>
                  </li>
                  <li className="d-flex justify-content-between">
                    <span>{t("Shipments.ShipmentDetails.Prep&Ship")}</span>
                    <span>
                      <DisplayPrice source={sourceMarketplace?.currency} value={currentUserShipment.userShipmentFinance.expectedShippingPrice} decimals={2} notation="decimal" />
                    </span>
                  </li>
                </ul>
                <ul className="list-group mt-auto">
                  <hr></hr>
                  <li className="d-flex justify-content-between mb-1">
                    <span>{t("Total")}</span>
                    <span>
                      <DisplayPrice source={sourceMarketplace?.currency} value={currentUserShipment.userShipmentFinance.expectedTotalCost} decimals={2} notation="decimal" />
                    </span>
                  </li>
                  <li className="d-flex justify-content-between mb-1">
                    <span></span>
                    <span>
                      <DisplayPrice source={destinationMarketplace?.currency} value={currentUserShipment.userShipmentFinance.expectedTotalCostTarget} decimals={2} notation="decimal" />
                    </span>
                  </li>
                  <li className="d-flex justify-content-between mt-3 fs-11">
                    <span></span>
                    <span className="text-muted">
                      <em>{t("Exchange Rate") + ": " + currentUserShipment.userShipmentFinance.exchangeRateTarget}</em>
                    </span>
                  </li>
                </ul>
              </CardBody>
            </Card>
          </Col>
          <Col xxl={3} sm={6} className="same-height">
            <Card className="card-animate border shipment-finance-items">
              <CardHeader>
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="card-title mb-0">{t("Shipments.ShipmentDetails.ActualPurchaseCost")}</h6>
                  <div className="avatar-xs flex-shrink-0 common-css">
                    <span className={"avatar-title rounded-circle fs-4 bg-danger-subtle text-danger"}>
                      <i className="ri-exchange-funds-line"></i>
                    </span>
                  </div>
                </div>
              </CardHeader>
              <CardBody className="d-flex flex-column">
                <ul className="list-group">
                  <li className="d-flex justify-content-between mb-1">
                    <span>{t("Shipments.ShipmentDetails.BuyPrice")}</span>
                    <span>
                      {currentUserShipment.userShipmentItems.length !== 0 ? (
                        <>
                          <InputGroup className="shipment-details input-group-size" size="sm">
                            <Input
                              type="text"
                              className="form-control form-control-sm border-input-group"
                              size={20}
                              name="actualPurchasePrice"
                              id="actualPurchasePrice"
                              onChange={validation.handleChange}
                              onBlur={handleInputBlur}
                              value={validation.values.actualPurchasePrice}
                              invalid={validation.touched.actualPurchasePrice && validation.errors.actualPurchasePrice ? true : false}
                              onKeyDown={(event: React.KeyboardEvent) => event.key === "Enter" && handleInputBlur(event)}
                              readOnly={currentUserShipment.status !== ShipmentStatus.DRAFT}
                            />
                            <InputGroupText style={{background: "var(--vz-input-bg-custom)"}}>{sourceMarketplace?.currency}</InputGroupText>
                          </InputGroup>
                          {validation.touched.actualPurchasePrice && validation.errors.actualPurchasePrice ? (
                            <div className="text-end">
                              <FormFeedback type="invalid" className="shipment-details validation">
                                {validation.errors.actualPurchasePrice}
                              </FormFeedback>
                            </div>
                          ) : null}
                        </>
                      ) : (
                        currentUserShipment.userShipmentFinance.actualPurchasePrice.toLocaleString(currentLocale, {
                          style: "currency",
                          currency: sourceMarketplace?.currency,
                        })
                      )}
                    </span>
                  </li>
                  <li className="d-flex justify-content-between mb-1">
                    <span>{t("Shipments.ShipmentDetails.Tax")}</span>
                    <span>
                      {currentUserShipment.userShipmentItems.length !== 0 ? (
                        <>
                          <InputGroup className="shipment-details input-group-size" size="sm">
                            <Input
                              type="text"
                              className="form-control form-control-sm border-input-group"
                              name="actualTax"
                              id="actualTax"
                              onChange={validation.handleChange}
                              onBlur={handleInputBlur}
                              value={validation.values.actualTax}
                              invalid={validation.touched.actualTax && validation.errors.actualTax ? true : false}
                              onKeyDown={(event: React.KeyboardEvent) => event.key === "Enter" && handleInputBlur(event)}
                              readOnly={currentUserShipment.status !== ShipmentStatus.DRAFT}
                            />
                            <InputGroupText style={{background: "var(--vz-input-bg-custom)"}}>{sourceMarketplace?.currency}</InputGroupText>
                          </InputGroup>
                          {validation.touched.actualTax && validation.errors.actualTax ? (
                            <div className="text-end">
                              <FormFeedback type="invalid" className="shipment-details validation">
                                {validation.errors.actualTax}
                              </FormFeedback>
                            </div>
                          ) : null}
                        </>
                      ) : (
                        currentUserShipment.userShipmentFinance.actualTax.toLocaleString(currentLocale, {
                          style: "currency",
                          currency: sourceMarketplace?.currency,
                        })
                      )}
                    </span>
                  </li>
                  <li className="d-flex justify-content-between mb-1">
                    <span>{t("Shipments.ShipmentDetails.Prep&Ship")}</span>
                    <span>
                      {currentUserShipment.userShipmentItems.length !== 0 ? (
                        <>
                          <InputGroup className="shipment-details input-group-size" size="sm">
                            <Input
                              type="text"
                              className="form-control form-control-sm border-input-group"
                              name="actualShippingPrice"
                              id="actualShippingPrice"
                              onChange={validation.handleChange}
                              onBlur={handleInputBlur}
                              value={validation.values.actualShippingPrice}
                              invalid={validation.touched.actualShippingPrice && validation.errors.actualShippingPrice ? true : false}
                              onKeyDown={(event: React.KeyboardEvent) => event.key === "Enter" && handleInputBlur(event)}
                              readOnly={currentUserShipment.status !== ShipmentStatus.DRAFT}
                            />
                            <InputGroupText style={{background: "var(--vz-input-bg-custom)"}}>{sourceMarketplace?.currency}</InputGroupText>
                          </InputGroup>
                          {validation.touched.actualShippingPrice && validation.errors.actualShippingPrice ? (
                            <div className="text-end">
                              <FormFeedback type="invalid" className="shipment-details validation">
                                {validation.errors.actualShippingPrice}
                              </FormFeedback>
                            </div>
                          ) : null}
                        </>
                      ) : (
                        currentUserShipment.userShipmentFinance.actualShippingPrice.toLocaleString(currentLocale, {
                          style: "currency",
                          currency: sourceMarketplace?.currency,
                        })
                      )}
                    </span>
                  </li>
                  <li className="d-flex justify-content-between">
                    <span>{t("Shipments.ShipmentDetails.Discount")}</span>
                    <span>
                      {currentUserShipment.userShipmentItems.length !== 0 ? (
                        <>
                          <InputGroup className="shipment-details input-group-size" size="sm">
                            <Input
                              type="text"
                              className="form-control form-control-sm border-input-group"
                              name="actualDiscount"
                              id="actualDiscount"
                              onChange={validation.handleChange}
                              onBlur={handleInputBlur}
                              value={validation.values.actualDiscount}
                              invalid={validation.touched.actualDiscount && validation.errors.actualDiscount ? true : false}
                              onKeyDown={(event: React.KeyboardEvent) => event.key === "Enter" && handleInputBlur(event)}
                              readOnly={currentUserShipment.status !== ShipmentStatus.DRAFT}
                            />
                            <InputGroupText style={{background: "var(--vz-input-bg-custom)"}}>{sourceMarketplace?.currency}</InputGroupText>
                          </InputGroup>
                          {validation.touched.actualDiscount && validation.errors.actualDiscount ? (
                            <div className="text-end">
                              <FormFeedback type="invalid" className="shipment-details validation">
                                {validation.errors.actualDiscount}
                              </FormFeedback>
                            </div>
                          ) : null}
                        </>
                      ) : (
                        currentUserShipment.userShipmentFinance.actualDiscount.toLocaleString(currentLocale, {
                          style: "currency",
                          currency: sourceMarketplace?.currency,
                        })
                      )}
                    </span>
                  </li>
                </ul>
                <ul className="list-group mt-auto">
                  <hr></hr>
                  <li className="d-flex justify-content-between">
                    <span>{t("Total")}</span>
                    <span>
                      <DisplayPrice source={sourceMarketplace?.currency} value={currentUserShipment.userShipmentFinance.actualTotalCost} decimals={2} notation="decimal" />
                    </span>
                  </li>
                  <li className="d-flex justify-content-between">
                    <span></span>
                    <span>
                      <DisplayPrice source={destinationMarketplace?.currency} value={currentUserShipment.userShipmentFinance.actualTotalCostTarget} decimals={2} notation="decimal" />
                    </span>
                  </li>
                  <li className="d-flex justify-content-between mt-3 fs-11">
                    <span></span>
                    <span className="text-muted">
                      <em>{t("Exchange Rate") + ": " + currentUserShipment.userShipmentFinance.exchangeRateTarget}</em>
                    </span>
                  </li>
                </ul>
              </CardBody>
            </Card>
          </Col>
          <Col xxl={3} sm={6} className="same-height">
            <Card className="card-animate border">
              <CardHeader>
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="card-title mb-0">{t("Fees")}</h6>
                  <div className="avatar-xs flex-shrink-0 common-css">
                    <span className={"avatar-title rounded-circle fs-4 bg-info-subtle text-info"}>
                      <i className="ri-bank-line"></i>
                    </span>
                  </div>
                </div>
              </CardHeader>
              <CardBody className="d-flex flex-column">
                <ul className="list-group">
                  <li className="d-flex justify-content-between mb-1">
                    <span>{t("Shipments.ShipmentDetails.FBAFees")}</span>
                    <span>
                      <DisplayPrice source={destinationMarketplace?.currency} value={currentUserShipment.userShipmentFinance.fbaFees} decimals={2} notation="decimal" />
                    </span>
                  </li>
                  {currentUserShipment.userShipmentSetting.fbaFeeTaxRate !== undefined && currentUserShipment.userShipmentSetting.fbaFeeTaxRate > 0 && (
                    <>
                      <li className="d-flex justify-content-between mb-1">
                        <span>{t("Shipments.ShipmentDetails.FBAFeeTaxes")}</span>
                        <span>
                          <DisplayPrice source={destinationMarketplace?.currency} value={currentUserShipment.userShipmentFinance.fbaFeeTaxes} decimals={2} notation="decimal" />
                        </span>
                      </li>
                    </>
                  )}
                  <li className="d-flex justify-content-between mb-1">
                    <span>{t("Shipments.ShipmentDetails.ReferralFees")}</span>
                    <span>
                      <DisplayPrice source={destinationMarketplace?.currency} value={currentUserShipment.userShipmentFinance.referralFees} decimals={2} notation="decimal" />
                    </span>
                  </li>
                  {currentUserShipment.userShipmentSetting.referralFeeTaxRate !== undefined && currentUserShipment.userShipmentSetting.referralFeeTaxRate > 0 && (
                    <>
                      <li className="d-flex justify-content-between mb-1">
                        <span>{t("Shipments.ShipmentDetails.ReferralFeeTaxes")}</span>
                        <span>
                          <DisplayPrice source={destinationMarketplace?.currency} value={currentUserShipment.userShipmentFinance.referralFeeTaxes} decimals={2} notation="decimal" />
                        </span>
                      </li>
                    </>
                  )}
                  <li className="d-flex justify-content-between mb-1">
                    <span>{t("Shipments.ShipmentDetails.OtherFees")}</span>
                    <span>
                      <DisplayPrice source={destinationMarketplace?.currency} value={currentUserShipment.userShipmentFinance.otherFees} decimals={2} notation="decimal" />
                    </span>
                  </li>
                </ul>
                <ul className="list-group mt-auto">
                  <hr></hr>
                  <li className="d-flex justify-content-between">
                    <span>{t("Total")}</span>
                    <span>
                      <DisplayPrice source={destinationMarketplace?.currency} value={currentUserShipment.userShipmentFinance.totalFees} decimals={2} notation="decimal" />
                    </span>
                  </li>
                </ul>
              </CardBody>
            </Card>
          </Col>
          <Col xxl={3} sm={6} className="same-height">
            <Card className="card-animate border">
              <CardHeader>
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="card-title mb-0">{t("Shipments.ShipmentDetails.ShipmentProfit")}</h6>
                  <div className="avatar-xs flex-shrink-0 common-css">
                    <span className={"avatar-title rounded-circle fs-4 bg-success-subtle text-success"}>
                      <i className="ri-exchange-dollar-line"></i>
                    </span>
                  </div>
                </div>
              </CardHeader>
              <CardBody className="d-flex flex-column">
                <ul className="list-group">
                  <li className="d-flex justify-content-between mb-1">
                    <span>{t("Shipments.ShipmentDetails.TotalRevenue")}</span>
                    <span>
                      <DisplayPrice source={destinationMarketplace?.currency} value={currentUserShipment.userShipmentFinance.totalRevenue} decimals={2} notation="decimal" />
                    </span>
                  </li>
                  <li className="d-flex justify-content-between mb-1">
                    <span>{t("Shipments.ShipmentDetails.TotalPurchaseCost")}</span>
                    <span>
                      <DisplayPrice source={destinationMarketplace?.currency} value={currentUserShipment.userShipmentFinance.actualTotalCostTarget} decimals={2} notation="decimal" />
                    </span>
                  </li>
                  <li className="d-flex justify-content-between">
                    <span>{t("Shipments.ShipmentDetails.TotalFees")}</span>
                    <span>
                      <DisplayPrice source={destinationMarketplace?.currency} value={currentUserShipment.userShipmentFinance.totalFees} decimals={2} notation="decimal" />
                    </span>
                  </li>
                </ul>
                <ul className="list-group mt-auto">
                  <hr></hr>
                  <li className="d-flex justify-content-between mb-1">
                    <span>{t("Shipments.ShipmentDetails.ExpectedProfit")}</span>
                    <span>
                      <DisplayPrice source={destinationMarketplace?.currency} value={currentUserShipment.userShipmentFinance.expectedProfit} decimals={2} notation="decimal" />
                    </span>
                  </li>
                  <li className="d-flex justify-content-between mb-1">
                    <span>{t("Shipments.ShipmentDetails.ExpectedProfitPerItem")}</span>
                    <span>
                      <DisplayPrice source={destinationMarketplace?.currency} value={currentUserShipment.userShipmentFinance.expectedProfitPerItem} decimals={2} notation="decimal" />
                    </span>
                  </li>
                  <li className="d-flex justify-content-between mb-1">
                    <span>{t("Shipments.ShipmentDetails.ExpectedMargin")}</span>
                    <span>%{currentUserShipment.userShipmentFinance.expectedMargin}</span>
                  </li>
                  <li className="d-flex justify-content-between">
                    <span>{t("Shipments.ShipmentDetails.ExpectedROI")}</span>
                    <span>%{currentUserShipment.userShipmentFinance.expectedROI}</span>
                  </li>
                </ul>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Products />
        <div className="hstack gap-2 justify-content-end">
          {currentUserShipment.userShipmentItems.length !== 0 &&
            (currentUserShipment.status === ShipmentStatus.DRAFT ? (
              <Button
                type="button"
                color="info"
                size="sm"
                onClick={() => changeUserShipmentStatus({userShipmentId: currentUserShipment.userShipmentId, status: ShipmentStatus.ORDERED})(dispatch)}
                disabled={loading.changeStatus}
              >
                {loading.changeStatus ? <Spinner size="sm" className="align-middle me-2 my-1" /> : <i className="ri-amazon-fill label-icon align-middle fs-16 me-2"></i>}
                <span>{t("Shipments.ShipmentDetails.MarkAsOrdered")}</span>
              </Button>
            ) : currentUserShipment.status === ShipmentStatus.ORDERED ? (
              <Button type="button" color="primary" size="sm" onClick={() => MarkAsShippedModal.open({userShipment: currentUserShipment})} disabled={loading.changeStatus}>
                {loading.changeStatus ? <Spinner size="sm" className="align-middle me-2 my-1" /> : <i className="ri-amazon-fill label-icon align-middle fs-16 me-2"></i>}
                <span>{t("Shipments.ShipmentDetails.Dialog.MarkAsShipped.Title")}</span>
              </Button>
            ) : null)}
        </div>
      </React.Fragment>
    )
  );
};

export default ShipmentFinance;
