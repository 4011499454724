import SelectOptions, {UserSelectOption} from "Components/Hooks/useSelectOptions";
import {Button, Card, CardBody, Col, Form, Label, Row, Spinner} from "reactstrap";
import {useFormik} from "formik";
import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useProfile} from "Components/Hooks/useProfile";
import {FieldConfig, useUrlQuery} from "Components/Hooks/useUrlQuery";
import {getActiveUserStoresWithActiveMarketplaceOptions} from "helpers/utilities";
import {GetUserFavoriteListQuery} from "api/query";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import ValidatedMultiSelect from "Components/Common/ValidatedMultiSelect";
import ValidatedSelect from "Components/Common/ValidatedSelect";
import useSelectOptions from "Components/Hooks/useSelectOptions";
import * as Yup from "yup";

interface FiltersProps {
  busy: boolean;
  fields: FieldConfig<GetUserFavoriteListQuery>[];
}
const Filters = (props: FiltersProps) => {
  const {t} = useTranslation();
  const {shareTypeSelectOptions} = useSelectOptions();
  const {userProfile} = useProfile();
  const {multiSelectTranslations} = SelectOptions();
  const [teamUserOptions, setTeamUserOptions] = useState<UserSelectOption[]>([]);

  // validation
  const validation = useFormik({
    initialValues: {} as GetUserFavoriteListQuery,
    validationSchema: Yup.object<GetUserFavoriteListQuery>({
      marketplace: Yup.array<String>().notRequired(),
      teamUserIds: Yup.array<String>().notRequired(),
      shareType: Yup.string().notRequired(),
    }),
    onSubmit: (values: GetUserFavoriteListQuery) => {
      const payload: GetUserFavoriteListQuery = {
        page: values.page,
        pageSize: values.pageSize,
        marketplace: values.marketplace,
        shareType: values.shareType?.toString() === "all" ? undefined : values.shareType,
        teamUserIds: values.teamUserIds,
        sortBy: values.sortBy,
        sortingOrder: values.sortingOrder,
        action: "filtering",
      };
      updateQuery(payload);
    },
  });
  const {updateQuery} = useUrlQuery<GetUserFavoriteListQuery>(props.fields, validation);

  const onLoad = useCallback(() => {
    validation.handleSubmit();
  }, []); // eslint-disable-line

  const prepareTeamUserOptions = useCallback(() => {
    if (userProfile?.teamUsers) {
      const teamOptions: UserSelectOption[] = userProfile.teamUsers
        .map((user) => ({
          label: user.fullName,
          value: user.userId,
          avatar: user.userUiPreferences?.avatar,
          email: user.email,
        }));
      setTeamUserOptions([...teamOptions]);
    }
  }, [userProfile]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  useEffect(() => {
    prepareTeamUserOptions();
  }, [prepareTeamUserOptions]);

  return (
    <PlaceholderGlow busy={props.busy}>
      <Card style={{zIndex: 4}}>
        <CardBody>
          <div className="live-preview">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Row className="mt-2 gx-5 gy-3">
                <Col xs={12} sm={6} md={4} lg={2}>
                  <Label htmlFor="marketplace">{t("Marketplace")}</Label>
                  <ValidatedMultiSelect
                    options={[...getActiveUserStoresWithActiveMarketplaceOptions()]}
                    validation={validation}
                    field="marketplace"
                    value={validation.values.marketplace}
                    translations={{
                      ...multiSelectTranslations,
                      selectSomeItems: t("General.Select.SelectStore"),
                    }}
                    optionStyle="marketplace"
                  />
                </Col>
                {userProfile?.teamUsers != null && userProfile?.teamUsers.length > 1 && (
                  <Col xs={12} sm={6} md={4} lg={2}>
                    <Label htmlFor="status">{t("User")}</Label>
                    <ValidatedMultiSelect options={teamUserOptions} validation={validation} field="teamUserIds" value={validation.values.teamUserIds} optionStyle="user" hasSelectAll />
                  </Col>
                )}
                {userProfile?.subscription === "Premium" && (
                  <Col xs={12} sm={6} md={4} lg={2}>
                    <Label htmlFor="status">{t("Searches.Filters.ShareType")}</Label>
                    <ValidatedSelect options={shareTypeSelectOptions} validation={validation} field="shareType" value={validation.values.shareType} />
                  </Col>
                )}

                <Col xs={12} sm={6} md={4} lg={2}>
                  <Label>&nbsp;</Label>
                  <Button type="submit" color="secondary" className="d-block" disabled={props.busy} onClick={() => validation.setFieldValue("page", 1)}>
                    {props.busy ? <Spinner size="sm" className="me-2 align-middle"></Spinner> : <i className="ri-equalizer-fill me-1 align-bottom"></i>}
                    {t("Searches.Filters.Button.Apply")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </CardBody>
      </Card>
    </PlaceholderGlow>
  );
};

export default Filters;
