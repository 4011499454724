import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import DisplayPrice from "Components/Common/DisplayPrice";
import DisplayNumber from "Components/Common/DisplayNumber";
import {useTranslation} from "react-i18next";
import {Button, Card, CardBody, Col, Input, Row, Table, UncontrolledPopover} from "reactstrap";
import {SellerTypeBadge} from "../../../pages/Searches/SearchResults/Item/Components/SellerTypeBadge";
import {useCallback, useEffect, useState} from "react";
import {AmazonBusinessModel} from "models/enums/user_search_type";
import {UserSearchProductProfitAnalysis} from "models/user_search_product_profit_analysis";
import _ from "lodash";
import {AmazonMarketplace} from "helpers/marketplace_helper";

export interface ProfitCalculatorProps {
  title: string;
  amazonBusinessModel?: AmazonBusinessModel;
  itemProfitAnalysis?: UserSearchProductProfitAnalysis;
  sourceMarketplace?: AmazonMarketplace;
  destinationMarketplace?: AmazonMarketplace;
  buyBoxType?: number;
  offerCount?: number;
  userSearchProductId?: string;
  inPopup?: boolean;
}
const ProfitCalculator = ({
  title,
  amazonBusinessModel,
  itemProfitAnalysis,
  sourceMarketplace,
  destinationMarketplace,
  buyBoxType,
  offerCount,
  userSearchProductId,
  inPopup = false,
}: ProfitCalculatorProps) => {
  const {t} = useTranslation();

  const [purchasePriceSource, setPurchasePriceSource] = useState<number | undefined>(undefined);
  const [taxSource, setTaxSource] = useState<number | undefined>(undefined);
  const [prepAndShipSource, setPrepAndShipSource] = useState<number | undefined>(undefined);

  const [purchasePrice, setPurchasePrice] = useState<number | undefined>(undefined);
  const [stateSalesTax, setStateSalesTax] = useState<number | undefined>(undefined);
  const [prepAndShip, setPrepAndShip] = useState<number | undefined>(undefined);

  const [totalCost, setTotalCost] = useState<number | undefined>(undefined);
  const [totalFees, setTotalFees] = useState<number | undefined>(undefined);
  const [fbaFee, setFbaFee] = useState<number | undefined>(undefined);
  const [fbaFeeTax, setFbaFeeTax] = useState<number | undefined>(undefined);
  const [referralFee, setReferralFee] = useState<number | undefined>(undefined);
  const [referralFeeTax, setReferralFeeTax] = useState<number | undefined>(undefined);
  const [otherFees, setOtherFees] = useState<number | undefined>(undefined);
  const [exchangeRateSource, setExchangeRateSource] = useState<number | undefined>(undefined);
  const [exchangeRateTarget, setExchangeRateTarget] = useState<number | undefined>(undefined);
  const [overallCost, setOverallCost] = useState<number | undefined>(undefined);
  const [sellPrice, setSellPrice] = useState<number | undefined>(undefined);
  const [sellPriceTax, setSellPriceTax] = useState<number | undefined>(undefined);
  const [marketplaceTaxException, setMarketplaceTaxException] = useState<number | undefined>(undefined);
  const [profit, setProfit] = useState<number | undefined>(undefined);
  const [profitSource, setProfitSource] = useState<number | undefined>(undefined);
  const [margin, setMargin] = useState<number | undefined>(undefined);
  const [roi, setRoi] = useState<number | undefined>(undefined);

  const [bankTransferCommission, setBankTransferCommission] = useState<number | undefined>(undefined);
  const [estimatedRefund, setEstimatedRefund] = useState<number | undefined>(undefined);
  const [currencyExchangeLoss, setCurrencyExchangeLoss] = useState<number | undefined>(undefined);

  const initValues = useCallback(() => {
    setPurchasePriceSource(itemProfitAnalysis?.purchasePriceSource);
    setTaxSource(itemProfitAnalysis?.taxSource);
    setPurchasePrice(itemProfitAnalysis?.purchasePrice);
    setStateSalesTax(itemProfitAnalysis?.tax);
    setSellPrice(itemProfitAnalysis?.sellPrice);
    setSellPriceTax(itemProfitAnalysis?.marketplaceTax);
    setReferralFee(itemProfitAnalysis?.referralFee);
    setReferralFeeTax(itemProfitAnalysis?.referralFeeTax);
    setExchangeRateSource(itemProfitAnalysis?.exchangeRateSource);
    setExchangeRateTarget(itemProfitAnalysis?.exchangeRateTarget);
    setMarketplaceTaxException(itemProfitAnalysis?.marketplaceTaxException);

    if (amazonBusinessModel === AmazonBusinessModel.DROPSHIPPING) {
      setPrepAndShipSource(itemProfitAnalysis?.shippingPriceSourceDS);
      setPrepAndShip(itemProfitAnalysis?.shippingPriceDS);
      setTotalCost(itemProfitAnalysis?.totalCostDS);
      setTotalFees(itemProfitAnalysis?.totalFeesDS);
      setFbaFee(undefined);
      setFbaFeeTax(undefined);
      setOtherFees(itemProfitAnalysis?.otherFeesDS);
      setOverallCost(itemProfitAnalysis?.overallCostDS);
      setProfit(itemProfitAnalysis?.profitDS);
      setProfitSource(itemProfitAnalysis?.profitSourceDS);
      setMargin(itemProfitAnalysis?.marginDS);
      setRoi(itemProfitAnalysis?.roids);
      setBankTransferCommission(itemProfitAnalysis?.bankTransferCommissionDS);
      setEstimatedRefund(itemProfitAnalysis?.estimatedRefundDS);
      setCurrencyExchangeLoss(itemProfitAnalysis?.currencyExchangeLossDS);
    } else {
      setPrepAndShipSource(itemProfitAnalysis?.shippingPriceSource);
      setPrepAndShip(itemProfitAnalysis?.shippingPrice);
      setTotalCost(itemProfitAnalysis?.totalCost);
      setTotalFees(itemProfitAnalysis?.totalFees || undefined);
      setFbaFee(itemProfitAnalysis?.fbaFee);
      setFbaFeeTax(itemProfitAnalysis?.fbaFeeTax);
      setOtherFees(itemProfitAnalysis?.otherFees);
      setOverallCost(itemProfitAnalysis?.overallCost);
      setProfit(itemProfitAnalysis?.profit);
      setProfitSource(itemProfitAnalysis?.profitSource);
      setMargin(itemProfitAnalysis?.margin);
      setRoi(itemProfitAnalysis?.roi);
      setBankTransferCommission(itemProfitAnalysis?.bankTransferCommission);
      setEstimatedRefund(itemProfitAnalysis?.estimatedRefund);
      setCurrencyExchangeLoss(itemProfitAnalysis?.currencyExchangeLoss);
    }
  }, [itemProfitAnalysis, amazonBusinessModel]);

  const handleValueChange = useCallback(() => {
    if (exchangeRateTarget && exchangeRateSource) {
      const _fbaFeeTax = ((fbaFee ?? 0) * (itemProfitAnalysis?.fbaFeeTaxRate ?? 0)) / 100;
      const _referralFeeTax = ((referralFee ?? 0) * (itemProfitAnalysis?.referralFeeTaxRate ?? 0)) / 100;
      const _totalCost = !_.isNil(purchasePrice) ? (purchasePrice ?? 0) + (stateSalesTax ?? 0) + (prepAndShip ?? 0) : undefined;
      const _totalFees =
        !_.isNil(fbaFee) || !_.isNil(referralFee) || !_.isNil(otherFees) ? (fbaFee ?? 0) + (_fbaFeeTax ?? 0) + (referralFee ?? 0) + (_referralFeeTax ?? 0) + (otherFees ?? 0) : undefined;

      setFbaFeeTax(_fbaFeeTax);
      setReferralFeeTax(_referralFeeTax);
      setTotalCost(_totalCost);
      setTotalFees(_totalFees);

      const _overallCost = !_.isNil(_totalCost) && !_.isNil(_totalFees) ? _totalCost + _totalFees : undefined;
      setOverallCost(_overallCost);

      const _salesProceeds = !_.isNil(sellPrice) ? sellPrice - (sellPriceTax ?? 0) + (marketplaceTaxException ?? 0) : undefined;
      const _profit = !_.isNil(_salesProceeds) && !_.isNil(_overallCost) ? _salesProceeds - _overallCost : undefined;
      //const _profitSource = !_.isNil(_profit) ? _profit / exchangeRateTarget : undefined;
      const _margin = !_.isNil(_profit) && !_.isNil(_salesProceeds) ? (_profit / (_salesProceeds ?? 1)) * 100 : undefined;
      const _roi = !_.isNil(_profit) && !_.isNil(_totalCost) ? (_profit / (_totalCost ?? 1)) * 100 : undefined;

      const conversionRate = exchangeRateSource / exchangeRateTarget;
      const _profitSource = !_.isNil(_profit) ? _profit * conversionRate : undefined;

      setProfit(_profit);
      setProfitSource(_profitSource);
      setMargin(_margin);
      setRoi(_roi);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    purchasePrice,
    stateSalesTax,
    prepAndShip,
    fbaFee,
    fbaFeeTax,
    referralFee,
    referralFeeTax,
    otherFees,
    sellPrice,
    exchangeRateSource,
    exchangeRateTarget,
    itemProfitAnalysis?.referralFeeTaxRate,
    itemProfitAnalysis?.fbaFeeTaxRate,
  ]);

  const handleSellPriceChange = useCallback(() => {
    if (referralFee && sellPrice) {
      const _oldSellPrice = itemProfitAnalysis?.sellPrice ?? 1;
      const _referralFee = (itemProfitAnalysis?.referralFee ?? 0) * ((sellPrice ?? 0) / _oldSellPrice);
      setReferralFee(Number(_referralFee.toFixed(2)));

      setSellPriceTax(((sellPrice ?? 0) * (itemProfitAnalysis?.marketplaceTaxRate ?? 0)) / 100);
      setMarketplaceTaxException(((sellPrice ?? 0) * (itemProfitAnalysis?.marketplaceTaxExceptionRate ?? 0)) / 100);
    } else {
      setSellPriceTax(undefined);
      setMarketplaceTaxException(undefined);
    }
  }, [sellPrice]); //eslint-disable-line

  const convertCurrencies = useCallback(() => {
    if (!exchangeRateSource || !exchangeRateTarget) return;
    const conversionRate = exchangeRateTarget / exchangeRateSource;

    if (purchasePriceSource) setPurchasePrice(purchasePriceSource * conversionRate);
    else setPurchasePrice(undefined);

    if (taxSource) setStateSalesTax(taxSource * conversionRate);
    else setStateSalesTax(undefined);

    if (prepAndShipSource) setPrepAndShip(prepAndShipSource * conversionRate);
    else setPrepAndShip(undefined);
  }, [purchasePriceSource, taxSource, prepAndShipSource]); //eslint-disable-line

  useEffect(() => {
    initValues();
  }, [initValues]);

  useEffect(() => {
    convertCurrencies();
  }, [convertCurrencies]);

  useEffect(() => {
    handleValueChange();
  }, [handleValueChange]);

  useEffect(() => {
    handleSellPriceChange();
  }, [handleSellPriceChange]);

  return (
    <Card className={`mb-3 ${inPopup ? "border-bottom shadow-none" : "border card-animate"}`}>
      <CardBody>
        <h5 className="mb-2">{t(title)}</h5>
        <ul className="list-group d-flex flex-group gap-2">
          <li className="d-flex justify-content-between fw-semibold">
            <span>{t("SearchResults.Analyzes.ProfitAnalysis.Label.Costs")}</span>
            <Row>
              <Col>
                {totalCost !== undefined && sourceMarketplace?.currency !== destinationMarketplace?.currency && (
                  <>
                    <DisplayPrice
                      source={sourceMarketplace?.currency}
                      value={totalCost * ((exchangeRateSource ?? 1) / (exchangeRateTarget ?? 1))}
                      decimals={2}
                      prefix="("
                      suffix=")"
                      notation="abbreviated"
                    />
                  </>
                )}
              </Col>
              <Col>
                <DisplayPrice source={destinationMarketplace?.currency} value={totalCost} renderOnEmpty={<span className="text-muted">{t("NA")}</span>} decimals={2} notation="abbreviated" />{" "}
              </Col>
            </Row>
          </li>
          <li className="d-flex align-items-center justify-content-between">
            <span>{t("SearchResults.Analyzes.ProfitAnalysis.Label.PurchasePrice")}</span>
            <span className="d-flex align-items-center">
              <span>
                {sourceMarketplace?.currency !== destinationMarketplace?.currency && (
                  <DisplayPrice value={purchasePrice} source={destinationMarketplace?.currency} decimals={2} notation="abbreviated" />
                )}
              </span>
              <div className="input-group input-group-sm shipment-details input-group-size ms-2">
                <Input
                  type="number"
                  className="form-control form-control-sm border-input-group fw-medium"
                  value={purchasePriceSource}
                  onChange={(e) => setPurchasePriceSource(e.target.value === "" ? undefined : Number(e.target.value))}
                />
                <span className="input-group-text">{sourceMarketplace?.currency}</span>
              </div>
            </span>
          </li>
          <li className="d-flex align-items-center justify-content-between">
            <span>{t("SearchResults.Analyzes.ProfitAnalysis.Label.StateSalesTax")}</span>
            <span className="d-flex align-items-center">
              <span>
                {sourceMarketplace?.currency !== destinationMarketplace?.currency && (
                  <DisplayPrice value={stateSalesTax} source={destinationMarketplace?.currency} decimals={2} notation="abbreviated" />
                )}
              </span>
              <div className="input-group input-group-sm shipment-details input-group-size ms-2">
                <Input
                  type="number"
                  className="form-control form-control-sm border-input-group fw-medium"
                  value={taxSource}
                  onChange={(e) => setTaxSource(e.target.value === "" ? undefined : Number(e.target.value))}
                />
                <span className="input-group-text">{sourceMarketplace?.currency}</span>
              </div>
            </span>
          </li>
          <li className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <span>{t("SearchResults.Analyzes.ProfitAnalysis.Label.Prep&Ship")}</span>
              <i id="PopoverShippingPrice" className="ms-1 bx bxs-info-circle text-secondary"></i>
              <DefaultUncontrolledTooltip target="PopoverShippingPrice">{t("Definitions.ShippingPrice")}</DefaultUncontrolledTooltip>
            </div>
            <span className="d-flex align-items-center">
              <span>
                {sourceMarketplace?.currency !== destinationMarketplace?.currency && <DisplayPrice value={prepAndShip} source={destinationMarketplace?.currency} decimals={2} notation="abbreviated" />}
              </span>
              <div className="input-group input-group-sm shipment-details input-group-size ms-2">
                <Input
                  type="number"
                  className="form-control form-control-sm border-input-group fw-medium"
                  value={prepAndShipSource}
                  onChange={(e) => setPrepAndShipSource(e.target.value === "" ? undefined : Number(e.target.value))}
                />
                <span className="input-group-text">{sourceMarketplace?.currency}</span>
              </div>
            </span>
          </li>
        </ul>

        <ul className="list-group d-flex flex-group gap-2 mt-3">
          <li className="d-flex justify-content-between fw-bold">
            <span>{t("SearchResults.Analyzes.ProfitAnalysis.Label.Fees")}</span>
            <span>
              <DisplayPrice source={destinationMarketplace?.currency} decimals={2} value={totalFees} renderOnEmpty={<span className="text-muted">{t("NA")}</span>} notation="abbreviated" />
            </span>
          </li>
          {amazonBusinessModel !== AmazonBusinessModel.DROPSHIPPING && (
            <li className="d-flex align-items-center justify-content-between">
              <span>{t("SearchResults.Analyzes.ProfitAnalysis.Label.FbaFee")}</span>
              <div className="input-group input-group-sm shipment-details input-group-size ms-2">
                <Input
                  type="number"
                  className="form-control form-control-sm border-input-group fw-medium"
                  value={fbaFee}
                  onChange={(e) => setFbaFee(e.target.value === "" ? undefined : Number(e.target.value))}
                />
                <span className="input-group-text">{destinationMarketplace?.currency}</span>
              </div>
            </li>
          )}

          {amazonBusinessModel !== AmazonBusinessModel.DROPSHIPPING && fbaFeeTax !== undefined && fbaFeeTax > 0 && (
            <li className="d-flex align-items-center justify-content-between">
              <span>{t("SearchResults.Analyzes.ProfitAnalysis.Label.FbaFeeTax")}</span>
              <DisplayPrice source={destinationMarketplace?.currency} decimals={2} value={fbaFeeTax} notation="decimal" />
            </li>
          )}

          <li className="d-flex align-items-center justify-content-between">
            <span>{t("SearchResults.Analyzes.ProfitAnalysis.Label.ReferralFee")}</span>
            <div className="input-group input-group-sm shipment-details input-group-size ms-2">
              <Input
                type="number"
                className="form-control form-control-sm border-input-group fw-medium"
                value={referralFee}
                onChange={(e) => setReferralFee(e.target.value === "" ? undefined : Number(e.target.value))}
              />
              <span className="input-group-text">{destinationMarketplace?.currency}</span>
            </div>
          </li>
          {referralFeeTax !== undefined && referralFeeTax > 0 && (
            <li className="d-flex align-items-center justify-content-between">
              <span>{t("SearchResults.Analyzes.ProfitAnalysis.Label.ReferralFeeTax")}</span>
              <DisplayPrice source={destinationMarketplace?.currency} decimals={2} value={referralFeeTax} notation="decimal" />
            </li>
          )}
          <li className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <Button id={`OtherFees-${itemProfitAnalysis?.userSearchProductProfitAnalysisId}`} color="link" className="p-0 link-body-emphasis link-opacity-75-hover d-flex align-items-center">
                <span>{t("SearchResults.Analyzes.ProfitAnalysis.Label.OtherFees")}</span>
                <i className="ri-arrow-down-s-line fs-16 ms-1 text-info"></i>
              </Button>

              <UncontrolledPopover trigger="legacy" placement="right" popperClassName="search-results-other-fees" target={`OtherFees-${itemProfitAnalysis?.userSearchProductProfitAnalysisId}`}>
                <Table className="align-middle table table-hover mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>{t("SearchResults.Analyzes.ProfitAnalysis.TableColumn.Type")}</th>
                      <th>{t("SearchResults.Analyzes.ProfitAnalysis.TableColumn.Fee")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{t("SearchResults.Analyzes.ProfitAnalysis.BankTransferCommission")}</td>
                      <td>
                        {bankTransferCommission ? (
                          <div className="d-flex gap-1">
                            <DisplayPrice source={destinationMarketplace?.currency} decimals={2} value={bankTransferCommission} notation="abbreviated" />
                            <DisplayNumber value={itemProfitAnalysis?.bankTransferCommissionRate} prefix="(%" suffix=")" />
                          </div>
                        ) : (
                          <span className="text-muted">{t("NA")}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("SearchResults.Analyzes.ProfitAnalysis.EstimatedRefund")}</td>
                      <td>
                        {estimatedRefund ? (
                          <div className="d-flex gap-1">
                            <DisplayPrice source={destinationMarketplace?.currency} decimals={2} value={estimatedRefund} notation="abbreviated" />
                            <DisplayNumber value={itemProfitAnalysis?.estimatedRefundRate} prefix="(%" suffix=")" />
                          </div>
                        ) : (
                          <span className="text-muted">{t("NA")}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("SearchResults.Analyzes.ProfitAnalysis.CurrencyExchangeLoss")}</td>
                      <td>
                        {currencyExchangeLoss ? (
                          <>
                            <DisplayNumber className="text-nowrap" value={itemProfitAnalysis?.currencyExchangeLossRate} prefix="%" />
                          </>
                        ) : (
                          <span className="text-muted">{t("NA")}</span>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </UncontrolledPopover>
            </div>
            <span>
              <div className="input-group input-group-sm shipment-details input-group-size ms-2">
                <Input
                  type="number"
                  className="form-control form-control-sm border-input-group fw-medium"
                  value={otherFees}
                  onChange={(e) => setOtherFees(e.target.value === "" ? undefined : Number(e.target.value))}
                />
                <span className="input-group-text">{destinationMarketplace?.currency}</span>
              </div>
            </span>
          </li>
        </ul>
        <ul className="list-group d-flex flex-group gap-2 mt-3">
          <li className="d-flex justify-content-between fw-bold">
            <span>{t("SearchResults.Analyzes.ProfitAnalysis.Label.OverallCost")}</span>
            <span>
              <DisplayPrice value={overallCost} source={destinationMarketplace?.currency} decimals={2} renderOnEmpty={<span className="text-muted">{t("NA")}</span>} notation="decimal" />
            </span>
          </li>
          <li className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <span>
                {t("SearchResults.Analyzes.ProfitAnalysis.Label.SellPrice")}
                {offerCount === 0 && (
                  <>
                    <i id={`PopoverNoSellerInTheTargetMarketplace-${userSearchProductId}`} className="ms-1 mdi mdi-alert align-middle text-warning"></i>
                    <DefaultUncontrolledTooltip target={`PopoverNoSellerInTheTargetMarketplace-${userSearchProductId}`}> {t("NoSellerInTheTargetMarketplace")}</DefaultUncontrolledTooltip>
                  </>
                )}
              </span>
            </div>
            <span>
              <Row>
                <Col className="d-flex justify-content-end align-items-center pe-0">
                  <SellerTypeBadge type={buyBoxType ?? null} hideIfNA={true} />
                </Col>
                <Col xs="auto">
                  <div className="input-group input-group-sm shipment-details input-group-size ms-2">
                    <Input
                      type="number"
                      className="form-control form-control-sm border-input-group fw-medium"
                      value={sellPrice}
                      onChange={(e) => setSellPrice(e.target.value === "" ? undefined : Number(e.target.value))}
                    />
                    <span className="input-group-text">{destinationMarketplace?.currency}</span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  {itemProfitAnalysis?.currentShippingPrice !== 0 && itemProfitAnalysis?.currentPrice && (
                    <small className="d-flex mt-2 ms-1 text-muted">
                      <DisplayPrice source={destinationMarketplace?.currency} value={itemProfitAnalysis?.currentPrice} decimals={2} prefix="(" suffix=" + " className="me-1" notation="abbreviated" />{" "}
                      <DisplayPrice
                        source={destinationMarketplace?.currency}
                        value={itemProfitAnalysis?.currentShippingPrice}
                        decimals={2}
                        prefix=" "
                        suffix={` ${t("Shipping")})`}
                        notation="abbreviated"
                      />
                    </small>
                  )}
                </Col>
              </Row>
            </span>
          </li>
          {sellPriceTax !== undefined && sellPriceTax > 0 && (
            <li className="d-flex align-items-center justify-content-between">
              <span>{t("SearchResults.Analyzes.ProfitAnalysis.Label.SellPriceTax")}</span>
              <DisplayPrice source={destinationMarketplace?.currency} decimals={2} value={sellPriceTax} notation="decimal" />
            </li>
          )}
        </ul>

        <hr />
        <ul className="list-group d-flex flex-group gap-1">
          {itemProfitAnalysis?.marketplaceTaxExceptionRate !== 0 && (
            <li className="d-flex justify-content-between">
              <span>
                <div>{t("SearchResults.Analyzes.ProfitAnalysis.MarketplaceTaxException")}</div>
              </span>
              <span>
                <DisplayPrice
                  source={destinationMarketplace?.currency}
                  value={itemProfitAnalysis?.marketplaceTaxException}
                  decimals={2}
                  renderOnEmpty={<span className="text-muted">{t("NA")}</span>}
                  notation="abbreviated"
                />
              </span>
            </li>
          )}
          <li className="d-flex justify-content-between fw-bold">
            <span>{t("SearchResults.Analyzes.ProfitAnalysis.Profit")}</span>
            <DisplayPrice
              source={destinationMarketplace?.currency}
              value={profit}
              decimals={2}
              renderOnEmpty={<span className="text-muted">{t("NA")}</span>}
              className={profit && profit < 0 ? "text-danger" : ""}
              notation="abbreviated"
            />
          </li>
          <li className="d-flex justify-content-between fw-bold">
            <span>
              {t("SearchResults.Analyzes.ProfitAnalysis.Profit")} ({_.toUpper(sourceMarketplace?.currency)})
            </span>
            <DisplayPrice
              source={sourceMarketplace?.currency}
              value={profitSource}
              decimals={2}
              renderOnEmpty={<span className="text-muted">{t("NA")}</span>}
              className={profitSource && profitSource < 0 ? "text-danger" : ""}
              notation="abbreviated"
            />
          </li>

          <li className="d-flex justify-content-between fw-bold">
            <span>{t("SearchResults.Analyzes.ProfitAnalysis.Margin")}</span>
            <DisplayNumber value={margin} decimals={2} renderOnEmpty={<span className="text-muted">{t("NA")}</span>} className={margin && margin < 0 ? "text-danger" : ""} suffix="%" />
          </li>
          <li className="d-flex justify-content-between fw-bold">
            <div>
              <span>{t("SearchResults.Analyzes.ProfitAnalysis.ROI")}</span>
            </div>
            <DisplayNumber value={roi} decimals={2} renderOnEmpty={<span className="text-muted">{t("NA")}</span>} className={roi && roi < 0 ? "text-danger" : ""} suffix="%" />
          </li>
        </ul>

        {sourceMarketplace?.marketplace !== "US" && (
          <div className="d-flex justify-content-end mt-2 fs-11">
            <em>
              <DisplayNumber className="text-muted" value={exchangeRateSource} decimals={2} prefix={`${t("SearchResults.Analyzes.ProfitAnalysis.ExchangeRate")} (${sourceMarketplace?.currency}): `} />
            </em>
          </div>
        )}

        {destinationMarketplace?.marketplace !== "US" && destinationMarketplace?.currency !== sourceMarketplace?.currency && (
          <div className="d-flex justify-content-end mt-2 fs-11">
            <em>
              <DisplayNumber className="text-muted" value={exchangeRateTarget} decimals={2} prefix={`${t("SearchResults.Analyzes.ProfitAnalysis.ExchangeRate")} (${destinationMarketplace?.currency}): `} />
            </em>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default ProfitCalculator;
