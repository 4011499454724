import { useCallback, useEffect, useState, useRef, useMemo, useTransition } from "react";
import { Button, Card, CardBody, Col, Collapse, Row } from "reactstrap";
import { UserSearchProduct } from "models/user_search_product";
import { useDispatch, useSelector } from "react-redux";
import { markSearchProductReviewed } from "slices/search-result/thunk";
import { ActiveMarketplaces, AmazonMarketplace } from "helpers/marketplace_helper";
import { SearchResultSlice } from "slices/search-result/selector";
import Masonry from "react-masonry-css";
import Summary from "./_Summary";
import React from 'react';
import ProfitAnalysis from "./_ProfitAnalysis";
import ProductIsExist from "./_ProductIsExist";
import PriceAnalysis from "./_PriceAnalysis";
import SaleAnalysis from "./_SaleAnalysis";
import CompetitorAnalysis from "./_CompetitorAnalysis";
import CheckListItems from "./_CheckListItems";
import VariationAnalysis from "./_VariationAnalysis";
import SellerAnalysis from "./_SellerAnalysis";
import RestrictionCheck from "./_RestrictionCheck";
import ProductSize from "./_ProductSize";
import HistoryGraphics from "./_HistoryGraphics";

// Define component props
interface SearchResultItemProps {
  item: UserSearchProduct;
  width?: number;
}

const SearchResultItem: React.FC<SearchResultItemProps> = ({ item, width = window.innerWidth }) => {
  const { detailedModeVisibility } = useSelector(SearchResultSlice);
  const initialRender = useRef(true);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(!detailedModeVisibility);
  const [, startTransition] = useTransition();
  const [autoMarkUsed, setAutoMarkUsed] = useState<boolean>(false);
  const dispatch = useDispatch();
  const containerRef = useRef<HTMLDivElement>(null);
  const [sourceMarketplace, setSourceMarketplace] = useState<AmazonMarketplace | undefined>();
  const [hasInitialized, setHasInitialized] = useState<boolean>(false);

  // Memoize breakpoint columns configuration
  const breakpointColumnsObj = useMemo(() => ({
    default: 4,
    1300: 3,
    1000: 2,
    700: 1,
  }), []);

  const handleCollapse = useCallback((): void => {
    setIsCollapsed(prev => !prev);
  }, []);

  useEffect(() => {
    if (!isCollapsed && !hasInitialized) {
      setHasInitialized(true);
    }
  }, [isCollapsed, hasInitialized]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    
    startTransition(() => {
      setIsCollapsed(!detailedModeVisibility);
    });
  }, [detailedModeVisibility]);

  const handleMarkReviewed = useCallback(async (): Promise<void> => {
    if (!autoMarkUsed && item.isReviewed !== true) {
      await markSearchProductReviewed(
        { userSearchId: item.userSearchId, userSearchProductId: item.userSearchProductId, isReviewed: true },
        "SearchResult"
      )(dispatch);
      setAutoMarkUsed(true);
    }
  }, [autoMarkUsed, item.isReviewed, item.userSearchId, item.userSearchProductId, dispatch]);

  useEffect(() => {
    const source = ActiveMarketplaces.find(
      (amazonMarketplaceInfo) => amazonMarketplaceInfo.marketplace === item.marketplaceSource
    );
    setSourceMarketplace(source);
  }, [item.marketplaceSource]);

  const hasNoAvailableOffer = useMemo(() => 
    item.summary?.decisionReasons?.includes("NoAvailableOfferAtTarget") || 
    item.summary?.decisionReasons?.includes("NoAvailableOfferAtSource"),
  [item.summary?.decisionReasons]);

  const gridItems = useMemo(() => {
    // Create the appropriate grid based on whether there are available offers
    if (hasNoAvailableOffer) {
      return [
        {
          id: "col1",
          component: (
            <>
              <ProfitAnalysis item={item} />
              <ProductIsExist item={item} />
              <RestrictionCheck item={item} />
              <ProductSize item={item}/>
            </>
          ),
        },
        {
          id: "col2",
          component: (
            <>
              <CheckListItems item={item} />
              <VariationAnalysis item={item} />
              <SellerAnalysis item={item} />
            </>
          ),
        },
        {
          id: "col3",
          component: (
            <>
              <PriceAnalysis item={item} />
              <CompetitorAnalysis item={item} />
              <SaleAnalysis item={item} />
            </>
          ),
        },
        {
          id: "col4",
          component: (
            <>
              <HistoryGraphics item={item} />
            </>
          ),
        },
      ];
    } 
    
    else return [
      {
        id: "col1",
        component: (
          <>
            <ProfitAnalysis item={item} />
            <ProductIsExist item={item} />
          </>
        ),
      },
      {
        id: "col2",
        component: (
          <>
            <CheckListItems item={item} />
            <VariationAnalysis item={item} />
            <SellerAnalysis item={item} />
            <RestrictionCheck item={item} />
          </>
        ),
      },
      {
        id: "col3",
        component: (
          <>
            <PriceAnalysis item={item} />
            <CompetitorAnalysis item={item} />
            <SaleAnalysis item={item} />
            <ProductSize item={item}/>
          </>
        ),
      },
      {
        id: "col4",
        component: (
          <>
            <HistoryGraphics item={item} />
          </>
        ),
      },
    ];
  }, [item, hasNoAvailableOffer]); 



  return (
    <div
      key={`SearchResultItem-${item.userSearchProductId}`}
      ref={containerRef}
      onClick={handleMarkReviewed}
      onTouchEnd={handleMarkReviewed}
    >
      <Card className="border">
        <Summary item={item} sourceMarketplace={sourceMarketplace}></Summary>
        <Button color="link" className="" onClick={handleCollapse}>
          <i className={!isCollapsed ? "bx bxs-chevrons-up fs-22" : "bx bxs-chevrons-down fs-22"}></i>
        </Button>


        <Collapse isOpen={!isCollapsed}>
          {!isCollapsed && (
            <CardBody className="pb-0">
              <Row>
                <Col>
                  <React.Suspense fallback={null}>
                    <Masonry
                      breakpointCols={breakpointColumnsObj}
                      className="search-result-grid"
                      columnClassName="search-result-masonry-item-column"
                    >
                      {gridItems.map(({ id, component }) => (
                        <div key={id} className="search-result-masonry-item w-100">
                          {component}
                        </div>
                      ))}
                    </Masonry>
                  </React.Suspense>
                </Col>
              </Row>
            </CardBody>
          )}
        </Collapse>
      </Card>
    </div>
  );
};

export default React.memo(SearchResultItem);