import {useMemo, useState} from "react";
import {ApexOptions} from "apexcharts";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import i18n from "i18n";
import NoData from "Components/Common/NoData";
import {CardHeader, Col, Row} from "reactstrap";
import DisplayNumber from "Components/Common/DisplayNumber";

interface BSRHistoryData {
  Date: string;
  BSR: number;
}

interface BSRHistoryChartProps {
  bsrHistoryJSON?: string;
}

const BSRHistoryChart = ({bsrHistoryJSON}: BSRHistoryChartProps) => {
  const [historyDay, setHistoryDay] = useState<number>(0);
  // Memoize data parsing
  const bsrHistoryData = useMemo(() => {
    if (!bsrHistoryJSON) {
      return [];
    }

    try {
      const parsedData: BSRHistoryData[] = JSON.parse(bsrHistoryJSON);
      const sortedData = parsedData.sort((a, b) => new Date(a.Date).getTime() - new Date(b.Date).getTime());

      let dayDifference = 0;
      if (sortedData.length >= 2) {
        const minDate = new Date(sortedData[0].Date);
        const maxDate = new Date(sortedData[sortedData.length - 1].Date);
        const diffTime = Math.abs(maxDate.getTime() - minDate.getTime());
        dayDifference = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      }

      setHistoryDay(dayDifference);

      return sortedData;
    } catch (error) {
      console.error("Error parsing BSR history data:", error);
      return [];
    }
  }, [bsrHistoryJSON]);

  const formatDateForXAxis = (value: number) => {
    moment.locale(i18n.language);
    return moment(value).format("MMM D");
  };

  const formatDateTimeForTooltip = (value: number) => {
    return moment(value).format("DD MMM YYYY HH:mm");
  };

  // Memoize series data - convert to [timestamp, value] format for datetime x-axis
  const series = [
    {
      name: i18n.t("SearchResults.Analyzes.BsrHistory.SalesRank"),
      data: bsrHistoryData.map((item) => [
        new Date(item.Date).getTime(), // x: timestamp
        item.BSR, // y: BSR value
      ]),
    },
  ];

  // Memoize the entire options object
  const options: ApexOptions = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: false,
      },
      width: "100%",
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    colors: ["#769e76"], // Green for BSR line
    xaxis: {
      type: "datetime",
      labels: {
        formatter: (value: string) => formatDateForXAxis(Number(value)),
        rotate: 0,
        rotateAlways: false,
        hideOverlappingLabels: true,
        style: {
          fontSize: "10px",
        },
      },
      axisBorder: {
        show: true,
        strokeWidth: 1,
        color: "#78909C",
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "#78909C",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
    },
    yaxis: {
      forceNiceScale: true,
      decimalsInFloat: 0,
      floating: false,
      labels: {
        formatter: (value) => {
          // Format to integer with thousands separator using the memoized formatter
          return new Intl.NumberFormat().format(Math.round(value));
        },
        style: {
          fontSize: "10px",
        },
      },
      axisBorder: {
        show: true,
        color: "#78909C",
        width: 1,
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: true,
        color: "#78909C",
        width: 6,
        offsetX: 0,
        offsetY: 0,
      },
    },
    tooltip: {
      enabled: true,
      followCursor: true,
      intersect: false,
      shared: true,
      style: {
        fontSize: "12px",
        fontFamily: "Helvetica, Arial, sans-serif",
      },
      x: {
        formatter: function(value) {
          // Return full formatted date+time for the datapoint with bold styling
          return "<strong>" + formatDateTimeForTooltip(value) + "</strong>";
        },
      },
      y: {
        formatter: (value) => {
          // Format BSR with thousands separator using the memoized formatter
          return new Intl.NumberFormat().format(value);
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      size: 0, // Set marker size to 0 to hide the data points
      hover: {
        size: 5, // Show markers on hover
        sizeOffset: 3,
      },
    },
  };

  if (bsrHistoryData.length === 0) {
    return <NoData icon="mdi mdi-chart-line" style={{minHeight: 100}} />;
  }

  return (
    <>
      <CardHeader className="p-0">
        <Row className="mb-2">
          <Col>
            <h5>
              {i18n.t("SearchResults.Analyzes.BsrHistory.Title")}
              <span className="text-muted fs-12 align-middle ms-1">
                <DisplayNumber
                  value={historyDay}
                  prefix="("
                  suffix={`
                    ${historyDay > 1 ? i18n.t("days") + ")" : i18n.t("day") + ")"}`}
                />
              </span>
            </h5>
          </Col>
        </Row>
      </CardHeader>
      <div className="chart-container">
        <ReactApexChart options={options} series={series} type="line" height={150} />
      </div>
    </>
  );
};

export default BSRHistoryChart;
