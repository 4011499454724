import { ShipmentStatus } from "models/enums/shipment_status";
import { getStatusColor, getUserShipmentStatus } from "pages/Shipments/ShipmentDetails/_helper";

interface ShipmentStatusBadgeProps {
  status: ShipmentStatus;
  size?: "sm" | "md" | "lg";
}

export const ShipmentStatusBadge = ({status, size = "md"}: ShipmentStatusBadgeProps) => {
  const color = getStatusColor(status);
  const fontSize = size === "sm" ? "fs-12" : size === "lg" ? "fs-16" : "fs-14";

  let text = status != null ? getUserShipmentStatus(status) : "";

  return <span className={`badge rounded-pill bg-${color}-subtle text-${color} ${fontSize}`}>{text}</span>;
};
