import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {MarketplaceSelectOption} from "Components/Hooks/useSelectOptions";
import {ApiError, LoadingStates} from "helpers/types";
import {Currencies} from "models/currencies";
import {UserShipment} from "models/user_shipment";
import {UserStore} from "models/user_stores";
import {fetchUserStores} from "services/store_service";
import {Tag} from "models/tag";
import { ActiveMarketplaces, AllMarketplaces } from "helpers/marketplace_helper";
import { UserStoreStatusReason } from "models/enums/user_store_status_reason";
import { DeleteUserShipmentCommand } from "api/command";

export type CommonLoadingState = LoadingStates<"userStore" | "userShipment" | "loggedUser" | "reanalyze" | "save" | "update" | "delete" | "search" | "tag">;

export type AsinList = {
  asin: string;
  quantity: string;
};

export type ShipmentInfo = {
  userShipments: UserShipment[];
  asinList: AsinList[];
};

export type CommonState = {
  loading: CommonLoadingState;
  userStores: UserStore[];
  activeUserStoreOptions: MarketplaceSelectOption[];
  disconnectedUserStoreOptions: MarketplaceSelectOption[];
  activeMarketplaceOptions: MarketplaceSelectOption[];
  shipmentInfo: ShipmentInfo;
  currencies: Currencies[];
  searchResponse: SearchResponse;
  tags?: Tag[];
  error: ApiError;
};

type Product = {
  asin: string;
  title: string;
  image: string;
};

type Order = {
  amazonOrderId: string;
};

type SearchResponse = {
  products: Product[];
  orders: Order[];
};

const initialState: CommonState = {
  loading: {
    userStore: true,
    userShipment: false,
    loggedUser: false,
    reanalyze: false,
    save: false,
    update: false,
    delete: false,
    search: false,
    tag: false,
  },
  userStores: [] as UserStore[],
  activeUserStoreOptions: [] as MarketplaceSelectOption[],
  disconnectedUserStoreOptions: [] as MarketplaceSelectOption[],
  activeMarketplaceOptions: [] as MarketplaceSelectOption[],
  shipmentInfo: {} as ShipmentInfo,
  currencies: [] as Currencies[],
  searchResponse: {} as SearchResponse,
  tags: [] as Tag[],
  error: {} as ApiError,
};

const CommonSlice = createSlice({
  name: "Common",
  initialState,
  reducers: {
    setShipmentInfo(state, action: PayloadAction<ShipmentInfo>) {
      state.shipmentInfo = action.payload;
    },
    setShipmentAsins(state, action: PayloadAction<AsinList[]>) {
      state.shipmentInfo.asinList = action.payload;
    },
    setCurrencies(state, action: PayloadAction<Currencies[]>) {
      state.currencies = action.payload;
    },
    setSearchResponse(state, action: PayloadAction<SearchResponse>) {
      state.searchResponse = action.payload;
    },
    deleteOrRestoreShipment(state, action: PayloadAction<DeleteUserShipmentCommand>) {
      state.shipmentInfo = {
        ...state.shipmentInfo,
        userShipments: state.shipmentInfo.userShipments.map((item) => (item.userShipmentId === action.payload.userShipmentId ? {...item, deleted: action.payload.deleted} : item)),
      };
    },
    setTags(state, action: PayloadAction<Tag[]>) {
      state.tags = action.payload;
    },
    updateShipmentInfoItem(state, action: PayloadAction<UserShipment>) {
      state.shipmentInfo = {
        ...state.shipmentInfo,
        userShipments: state.shipmentInfo.userShipments.map((item) => (item.userShipmentId === action.payload.userShipmentId ? action.payload : item)),
      };
    },
    loading(state, action: PayloadAction<[keyof CommonLoadingState, boolean]>) {
      const [operation, loadingState] = action.payload;
      state.loading[operation] = loadingState;
    },
    apiError(state, action: PayloadAction<ApiError>) {
      state.error = action.payload;
    },
    reset(state) {
      state.loading = initialState.loading;
      state.userStores = initialState.userStores;
      state.shipmentInfo = initialState.shipmentInfo;
      state.error = initialState.error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserStores.pending, (state) => {
        state.loading.userStore = true;
      })
      .addCase(fetchUserStores.fulfilled, (state, action) => {
        state.userStores = action.payload;
        let activeAmazonMarketplaces: MarketplaceSelectOption[] = ActiveMarketplaces
          .filter((x) => x.active)
          .map((x) => {
            return {
              value: x.marketplace,
              label: x.countryName,
              marketplace: x.marketplace,
            };
          });
        let userStores = action.payload
          .filter((x) => x.status)
          .map((store) => {
            let mp = AllMarketplaces.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.marketplace === store.marketplace)!;
            return {
              value: store.userStoreId,
              label: `${mp.marketplace} - ${store.name}`,
              marketplace: mp.marketplace,
            };
          })
          .sort((a, b) => a.label.localeCompare(b.label));
        let disconnectedUserStoreOptions = action.payload
        .filter((x) => x.status === false && (x.statusReason === UserStoreStatusReason.DISCONNECTED_BY_SELLTHIS || x.statusReason === UserStoreStatusReason.DISCONNECTED_BY_USER))
        .map((store) => {
          let mp = AllMarketplaces.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.marketplace === store.marketplace)!;
          return {
            value: store.userStoreId,
            label: `${mp.marketplace} - ${store.name}`,
            marketplace: mp.marketplace,
          };
        })


        state.activeUserStoreOptions = [...userStores];
        state.activeMarketplaceOptions = [...activeAmazonMarketplaces];
        state.disconnectedUserStoreOptions = [...disconnectedUserStoreOptions];
        state.loading.userStore = false;
      })
      .addCase(fetchUserStores.rejected, (state, action) => {
        state.error = action.error as ApiError;
        state.loading.userStore = false;
      });
  },
});

export const {setShipmentInfo, setShipmentAsins, setCurrencies, setSearchResponse, deleteOrRestoreShipment, setTags, updateShipmentInfoItem, loading, apiError, reset} = CommonSlice.actions;

export default CommonSlice.reducer;
