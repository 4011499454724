import {ShareType} from "models/enums/share_type";
import {useTranslation} from "react-i18next";
import {useProfile} from "Components/Hooks/useProfile";
import {SelectVisibilityModal} from "../Modals/SelectVisibility";
import {useCallback, useEffect, useState} from "react";
import {User} from "models/user";
import DefaultUncontrolledTooltip from "../DefaultUncontrolledTooltip";

interface ShareTypeBadgeProps {
  ownerUserId: string;
  id: string;
  status?: ShareType;
  size?: "sm" | "md" | "lg";
}

export const ShareTypeBadge = ({status, size = "sm", id, ownerUserId}: ShareTypeBadgeProps) => {
  const {t} = useTranslation();
  const {userProfile} = useProfile();
  const [icon, setIcon] = useState<string>();
  const [tooltipText, setTooltipText] = useState<string>();
  const [canChangeVisibility, setCanChangeVisibility] = useState<boolean>();
  
  

  const onLoad = useCallback(
    () => {
      switch (status) {
        case ShareType.PRIVATE:
          setIcon("mdi mdi-lock");
          setTooltipText(t("AccessDropdown.PrivateDescription"));
          break;
        case ShareType.SHARED:
          setIcon("mdi mdi-account-supervisor");
          setTooltipText(t("AccessDropdown.SharedDescription"));
          break;
        default:
          setIcon("");
          setTooltipText("");
          break;
      }

      if (userProfile) {
        const parent = userProfile?.teamUsers.find((user: User) => user.parentUserId == null);
        const canChange = userProfile?.userId === ownerUserId || userProfile?.userId === parent?.userId;
        setCanChangeVisibility(canChange);
      }
    },
    [], // eslint-disable-line
  );

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const shouldDisplay = (): boolean => {
    if (!userProfile) return false;

    if (userProfile.teamUsers != null && userProfile.teamUsers.length > 1) {
      return true;
    }

    return false;
  };
  
  const handleClick = () => {
    if (status != null && canChangeVisibility) {
      SelectVisibilityModal.open({id, status});
    }
  };

  if (!shouldDisplay()) return null;

  const fontSize = size === "sm" ? "fs-12" : size === "lg" ? "fs-16" : "fs-14";

  return (
    <>
      <span id={`ShareTypeBadge-${id}`} className={`${fontSize} text-muted ${!canChangeVisibility ? "disabled cursor-default" : "cursor-pointer"}`} onClick={handleClick}>
        {icon && <i className={`${icon} align-middle`}></i>}
      </span>
      <DefaultUncontrolledTooltip target={`ShareTypeBadge-${id}`}>{tooltipText}</DefaultUncontrolledTooltip>
    </>
  );
};
