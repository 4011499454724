import {ResponseModel} from "models/response_model";
import CryptoJS from "crypto-js";
import {
  AccountLoadingStates,
  apiError,
  loading,
  setList,
  setNotificationOption,
  setPaymentMethods,
  setTeam,
  setUserBalance,
  setUserLimitUsageHistory,
  setUserSubscription,
  setTwoFactorSetup,
  TwoFactorSetup,
} from "./reducer";
import {
  getUserSubscription,
  postChangePassword,
  postGetSubUsers,
  postResendSubUserInvitation,
  postSaveSubUserInvitation,
  postUpdateAvatar,
  postUpdateGeneralInformation,
  postUpdateUIPreferences,
  deleteSubUserRequest,
  postGetNotificationOptions,
  postUpdateNotificationOptions,
  postUpdateSubUserInformation,
  postGetUserLimitUsageHistory,
  postSendSupportRequest,
  getCaptchaInfo,
  postTwoFactorSetup,
  postTwoFactorEnable,
  postTwoFactorDisable,
} from "services/identity_service";
import {
  getUserPayments,
  postCancelUserSubscription,
  postDeleteUserPaymentMethod,
  postDownloadInvoice,
  postGetUserPaymentMethods,
  postResumeUserSubscription,
  postRetrySubscriptionPayment,
  postSaveUserPaymentMethod,
  postSetAsDefaultPaymentMethod,
  postUpdateUserPaymentMethod,
} from "services/payment_service";
import i18n from "i18n";
import {ApplicationUser, IUpdateGeneralInformationDto, IUpdateUIPreferencesDto} from "models/application_user";
import {IUpdateUserPaymentMethodDto} from "models/user_payment_methods";
import {ICreditCardDto} from "models/user_payment";
import {generateError, renderSuccessToast} from "helpers/utilities";
import {UserSubscription} from "models/user_subscription";
import {ICreateOrUpdateSubUserInvitationDto, TeamUser, SendSupportRequest} from "models/user";
import {UserSettingNotificationOptions} from "models/user_setting_notification_options";
import {setLoginUser, setUserPreferences} from "slices/auth/login/reducer";
import {UserLimitUsageHistory} from "models/user_limit_usage_history";
import {PagedList} from "helpers/types";
import {GetPaymentsQuery, UserLimitUsageHistoryQuery} from "api/query";
import {LOCAL_STORAGE} from "helpers/local_storage";
import {CancelUserSubscriptionCommand, DisableTwoFactorCommand, TwoFactorEnableCommand} from "api/command";
import {reloadUser} from "slices/thunks";

export const setLoading = (payload: keyof AccountLoadingStates, value: boolean) => (dispatch: any) => {
  dispatch(loading([payload, value]));
};

//#region General
export const updateUIPreferences = (command: IUpdateUIPreferencesDto) => async (dispatch: any) => {
  try {
    dispatch(loading(["update", true]));
    postUpdateUIPreferences(command);
    dispatch(setUserPreferences(command));
    return true;
  } catch (error) {
    const errorObject = generateError(error, false);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["update", false]));
  }
};

export const updateGeneralInformation = (information: IUpdateGeneralInformationDto) => async (dispatch: any) => {
  try {
    dispatch(loading(["update", true]));
    await postUpdateGeneralInformation(information);
    const user: ApplicationUser = localStorage.getItem(LOCAL_STORAGE.LOGGED_USER) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE.LOGGED_USER)!) : {};
    user.firstName = information.firstName;
    user.lastName = information.lastName;
    user.phone = information.phone;
    user.timezone = information.timezone;
    localStorage.setItem(LOCAL_STORAGE.LOGGED_USER, JSON.stringify(user));
    dispatch(setLoginUser(user));
    renderSuccessToast(i18n.t("Account.ProfileInformation.Toast.ProfileUpdateSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["update", false]));
  }
};

export const uploadAvatar = (imageFile: File) => async (dispatch: any) => {
  try {
    dispatch(loading(["upload", true]));
    const formData = new FormData();
    formData.append("avatar", imageFile);

    const response: ResponseModel = await postUpdateAvatar(formData);
    const newImageUrl: string = response.data;
    const user: ApplicationUser = localStorage.getItem(LOCAL_STORAGE.LOGGED_USER) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE.LOGGED_USER)!) : {};
    user.avatar = newImageUrl;
    localStorage.setItem(LOCAL_STORAGE.LOGGED_USER, JSON.stringify(user));
    dispatch(setLoginUser(user));
    renderSuccessToast(i18n.t("Account.ProfileInformation.Toast.ImageUpdateSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["upload", false]));
  }
};
//#endregion

//#region Security
export const changePassword = (oldPassword: string, newPassword: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["update", true]));
    await postChangePassword({oldPassword, newPassword});
    renderSuccessToast(i18n.t("Account.Security.Toast.PasswordChanged"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["update", false]));
  }
};

export const getTwoFactorSetup = () => async (dispatch: any) => {
  try {
    dispatch(loading(["twoFactorSetup", true]));
    const response: ResponseModel = await postTwoFactorSetup();
    const result: TwoFactorSetup = response.data;
    dispatch(setTwoFactorSetup(result));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["twoFactorSetup", false]));
  }
};

export const enableTwoFactor = (command: TwoFactorEnableCommand) => async (dispatch: any) => {
  try {
    dispatch(loading(["twoFactorEnable", true]));
    await postTwoFactorEnable(command);
    await dispatch(reloadUser());
    renderSuccessToast(i18n.t("Account.Security.Toast.TwoFactorEnabled"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["twoFactorEnable", false]));
  }
};

export const disableTwoFactor = (command: DisableTwoFactorCommand) => async (dispatch: any) => {
  try {
    dispatch(loading(["twoFactorDisable", true]));
    await postTwoFactorDisable(command);
    await dispatch(reloadUser());
    renderSuccessToast(i18n.t("Account.Security.Toast.TwoFactorDisabled"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["twoFactorDisable", false]));
  }
};

//#endregion

//#region Payments
export const getUserPaymentHistory = (query: GetPaymentsQuery) => async (dispatch: any) => {
  try {
    dispatch(loading(["list", true]));
    const result: ResponseModel = await getUserPayments(query);
    dispatch(setList(result.data));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["list", false]));
  }
};

export const downloadUserPayment = (userPaymentId: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["download", true]));
    const result = await postDownloadInvoice({userPaymentId});
    window.open(result.message, "_blank", "noreferrer noopener");
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["download", false]));
  }
};
//#endregion

//#region Subscription
export const getUserPaymentMethods = () => async (dispatch: any) => {
  try {
    dispatch(loading(["paymentMethodList", true]));
    const result: ResponseModel = await postGetUserPaymentMethods();
    dispatch(setPaymentMethods(result.data));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["paymentMethodList", false]));
  }
};

export const getUserSubscriptionPackage = () => async (dispatch: any) => {
  try {
    dispatch(loading(["userSubscription", true]));
    const result: ResponseModel = await getUserSubscription();

    await dispatch(setUserSubscription(result.data.userSubscription));
    await dispatch(setUserBalance(result.data.balance));

    return true;
  } catch (error) {
    return false;
  } finally {
    dispatch(loading(["userSubscription", false]));
  }
};

export const resumeUserSubscription = (subscriptionId: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["resumeSubscription", true]));
    const response: ResponseModel = await postResumeUserSubscription({subscriptionId});
    const updatedSubscription: UserSubscription = response.data;
    dispatch(setUserSubscription(updatedSubscription));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["resumeSubscription", false]));
  }
};

export const retrySubscriptionPayment = (userSubscriptionId: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["retryPayment", true]));
    await postRetrySubscriptionPayment({userSubscriptionId});
    dispatch(getUserSubscriptionPackage());
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["retryPayment", false]));
  }
};

export const saveUserPaymentMethod = (creditCardDto: ICreditCardDto) => async (dispatch: any) => {
  try {
    dispatch(loading(["paymentMethod", true]));
    await postSaveUserPaymentMethod(creditCardDto);
    renderSuccessToast(i18n.t("Account.Subscription.Toast.PaymentMethodCreateSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["paymentMethod", false]));
  }
};

export const updateUserPaymentMethod = (updatePaymentMethodDto: IUpdateUserPaymentMethodDto) => async (dispatch: any) => {
  try {
    dispatch(loading(["paymentMethod", true]));
    await postUpdateUserPaymentMethod(updatePaymentMethodDto);
    renderSuccessToast(i18n.t("Account.Subscription.Toast.PaymentMethodUpdateSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["paymentMethod", false]));
  }
};
export const deleteUserPaymentMethod = (userPaymentMethodId: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["delete", true]));
    await postDeleteUserPaymentMethod({userPaymentMethodId});
    renderSuccessToast(i18n.t("Account.Subscription.Toast.PaymentMethodDeleteSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["delete", false]));
  }
};

export const cancelUserSubscription = (command: CancelUserSubscriptionCommand) => async (dispatch: any) => {
  try {
    dispatch(loading(["delete", true]));
    const response: ResponseModel = await postCancelUserSubscription(command);
    const updatedSubscription: UserSubscription = response.data;
    dispatch(setUserSubscription(updatedSubscription));
    renderSuccessToast(i18n.t("Account.Subscription.CancelSubscription.Toast.SubscriptionCancellationSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["delete", false]));
  }
};

export const setAsDefaultPaymentMethod = (userPaymentMethodId: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["update", true]));
    await postSetAsDefaultPaymentMethod({userPaymentMethodId});
    renderSuccessToast(i18n.t("Account.Subscription.Toast.PaymentMethodSetAsDefaultSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["update", false]));
  }
};
//#endregion

//#region Team
export const getSubUsers = () => async (dispatch: any) => {
  try {
    dispatch(loading(["list", true]));
    const result: ResponseModel = await postGetSubUsers();
    dispatch(setTeam(result.data));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["list", false]));
  }
};

export const createSubUserInvitation = (createSubUserDto: ICreateOrUpdateSubUserInvitationDto) => async (dispatch: any) => {
  try {
    dispatch(loading(["save", true]));
    const result: ResponseModel = await postSaveSubUserInvitation(createSubUserDto);
    const updatedList: TeamUser[] = result.data;
    dispatch(setTeam(updatedList));
    renderSuccessToast(i18n.t("Account.Team.Toast.SubUserCreateSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["save", false]));
  }
};

export const updateSubUserInformation = (createSubUserDto: ICreateOrUpdateSubUserInvitationDto) => async (dispatch: any) => {
  try {
    dispatch(loading(["save", true]));
    const result: ResponseModel = await postUpdateSubUserInformation(createSubUserDto);
    const updatedList: TeamUser[] = result.data;
    dispatch(setTeam(updatedList));
    renderSuccessToast(i18n.t("Account.Team.Toast.SubUserUpdateSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["save", false]));
  }
};

export const resendSubUserInvitation = (subUserId: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["update", true]));
    const result: ResponseModel = await postResendSubUserInvitation({subUserId});
    const updatedList: TeamUser[] = result.data;
    dispatch(setTeam(updatedList));
    renderSuccessToast(i18n.t("Account.Team.Toast.SubUserResendInvitationSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["update", false]));
  }
};

export const deleteSubUser = (subUserId: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["delete", true]));
    const result: ResponseModel = await deleteSubUserRequest({subUserId});
    const updatedList: TeamUser[] = result.data;
    dispatch(setTeam(updatedList));
    renderSuccessToast(i18n.t("Account.Team.Toast.SubUserDeleteSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["delete", false]));
  }
};

//#endregion
export const resetExistSubscription = () => (dispatch: any) => {
  dispatch(setUserSubscription({} as UserSubscription));
};

//#region Notification
export const getNotificationOptions = () => async (dispatch: any) => {
  try {
    dispatch(loading(["list", true]));
    const result: ResponseModel = await postGetNotificationOptions();
    const userNotificationOptions: UserSettingNotificationOptions = result.data;
    dispatch(setNotificationOption(userNotificationOptions));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["list", false]));
  }
};

export const updateNotificationOptions = (data: any) => async (dispatch: any) => {
  try {
    dispatch(loading(["update", true]));
    const result: ResponseModel = await postUpdateNotificationOptions(data);
    const updatedResult: UserSettingNotificationOptions = result.data;
    dispatch(setNotificationOption(updatedResult));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["update", false]));
  }
};
//#endregion

//#region UserLimitUsageHistory
export const getUserLimitUsageHistory = (data: UserLimitUsageHistoryQuery) => async (dispatch: any) => {
  try {
    dispatch(loading(["list", true]));
    const result: ResponseModel = await postGetUserLimitUsageHistory(data);
    const userLimitUsageHistory: PagedList<UserLimitUsageHistory> = result.data.userLimitUsageHistories;
    dispatch(setUserLimitUsageHistory(userLimitUsageHistory));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["list", false]));
  }
};

//#endregion

//#region Support
export const sendSupportRequest = (data: SendSupportRequest) => async (dispatch: any) => {
  try {
    dispatch(loading(["save", true]));
    var formData = new FormData();
    formData.append("description", data.description);
    formData.append("issue", data.issue);
    formData.append("issueDetail", data.issueDetail);
    formData.append("priority", data.priority);
    data.attachments.forEach((attachment) => {
      formData.append("attachments", attachment);
    });

    await postSendSupportRequest(formData);
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["save", false]));
  }
};

export const getCaptchaInfoRequest = () => async () => {
  try {
    const result: ResponseModel = await getCaptchaInfo();

    const key = CryptoJS.enc.Utf8.parse("captcha-SellThis");
    const iv = CryptoJS.enc.Utf8.parse("googleToSellThis");
    const decrypted = CryptoJS.AES.decrypt(result.data, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return CryptoJS.enc.Utf8.stringify(decrypted);
  } catch (error) {
    return "";
  }
};
//#endregion
