import React, {useState, useRef, useEffect} from "react";
import _ from "lodash";
import {Button, Card, CardBody, Col, Container, Row, Input, FormGroup, Form} from "reactstrap";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";
import ParticlesAuth from "./ParticlesAuth";
import logoTransparent from "assets/images/logo-dark-transparent.png";
import {verifyTwoFactor} from "slices/thunks";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useProfile} from "Components/Hooks/useProfile";
import TwoFactorLayout from "layouts/TwoFactorLayout";
import i18n from "i18n";

// Constants
const CODE_LENGTH = 6;
interface InputRef {
  current: HTMLInputElement | null;
}

const Verify2FA = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const {userProfile} = useProfile();
  const {tempToken} = location.state || {};

  const loginSelector = createSelector([(state: RootState) => state.Login], (login) => ({
    loading: login.loading,
  }));

  const {loading} = useSelector(loginSelector);

  const [code, setCode] = useState<string[]>(Array(CODE_LENGTH).fill(""));
  const inputRefs = useRef<InputRef[]>(
    Array(CODE_LENGTH)
      .fill(null)
      .map(() => ({current: null})),
  );

  // Redirect if no temp token is found
  useEffect(() => {
    if (!tempToken) {
      navigate("/", {replace: true});
    }
  }, [tempToken, navigate]);

  // Focus first input on mount
  useEffect(() => {
    inputRefs.current[0]?.current?.focus();
  }, []);

  const isFormComplete = _.every(code, (digit) => digit !== "");

  const handleInputChange = (index: number, value: string): void => {
    // Only allow numeric input
    if (!/^\d*$/.test(value)) return;

    setCode((prevCode) => {
      const newCode = [...prevCode];
      newCode[index] = value;
      return newCode;
    });

    // Auto-focus next input if available
    if (value && index < CODE_LENGTH - 1) {
      inputRefs.current[index + 1]?.current?.focus();
    }
  };

  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>): void => {
    // Handle backspace - move to previous input if current is empty
    if (e.key === "Backspace" && !code[index] && index > 0) {
      inputRefs.current[index - 1]?.current?.focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>): void => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text").replace(/\D/g, "");

    if (!pastedData) return;

    const digits = _.take(pastedData.split(""), CODE_LENGTH);
    setCode((prev) => {
      const newCode = [...prev];
      digits.forEach((digit, index) => {
        if (index < CODE_LENGTH) newCode[index] = digit;
      });
      return newCode;
    });

    // Focus the input after the last pasted digit
    const nextIndex = Math.min(digits.length, CODE_LENGTH - 1);
    inputRefs.current[nextIndex]?.current?.focus();
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    const verificationCode = code.join("");

    if (verificationCode.length === CODE_LENGTH) {
      await verifyTwoFactor({verificationCode, refreshToken: userProfile!.tokenInfo.refreshToken}, navigate)(dispatch);
      navigate("/");
    }
  };

  return (
    <ParticlesAuth>
      <TwoFactorLayout>
        <div className="auth-page-content mt-lg-5">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoTransparent} alt="" height="50" />
                    </Link>
                  </div>
                  <p className="mt-3 fs-15 fw-medium">{t("General.Slogan")}</p>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">{t("Verify2FA.Title")}</h5>
                      <p className="text-muted">{t("Verify2FA.Subtitle")}</p>
                    </div>

                    <Form onSubmit={handleSubmit}>
                      <FormGroup className=" text-center mb-4">
                        <div className="d-flex justify-content-center gap-1">
                          {code.map((digit, index) => (
                            <Input
                              key={index}
                              type="text"
                              maxLength={1}
                              value={digit}
                              innerRef={(el: HTMLInputElement) => (inputRefs.current[index].current = el)}
                              onChange={(e) => handleInputChange(index, e.target.value)}
                              onKeyDown={(e) => handleKeyDown(index, e)}
                              onPaste={handlePaste}
                              className="form-control-lg text-center bg-light"
                            />
                          ))}
                        </div>
                      </FormGroup>

                      <div className="mt-4">
                        <Button color="success" className="w-100" type="submit" disabled={!isFormComplete || loading.verify2fa}>
                          {loading.verify2fa ? t("Verify2FA.Button.Verifying") : t("Verify2FA.Button.Verify")}
                        </Button>
                      </div>
                      <p className="mt-2 fs-12 text-muted text-center">
                        <Trans i18nKey="Verify2FA.Info.RecoveryOptions">
                          {t("Verify2FA.Info.RecoveryOptions")}
                          <Link
                            to={i18n.language === "en" ? "https://www.sellthis.com/contact-us" : i18n.language === "tr" ? "https://www.sellthis.com/tr/contact-us" : ""}
                            target="_blank"
                            className="text-primary text-decoration-underline fst-normal fw-medium"
                          ></Link>
                        </Trans>
                      </p>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </TwoFactorLayout>
    </ParticlesAuth>
  );
};

export default Verify2FA;
