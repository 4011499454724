import {FormikValidation} from "helpers/types";
import {CSSProperties} from "react";
import {Input, InputGroup, InputGroupText, FormFeedback} from "reactstrap";

export type ValidatedInputGroupProps<T> = {
  validation: FormikValidation<T>;
  field: keyof T;
  type?: "text" | "email" | "url" | "textarea" | "password" | "number";
  id?: string;
  disabled?: boolean;
  className?: string;
  style?: CSSProperties;
  placeholder?: string;
  maxLength?: number;
  bsSize?: "sm" | "lg";
  tabIndex?: number;
  suffix?: string | React.ReactNode;
  prefix?: string | React.ReactNode;
  disableValidationUI?: boolean;
  hideErrorText?: boolean;
};

const ValidatedInputGroup = <T,>({field, validation, type = "text", disableValidationUI, disabled, suffix, prefix, hideErrorText, ...rest}: ValidatedInputGroupProps<T>) => {
  const {handleChange, handleBlur} = validation;
  const fieldName = field.toString();
  const {error, touched, value} = validation.getFieldMeta(fieldName);

  return (
    <>
      <InputGroup size={rest.bsSize}>
        {prefix && <InputGroupText>{prefix}</InputGroupText>}

        <Input
          id={rest.id || fieldName}
          name={fieldName}
          type={type}
          invalid={touched && error ? true : false}
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
          disabled={disabled}
          {...rest}
        />

        {suffix && <InputGroupText>{suffix}</InputGroupText>}
        {touched && error && !hideErrorText && <FormFeedback>{error}</FormFeedback>}
      </InputGroup>
    </>
  );
};

export default ValidatedInputGroup;
