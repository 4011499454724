import {UserSearchProduct} from "models/user_search_product";
import {useTranslation} from "react-i18next";
import {Badge, Card, CardBody} from "reactstrap";
import {useCallback, useEffect, useState} from "react";
import {AmazonBusinessModel} from "models/enums/user_search_type";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import DisplayPrice from "Components/Common/DisplayPrice";
import DisplayNumber from "Components/Common/DisplayNumber";

interface PriceAnalysisProps {
  item: UserSearchProduct;
}
const PriceAnalysis = ({item}: PriceAnalysisProps) => {
  const {t} = useTranslation();
  const [result, setResult] = useState<string>();

  const onLoad = useCallback(
    () => {
      if (item.amazonBusinessModel === AmazonBusinessModel.DROPSHIPPING) {
        setResult(item.priceAnalysis?.resultDS ? item.priceAnalysis?.resultDS : undefined);
      } else {
        setResult(item.priceAnalysis?.result ? item.priceAnalysis?.result : undefined);
      }
    },
    [item.priceAnalysis], // eslint-disable-line
  );

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const statusColor = result != null ? (result === "Stable" ? "success" : result === "Recheck" ? "warning" : "danger") : "";
  return (
    <Card className="border card-animate mb-3">
      <CardBody>
        <h5>{t("SearchResults.Analyzes.PriceAnalysis.Title")}</h5>
        <ul className="list-group d-flex flex-group gap-1">
          <li className="d-flex justify-content-between">
            <span>{t("SearchResults.Analyzes.PriceAnalysis.Status")}</span>
            {result != null && result !== "NA" ? (
              <Badge color={statusColor} className="h-25 fs-11 rounded-pill">
                {t(result)}
                {result === "Stable" && <i className="mdi mdi-check ms-1"></i>}
              </Badge>
            ) : (
              <span>{t("NA")}</span>
            )}
          </li>
          <li className="d-flex justify-content-between">
            <span className="d-flex align-items-center">
              {t("SearchResults.Analyzes.PriceAnalysis.MinPrice")}
              <i className="fs-10 text-muted ms-1">
                ({t("XDays", {value: item.priceAnalysis?.result && item.priceAnalysis?.priceAnalysisDateRange != null ? item.priceAnalysis.priceAnalysisDateRange : 90})})
              </i>
            </span>
            <span>
              {item.priceAnalysis?.result && item.priceAnalysis?.minPrice ? (
                <DisplayPrice source={item.currencyCodeTarget} value={item.priceAnalysis?.minPrice} decimals={2} notation="decimal" />
              ) : (
                t("NA")
              )}
            </span>
          </li>
          <li className="d-flex justify-content-between">
            <span className="d-flex align-items-center">
              {t("SearchResults.Analyzes.PriceAnalysis.MaxPrice")}
              <i className="fs-10 text-muted ms-1">
                ({t("XDays", {value: item.priceAnalysis?.result && item.priceAnalysis?.priceAnalysisDateRange != null ? item.priceAnalysis.priceAnalysisDateRange : 90})})
              </i>
            </span>
            <span>
              {item.priceAnalysis?.result && item.priceAnalysis?.maxPrice ? (
                <DisplayPrice source={item.currencyCodeTarget} value={item.priceAnalysis?.maxPrice} decimals={2} notation="decimal" />
              ) : (
                t("NA")
              )}
            </span>
          </li>

          <li className="d-flex justify-content-between">
            <div>
              <span>{t("SearchResults.Analyzes.PriceAnalysis.CurrentPrice")}</span>
            </div>
            <span>
              <DisplayPrice source={item.currencyCodeTarget} value={item.priceAnalysis?.currentPrice} decimals={2} notation="decimal" renderOnEmpty={<>{t("NA")}</>} />
            </span>
          </li>
          <li className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <span>{t("SearchResults.Analyzes.PriceAnalysis.Change")}</span>
              <i id={`PopoverPriceAnalysisChange-${item.userSearchProductId}`} className="ms-1 bx bxs-info-circle text-secondary"></i>
              <DefaultUncontrolledTooltip target={`PopoverPriceAnalysisChange-${item.userSearchProductId}`}>{t("Definitions.PriceChange")}</DefaultUncontrolledTooltip>
            </div>
            <span>
              <DisplayNumber value={item.priceAnalysis?.priceDiffRate} suffix={"%"} decimals={2} renderOnEmpty={<>{t("NA")}</>} />
            </span>
          </li>
        </ul>
      </CardBody>
    </Card>
  );
};

export default PriceAnalysis;
