import DataTable, {DataTableRef} from "Components/Common/DataTable";
import NewShipment, {NewShipmentModal} from "pages/Shipments/Shipments/Modal/NewShipment";
import {Modal, ModalHeader, ModalBody, Col, Row, Input, Button, ModalFooter} from "reactstrap";
import {ColumnDef} from "@tanstack/react-table";
import {addUserShipmentItem, deleteUserShipment, deleteUserShipmentItem, getUserDraftShipments, updateUserShipmentItem} from "slices/common/thunk";
import {useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {AddUserShipmentItemCommand, DeleteUserShipmentItemCommand, UpdateUserShipmentItemCommand} from "api/command";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useProfile} from "Components/Hooks/useProfile";
import {useDebounce} from "Components/Hooks/useDebounce";
import {createTypedModal} from "helpers/modal_helpers";
import {AllMarketplaces} from "helpers/marketplace_helper";
import {CommonSliceSelector} from "slices/common/selector";
import {UserShipment} from "models/user_shipment";
import {changeShipmentVisibility} from "slices/thunks";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import EditShipment, {EditShipmentModal} from "pages/Shipments/Shipments/Modal/EditShipment";
import DisplayNumber from "./DisplayNumber";
import DefaultUncontrolledTooltip from "./DefaultUncontrolledTooltip";
import DisplayDate from "./DisplayDate";
import Restricted from "./Restricted";
import ResourceLink from "./ResourceLink";
import SelectVisibility from "./Modals/SelectVisibility";

interface ModalData {
  asin: string;
  marketplace: string;
  purchasePrice?: number;
  exchangeRateSource?: number;
}
export const SellThisModal = createTypedModal<ModalData>("sell_this");
export type ListRef = {
  reload: VoidFunction;
};
const SellThis = () => {
  const {t} = useTranslation();
  const {open, data} = SellThisModal.useModal();
  const {userProfile} = useProfile();
  const dispatch = useDispatch();
  const tableRef = useRef<DataTableRef>(null);
  const listRef = useRef<ListRef>(null);
  const {loading, shipmentInfo, userStores} = useSelector(CommonSliceSelector);

  const handleCheckBoxChange = (event: any, userStoreId: string, userShipmentId: string, shipmentName: string) => {
    if (event.target.checked) {
      let parameters: AddUserShipmentItemCommand = {
        userStoreId: userStoreId,
        userShipmentId: userShipmentId,
        shipmentName: shipmentName,
        asin: data?.asin!,
        exchangeRateSource: data?.exchangeRateSource,
        purchasePrice: data?.purchasePrice ?? 0,
        tax: 0,
        shippingPrice: 0,
        fBAFee: 0,
        referralFee: 0,
        otherFees: 0,
        expectedSellPrice: 0,
        totalItems: 1,
        weight: 0,
        from: "SearchResults",
      };
      addUserShipmentItem(parameters)(dispatch);
    } else {
      let parameters: DeleteUserShipmentItemCommand = {
        userShipmentId: userShipmentId,
        userShipmentItemId: "",
        asin: data?.asin!,
        from: "SearchResults",
      };
      deleteUserShipmentItem(parameters)(dispatch);
    }
  };

  const debouncedLoadList = useDebounce(() => {
    if (open) {
      getUserDraftShipments(
        data?.asin!,
        data?.marketplace!,
      )(dispatch).then(() => {
        tableRef.current?.resetSelection();
      });
    }
  }, 200);

  useEffect(() => {
    debouncedLoadList();
  }, [debouncedLoadList]);

  useImperativeHandle(
    listRef,
    () => {
      return {
        reload: () => {
          debouncedLoadList();
        },
      };
    },
    [debouncedLoadList],
  );

  const initModal = useCallback(() => {
    if (open) {
      getUserDraftShipments(
        data?.asin!,
        data?.marketplace!,
      )(dispatch).then(() => {
        tableRef.current?.resetSelection();
      });
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    initModal();
  }, [initModal]);

  const columns = useMemo<ColumnDef<UserShipment, any>[]>(
    () => [
      {
        header: t("Select"),
        size: 10,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          const dynamic = row as any;
          return (
            <Row className="align-items-center w-100">
              <div className="align-middle">
                <Input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  checked={dynamic.userShipmentItem}
                  onChange={(event) => handleCheckBoxChange(event, row.userStoreId, row.userShipmentId, "")}
                />
              </div>
            </Row>
          );
        },
      },
      {
        header: t("Shipments.ShipmentItems.Quantity"),
        size: 10,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          const dynamic = row as any;

          const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = parseInt(e.target.value);

            if (newValue !== (dynamic.userShipmentItem?.totalItems || 0)) {
              let payload: UpdateUserShipmentItemCommand = {
                userShipmentId: row.userShipmentId,
                userShipmentItemId: dynamic.userShipmentItem?.userShipmentItemId,
                field: "TotalItems",
                newValue: newValue,
                from: "SellThis",
              };

              updateUserShipmentItem(payload)(dispatch);
            }
          };

          return (
            <>
              <Input
                id={"quantity-" + row.userShipmentId}
                type="number"
                style={{width: 60}}
                className="form-control form-control-sm border-input-group"
                defaultValue={dynamic.userShipmentItem?.totalItems || 0}
                onBlur={handleValueChange}
                onKeyDown={(event: React.KeyboardEvent) => event.key === "Enter" && handleValueChange(event as any)}
                disabled={(dynamic.userShipmentItem?.totalItems || 0) === 0}
              />
            </>
          );
        },
      },
      {
        header: t("Action"),
        size: 50,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          const [isDeletingOrRestoring, setIsDeletingOrRestoring] = useState<boolean>(false); // eslint-disable-line

          const handleDeleteOrRestore = async () => {
            setIsDeletingOrRestoring(true);
            await deleteUserShipment({asin: data!.asin, from: "SearchResults", userShipmentId: row.userShipmentId, deleted: !row.deleted}, row.name)(dispatch);
            setIsDeletingOrRestoring(false);
          };

          return (
            <div className="d-flex align-items-center">
              <Button
                id={`Edit-${row.userShipmentId}`}
                className="btn btn-ghost-secondary px-1 py-0 fs-16"
                onClick={() => {
                  EditShipmentModal.open({shipment: row});
                }}
              >
                <i className="ri-pencil-fill align-middle"></i>
              </Button>
              <DefaultUncontrolledTooltip target={`Edit-${row.userShipmentId}`}>{t("Edit")}</DefaultUncontrolledTooltip>

              <Button
                id={`Delete-${row.userShipmentId}`}
                color={row.deleted ? "ghost-secondary" : "ghost-danger"}
                className="btn-link px-1 py-0 fs-16"
                onClick={handleDeleteOrRestore}
                disabled={isDeletingOrRestoring}
              >
                {row.deleted ? <i className="ri-arrow-go-back-line align-middle" /> : <i className="ri-delete-bin-fill align-middle"></i>}
              </Button>
              <DefaultUncontrolledTooltip target={`Delete-${row.userShipmentId}`}>{row.deleted ? t("Shipments.Tooltip.Restore") : t("Shipments.Tooltip.Delete")}</DefaultUncontrolledTooltip>
            </div>
          );
        },
      },
      {
        header: t("Shipments.Name"),
        size: 150,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          return (
            <ResourceLink
              key={row.userShipmentId}
              href={`/shipments/shipment-details/${row.userShipmentId}`}
              userId={row.createdUserId}
              id={row.userShipmentId}
              maxLength={30}
              name={row.name}
              shareType={row.shareType}
              tooltipKey="Shipments.Tooltip.CreatedBy"
              target="_blank"
            />
          );
        },
      },
      {
        header: t("Store"),
        size: 200,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          const marketplaceObject = AllMarketplaces.find((amazonMarketplaceInfo) => amazonMarketplaceInfo.marketplace === data?.marketplace);
          return (
            <span className="hstack gap-2">
              <div className="avatar-xs img-thumbnail rounded-circle flex-shrink-0">
                <img src={marketplaceObject?.flag} alt="" className=" rounded-circle" />
              </div>
              {marketplaceObject?.countryName} {`(${data?.marketplace}) - ${row.userStore?.name}`}
            </span>
          );
        },
      },
      {
        header: t("Shipments.SKUs"),
        size: 50,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          return <>{<DisplayNumber value={row.totalSKU} />}</>;
        },
      },
      {
        header: t("Shipments.Units"),
        size: 50,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          return <>{<DisplayNumber value={row.totalItems} />}</>;
        },
      },
      {
        header: t("CreateDate"),
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          return <DisplayDate id={`DisplayDateSellThis-${row.userShipmentId}`} value={row.createDate} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} />;
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, userStores, data, shipmentInfo],
  );

  const toggle = () => {
    SellThisModal.close();
  };
  return (
    <Modal backdrop="static" id="showSellThisModal" isOpen={open} toggle={toggle} fade={true} centered={true} size="xl">
      <PlaceholderGlow busy={loading.userShipment || loading.save || loading.update || loading.delete}>
        <ModalHeader className="p-3" toggle={toggle}>
          {t("Shipments.CurrentShipments")}
          <span className="ms-1 fs-13 text-muted fw-medium">
            -{" "}
            <span
              dangerouslySetInnerHTML={{
                __html: t("Shipments.PleaseSelectShipmentForASIN", {asin: data?.asin}),
              }}
            ></span>
          </span>
        </ModalHeader>
        <ModalBody>
          {userStores != null && userStores.filter((store) => store.marketplace === data?.marketplace).length === 0 ? (
            <>
              <div>
                <i className="ms-1 bx bxs-info-circle fs-20 text-danger"></i> {t("Shipments.StoreConnectInfoMessage")}
              </div>
              <div className="mt-4">
                <Button
                  type="submit"
                  className="btn w-sm btn-success"
                  onClick={() => {
                    window.location.href = "/stores?connect=true";
                  }}
                >
                  {t("Shipments.GoToStores")}
                </Button>
              </div>
            </>
          ) : (
            <>
              <Row>
                <Col>
                  {shipmentInfo?.userShipments && shipmentInfo?.userShipments.length > 0 ? (
                    <DataTable
                      ref={tableRef}
                      columns={columns}
                      data={shipmentInfo.userShipments}
                      totalDataLength={shipmentInfo?.userShipments.length || 0}
                      thClass=""
                      busy={loading.userShipment || loading.update}
                    />
                  ) : (
                    t("Shipments.NotHaveCurrentShipments")
                  )}
                </Col>
              </Row>
              <Restricted require="shipments" create>
                <NewShipment />
                <Row className="mt-4 pt-2">
                  <Col>
                    <Button type="button" color="secondary" className="btn-sm fw-medium add-btn" onClick={() => NewShipmentModal.open({listRef, targetMarketplace: data?.marketplace})}>
                      <i className="ri-add-line align-bottom me-1"></i>
                      {t("Shipments.NewShipment")}
                    </Button>
                  </Col>
                </Row>
              </Restricted>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <Button type="button" className="btn btn-light" onClick={toggle}>
              {t("Close")}
            </Button>
          </div>
        </ModalFooter>
      </PlaceholderGlow>
      <SelectVisibility onChange={(id, visibility) => changeShipmentVisibility({userShipmentId: id, shareType: visibility}, "SellThisModal")(dispatch)} />
      <EditShipment />
    </Modal>
  );
};

export default SellThis;
