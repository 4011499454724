import BreadCrumb, {BreadcrumbMenuItem} from "Components/Common/BreadCrumb";
import DataTable, {DataTableRef} from "Components/Common/DataTable";
import {useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {useImmer} from "use-immer";
import {useDebounce} from "Components/Hooks/useDebounce";
import {changeShipmentVisibility, deleteUserShipment, getUserShipments, resetShipmentState} from "slices/shipment/thunk";
import {ColumnDef} from "@tanstack/react-table";
import {setCurrentUserShipment} from "slices/shipment/reducer";
import {Button, Card, CardBody, Col, Container, Progress} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {UserShipment} from "models/user_shipment";
import {FieldConfig, useUrlQuery} from "Components/Hooks/useUrlQuery";
import {getShipmentSpeed} from "helpers/utilities";
import {useProfile} from "Components/Hooks/useProfile";
import {ConstantPage} from "helpers/permission_helper";
import {ActiveMarketplaces} from "helpers/marketplace_helper";
import {ShipmentStatus} from "models/enums/shipment_status";
import {UserShipmentsQuery} from "api/query";
import Loader from "Components/Common/Loader";
import Filters from "./Filters";
import EditShipment, { EditShipmentModal } from "./Modal/EditShipment";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";
import DomainToFlag from "Components/Common/DomainToFlag";
import DisplayPrice from "Components/Common/DisplayPrice";
import DisplayNumber from "Components/Common/DisplayNumber";
import NoResult from "Components/Common/NoResult";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import DisplayDate from "Components/Common/DisplayDate";
import SuccessRateCalculator from "Components/Common/Calculator/SuccessRateCalculator";
import SelectVisibility from "Components/Common/Modals/SelectVisibility";
import ResourceLink from "Components/Common/ResourceLink";
import useSelectOptions from "Components/Hooks/useSelectOptions";
import {ShipmentStatusBadge} from "Components/Common/Badges/ShipmentStatusBadge";
import { ShipmentSlice } from "slices/shipment/selector";

export type ShipmentListRef = {
  reload: VoidFunction;
};

const PAGE_IDENTIFIER: ConstantPage = "shipments";
const Shipments = () => {
  const {t} = useTranslation();
  const {userProfile, hasPermission} = useProfile();
  const {shareTypeSelectOptions} = useSelectOptions();
  const dispatch = useDispatch();

  const tableRef = useRef<DataTableRef>(null);
  const listRef = useRef<ShipmentListRef>(null);
  const [localQuery, updateLocalQuery] = useImmer<UserShipmentsQuery>({
    userStoreIds: [],
    statuses: [],
    page: 1,
    pageSize: 10,
    action: "filtering",
  });

  let filterFields: FieldConfig<UserShipmentsQuery>[] = [
    {field: "search", queryParam: "search", type: "string"},
    {field: "createDateRange", queryParam: "createDateRange", type: "dateRange"},
    {field: "shipDateRange", queryParam: "shipDateRange", type: "dateRange"},
    {field: "userStoreIds", queryParam: "userStoreIds", type: "array"},
    {field: "teamUserIds", queryParam: "teamUserIds", type: "array"},
    {field: "shareType", queryParam: "shareType", type: "string", defaultValue: shareTypeSelectOptions[0].value},
    {field: "statuses", queryParam: "statuses", type: "array"},
    {field: "page", queryParam: "page", type: "number", defaultValue: localQuery.page},
    {field: "pageSize", queryParam: "pageSize", type: "number", defaultValue: localQuery.pageSize},
    {field: "sortBy", queryParam: "sortBy", type: "string", defaultValue: localQuery.sortBy},
    {field: "sortingOrder", queryParam: "sortingOrder", type: "number", defaultValue: localQuery.sortingOrder},
    {field: "action", queryParam: "action", type: "string", defaultValue: "filtering"},
  ];
  const {readQueryParams, updateQuery, searchParams} = useUrlQuery<UserShipmentsQuery>(filterFields);

  const debouncedLoadList = useDebounce(() => {
    getUserShipments(localQuery)(dispatch).then(() => {
      tableRef.current?.resetSelection();
    });
  }, 200);

  useEffect(() => {
    if (hasPermission("shipments") && searchParams.has("action")) {
      const params = readQueryParams();
      updateLocalQuery(params as UserShipmentsQuery);
      debouncedLoadList();
    }
  }, [debouncedLoadList, searchParams]); //eslint-disable-line

  useImperativeHandle(
    listRef,
    () => {
      return {
        reload: () => {
          debouncedLoadList();
        },
      };
    },
    [debouncedLoadList],
  );


  const {loading, userShipmentList} = useSelector(ShipmentSlice);

  const columns = useMemo<ColumnDef<UserShipment, any>[]>(
    () => [
      {
        header: t("Actions"),
        enableSorting: false,
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          const [isDeletingOrRestoring, setIsDeletingOrRestoring] = useState<boolean>(false); // eslint-disable-line
          const notPermitted: boolean = row.createdUserId !== userProfile?.userId && userProfile?.isParentUser === false;

          const handleDeleteOrRestore = async () => {
            setIsDeletingOrRestoring(true);
            await deleteUserShipment({asin: "", from: "Shipments", userShipmentId: row.userShipmentId, deleted: !row.deleted}, row.name)(dispatch);
            setIsDeletingOrRestoring(false);
          };
          return (
            <>
              <div className="d-flex flex-row align-items-center">
                <Col xs="auto">
                  <Button
                    id={`EditUserShipment-${row.userShipmentId}`}
                    color="link"
                    className="p-0 btn btn-ghost-info px-1 py-0 fs-18"
                    onClick={() => {
                      dispatch(setCurrentUserShipment(row));
                      EditShipmentModal.open({shipment: row});
                    }}
                  >
                    <i className="ri-pencil-fill align-middle"></i>
                  </Button>
                  <DefaultUncontrolledTooltip target={`EditUserShipment-${row.userShipmentId}`}>{t("Edit")}</DefaultUncontrolledTooltip>
                </Col>
                <Col xs="auto">
                  <Button
                    id={`DeleteShipment-${row.userShipmentId}`}
                    color={row.deleted ? "ghost-secondary" : "ghost-danger"}
                    className="btn-link px-1 py-0 fs-18"
                    onClick={handleDeleteOrRestore}
                    disabled={isDeletingOrRestoring || notPermitted}
                  >
                    {row.deleted ? <i className="ri-arrow-go-back-line align-middle" /> : <i className="ri-delete-bin-fill align-middle"></i>}
                  </Button>
                  <DefaultUncontrolledTooltip target={`DeleteShipment-${row.userShipmentId}`}>
                    {row.deleted ? t("Shipments.Tooltip.Restore") : t("Shipments.Tooltip.Delete")}
                  </DefaultUncontrolledTooltip>
                </Col>
              </div>
            </>
          );
        },
      },
      {
        header: t("Shipments.TableColumn.NameDate"),
        enableSorting: true,
        accessorKey: "createDate",
        size: 200,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          return (
            <>
              <div className="d-flex align-items-start">
                <div>
                  <div className="mb-1 nowrap">
                    <ResourceLink
                      key={row.userShipmentId}
                      href={`/shipments/shipment-details/${row.userShipmentId}`}
                      userId={row.createdUserId}
                      id={row.userShipmentId}
                      maxLength={30}
                      name={row.name}
                      shareType={row.shareType}
                      tooltipKey="Shipments.Tooltip.CreatedBy"
                      target="_self"
                    />
                  </div>
                  <span className="my-1 text-nowrap text-muted d-flex align-items-center">
                    <i className="bx bx-calendar-check me-1 align-middle"></i>
                    <DisplayDate id={`DisplayDateUserShipmentCreateDate-${row.userShipmentId}`} value={row.createDate} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} />
                  </span>
                  <div className="d-flex align-items-center text-nowrap text-muted">
                    <DomainToFlag marketplace={row.userStore.marketplace} />
                    <span>{`${row.userStore.marketplace} - ${row.userStore.name}`}</span>
                  </div>
                </div>
              </div>
            </>
          );
        },
      },
      {
        header: t("Shipments.TableColumn.Status"),
        enableSorting: true,
        size: 100,
        accessorKey: "status",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          return (
            <>
              <ShipmentStatusBadge status={row.status} size="sm" />
            </>
          );
        },
      },
      {
        header: t("Shipments.TableColumn.SkuUnits"),
        accessorKey: "totalSKU",
        enableSorting: true,
        size: 180,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          return (
            <>
              <h5 className="fs-14 my-1 align-items-center d-flex nowrap">{`${row.totalSKU} ${row.totalSKU <= 1 ? t("SKU") : t("SKUs")}`}</h5>
              <span className="text-muted nowrap">{`${row.totalItems} ${row.totalItems <= 1 ? t("Unit") : t("Units")}`}</span>
            </>
          );
        },
      },
      {
        header: t("Shipments.TableColumn.SoldLeftItems"),
        enableSorting: true,
        size: 200,
        accessorKey: "totalItems",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          return (
            <>
              <h5 className="fs-13 align-items-center d-flex">
                <span className="text-nowrap">
                  {`${row.soldItems} ${t("Shipments.Label.Sold")}`}
                  <i className="mdi mdi-slash-forward"></i>
                  {`${row.totalItems - row.soldItems} ${t("Shipments.Label.Left")}`}
                </span>
              </h5>
              <Progress color="secondary" value={(row.soldItems / row.totalItems) * 100} className="progress-sm" />
            </>
          );
        },
      },
      {
        header: t("Shipments.TableColumn.Speed"),
        enableSorting: false,
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          const {dayPassed, shipmentSpeed} = getShipmentSpeed(row);
          return (
            <>
              {row.status === ShipmentStatus.DRAFT ? (
                <span className="text-muted">—</span>
              ) : (
                <>
                  <div className="align-items-center d-flex">
                    <i className="text-info mdi mdi-rocket-launch me-1 fs-14"></i>
                    <span className="text-nowrap">
                      {shipmentSpeed.toFixed(2)}
                      <span className="ms-1">
                        {t("items")}
                        <i className="mdi mdi-slash-forward"></i>
                        {t("day")}
                      </span>
                    </span>
                  </div>
                  <div className="align-items-center d-flex">
                    <i className="text-secondary mdi mdi-calendar-clock me-1 fs-14"></i>
                    <span className="text-nowrap">{t("Shipments.Label.DayPassed", {value: dayPassed})}</span>
                  </div>
                </>
              )}
            </>
          );
        },
      },
      {
        header: t("Shipments.TableColumn.RevenueAndProfit"),
        enableSorting: true,
        accessorKey: "userShipmentFinance.actualSoldRevenue",
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          const sourceCurrency = ActiveMarketplaces.find((info) => info.marketplace === row.userStore.marketplace)?.currency;
          return (
            <>
              <span className="text-muted text-nowrap">
                <DisplayPrice source={sourceCurrency!} value={row.userShipmentFinance.actualSoldRevenue} decimals={2} notation="abbreviated" />
              </span>
              <h5 className="fs-14 my-1 text-nowrap">
                <DisplayPrice source={sourceCurrency!} value={row.userShipmentFinance.actualSoldProfit} decimals={2} notation="abbreviated" />
              </h5>
            </>
          );
        },
      },
      {
        header: t("Shipments.TableColumn.RoiMargin"),
        enableSorting: false,
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          return (
            <>
              <h5 className="fs-14 my-1">
                <DisplayNumber value={row.userShipmentFinance.actualSoldROI} decimals={0} suffix="%" />
              </h5>
              <span className="text-muted">
                <DisplayNumber value={row.userShipmentFinance.actualSoldMargin} decimals={0} suffix="%" />
              </span>
            </>
          );
        },
      },
      {
        header: t("Shipments.TableColumn.TotalCosts"),
        enableSorting: true,
        accessorKey: "userShipmentFinance.actualTotalCostTarget",
        size: 200,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          const sourceCurrency = ActiveMarketplaces.find((info) => info.marketplace === row.userStore.marketplace)?.currency;
          return (
            <>
              <h5 className="fs-14 my-1 text-nowrap">
                <DisplayPrice source={sourceCurrency!} value={row.userShipmentFinance.actualTotalCostTarget} decimals={2} notation="abbreviated" />
              </h5>
            </>
          );
        },
      },
      {
        header: t("Shipments.TableColumn.TotalPayouts"),
        enableSorting: true,
        accessorKey: "userShipmentFinance.estimatedSoldPayout",
        size: 200,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          const sourceCurrency = ActiveMarketplaces.find((info) => info.marketplace === row.userStore.marketplace)?.currency;
          return (
            <>
              <h5 className="fs-14 my-1 text-nowrap">
                <DisplayPrice source={sourceCurrency!} value={row.userShipmentFinance.estimatedSoldPayout} decimals={2} notation="abbreviated" />
              </h5>
            </>
          );
        },
      },
      {
        header: t("Shipments.TableColumn.CostRecovery"),
        enableSorting: true,
        accessorKey: "userShipmentFinance.costRecoveryStatusRate",
        size: 230,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          return (
            <>
              <h5 className="fs-13">
                <span className="fw-medium me-1">{t("PercentSign", {value: row.userShipmentFinance.costRecoveryStatusRate.toFixed(0)})}</span>
                {t("Dashboard.LatestShipments.Label.Recovered")}
              </h5>
              <Progress color="secondary" value={row.userShipmentFinance.costRecoveryStatusRate} className="progress-sm" />
            </>
          );
        },
      },
      {
        header: t("Shipments.TableColumn.SuccessRate"),
        size: 200,
        cell: (cellProps) => {
          const row = cellProps.row.original as UserShipment;
          return (
            <>
              <h5 className="my-1 text-nowrap align-items-center d-flex">
                <i className="text-secondary mdi mdi-trophy me-1 fs-16"></i>
                <DisplayNumber value={SuccessRateCalculator(row.userShipmentFinance.actualSoldProfit, row.userShipmentFinance.expectedSoldProfit)} decimals={2} suffix="%" />
              </h5>
            </>
          );
        },
      },
    ],
    [t, userShipmentList], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    return () => {
      resetShipmentState()(dispatch);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  document.title = t("PageTitles.Shipments");

  const breadcrumbMenus: BreadcrumbMenuItem[] = [
    {
      label: t("Shipments.Title"),
    },
  ];
  return (
    <Restricted require={PAGE_IDENTIFIER} read fallback={() => <Unauthorized />}>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Shipments.Title")} menus={breadcrumbMenus} />
          <Filters busy={loading.filter} listRef={listRef} fields={filterFields} />
          <Card>
            <CardBody>
              {loading.filter ? (
                <>
                  <Loader />
                </>
              ) : userShipmentList.items && userShipmentList.items.length > 0 ? (
                <>
                  <DataTable
                    ref={tableRef}
                    busy={loading.list}
                    columns={columns}
                    data={userShipmentList?.items || []}
                    totalDataLength={userShipmentList?.totalCount}
                    pagination={{
                      pageIndex: localQuery.page - 1,
                      pageSize: localQuery.pageSize,
                    }}
                    onPaginationChanged={(pagination) => {
                      updateQuery({...localQuery, page: pagination.pageIndex + 1, pageSize: pagination.pageSize, action: "paginating"});
                    }}
                    onSortingChanged={(sorting) => {
                      updateQuery({...localQuery, sortBy: sorting?.sortBy, sortingOrder: sorting?.sortingOrder, action: "sorting"});
                    }}
                    hovered
                  />
                </>
              ) : (
                <NoResult title={t("Shipments.NoResult.Title")} description={t("Shipments.NoResult.Description")} />
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <EditShipment />
      <SelectVisibility onChange={(id, visibility) => changeShipmentVisibility({userShipmentId: id, shareType: visibility}, "Shipments")(dispatch)} />
    </Restricted>
  );
};

export default Shipments;
