import React, {useEffect, useMemo, useRef, useState} from "react";
import DataTable, {DataTableRef} from "Components/Common/DataTable";
import Dialog, {DialogRef} from "Components/Common/Dialog";
import {ColumnDef} from "@tanstack/react-table";
import {OrderShipments} from "models/user_order";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Button, Card, CardBody, CardHeader, Col, Form, Input, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {getReAssignShipmentOrderDetails, reassignShipment} from "slices/order-details/thunk";
import {updateQuantity} from "slices/order-details/reducer";
import {ReassignParametersCommand} from "api/command";
import {createTypedModal} from "helpers/modal_helpers";
import errorImage from "assets/images/svg/product.svg";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import {ShipmentStatusBadge} from "Components/Common/Badges/ShipmentStatusBadge";

interface ModalData {
  amazonOrderId: string;
}
export const ReAssignShipmentModal = createTypedModal<ModalData>("reassign_shipment");
const ReassignShipment = () => {
  const {t} = useTranslation();
  const {open, data} = ReAssignShipmentModal.useModal();
  const dispatch: any = useDispatch();
  const tableRef = useRef<DataTableRef>(null);
  const reassignChangesDialogRef = useRef<DialogRef>(null);
  const [selectedProductAsin, setSelectedProductAsin] = useState<string>("");
  const [shipmentGroups, setShipmentGroups] = useState<OrderShipments[][]>([]);

  const orderDetailPageData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.OrderDetails.loading,
      editableShipments: state.OrderDetails.editableShipments,
      order: state.OrderDetails.order,
    }),
  );

  const {loading, editableShipments, order} = useSelector(orderDetailPageData);

  useEffect(() => {
    if (open) {
      getReAssignShipmentOrderDetails(data!.amazonOrderId)(dispatch);
    }
  }, [open]); //eslint-disable-line

  const groupShipments = (shipments: OrderShipments[]): OrderShipments[][] => {
    const groupedShipments: {[key: string]: OrderShipments[]} = {};

    // Grouping shipments
    shipments.forEach((shipment) => {
      if (!groupedShipments[shipment.asin]) {
        groupedShipments[shipment.asin] = [];
      }
      groupedShipments[shipment.asin].push(shipment);
    });

    const result: OrderShipments[][] = Object.values(groupedShipments);
    return result;
  };

  useEffect(() => {
    const groupedShipments = groupShipments(editableShipments);
    setShipmentGroups(groupedShipments);
  }, [editableShipments]);

  const saveChanges = async () => {
    const itemsToUpdate: ReassignParametersCommand[] = [];
    const targetOrderItem = order.userOrderItems?.find((item) => item.asin === selectedProductAsin)!;
    for (let i = 0; i < editableShipments.length; i++) {
      if (editableShipments[i].asin === selectedProductAsin) {
        itemsToUpdate.push({
          userOrderItemId: targetOrderItem?.userOrderItemId,
          userShipmentItemId: editableShipments[i].userShipmentItemId,
          quantity: editableShipments[i].quantity,
        });
      }
    }

    await dispatch(reassignShipment(itemsToUpdate));
    toggle();
  };

  const columns = useMemo<ColumnDef<OrderShipments, any>[]>(
    () => [
      {
        header: t("OrderDetails.Dialog.ReassignShipment.TableColumn.ShipmentName"),
        size: 100,
        accessorKey: "shipment",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as OrderShipments;
          return <>{row.shipment}</>;
        },
      },
      {
        header: t("OrderDetails.Dialog.ReassignShipment.TableColumn.Status"),
        size: 100,
        accessorKey: "status",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as OrderShipments;
          return (
            <>
              <ShipmentStatusBadge status={row.status} size="sm" />
            </>
          );
        },
      },
      {
        header: t("OrderDetails.Dialog.ReassignShipment.TableColumn.Quantity"),
        size: 100,
        accessorKey: "quantity",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as OrderShipments;
          const [isValid, setIsValid] = useState<boolean>(); // eslint-disable-line
          const [firstAvailableCountValue] = useState(row.availableCount); // eslint-disable-line
          const [firstQuantityValue] = useState(row.quantity); // eslint-disable-line

          // eslint-disable-next-line
          const data = createSelector(
            (state: RootState) => state,
            (state) => ({
              order: state.OrderDetails.order,
            }),
          );
          const {order} = useSelector(data); // eslint-disable-line

          // eslint-disable-next-line
          useEffect(() => {
            const quantityTotalValue = cellProps.table.getFilteredRowModel().rows.reduce((total, row) => total + (row.getValue("quantity") as number), 0);
            const countValue = order.userOrderItems?.find((item) => item.asin === row.asin)?.quantityOrdered;
            var button = document.getElementById(`ReassignShipment-${row.asin}`) as HTMLInputElement;

            if (row.quantity < 0 || row.availableCount < 0 || (countValue !== undefined && countValue < quantityTotalValue)) {
              button.disabled = true;
              setIsValid(false);
            } else {
              button.disabled = false;
            }
            setIsValid(row.quantity >= 0 && row.availableCount >= 0);
          }, [row]); // eslint-disable-line

          // eslint-disable-next-line
          useEffect(() => {
            var button = document.getElementById(`ReassignShipment-${row.asin}`) as HTMLInputElement;
            if (!isValid) {
              button.disabled = true;
            } else {
              button.disabled = false;
            }
          }, [isValid]); // eslint-disable-line

          return (
            <>
              <Row>
                <Col style={{minWidth: "100px"}}>
                  <Input invalid={!isValid} type="number" min={0} step={1} onChange={(e) => dispatch(updateQuantity({orderShipment: row, quantity: e.target.valueAsNumber}))} value={row.quantity} />
                </Col>
                <Col className="ps-0 d-flex align-items-center">
                  {!isValid && (
                    <>
                      <i className="ri-alert-fill cursor-pointer fs-5 text-danger " id={`OrderShipmentItem-${row.userShipmentItemId}`}></i>
                      <DefaultUncontrolledTooltip target={`OrderShipmentItem-${row.userShipmentItemId}`}>
                        {t("OrderDetails.Dialog.ReassignShipment.Tooltip.QuantityOutOfRange", {min: 0, max: firstAvailableCountValue + firstQuantityValue})}
                      </DefaultUncontrolledTooltip>
                    </>
                  )}
                </Col>
              </Row>
            </>
          );
        },
        footer: ({table}) => {
          const totalValue = table.getFilteredRowModel().rows.length > 0 ? table.getFilteredRowModel().rows.reduce((total, row) => total + (row.getValue("quantity") as number), 0) : 0;

          // eslint-disable-next-line
          const data = createSelector(
            (state: RootState) => state,
            (state) => ({
              order: state.OrderDetails.order,
            }),
          );
          const {order} = useSelector(data); // eslint-disable-line
          return (
            <Row className="d-flex justify-content-center align-items-center">
              <Col>
                <p className="nowrap">{`${t("Total")} : ${totalValue}`} </p>
              </Col>
              <Col>
                {order.quantityOrdered !== undefined && totalValue > order.quantityOrdered && (
                  <>
                    <i className="ri-alert-fill cursor-pointer fs-5 text-danger " id={`ReassignShipmentTooltip`}></i>
                    <DefaultUncontrolledTooltip target={`ReassignShipmentTooltip`}>
                      {t("OrderDetails.Dialog.ReassignShipment.Tooltip.TotalValueOutOfRange", {value: order.quantityOrdered})}
                    </DefaultUncontrolledTooltip>
                  </>
                )}
              </Col>
            </Row>
          );
        },
      },
      {
        header: t("OrderDetails.Dialog.ReassignShipment.TableColumn.AvailableCount"),
        size: 100,
        accessorKey: "availableCount",
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as OrderShipments;
          return <>{row.availableCount}</>;
        },
      },
    ],
    [t, dispatch], // eslint-disable-line
  );

  const toggle = () => {
    ReAssignShipmentModal.close();
  };

  return (
    <>
      <Modal backdrop="static" isOpen={open} toggle={toggle} fade={true} centered={true} size="lg" scrollable={true}>
        <ModalHeader className="p-3" toggle={toggle}>
          {t("OrderDetails.Dialog.ReassignShipment.Title")}
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              //validation.handleSubmit();
              return false;
            }}
          >
            {shipmentGroups.map((group, groupIndex) => {
              // Ensure group is not empty before trying to access its elements
              if (!group || group.length === 0) {
                return null;
              }
              
              // Get the ASIN from the first item in the group
              const asin = group[0].asin;
              
              const targetOrderItem = order?.userOrderItems?.find((item) => item.asin === asin);
              const imagePath = targetOrderItem?.userInventory != null ? `https://m.media-amazon.com/images/I/${targetOrderItem.userInventory.imageBig}` : "";
              
              return (
                <PlaceholderGlow key={groupIndex} busy={loading.reAssignShipmentList}>
                  <Card>
                    <CardHeader className="bg-light">{targetOrderItem && targetOrderItem.sellerSKU}</CardHeader>
                    <CardBody>
                      <Row>
                        <Col sm={3}>
                          <Row>
                            <div className="product-img-slider product-img-container h-100 ">
                              <img
                                className="rounded w-100"
                                src={imagePath}
                                onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                  e.currentTarget.onerror = null;
                                  e.currentTarget.src = errorImage;
                                }}
                                alt=""
                              />
                            </div>
                          </Row>
                          <Row className="d-flex justify-content-center mt-2">
                            <Col xs="auto">
                              <div className="d-flex flex-row align-items-center">
                                <span className="fw-bold me-1">{targetOrderItem?.asin}</span>
                              </div>
                              <div className="d-flex flex-row align-items-center">
                                <span className="fw-bold">{`${t("Count")} : ${targetOrderItem?.quantityOrdered}`}</span>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col sm={9}>
                          <div className="table-responsive overflow-visible ">
                            <DataTable ref={tableRef} busy={loading.reAssignShipmentList} columns={columns} data={group} totalDataLength={group.length} hovered />
                          </div>
                        </Col>
                        <Col sm={12} className="d-flex justify-content-end mt-4">
                          <Button
                            id={`ReassignShipment-${asin}`}
                            type="submit"
                            color="secondary"
                            onClick={() => {
                              setSelectedProductAsin(asin);
                              reassignChangesDialogRef.current?.show();
                            }}
                          >
                            {t("OrderDetails.Dialog.ReassignShipment.Button.Save")}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </PlaceholderGlow>
              );
            })}
          </Form>
        </ModalBody>
      </Modal>
      <Dialog
        ref={reassignChangesDialogRef}
        color="info"
        buttons={["yes", "no"]}
        busy={loading.reassignShipment}
        iconClass="ri-alert-fill"
        message={t("OrderDetails.Dialog.ReassignShipment.Dialog.Description")}
        title={t("OrderDetails.Dialog.ReassignShipment.Dialog.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            await saveChanges();
          }
          reassignChangesDialogRef.current?.hide();
        }}
      />
    </>
  );
};

export default ReassignShipment;