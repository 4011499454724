import {createSelector} from "reselect";
import {RootState} from "slices";

export const SearchesSlice = createSelector(
  (state: RootState) => state,
  (state) => ({
    searchesList: state.Searches.searchesList,
    loading: state.Searches.loading,
    error: state.Searches.error,
  }),
);
