import {Col, Container, Row} from "reactstrap";
import {useTranslation} from "react-i18next";
import React from "react";
import Widget from "./Widgets/Totals";
import Section from "./Widgets/Section";
import SalesOverview from "./_SalesOverview";
import BestSellingProductsWidget from "./_BestSellingProducts";
import RestockInventory from "./_RestockInventory";
import SalesSummary from "./_SalesSummary";
import LatestOrders from "./_LatestOrders";
import LatestShipments from "./_LatestShipments";
import RemainingInventory from "./_RemainingInventory";
import OrdersSummary from "./_OrdersSummary";
import BestSellingCategories from "./_BestSellingCategories";
import SellThis from "Components/Common/SellThis";
import SalesHistoryGraphic from "./_SalesHistory";
import DisconnectedStoreAlert from "pages/Alerts/DisconnectedStoreAlert";
import ReAnalyze from "Components/Common/Modals/ReAnalyze";

const Dashboard = () => {
  const {t} = useTranslation();
  document.title = t("PageTitles.Dashboard");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Section />
                <DisconnectedStoreAlert />
                <Row>
                  <Widget />
                </Row>
                <Row>
                  <SalesHistoryGraphic />
                  <SalesSummary />
                </Row>
                <Row>
                  <SalesOverview />
                  <LatestOrders />
                </Row>
                <Row>
                  <BestSellingProductsWidget />
                  <RestockInventory />
                </Row>
                <Row>
                  <LatestShipments />
                </Row>
                <Row>
                  <RemainingInventory />
                  <BestSellingCategories />
                  <OrdersSummary />
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <SellThis />
      <ReAnalyze />
    </React.Fragment>
  );
};

export default Dashboard;
