import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Modal, ModalHeader, ModalBody} from "reactstrap";
import {createTypedModal} from "helpers/modal_helpers";
import {memo} from "react";

interface ModalData {
  asin: string;
  domainTarget: string;
  restrictionReasonCodes?: string;
}

export const RestrictionReasonModal = createTypedModal<ModalData>("restriction_reason");
const RestrictionReasons = () => {
  const {t} = useTranslation();
  const {open, data} = RestrictionReasonModal.useModal();

  const toggle = () => {
    RestrictionReasonModal.close();
  };

  if (!open) return null;
  return (
    <Modal isOpen={open} toggle={toggle} fade={true} centered={true}>
      <ModalHeader className="p-3" toggle={toggle}>
        {t("SearchResults.Dialog.RestrictionReasonCodes.Title")}
      </ModalHeader>
      <ModalBody>
        {data?.restrictionReasonCodes?.split(",") && data.restrictionReasonCodes?.split(",").length > 0 ? (
          data?.restrictionReasonCodes?.split(",").map((reason: string, index: number) => (
            <div key={index} className="d-flex">
              <div className="flex-shrink-0 me-2">
                <i className={`bx ${reason.endsWith("Recheck") || reason.endsWith("Warning") ? "bxs-error text-warning" : "bxs-x-circle text-danger"} fs-20`}></i>
              </div>
              <div className="flex-grow-1">
                <h5>{t(`RestrictionReasonCodes.${reason}.Name`)}</h5>
                <p className="text-muted">{t(`RestrictionReasonCodes.${reason}.Description`)}</p>
                {reason === "APPROVAL_REQUIRED" && (
                  <div>
                    <Link
                      to={`https://sellercentral.amazon.${data.domainTarget}/hz/approvalrequest/restrictions/approve?asin=${data.asin}`}
                      target="_blank"
                      className="link-secondary link-offset-2 text-decoration-underline"
                    >
                      {t("SearchResults.Dialog.RestrictionReasonCodes.RequestApprovalViaSellerCentral")}: {data.asin}
                      <i className="ri-arrow-right-up-line"></i>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          ))
        ) : (
          <div>{t("No reason")}</div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default memo(RestrictionReasons);
