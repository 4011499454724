import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {Modal, ModalHeader, ModalBody, Col, Row, Button, ModalFooter, Form, Label, Spinner} from "reactstrap";
import {UserShipment} from "models/user_shipment";
import {changeUserShipmentStatus} from "slices/thunks";
import {useDispatch, useSelector} from "react-redux";
import {getToday} from "helpers/utilities";
import {ChangeUserShipmentStatusCommand} from "api/command";
import {ShipmentStatus} from "models/enums/shipment_status";
import {createTypedModal} from "helpers/modal_helpers";
import {ShipmentSlice} from "slices/shipment/selector";
import Checkbox from "Components/Common/Checkbox";
import ValidationWrapper from "Components/Common/ValidationWrapper";
import ValidatedDate from "Components/Common/ValidatedDate";
import ValidatedInput from "Components/Common/ValidatedInput";
import * as Yup from "yup";

interface ModalData {
  userShipment: UserShipment;
}
export const MarkAsShippedModal = createTypedModal<ModalData>("mark_as_shipped");

const MarkAsShipped = () => {
  const {t} = useTranslation();
  const {open, data} = MarkAsShippedModal.useModal();
  const {loading} = useSelector(ShipmentSlice);

  const dispatch = useDispatch();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      amazonShipmentId: data?.userShipment.amazonShipmentId,
      shipDate: data?.userShipment.shipDate,
      updateInventory: false,
    },
    validationSchema: Yup.object().shape({
      amazonShipmentId: Yup.string().max(50, t("Shipments.AmazonShipmentIdMaxLength")),
      shipDate: Yup.date()
        .required(t("Shipments.ShipDateRequired"))
        .min(new Date("2023-01-01"), t("Start date must be later than January 01, 2023"))
        .typeError(t("Invalid date type")),
      updateInventory: Yup.boolean(),
    }),
    onSubmit: (values: any) => {
      let payload: ChangeUserShipmentStatusCommand = {
        userShipmentId: data!.userShipment.userShipmentId,
        status: ShipmentStatus.SHIPPED,
        amazonShipmentId: values.amazonShipmentId,
        shipDate: values.shipDate,
        updateInventoryCosts: values.updateInventory,
      };

      const setPromise = changeUserShipmentStatus(payload)(dispatch);
      setPromise.then((isSuccess) => {
        if (isSuccess) {
          validation.resetForm();
          toggle();
        }
      });
    },
  });

  const toggle = () => {
    MarkAsShippedModal.close();
    validation.resetForm();
  };

  return (
    <>
      <Modal id="showChangeShipmentStatusModal" isOpen={open} toggle={toggle} fade={true} centered={true} size="md">
        <ModalHeader className="p-3" toggle={toggle}>
          {t("Shipments.ShipmentDetails.Dialog.MarkAsShipped.Title")}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <h5 className="mb-0">{t("Shipments.ShipmentDetails.Dialog.MarkAsShipped.Description")}</h5>
            </Col>
          </Row>
          <Form
            className="mt-3"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row className="g-3">
              <Col xs={12}>
                <Label>{t("Shipments.AmazonShipmentId")}</Label>
                <ValidatedInput validation={validation} field={"amazonShipmentId"} maxLength={50} disableValidationUI />
              </Col>
              <Col xs={12}>
                <Label htmlFor="shipDate">{t("Shipments.ShipDate")}</Label>
                <ValidatedDate
                  className="w-100"
                  field="shipDate"
                  validation={validation}
                  value={validation.values.shipDate}
                  max={getToday()
                    .add(2, "month")
                    .toDate()}
                ></ValidatedDate>
              </Col>
              <Col xs={12}>
                <ValidationWrapper validation={validation} field="updateInventory">
                  <Checkbox>
                    <span>{t("Shipments.UpdateInventory")}</span>
                  </Checkbox>
                </ValidationWrapper>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Row className="g-3">
            <Col xs={12} className="px-0 mt-n1">
              <div className="text-muted fs-13">
                <i className="mdi mdi-information me-1"></i>
                {t("Shipments.ShipmentDetails.Dialog.MarkAsShipped.Label.ShipDateInfo")}
              </div>
            </Col>
            <Col xs={12} className="col-auto pe-0">
              <div className="hstack gap-2 justify-content-end">
                <Button
                  type="button"
                  color="light"
                  disabled={loading.changeStatus}
                  onClick={() => {
                    toggle();
                  }}
                >
                  {t("Close")}
                </Button>
                <Button
                  type="submit"
                  color="secondary"
                  disabled={loading.changeStatus}
                  onClick={() => {
                    validation.setFieldTouched("shipDate");
                    validation.setFieldTouched("amazonShipmentId");

                    if (validation.isValid) {
                      validation.handleSubmit();
                    }
                  }}
                >
                  {loading.changeStatus && <Spinner size="sm" className="me-2 align-middle"></Spinner>}
                  {t("Shipments.ShipmentDetails.Dialog.MarkAsShipped.Title")}
                </Button>
              </div>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default MarkAsShipped;
