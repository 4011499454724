import React, {useRef} from "react";
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {changeUserShipmentStatus} from "slices/thunks";
import {AnalyzeType, ReAnalyzeModal} from "Components/Common/Modals/ReAnalyze";
import {AmazonBusinessModel} from "models/enums/user_search_type";
import {AmazonMarketplace} from "helpers/marketplace_helper";
import {ShipmentSlice} from "slices/shipment/selector";
import {ShipmentStatus} from "models/enums/shipment_status";
import {ChangeUserShipmentStatusCommand} from "api/command";
import {getStatusColor, renderStatusDropdown} from "../_helper";
import {MarkAsShippedModal} from "../Draft/Modals/MarkAsShipped";
import EditShipment, {EditShipmentModal} from "../../Shipments/Modal/EditShipment";
import Moment from "react-moment";
import i18n from "../../../../i18n";
import img from "assets/images/amazon-package.png";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import "moment/locale/tr";

export type ListRef = {
  reload: VoidFunction;
};

interface ShipmentInfoProps {
  sourceMarketplace?: AmazonMarketplace;
  destinationMarketplace?: AmazonMarketplace;
}
const ShipmentInfo = ({sourceMarketplace, destinationMarketplace}: ShipmentInfoProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const listRef = useRef<ListRef>(null);
  const {loading, currentUserShipment} = useSelector(ShipmentSlice);

  const handleStatusChange = (newStatus: ShipmentStatus) => {
    if (newStatus === ShipmentStatus.SHIPPED) {
      MarkAsShippedModal.open({userShipment: currentUserShipment});
    } else {
      const command: ChangeUserShipmentStatusCommand = {
        userShipmentId: currentUserShipment.userShipmentId,
        status: newStatus,
      };

      changeUserShipmentStatus(command)(dispatch).then((success) => {
        if (success) {
          // Reload the list if necessary
          listRef.current?.reload();
        }
      });
    }
  };

  return (
    <React.Fragment>
      <Card>
        <div className={`bg-${getStatusColor(currentUserShipment.status)}-subtle`}>
          <CardBody>
            <Row>
              <Col xs="auto">
                <div className="avatar-md mb-md-0 mb-4">
                  <div className="avatar-title bg-white rounded-circle">
                    <img src={img} alt="" className="avatar-sm" />
                  </div>
                </div>
              </Col>
              <Col>
                <Row>
                  <Col xs="auto">
                    <div className="hstack gap-3 flex-wrap">
                      <h4 className="mb-0 d-flex align-items-center">
                        <span>{"#" + currentUserShipment.nameId + " - " + currentUserShipment.name}</span>
                        <Link to="" id={`EditButton1`} className="btn btn-ghost-secondary px-1 py-0 fs-16 ms-1" onClick={() => EditShipmentModal.open({shipment: currentUserShipment})}>
                          <i className="ri-pencil-fill"></i>
                        </Link>
                        <DefaultUncontrolledTooltip target={`EditButton1`}>{t("Edit")}</DefaultUncontrolledTooltip>
                      </h4>
                      <div className="vr"></div>
                      <div>
                        <div className="hstack gap-1">
                          <i className="bx bxs-location-plus text-success fs-20"></i>
                          <span>
                            <span className="text-nowrap">{sourceMarketplace?.countryName} </span>
                            <img src={sourceMarketplace?.flag} alt={`${sourceMarketplace?.countryName} Flag`} className="rounded-circle ms-1" height="20" />
                          </span>
                          <span className="mx-1">
                            <i className="ri-arrow-right-line fs-20 align-bottom"></i>
                          </span>
                          <span className="text-nowrap">
                            <img src={destinationMarketplace?.flag} alt={`${t(destinationMarketplace?.countryName ?? "")} Flag`} className="rounded-circle me-1" height="20" />{" "}
                            {t(destinationMarketplace?.countryName ?? "")}
                            {" - "}
                            <span className="text-nowrap">{currentUserShipment.userStore.name}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col xs="auto">
                    <div className="hstack gap-3 flex-wrap">
                      <div className="d-flex align-items-center">
                        <i className=" ri-calendar-2-line align-bottom me-1"></i>
                        <span>{t("Shipped Date")}:</span>
                        <span className="fw-medium ms-1">
                          <Moment locale={i18n.language} format="DD/MM/Y">
                            {currentUserShipment.shipDate}
                          </Moment>

                          <span className="text-nowrap">
                            {" ("}
                            <Moment className="text-primary" locale={i18n.language} fromNow>
                              {currentUserShipment.shipDate}
                            </Moment>
                            {")"}
                          </span>
                        </span>
                      </div>
                      <div className="vr"></div>
                      <div className="hstack gap-4 flex-wrap">
                        <div className="d-flex align-items-center">
                          <i className="ri-flight-takeoff-line me-1"></i>
                          <span className="me-1 text-nowrap">{t("Shipments.AmazonShipmentId")}:</span>
                          <span className="fw-medium">
                            {currentUserShipment.amazonShipmentId !== "" && currentUserShipment.amazonShipmentId !== null ? (
                              currentUserShipment.amazonShipmentId
                            ) : (
                              <i className="las la-ellipsis-h align-bottom"></i>
                            )}
                            {currentUserShipment.status === ShipmentStatus.SHIPPED ? (
                              <>
                                <Link
                                  to=""
                                  id={`EditButton2`}
                                  className="btn btn-ghost-secondary px-1 py-0 fs-14 ms-1"
                                  onClick={() => {
                                    EditShipmentModal.open({shipment: currentUserShipment});
                                  }}
                                >
                                  <i className="ri-pencil-fill"></i>
                                </Link>
                                <DefaultUncontrolledTooltip target={`EditButton2`}>{t("Edit")}</DefaultUncontrolledTooltip>
                              </>
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs="auto" className="mt-3 mt-md-0">
                <div className="hstack gap-3 flex-wrap">
                  <div>
                    <Button
                      size="sm"
                      id={`AnalyzeShipmentItems`}
                      className="btn btn-secondary fw-semibold"
                      onClick={() => {
                        ReAnalyzeModal.open({
                          searchName: currentUserShipment.name,
                          store: currentUserShipment.userStoreId,
                          shipmentId: currentUserShipment.userShipmentId,
                          businessModel: AmazonBusinessModel.CROSS_BORDER_ARBITRAGE,
                          type: AnalyzeType.SHIPMENT,
                        });
                      }}
                    >
                      <i className="mdi mdi-chart-timeline-variant-shimmer align-middle me-1"></i>
                      {t("Shipments.ShipmentDetails.Button.ReanalyzeTheProducts")}
                    </Button>
                    <DefaultUncontrolledTooltip target={`AnalyzeShipmentItems`}>{t("Shipments.ShipmentDetails.Info.ReAnalyze")}</DefaultUncontrolledTooltip>
                  </div>

                  <div id="shipment-status">{renderStatusDropdown(currentUserShipment, loading.changeStatus, (transition) => handleStatusChange(transition))}</div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </div>
      </Card>
      <EditShipment />
    </React.Fragment>
  );
};

export default ShipmentInfo;
