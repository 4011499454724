import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import {AmazonMarketplace} from "helpers/marketplace_helper";
import i18n from "i18n";
import {store} from "index";
import {SellerList} from "models/product_details";
import {UserStore} from "models/user_stores";
import {Link} from "react-router-dom";
import {Badge} from "reactstrap";

const getRatingPowerToolTip = (seller: SellerList) => {
  if (seller.ratingPercentage != null) {
    const userStores = store.getState().Common.userStores;
    const customerStore = userStores.find((userStore: UserStore) => userStore.sellerId === seller.sellerId);
    if (customerStore) return null;
    if (seller.isAmazon === true) {
      return (
        <div>
          <i id={`PopoverSellerStrength-${seller.sellerId}`} className="text-danger align-middle ri-flashlight-fill ms-1 fs-14"></i>
          <DefaultUncontrolledTooltip target={`PopoverSellerStrength-${seller.sellerId}`}>{i18n.t("ProductDetails.Tooltip.StrongSeller")}</DefaultUncontrolledTooltip>
        </div>
      );
    }
    if (seller.ratingPercentage >= 0 && seller.ratingPercentage < 33) {
      return (
        <div>
          <i id={`PopoverSellerStrength-${seller.sellerId}`} className="text-success align-middle ri-flashlight-fill ms-1 fs-14"></i>
          <DefaultUncontrolledTooltip target={`PopoverSellerStrength-${seller.sellerId}`}>{i18n.t("ProductDetails.Tooltip.WeakSeller")}</DefaultUncontrolledTooltip>
        </div>
      );
    } else if (seller.ratingPercentage >= 33 && seller.ratingPercentage < 66) {
      return (
        <div>
          <i id={`PopoverSellerStrength-${seller.sellerId}`} className="text-warning align-middle ri-flashlight-fill ms-1 fs-14"></i>
          <DefaultUncontrolledTooltip target={`PopoverSellerStrength-${seller.sellerId}`}>{i18n.t("ProductDetails.Tooltip.ModerateSeller")}</DefaultUncontrolledTooltip>
        </div>
      );
    } else {
      return (
        <div>
          <i id={`PopoverSellerStrength-${seller.sellerId}`} className="text-danger align-middle ri-flashlight-fill ms-1 fs-14"></i>
          <DefaultUncontrolledTooltip target={`PopoverSellerStrength-${seller.sellerId}`}>{i18n.t("ProductDetails.Tooltip.StrongSeller")}</DefaultUncontrolledTooltip>
        </div>
      );
    }
  } else {
    return;
  }
};

const getSellerName = (seller: SellerList, color: "dark" | "secondary", sellerMarketplace?: AmazonMarketplace, ) => {
  const userStores = store.getState().Common.userStores;
  if (seller.isAmazon) {
    return "Amazon " + sellerMarketplace?.marketplace;
  } else {
    const customerStore = userStores.find((userStore: UserStore) => userStore.sellerId === seller.sellerId);
    let customerStoreName = "";
    if (customerStore) {
      customerStoreName = customerStore.name;
    } else {
      customerStoreName = seller.sellerId ?? "";
    }
    return (
      <>
        <Link to={`https://www.amazon.${sellerMarketplace?.domain}/sp?seller=${seller.sellerId}`} target="_blank" className={`${color==="secondary" ? "link-secondary" : "link-dark"} link-offset-2 text-decoration-underline`}>
          {customerStoreName}
          <i className="ri-arrow-right-up-line"></i>
        </Link>
        {customerStore && <Badge className="ms-1 rounded-pill">{i18n.t("You")}</Badge>}
      </>
    );
  }
};
export {getRatingPowerToolTip, getSellerName};
