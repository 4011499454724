import React, {useEffect, useRef, useState} from "react";
import Dialog, {DialogRef} from "Components/Common/Dialog";
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {changeUserShipmentStatus, implementUserShipmentSettings} from "slices/thunks";
import {AnalyzeType, ReAnalyzeModal} from "Components/Common/Modals/ReAnalyze";
import {AmazonBusinessModel} from "models/enums/user_search_type";
import {ActiveMarketplaces, AmazonMarketplace} from "helpers/marketplace_helper";
import {ShipmentStatus} from "models/enums/shipment_status";
import {ChangeUserShipmentStatusCommand} from "api/command";
import {ShipmentSlice} from "slices/shipment/selector";
import {getStatusColor, renderStatusDropdown} from "../_helper";
import {MarkAsShippedModal} from "./Modals/MarkAsShipped";
import EditShipment, {EditShipmentModal} from "../../Shipments/Modal/EditShipment";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import Moment from "react-moment";
import i18n from "../../../../i18n";
import img from "../../../../assets/images/amazon-package.png";
import "moment/locale/tr";

export type ListRef = {
  reload: VoidFunction;
};
const ShipmentInfo = () => {
  const {t} = useTranslation();
  const [destinationMarketplace, setDestinationMarketplace] = useState<AmazonMarketplace>();
  const dispatch = useDispatch();
  const implementSettingsDialogRef = useRef<DialogRef>(null);
  const listRef = useRef<ListRef>(null);

  const {loading, currentUserShipment} = useSelector(ShipmentSlice);
  useEffect(() => {
    if (currentUserShipment) {
      let destination = ActiveMarketplaces.find((amazonMarketplaceInfo) => amazonMarketplaceInfo.marketplace === currentUserShipment.userStore.marketplace);
      setDestinationMarketplace(destination);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserShipment]);

  const handleUpdateSettings = () => {
    implementUserShipmentSettings({userShipmentId: currentUserShipment.userShipmentId})(dispatch);
  };

  const handleStatusChange = (newStatus: ShipmentStatus) => {
    if (newStatus === ShipmentStatus.SHIPPED) {
      MarkAsShippedModal.open({userShipment: currentUserShipment});
    } else {
      const command: ChangeUserShipmentStatusCommand = {
        userShipmentId: currentUserShipment.userShipmentId,
        status: newStatus,
      };

      changeUserShipmentStatus(command)(dispatch).then((success) => {
        if (success) {
          // Reload the list if necessary
          listRef.current?.reload();
        }
      });
    }
  };

  return (
    <React.Fragment>
      <Card>
        <div className={`bg-${getStatusColor(currentUserShipment.status)}-subtle`}>
          <CardBody>
            <Row>
              <Col xs="auto">
                <div className="avatar-md mb-md-0 mb-4">
                  <div className="avatar-title bg-white rounded-circle">
                    <img src={img} alt="" className="avatar-sm" />
                  </div>
                </div>
              </Col>
              <Col>
                <Row>
                  <Col xs="auto">
                    <div className="hstack gap-3 flex-wrap">
                      <h4 className="mb-0 d-flex align-items-center">
                        <span>{"#" + currentUserShipment.nameId + " - " + currentUserShipment.name}</span>
                        <Link to="" id={`EditButton1`} className="btn btn-ghost-secondary px-1 py-0 fs-16 ms-1" onClick={() => EditShipmentModal.open({shipment: currentUserShipment})}>
                          <i className="ri-pencil-fill"></i>
                        </Link>
                        <DefaultUncontrolledTooltip target={`EditButton1`}>{t("Edit")}</DefaultUncontrolledTooltip>
                      </h4>
                      <div className="vr"></div>
                      <div>
                        <div className="hstack gap-1">
                          <i className="bx bxs-location-plus text-success fs-20"></i>

                          <span className="mx-1">
                            <i className="ri-arrow-right-line fs-20 align-bottom"></i>
                          </span>
                          <span className="text-nowrap">
                            <img src={destinationMarketplace?.flag} alt={`${t(destinationMarketplace?.countryName ?? "")} Flag`} className="rounded-circle me-1" height="20" />{" "}
                            {t(destinationMarketplace?.countryName ?? "")}
                            {" - "}
                            <span className="text-nowrap">{currentUserShipment.userStore.name}</span>
                          </span>
                        </div>
                      </div>
                      <div className="vr"></div>
                      <div className="">
                        {currentUserShipment.status === ShipmentStatus.DRAFT && currentUserShipment?.userShipmentSetting?.updateDate?.toString().startsWith("0001") && (
                          <span id={`UpdateSettings`} className="d-flex align-items-center">
                            <Button
                              color="link"
                              className="btn-ghost-secondary px-1 py-0 "
                              onClick={() => {
                                implementSettingsDialogRef.current?.show();
                              }}
                            >
                              <i className="bx bxs-info-circle text-danger fs-16 me-1 align-middle"></i>

                              {t("Shipments.ShipmentDetails.UpdateShipmentSettingsInfoText")}
                            </Button>
                            <DefaultUncontrolledTooltip placement="top" autohide={false} target={`UpdateSettings`}>
                              {t("Shipments.ShipmentDetails.UpdateShipmentSettingsInfoText")}
                            </DefaultUncontrolledTooltip>
                          </span>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col xs="auto">
                    <div className="hstack gap-3 flex-wrap">
                      <div className="d-flex align-items-center">
                        <i className=" ri-calendar-2-line align-bottom me-1"></i>
                        {(currentUserShipment.status === ShipmentStatus.SHIPPED
                          ? t("Shipped Date")
                          : currentUserShipment.status === ShipmentStatus.COMPLETED
                          ? t("Completed Date")
                          : t("Create Date")) + ": "}
                        <span className="fw-medium ms-1">
                          <Moment locale={i18n.language} format="DD/MM/Y">
                            {currentUserShipment.createDate}
                          </Moment>
                          {" ("}
                          <Moment className="text-primary" locale={i18n.language} fromNow>
                            {currentUserShipment.createDate}
                          </Moment>
                          {")"}
                        </span>
                      </div>

                      {currentUserShipment.amazonShipmentId !== "" && currentUserShipment.amazonShipmentId !== null && (
                        <>
                          <div className="vr"></div>
                          <div>
                            <div className="d-flex align-items-center">
                              <i className="ri-flight-takeoff-line me-1"></i>
                              <span className="me-1 text-nowrap">{t("Shipments.AmazonShipmentId")}:</span>
                              <span className="fw-medium">
                                {currentUserShipment.amazonShipmentId !== "" && currentUserShipment.amazonShipmentId !== null ? (
                                  currentUserShipment.amazonShipmentId
                                ) : (
                                  <i className="las la-ellipsis-h align-bottom"></i>
                                )}
                              </span>
                            </div>
                          </div>
                        </>
                      )}

                      <div className="vr"></div>
                      <div className="badge rounded-pill bg-success fs-12">
                        {currentUserShipment.totalSKU.toLocaleString()} {t("SKUs")} <i className="mdi mdi-slash-forward"></i> {currentUserShipment.totalItems.toLocaleString()} {t("Units")}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs="auto" className="mt-3 mt-md-0">
                <div className="hstack gap-3 flex-wrap">
                  <div>
                    <Button
                      size="sm"
                      color="secondary"
                      id={`AnalyzeShipmentItems`}
                      className="fw-semibold"
                      onClick={() => {
                        ReAnalyzeModal.open({
                          searchName: encodeURIComponent(currentUserShipment.name),
                          store: currentUserShipment.userStoreId,
                          shipmentId: currentUserShipment.userShipmentId,
                          businessModel: AmazonBusinessModel.CROSS_BORDER_ARBITRAGE,
                          type: AnalyzeType.SHIPMENT,
                        });
                      }}
                    >
                      <i className="mdi mdi-chart-timeline-variant-shimmer align-middle me-1"></i>
                      {t("Shipments.ShipmentDetails.Button.ReanalyzeTheProducts")}
                    </Button>
                    <DefaultUncontrolledTooltip target={`AnalyzeShipmentItems`}>{t("Shipments.ShipmentDetails.Info.ReAnalyze")}</DefaultUncontrolledTooltip>
                  </div>
                  <div id="shipment-status">{renderStatusDropdown(currentUserShipment, loading.changeStatus, (transition) => handleStatusChange(transition))}</div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </div>
      </Card>
      <Dialog
        ref={implementSettingsDialogRef}
        color="info"
        buttons={["yes", "no"]}
        buttonConfig={(buttonConfigs) => {
          return {
            ...buttonConfigs,
            yes: {
              ...buttonConfigs.yes,
              label: t("Yes"),
            },
            no: {
              ...buttonConfigs.no,
              label: t("Close"),
            },
          };
        }}
        busy={loading.implementSettings}
        iconClass="mdi mdi-download"
        message={t("Shipments.ShipmentDetails.Dialog.ImplementSettings.Description")}
        title={t("Shipments.ShipmentDetails.Dialog.ImplementSettings.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            handleUpdateSettings();
            listRef.current?.reload();
          }
          implementSettingsDialogRef.current?.hide();
        }}
      />
      <EditShipment />
    </React.Fragment>
  );
};

export default ShipmentInfo;
