import {apiError, deleteOrRestoreItem, loading, reset, setList, updateScanItem} from "./reducer";
import {ResponseModel} from "models/response_model";
import {PagedList} from "helpers/types";
import {generateError, renderSuccessToast} from "helpers/utilities";
import {postGetScans, postSaveNewScan, postExportAsins, postDeleteScan, postChangeScanVisibility} from "services/scan_and_save_service";
import {ScanAndSave} from "models/scan_and_save";
import {exportAsinsToExcel} from "Components/Common/ExcelExport";
import {refreshRemainingLimits} from "slices/thunks";
import {ScanAndSaveQuery} from "api/query";
import {ChangeScanVisibilityCommand, DeleteScanCommand, ExportAsinsCommand, SaveNewScanCommand, SubmitForAnalysisCommand} from "api/command";
import {postSubmitForAnalysis} from "services/search_service";
import i18n from "i18n";

export const getScanAndSaveList = (query: ScanAndSaveQuery) => async (dispatch: any) => {
  const loadingType = query.action === "filtering" ? "filter" : "list";
  try {
    dispatch(loading([loadingType, true]));
    const result: ResponseModel = await postGetScans(query);
    const pagedResult: PagedList<ScanAndSave> = result.data;
    dispatch(setList(pagedResult));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
  } finally {
    dispatch(loading([loadingType, false]));
  }
};

export const saveScan = (command: SaveNewScanCommand) => async (dispatch: any) => {
  try {
    dispatch(loading(["save", true]));
    await postSaveNewScan(command);
    dispatch(refreshRemainingLimits());
    renderSuccessToast(i18n.t("ScanAndSave.Toast.SaveSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
  } finally {
    dispatch(loading(["save", false]));
  }
};

export const sendAnalysis = (analyzeParams: SubmitForAnalysisCommand) => async (dispatch: any) => {
  try {
    dispatch(loading(["send", true]));
    const result: ResponseModel = await postSubmitForAnalysis(analyzeParams);
    renderSuccessToast(i18n.t(result.message));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
  } finally {
    dispatch(loading(["send", false]));
  }
};

export const exportAsins = (exportParams: ExportAsinsCommand, fileName: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["send", true]));
    const result: ResponseModel = await postExportAsins(exportParams);

    exportAsinsToExcel(result.data, fileName);
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
  } finally {
    dispatch(loading(["send", false]));
  }
};

export const deleteScan = (command: DeleteScanCommand, scanName: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["delete", true]));
    await postDeleteScan(command);
    dispatch(deleteOrRestoreItem(command));
    if (command.deleted) {
      renderSuccessToast(i18n.t("ScanAndSave.Toast.DeleteSuccess", {value: scanName}));
    }
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
  } finally {
    dispatch(loading(["delete", false]));
  }
};

export const changeScanVisibility = (command: ChangeScanVisibilityCommand) => async (dispatch: any) => {
  try {
    const response:ResponseModel = await postChangeScanVisibility(command);
    const updatedItem: ScanAndSave = response.data;
    dispatch(updateScanItem(updatedItem));
    renderSuccessToast(i18n.t("Common.Dialog.SelectVisibility.Toast.AccessSettingsUpdated"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    return false;
  }
};


export const resetScanAndSaveState = () => async (dispatch: any) => {
  dispatch(reset());
};
