import { useProfile } from "Components/Hooks/useProfile";
import _ from "lodash";
import {ShareType} from "models/enums/share_type";
import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown} from "reactstrap";

interface AccessDropdownProps {
  onChange?: (shareType: ShareType) => void;
}
const AccessDropdown = (props: AccessDropdownProps) => {
  const {t} = useTranslation();
  const {userProfile} = useProfile()
  const [selectedType, setSelectedType] = useState<ShareType>(userProfile?.shareType ?? ShareType.PRIVATE);
  const [type, setType] = useState<string>();
  const [description, setDescription] = useState<string>();


  const onSelectedTypeChange = useCallback(() => {
    switch (selectedType) {
      case ShareType.PRIVATE:
        setType(t("AccessDropdown.Private"));
        setDescription(t("AccessDropdown.PrivateDescription"));
        break;
      case ShareType.SHARED:
        setType(t("AccessDropdown.Shared"));
        setDescription(t("AccessDropdown.SharedDescription"));
        break;
      default:
        break;
    }

    if(!_.isNil(selectedType))
    {
      props.onChange?.(selectedType);
    }

  }, [selectedType]); // eslint-disable-line

  useEffect(() => {
    onSelectedTypeChange();
  }, [onSelectedTypeChange]);

  return (
    <>
      <Row>
        <Col xs="auto" className="d-flex align-items-center pe-0">
          <div className={`d-flex align-items-center justify-content-center rounded-circle rounded-circle-sm ${selectedType === ShareType.PRIVATE ? "bg-light" : "bg-success-subtle"}`}>
            {selectedType === ShareType.PRIVATE ? <i className="mdi mdi-lock fs-4" /> : selectedType === ShareType.SHARED && <i className="mdi mdi-account-supervisor fs-4" />}
          </div>
        </Col>
        <Col>
          <UncontrolledButtonDropdown>
            <DropdownToggle className="btn btn-ghost-dark ps-2 pe-2 py-1 waves-light fw-semibold ms-n2" caret>
              {type}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  setSelectedType(ShareType.SHARED);
                }}
              >
                <i className="mdi mdi-account-supervisor me-1 fs-18 align-middle"></i>
                <span>
                  {t("AccessDropdown.Shared")}
                  {selectedType === ShareType.SHARED && <i className="mdi mdi-check ms-2 fs-18 text-secondary"></i>}
                </span>
              </DropdownItem>
              <DropdownItem onClick={() => setSelectedType(ShareType.PRIVATE)}>
                <i className="mdi mdi-lock me-1 fs-18 align-middle"></i>
                <span>
                  {t("AccessDropdown.Private")}
                  {selectedType === ShareType.PRIVATE && <i className="mdi mdi-check ms-2 fs-18 text-secondary"></i>}
                </span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
          <div className="mt-n1">
            <small className="text-muted">{description}</small>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default AccessDropdown;
