import {memo, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Modal, ModalHeader, ModalBody} from "reactstrap";
import {createTypedModal} from "helpers/modal_helpers";

interface ModalData {
  asin: string;
  ineligibilityReasonList?: string;
}

export const IneligibilityReasonListModal = createTypedModal<ModalData>("ineligibility_reason_list");

const IneligibilityReasonList = () => {
  const {open, data} = IneligibilityReasonListModal.useModal();

  if (!open) return null;
  return <IneligibilityReasonListContent data={data} />;
};

const IneligibilityReasonListContent = ({data}: {data: ModalData | undefined}) => {
  const {t} = useTranslation();
  const [ineligibilityReasonList, setIneligibilityReasonList] = useState<string[]>();

  const toggle = () => {
    IneligibilityReasonListModal.close();
  };

  useEffect(() => {
    setIneligibilityReasonList(data?.ineligibilityReasonList?.split(","));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal id="showIneligibilityReasonListModal" isOpen={true} toggle={toggle} fade={true} centered={true}>
      <ModalHeader className="p-3" toggle={toggle}>
        {t("SearchResults.Dialog.IneligibilityReasonsList.Title")}
      </ModalHeader>
      <ModalBody>
        {ineligibilityReasonList ? (
          ineligibilityReasonList.map((reason: string, index: number) => (
            <div key={index} className="d-flex">
              <div className="flex-shrink-0 me-2">
                <i className={`bx ${reason.endsWith("Recheck") || reason.endsWith("Warning") ? "bxs-error text-warning" : "bxs-x-circle text-danger"} fs-20`}></i>
              </div>
              <div className="flex-grow-1">
                <h5>{reason}</h5>
                <p className="text-muted">{t(`IneligibilityReasons.${reason}`)}</p>
              </div>
            </div>
          ))
        ) : (
          <div>{t("No reason")}</div>
        )}
      </ModalBody>
    </Modal>
  );
};
export default memo(IneligibilityReasonList);
