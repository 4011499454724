import React, {useState} from "react";
import {Modal, ModalHeader, ModalBody, Button, ModalFooter, Col, Input, Row} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {setUserShowCheckListOptionChoice} from "slices/search-result/thunk";
import {AmazonBusinessModel} from "models/enums/user_search_type";
import {createTypedModal} from "helpers/modal_helpers";
import {UserSearchProductCheckList} from "models/user_search_product_check_list";
import {SellThisModal} from "Components/Common/SellThis";
import CheckListItem from "../Item/Components/CheckListItem";
import { extractKeywords } from "../Item/_CheckListItems";
import { UserSearchProductSummary } from "models/user_search_product_summary";

enum SameProductCheckResult {
  YES = 0,
  NO = 1,
}

enum CustomsCheckResult {
  NO = 0,
  YES = 1,
}

interface ModalData {
  asin: string;
  checkList?: UserSearchProductCheckList;
  summary?: UserSearchProductSummary;
  marketplaceTarget: string;
  amazonBusinessModel: AmazonBusinessModel;
  purchasePrice?: number;
  exchangeRateSource?: number;
}

export const CheckListModal = createTypedModal<ModalData>("check_list");
const CheckList = () => {
  const {open, data} = CheckListModal.useModal();

  if (!open) return null;
  return <CheckListContent data={data} />;
};

const CheckListContent = ({data}: {data: ModalData | undefined}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const updateShowCheckListOption = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    setUserShowCheckListOptionChoice(!event.target.checked as boolean, data?.amazonBusinessModel!)(dispatch);
    setLoading(false);
  };

  let sameProductTooltip: string = "";
  let customsCheckTooltip: string = "";
  let productSizeEligibleToFbaTooltip: string = "";
  let isOldProductTooltip: string = "";
  let isTheRatingAverageGoodTooltip: string = "";

  switch (data?.checkList?.sameProductCheckResult) {
    case SameProductCheckResult.YES:
      sameProductTooltip = t("SearchResults.Analyzes.CheckList.NoProblem");
      break;

    case SameProductCheckResult.NO:
      sameProductTooltip = t("SearchResults.Analyzes.CheckList.SameProduct.Tooltip");
      break;

    default:
      sameProductTooltip = t("NA");
      break;
  }

  switch (data?.checkList?.customsCheckCategoryCheckResult) {
    case CustomsCheckResult.YES:
      customsCheckTooltip = t("SearchResults.Analyzes.CheckList.CustomsCheckCategory.Tooltip", {value: data?.checkList?.customsCheckCategoryCheckReason});

      break;

    case CustomsCheckResult.NO:
      // Only set status to success if both category and keyword checks are NO
      if (data?.checkList?.customsCheckKeywordCheckResult === CustomsCheckResult.NO) {
        customsCheckTooltip = t("SearchResults.Analyzes.CheckList.NoProblem");
      }
      break;

    default:
      customsCheckTooltip = t("NA");
      break;
  }

  // If keyword check is YES, override the status
  if (data?.checkList?.customsCheckKeywordCheckResult === CustomsCheckResult.YES) {
    customsCheckTooltip = t("SearchResults.Analyzes.CheckList.CustomsCheckKeyword.Tooltip", {value: extractKeywords(data?.checkList?.customsCheckKeywordCheckReason).join(", ")});
  }

  switch (data?.checkList?.productSizeEligibleToFBA) {
    case true:
      productSizeEligibleToFbaTooltip = t("SearchResults.Analyzes.CheckList.NoProblem");
      break;

    case false:
      productSizeEligibleToFbaTooltip = t("SearchResults.Analyzes.CheckList.ProductSizeEligibleToFba.Tooltip");
      break;

    default:
      productSizeEligibleToFbaTooltip = t("NA");
      break;
  }

  switch (data?.checkList?.isOldProduct) {
    case true:
      isOldProductTooltip = t("SearchResults.Analyzes.CheckList.NoProblem");
      break;

    case false:
      isOldProductTooltip = t("SearchResults.Analyzes.CheckList.IsOldProductTooltip");
      break;

    default:
      isOldProductTooltip = t("NA");
      break;
  }

  switch (data?.checkList?.isTheRatingAverageGood) {
    case true:
      isTheRatingAverageGoodTooltip = t("SearchResults.Analyzes.CheckList.NoProblem");
      break;

    case false:
      isTheRatingAverageGoodTooltip = t("SearchResults.Analyzes.CheckList.IsTheRatingAverageGood.Tooltip", {value: data?.summary?.reviewRating});
      break;

    default:
      isTheRatingAverageGoodTooltip = t("NA");
      break;
  }

  const toggle = () => {
    CheckListModal.close();
  };

  return (
    <Modal backdrop="static" isOpen={true} toggle={toggle} fade={true} centered={true}>
      <ModalHeader className="p-3" toggle={toggle}>
        {t("SearchResults.Dialog.ShowCheckList.Title")}
      </ModalHeader>
      <ModalBody>
        <ul className="list-unstyled vstack gap-1 mb-0">
          <h5>{t("SearchResults.Dialog.ShowCheckList.Description")}</h5>
          <CheckListItem
            labelName={t("SearchResults.Analyzes.CheckList.SameProduct.Title")}
            status={data?.checkList?.sameProductCheckResult === 1 ? "muted" : "success"}
            hide={data?.amazonBusinessModel === AmazonBusinessModel.WHOLESALE}
            tooltipMessage={sameProductTooltip}
          />
          <CheckListItem
            labelName={t("SearchResults.Analyzes.CheckList.CustomsCheck.Title")}
            status={data?.checkList?.customsCheckCategoryCheckResult === 1 || data?.checkList?.customsCheckKeywordCheckResult === 1 ? "muted" : "success"}
            hide={data?.amazonBusinessModel === AmazonBusinessModel.WHOLESALE}
            tooltipMessage={customsCheckTooltip}
          />

          <CheckListItem
            labelName={t("SearchResults.Analyzes.CheckList.IpClaim.Title")}
            status={"muted"}
            hide={data?.amazonBusinessModel === AmazonBusinessModel.WHOLESALE}
            tooltipMessage={t("SearchResults.Analyzes.CheckList.IpClaim.Tooltip")}
          />

          <CheckListItem
            labelName={t("SearchResults.Analyzes.CheckList.LogoBrand.Title")}
            status={"muted"}
            tooltipMessage={t("SearchResults.Analyzes.CheckList.LogoBrand.Tooltip")}
          />

          <CheckListItem
            labelName={t("SearchResults.Analyzes.CheckList.FragileItem.Title")}
            status={"muted"}
            tooltipMessage={t("SearchResults.Analyzes.CheckList.FragileItem.Tooltip")}
          />

          <CheckListItem
            labelName={t("SearchResults.Analyzes.CheckList.ProductSizeEligibleToFba.Title")}
            status={data?.checkList?.productSizeEligibleToFBA ? "success" : "muted"}
            hide={data?.amazonBusinessModel === AmazonBusinessModel.DROPSHIPPING || data?.checkList?.productSizeEligibleToFBA}
            tooltipMessage={productSizeEligibleToFbaTooltip}
          />
          <CheckListItem
            labelName={
              t("SearchResults.Analyzes.CheckList.IsOldProduct") +
              (data?.checkList?.productExistSince
                ? "(" +
                  data?.checkList.productExistSince
                    .replace("years", t("years"))
                    .replace("year", t("year"))
                    .replace("months", t("months"))
                    .replace("month", t("month"))
                    .replace("days", t("days"))
                    .replace("day", t("day"))
                    .replace("and", t("and"))
                    .replace("ago", t("ago")) +
                  ")"
                : "")
            }
            status={data?.checkList?.isOldProduct ? "success" : "muted"}
            hide={data?.amazonBusinessModel === AmazonBusinessModel.DROPSHIPPING || data?.checkList?.isOldProduct}
            tooltipMessage={isOldProductTooltip}
          />

          <CheckListItem
            labelName={t("SearchResults.Analyzes.CheckList.IsTheRatingAverageGood.Title")}
            status={data?.checkList?.isTheRatingAverageGood ? "success" : "muted"}
            hide={data?.checkList?.isTheRatingAverageGood}
            tooltipMessage={isTheRatingAverageGoodTooltip}
          />
        </ul>
        <br></br>
      </ModalBody>
      <ModalFooter>
        <div className="vstack justify-content-start">
          <Row className="d-flex align-items-center">
            <Col xs="auto">
              <span>{t("SearchResults.Dialog.ShowCheckList.SwitchShowCheckListTitle")}</span>
            </Col>
            <Col xs="auto">
              <div className="form-check form-switch">
                <Input id="showCheckList" className="form-check-input form-switch-md " disabled={loading} type="checkbox" role="switch" onChange={updateShowCheckListOption} />
              </div>
            </Col>
          </Row>
        </div>
        <div className="hstack gap-2 justify-content-end">
          <Button type="button" color="light" onClick={toggle}>
            {t("Close")}
          </Button>
          <Button
            type="submit"
            color="secondary"
            className="w-sm"
            onClick={() => {
              CheckListModal.close();
              SellThisModal.open({
                asin: data?.asin!,
                marketplace: data?.marketplaceTarget!,
                purchasePrice: data?.amazonBusinessModel === AmazonBusinessModel.WHOLESALE ? data?.purchasePrice : undefined,
                exchangeRateSource: data?.amazonBusinessModel === AmazonBusinessModel.WHOLESALE ? data?.exchangeRateSource : undefined,
              });
            }}
          >
            {t("SearchResults.Dialog.ShowCheckList.SubmitButton")}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
export default CheckList;
