import Dialog, {DialogRef} from "Components/Common/Dialog";
import NewShipment, {NewShipmentModal} from "../Modal/NewShipment";
import useSelectOptions, {UserSelectOption} from "Components/Hooks/useSelectOptions";
import {Button, Card, CardBody, Col, Form, Label, Row, Spinner} from "reactstrap";
import {useFormik} from "formik";
import {RefObject, useCallback, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {ShipmentListRef} from "..";
import {DateRange} from "helpers/types";
import {useProfile} from "Components/Hooks/useProfile";
import {useSelector} from "react-redux";
import {FieldConfig, useUrlQuery} from "Components/Hooks/useUrlQuery";
import {CommonSliceSelector} from "slices/common/selector";
import {useNavigate} from "react-router-dom";
import {UserShipmentsQuery} from "api/query";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import Restricted from "Components/Common/Restricted";
import ValidatedDateRangeInput from "Components/Common/ValidatedDateRangeInput";
import ValidatedMultiSelect from "Components/Common/ValidatedMultiSelect";
import ValidatedInput from "Components/Common/ValidatedInput";
import * as Yup from "yup";
import ValidatedSelect from "Components/Common/ValidatedSelect";

interface FiltersProps {
  listRef: RefObject<ShipmentListRef>;
  busy: boolean;
  fields: FieldConfig<UserShipmentsQuery>[];
}
const Filters = (props: FiltersProps) => {
  const {t} = useTranslation();
  const {shipmentStatusSelectOptions, multiSelectTranslations, shareTypeSelectOptions} = useSelectOptions();
  const {userProfile} = useProfile();
  const {activeUserStoreOptions, disconnectedUserStoreOptions} = useSelector(CommonSliceSelector);
  const [teamUserOptions, setTeamUserOptions] = useState<UserSelectOption[]>([]);
  const navigate = useNavigate();
  const connectStoreDialogRef = useRef<DialogRef>(null);

  const onLoad = useCallback(
    () => {
      validation.handleSubmit();

      if (userProfile?.teamUsers) {
        const teamOptions: UserSelectOption[] = userProfile.teamUsers
          .map((user) => ({
            label: user.fullName,
            value: user.userId,
            avatar: user.userUiPreferences?.avatar,
            email: user.email,
          }));
        setTeamUserOptions([...teamOptions]);
      }
    },
    [], // eslint-disable-line
  );

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  // validation
  const validation = useFormik({
    initialValues: {} as UserShipmentsQuery,
    validationSchema: Yup.object<UserShipmentsQuery>({
      search: Yup.string(),
      startDate: Yup.date(),
      endDate: Yup.date(),
      shipDateRange: Yup.object<DateRange>({
        startDate: Yup.date(),
        endDate: Yup.date(),
      }),
      marketplaceFilter: Yup.string(),
      statusFilter: Yup.string(),
      teamUserIds: Yup.array<String>(),
      shareType: Yup.string(),
    }),
    onSubmit: (values) => {
      const payload: UserShipmentsQuery = {
        page: values.page,
        pageSize: values.pageSize,
        search: values.search || undefined,
        createDateRange: {
          start: values.createDateRange?.start,
          end: values.createDateRange?.end,
        },
        shipDateRange: {
          start: values.shipDateRange?.start,
          end: values.shipDateRange?.end,
        },
        userStoreIds: values.userStoreIds || undefined,
        statuses: values.statuses || undefined,
        teamUserIds: values.teamUserIds,
        shareType: values.shareType?.toString() === "all" ? undefined : values.shareType,
        sortBy: values.sortBy,
        sortingOrder: values.sortingOrder,
        action: "filtering",
      };
      updateQuery(payload);
    },
  });
  const {updateQuery} = useUrlQuery<UserShipmentsQuery>(props.fields, validation);

  return (
    <PlaceholderGlow busy={props.busy}>
      <Card style={{zIndex: 4}}>
        <CardBody>
          <div className="live-preview">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Row className="align-items-center">
                <Col>
                  <div className="flex-shrink-0">
                    <Label className="form-label text-muted">{t("Shipments.Filters.Title")}</Label>
                  </div>
                </Col>
                <Col xs="auto" align="right">
                  <Restricted require="shipments" create>
                    <Button
                      type="button"
                      className="fw-semibold btn-sm"
                      disabled={props.busy}
                      onClick={() => {
                        if (activeUserStoreOptions.length === 0 && disconnectedUserStoreOptions.length === 0) {
                          connectStoreDialogRef.current?.show();
                        } else {
                          NewShipmentModal.open({
                            listRef: props.listRef,
                          });
                        }
                      }}
                    >
                      {props.busy ? <Spinner size="sm" className="me-2 align-middle"></Spinner> : <i className="ri-add-line align-middle me-1"></i>}
                      {t("Shipments.NewShipment")}
                    </Button>
                    <NewShipment />
                  </Restricted>
                </Col>
              </Row>
              <Row className="mt-2 gx-5 gy-3">
                <Col xs={12} sm={6} md={4} lg={3}>
                  <Label htmlFor="endDate">{t("Shipments.Filters.Label.Search")}</Label>
                  <ValidatedInput validation={validation} field="search" placeholder={t("Shipments.Filters.Label.SearchPlaceholder")} disableValidationUI />
                </Col>
                <Col xs={12} sm={6} md={4} lg={3}>
                  <Label htmlFor="endDate">{t("Shipments.Filters.Label.CreateDate")}</Label>
                  <ValidatedDateRangeInput validation={validation} field="createDateRange" />
                </Col>
                <Col xs={12} sm={6} md={4} lg={3}>
                  <Label htmlFor="endDate">{t("Shipments.Filters.Label.ShipDate")}</Label>
                  <ValidatedDateRangeInput validation={validation} field="shipDateRange" />
                </Col>
                <Col sm={2}>
                  <Label htmlFor="userStoreIds">{t("Shipments.Filters.Label.Store")}</Label>
                  <ValidatedMultiSelect
                    options={[...activeUserStoreOptions, ...disconnectedUserStoreOptions]}
                    validation={validation}
                    field="userStoreIds"
                    value={validation.values.userStoreIds}
                    translations={{
                      ...multiSelectTranslations,
                      selectSomeItems: t("General.Select.SelectStore"),
                      allItemsAreSelected: t("General.Select.AllStoresSelected"),
                    }}
                    optionStyle="marketplace"
                  />
                </Col>
                <Col sm={2}>
                  <Label htmlFor="statuses">{t("Shipments.Filters.Label.Status")}</Label>
                  <ValidatedMultiSelect options={shipmentStatusSelectOptions} validation={validation} field="statuses" value={validation.values.statuses} hasSelectAll />
                </Col>
                {userProfile?.teamUsers != null && userProfile?.teamUsers.length > 1 && (
                  <Col sm={2}>
                    <Label htmlFor="statuses">{t("User")}</Label>
                    <ValidatedMultiSelect options={teamUserOptions} validation={validation} field="teamUserIds" value={validation.values.teamUserIds} optionStyle="user" hasSelectAll />
                  </Col>
                )}
                {userProfile?.subscription === "Premium" && (
                  <Col sm={2}>
                    <Label htmlFor="status">{t("Searches.Filters.ShareType")}</Label>
                    <ValidatedSelect options={shareTypeSelectOptions} validation={validation} field="shareType" value={validation.values.shareType} />
                  </Col>
                )}
                <Col sm={2}>
                  <Label>&nbsp;</Label>
                  <Button type="submit" color="secondary" className="d-block" disabled={props.busy} onClick={() => validation.setFieldValue("page", 1)}>
                    {props.busy ? <Spinner size="sm" className="me-2 align-middle"></Spinner> : <i className="ri-equalizer-fill me-1 align-bottom"></i>}
                    {t("Shipments.Filters.Button.Apply")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </CardBody>
      </Card>
      <Dialog
        ref={connectStoreDialogRef}
        color="success"
        buttons={["yes", "no"]}
        buttonConfig={(buttonConfigs) => {
          return {
            ...buttonConfigs,
            yes: {
              ...buttonConfigs.yes,
              label: t("Shipments.GoToStores"),
            },
            no: {
              ...buttonConfigs.no,
              label: t("Close"),
            },
          };
        }}
        busy={false}
        iconClass="mdi mdi-connection"
        message={t("Shipments.StoreConnectInfoMessage")}
        title={t("Stores.Connect")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            navigate("/stores");
          }
          connectStoreDialogRef.current?.hide();
        }}
      />
    </PlaceholderGlow>
  );
};

export default Filters;
