import {getLoggedUserFromApi, postGetUserRemainingLimit, postLogin, postLoginWithToken, postLogout, postTwoFactorVerify} from "../../../services/identity_service";
import {ILoginDto, ApplicationUser} from "models/application_user";
import {setAuthorization} from "helpers/api_helper";
import {apiError, loading, reset, setLoggedUserRemainingLimits, setLoginUser, setStatus} from "./reducer";
import {generateError} from "helpers/utilities";
import {changeLayoutMode} from "slices/thunks";
import {ResponseModel} from "models/response_model";
import {UserRemainingLimitViewModel} from "models/user_limit";
import {LOCAL_STORAGE, updateMenuModeLocalData} from "helpers/local_storage";
import i18n from "i18n";
import {TwoFactorVerifyCommand} from "api/command";
import Cookies from "js-cookie";
import {COOKIE_STORAGE} from "helpers/cookie_helper";

const initSession = async (dispatch: any, loginResult: ApplicationUser, history: any, returnUrl?: string) => {
  setAuthorization(loginResult.tokenInfo.signature);
  localStorage.setItem(LOCAL_STORAGE.LOGGED_USER, JSON.stringify(loginResult));
  localStorage.setItem(LOCAL_STORAGE.I18N_LANGUAGE, loginResult.language);
  localStorage.setItem(LOCAL_STORAGE.THEME, loginResult.theme);

  i18n.changeLanguage(loginResult.language);
  await dispatch(setLoginUser(loginResult));
  await dispatch(changeLayoutMode(loginResult.theme));
  await dispatch(setStatus("success"));
  updateMenuModeLocalData("Customer");

  if (loginResult.tokenInfo.isTempToken) {
    history("/account/verify-2fa");
  } else if (returnUrl) {
    const redirectUrl = returnUrl?.split("return_url=")[1];
    const decodedUrl = decodeURIComponent(redirectUrl);
    history(decodedUrl);
  } else history("/");
};
export const loginUser = (user: ILoginDto, history: any, returnUrl?: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["login", true]));
    dispatch(setStatus("empty"));
    const loginResult: ApplicationUser = (await postLogin(user)).data;
    if (loginResult.tokenInfo.isTempToken) {
      initSession(dispatch, loginResult, history);
      history("/account/verify-2fa", {
        state: {
          tempToken: loginResult.tokenInfo.signature,
          returnUrl: returnUrl,
        },
      });
    } else {
      initSession(dispatch, loginResult as ApplicationUser, history, returnUrl);
    }
  } catch (error) {
    dispatch(setStatus("failed"));
    const errorObject = generateError(error, false);
    dispatch(apiError(errorObject));
  } finally {
    dispatch(loading(["login", false]));
  }
};

export const loginUserWithToken = (token: string, history: any, returnUrl?: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["loginWithToken", true]));
    dispatch(setStatus("empty"));
    const loginResult: ApplicationUser = (await postLoginWithToken({token})).data;
    initSession(dispatch, loginResult, history, returnUrl);
  } catch (error) {
    dispatch(setStatus("failed"));
    const errorObject = generateError(error, false);
    dispatch(apiError(errorObject));
  } finally {
    dispatch(loading(["loginWithToken", false]));
  }
};

export const logoutUser = () => async (dispatch: any) => {
  try {
    dispatch(loading(["logout", true]));
    const user = localStorage.getItem(LOCAL_STORAGE.LOGGED_USER);
    if (user) {
      const auth = (await JSON.parse(user)) as ApplicationUser;
      await postLogout({tokenInfo: auth.tokenInfo});
    }

    localStorage.removeItem(LOCAL_STORAGE.LOGGED_USER);
    localStorage.removeItem(LOCAL_STORAGE.MENU_DISPLAY_MODE);
    localStorage.removeItem(LOCAL_STORAGE.I18N_LANGUAGE);
    localStorage.removeItem(LOCAL_STORAGE.THEME);
    dispatch(changeLayoutMode("light"));
    dispatch(setStatus("empty"));
    dispatch(setLoginUser(undefined));
  } catch (error) {
    dispatch(loading(["logout", false]));
  }
};

export const reloadUser = () => async (dispatch: any) => {
  try {
    dispatch(loading(["load", true]));
    const response: ResponseModel = await getLoggedUserFromApi();
    const loginResult: ApplicationUser = response.data;
    setAuthorization(loginResult.tokenInfo.signature);
    localStorage.setItem(LOCAL_STORAGE.LOGGED_USER, JSON.stringify(loginResult));
    localStorage.setItem(LOCAL_STORAGE.I18N_LANGUAGE, loginResult.language);
    localStorage.setItem(LOCAL_STORAGE.THEME, loginResult.theme);

    i18n.changeLanguage(loginResult.language);
    await dispatch(setLoginUser(loginResult));
    await dispatch(changeLayoutMode(loginResult.theme));
    await dispatch(setStatus("success"));
  } catch (error) {
    const errorObject = generateError(error, false);
    dispatch(apiError(errorObject));
  } finally {
    dispatch(loading(["load", false]));
  }
};

export const refreshRemainingLimits = () => async (dispatch: any) => {
  try {
    dispatch(loading(["limit", true]));
    const response: ResponseModel = await postGetUserRemainingLimit();
    const limits: UserRemainingLimitViewModel = response.data;
    dispatch(setLoggedUserRemainingLimits(limits));
    return true;
  } catch (error) {
    const errorObject = generateError(error, false);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["limit", false]));
  }
};

export const loadLoggedUser = () => async (dispatch: any) => {
  try {
    dispatch(loading(["login", true]));
    const response: ResponseModel = await getLoggedUserFromApi();
    const result: ApplicationUser = response.data;
    dispatch(setLoginUser(result));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["login", false]));
  }
};

export const resetLoginFlag = () => async (dispatch: any) => {
  try {
    dispatch(setStatus("empty"));
    return true;
  } catch (error) {
    dispatch(setStatus("failed"));
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  }
};

export const verifyTwoFactor = (command: TwoFactorVerifyCommand, history: any) => async (dispatch: any) => {
  try {
    dispatch(loading(["verify2fa", true]));
    const response: ResponseModel = await postTwoFactorVerify(command);
    const result: ApplicationUser = response.data.loginDetails;
    const trustedBrowserId: string = response.data.trustedBrowserId;
    Cookies.set(COOKIE_STORAGE.TRUSTED_BROWSER_ID, trustedBrowserId, {expires: 7});
    initSession(dispatch, result, history);
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["verify2fa", false]));
  }
};
export const resetLoginState = () => (dispatch: any) => {
  dispatch(reset());
};
