import {store} from "index";
import {Option} from "react-multi-select-component";
import {Input, Label} from "reactstrap";
import {TagColor} from "models/enums/tag_color";
import {Tag} from "models/tag";
import {MarketplaceSelectOption, SelectOptionsType, UserSelectOption} from "Components/Hooks/useSelectOptions";
import i18n from "i18n";
import defaultAvatar from "assets/images/user-dummy-img.jpg";
import { AllMarketplaces } from "helpers/marketplace_helper";

export interface DefaultItemRendererProps {
  checked: boolean;
  option: Option;
  disabled?: boolean;
  onClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface MarketplaceItemRendererProps {
  checked: boolean;
  option: MarketplaceSelectOption;
  disabled?: boolean;
  onClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
export interface UserItemRendererProps {
  checked: boolean;
  option: UserSelectOption;
  disabled?: boolean;
  onClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const DividerOption: SelectOptionsType = {
  label: "",
  value: "divider",
  isDivider: true,
};

export const defaultItemRenderer = ({checked, option, onClick, disabled}: DefaultItemRendererProps) => (
  <div className={`d-flex align-items-center item-renderer ${disabled ? "disabled" : ""}`}>
    <Input
      id={`Option-${option.value}`}
      className="option-checkbox-style"
      type="checkbox"
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => onClick(event)}
      checked={checked}
      tabIndex={-1}
      disabled={disabled}
    />
    <Label htmlFor={`Option-${option.value}`} className="option-label-style" check>
      <span className="ellipsis-for-option" title={option.label}>
        {option.label}
      </span>
    </Label>
  </div>
);

export const marketplaceItemRenderer = ({checked, option, onClick, disabled}: MarketplaceItemRendererProps) => {
  const SELECT_ALL = "";
  if ((option as MarketplaceSelectOption).isDivider) {
    return (
      <div>
        <hr className="m-0" />
      </div>
    );
  }
  const marketplace = AllMarketplaces.find((info) => info.marketplace === option.marketplace)!;

  return (
    <div className={`d-flex align-items-center item-renderer ${disabled ? "disabled" : ""}`}>
      <Input
        id={`Option-${option.value}`}
        className="option-checkbox-style"
        type="checkbox"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onClick(event)}
        checked={checked}
        tabIndex={-1}
        disabled={disabled}
      />
      {option.value !== SELECT_ALL && <img src={marketplace.flag} alt={i18n.t(marketplace.countryName)} className="rounded-circle" width={15} height={15} />}
      <Label htmlFor={`Option-${option.value}`} className="option-label-style" check>
        <span className="ellipsis-for-option" title={option.label}>
          {option.label}
        </span>
      </Label>
    </div>
  );
};

export const tagColorItemRenderer = ({checked, option, onClick, disabled}: DefaultItemRendererProps) => {
  const tag: Tag | undefined = store.getState().Common.tags?.find((tag) => tag.tagId === option.value);
  const SELECT_ALL = "";
  let color = "";
  if (tag?.color === TagColor.PRIMARY) {
    color = "bg-primary";
  } else if (tag?.color === TagColor.SECONDARY) {
    color = "bg-secondary";
  } else if (tag?.color === TagColor.SUCCESS) {
    color = "bg-success";
  } else if (tag?.color === TagColor.DANGER) {
    color = "bg-danger";
  } else if (tag?.color === TagColor.WARNING) {
    color = "bg-warning";
  } else if (tag?.color === TagColor.INFO) {
    color = "bg-info";
  } else if (tag?.color === TagColor.DARK) {
    color = "bg-dark";
  } else {
    color = "";
  }
  return (
    <div className={`d-flex align-items-center gap-2 ${disabled ? "disabled" : ""}`}>
      <Input
        id={`Option-${option.value}`}
        className="option-checkbox-style"
        type="checkbox"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onClick(event)}
        checked={checked}
        tabIndex={-1}
        disabled={disabled}
      />
      {option.value !== SELECT_ALL && <div className={`${color} rounded-pill`} style={{height: "15px", width: "15px"}} />}
      <Label htmlFor={`Option-${option.value}`} className="option-label-style" check>
        <span className="ellipsis-for-option" title={option.label}>
          {option.label}
        </span>
      </Label>
    </div>
  );
};


export const userItemRenderer = ({checked, option, onClick, disabled}: UserItemRendererProps) => {
  const SELECT_ALL = "";
  return (
    <div className={`d-flex align-items-center item-renderer ${disabled ? "disabled" : ""}`}>
      <Input
        id={`Option-${option.value}`}
        className="option-checkbox-style"
        type="checkbox"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onClick(event)}
        checked={checked}
        tabIndex={-1}
        disabled={disabled}
      />
      {option.value !== SELECT_ALL && <img src={option.avatar || defaultAvatar} alt={option.email} className="rounded-circle" width={15} height={15} />}      
      <Label htmlFor={`Option-${option.value}`} className="option-label-style" check>
        <span className="ellipsis-for-option" title={option.label}>
          {option.label}
        </span>
      </Label>
    </div>
  );
};
