import _ from "lodash";

export const AmazonHelper = {
  isValidAsin(part: string): boolean {
    // ASIN: Must be 10 characters long and contain only letters and numbers
    const asinRegex = /^[A-Za-z0-9]{10}$/;
    return asinRegex.test(part);
  },

  isValidUpc(part: string): boolean {
    // UPC: Must be 12 characters long and contain only digits
    return part.length === 12 && /^[0-9]+$/.test(part);
  },

  isValidEan(part: string): boolean {
    // EAN: Must be 8 or 13 characters long and contain only digits
    return (part.length === 8 || part.length === 13) && /^[0-9]+$/.test(part);
  },
  calculateFBAFeeTax(fbaFee?: number, fbaFeeTaxRate?: number): number | undefined {
    if (!_.isNil(fbaFeeTaxRate) && fbaFeeTaxRate > 0) {
      return getRoundedDouble(((fbaFee ?? 0) * fbaFeeTaxRate) / 100.0);
    }
    return undefined;
  },
  calculateReferralFeeTax(referralFee: number | null, referralFeeTaxRate?: number): number | undefined {
    if (referralFeeTaxRate && referralFeeTaxRate > 0 && referralFee) {
      return getRoundedDouble((referralFee * referralFeeTaxRate) / 100.0);
    }

    return undefined;
  },
  calculateBankTransferCommission(
    salesProceeds: number | null,
    fBAFee: number | null,
    fBAFeeTax: number | null,
    referralFee: number | null,
    referralFeeTax: number | null,
    digitalServicesFee: number | null,
    bankTransferCommissionRate: number | null,
  ): number | undefined {
    return getRoundedDouble(
      (((salesProceeds ?? 0) - (fBAFee ?? 0) - (fBAFeeTax ?? 0) - (referralFee ?? 0) - (referralFeeTax ?? 0) - (digitalServicesFee ?? 0)) * (bankTransferCommissionRate ?? 0)) / 100.0,
    );
  },
  calculateEstimatedRefund(
    salesProceeds: number | null,
    fBAFee: number | null,
    fBAFeeTax: number | null,
    referralFee: number | null,
    referralFeeTax: number | null,
    digitalServicesFee: number | null,
    estimatedRefundRate: number | null,
  ): number | undefined {
    return getRoundedDouble((((salesProceeds ?? 0) - (fBAFee ?? 0) - (fBAFeeTax ?? 0) - (referralFee ?? 0) - (referralFeeTax ?? 0) - (digitalServicesFee ?? 0)) * (estimatedRefundRate ?? 0)) / 100.0);
  },
  calculateCurrencyExchangeLoss(
    salesProceeds: number | null,
    fBAFee: number | null,
    fBAFeeTax: number | null,
    referralFee: number | null,
    referralFeeTax: number | null,
    digitalServicesFee: number | null,
    currencyExchangeLossRate: number | null,
  ): number | undefined {
    return getRoundedDouble(
      (((salesProceeds ?? 0) - (fBAFee ?? 0) - (fBAFeeTax ?? 0) - (referralFee ?? 0) - (referralFeeTax ?? 0) - (digitalServicesFee ?? 0)) * (currencyExchangeLossRate ?? 0)) / 100.0,
    );
  },
};

const getRoundedDouble = (value: number | null): number | undefined => {
  if (value === null) {
    return undefined;
  }

  let roundedDouble = Number(value.toFixed(2));

  if (Object.is(roundedDouble, -0)) {
    roundedDouble = 0;
  }

  return roundedDouble;
};
