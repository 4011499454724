import { createSelector } from "reselect";
import { RootState } from "slices";

export const NotificationsSlice = createSelector(
  (state: RootState) => state,
  (state) => ({
    loading: state.Notifications.loading,
    userWebNotifications: state.Notifications.userWebNotifications,
    userWebNotificationsForDropdown: state.Notifications.userWebNotificationsForDropdown,
    unreadCount: state.Notifications.unreadCount,
    error: state.Notifications.error,
  }),
);
