import React, {useEffect, useState} from "react";
import {formatDate, getRelativeDate, getToday, numberFormatter} from "helpers/utilities";
import {Finance, InventoryWidget, ProfitRoiMargin, TotalOrders, TotalRevenue} from "models/dashboard";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {Card, CardBody, Col} from "reactstrap";
import {useProfile} from "Components/Hooks/useProfile";
import {OrderStatus} from "models/enums/order_status";
import {DashboardSlice} from "slices/dashboard/selector";
import CountUp from "react-countup";
import DisplayPrice from "Components/Common/DisplayPrice";
import DisplayNumber from "Components/Common/DisplayNumber";
import moment from "moment";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";

const Totals = () => {
  const {t} = useTranslation();

  const [totalRevenue, setTotalRevenue] = useState<TotalRevenue>({value: 0, changeRate: 0});
  const [totalOrders, setTotalOrders] = useState<TotalOrders>({value: 0, changeRate: 0, units: 0});
  const [profitRoiMargin, setProfitRoiMargin] = useState<ProfitRoiMargin>({roi: 0, margin: 0, profit: 0});
  const [inventoryWidget, setInventoryWidget] = useState<InventoryWidget>({inStock: 0, totalSku: 0, itemsMissingCost: 0});
  const [finance, setFinance] = useState<Finance>({} as Finance);
  const [filterStartDate, setFilterStartDate] = useState<Date>(getToday().toDate());
  const [filterEndDate, setFilterEndDate] = useState<Date>(getToday().toDate());
  const [currencyCode, setCurrencyCode] = useState<string>("USD");
  const {userProfile} = useProfile();

  const {filter, statistics, loading} = useSelector(DashboardSlice);

  useEffect(() => {
    if (statistics) {
      setTotalRevenue(statistics.totalRevenue);
      setTotalOrders(statistics.totalOrders);
      setProfitRoiMargin(statistics.profitRoiMargin);
      setInventoryWidget(statistics.inventoryWidget);
      setFinance(statistics.finance);
      setCurrencyCode(filter?.currencyCode || "USD");
    }
  }, [statistics, filter]);

  useEffect(() => {
    if (filter) {
      switch (filter.dateRange) {
        case 1:
          setFilterStartDate(getRelativeDate(0));
          break;
        case 2:
          const yesterday: Date = getRelativeDate(1);
          setFilterStartDate(getRelativeDate(1));
          setFilterEndDate(
            moment
              .tz(yesterday, userProfile?.timezone!)
              .endOf("day")
              .toDate(),
          );
          break;
        case 7:
          setFilterStartDate(getRelativeDate(6));
          break;
        case 14:
          setFilterStartDate(getRelativeDate(13));
          break;
        case 30:
          setFilterStartDate(getRelativeDate(29));
          break;
        case 90:
          setFilterStartDate(getRelativeDate(89));
          break;
        case 180:
          setFilterStartDate(getRelativeDate(179));
          break;
        case 365:
          setFilterStartDate(getRelativeDate(364));
          break;
        default:
          setFilterStartDate(getRelativeDate(0));
          break;
      }
    }
  }, [filter]); //eslint-disable-line

  const getViewOrdersLink = (): string => {
    let url = `/orders?start=${formatDate(filterStartDate)}&end=${formatDate(filterEndDate)}&status=${OrderStatus.PENDING},${OrderStatus.UNSHIPPED},${OrderStatus.PARTIALLY_SHIPPED},${
      OrderStatus.SHIPPED
    }`;
    if (filter?.userStoreIds != null && filter?.userStoreIds.length > 0) {
      url += `&stores=${filter.userStoreIds.join(",")}`;
    }

    return url;
  };
  return (
    <React.Fragment>
      <Col xl={3} md={6} className="same-height">
        <Card className="card-animate">
          <CardBody>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 overflow-hidden">
                <p className="fw-medium text-muted text-truncate mb-0">{t("Dashboard.Widgets.Label.TotalRevenue")}</p>
              </div>
              <div className="flex-shrink-0">
                {totalRevenue.changeRate >= 0 ? (
                  <h5 className="fs-14 mb-0 text-success d-flex">
                    <i className="fs-13 align-middle ri-arrow-right-up-line"></i>
                    <DisplayNumber value={totalRevenue?.changeRate} decimals={2} prefix={` +`} suffix=" %" busy={loading.list} />
                  </h5>
                ) : (
                  <h5 className="fs-14 mb-0 text-danger d-flex">
                    <i className="fs-13 align-middle ri-arrow-right-down-line"></i>
                    <DisplayNumber value={Math.abs(totalRevenue?.changeRate)} decimals={2} prefix={` -`} suffix=" %" busy={loading.list} />
                  </h5>
                )}
              </div>
            </div>
            <div className="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                  <span className="counter-value">
                    <DisplayPrice source={currencyCode} value={totalRevenue?.value} decimals={2} notation="abbreviated" countUp />
                  </span>
                </h4>
                <div className="vr"></div>
              </div>
              <div className="avatar-sm flex-shrink-0">
                <span className="avatar-title rounded fs-3 bg-success-subtle">
                  <i className="text-success bx bx-dollar-circle"></i>
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>

      <Col xl={3} md={6} className="same-height">
        <Card className="card-animate">
          <CardBody>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 overflow-hidden">
                <p className="fw-medium text-muted text-truncate mb-0">{t("Dashboard.Widgets.Label.TotalOrders")}</p>
              </div>
              <div className="flex-shrink-0">
                {totalOrders?.changeRate >= 0 ? (
                  <h5 className="fs-14 mb-0 text-success d-flex">
                    <i className="fs-13 align-middle ri-arrow-right-up-line"></i>
                    <DisplayNumber value={totalOrders?.changeRate} decimals={2} prefix={` +`} suffix=" %" busy={loading.list} />
                  </h5>
                ) : (
                  <h5 className="fs-14 mb-0 text-danger d-flex">
                    <i className="fs-13 align-middle ri-arrow-right-down-line"></i>
                    <DisplayNumber value={Math.abs(totalOrders?.changeRate)} decimals={2} prefix={` -`} suffix=" %" busy={loading.list} />
                  </h5>
                )}
              </div>
            </div>
            <div className="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h4 className="fs-22 fw-semibold ff-secondary mb-4 d-flex align-items-center">
                  <span className="counter-value">
                    <CountUp start={0} decimals={0} duration={2} end={totalOrders.value} />
                  </span>
                  <span className="badge rounded-pill bg-success-subtle text-success ms-2 fs-15">
                    <CountUp start={0} suffix={` ${t("Unit")}`} decimals={0} duration={2} end={totalOrders.units} />
                  </span>
                </h4>
                <Link to={getViewOrdersLink()} className="link-secondary text-decoration-underline">
                  {t("Dashboard.Widgets.Link.ViewOrders")}
                </Link>
              </div>
              <div className="avatar-sm flex-shrink-0">
                <span className="avatar-title rounded fs-3 bg-info-subtle">
                  <i className="text-info bx bx-shopping-bag"></i>
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>

      <Col xl={3} md={6} className="same-height">
        <Card className="card-animate">
          <CardBody>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 overflow-hidden">
                <p className="fw-medium text-muted text-truncate mb-0">
                  {t("Dashboard.Widgets.Label.Profit")}
                  <i className="mdi mdi-slash-forward"></i>
                  {t("Dashboard.Widgets.Label.ROI")}
                  <i className="mdi mdi-slash-forward"></i>
                  {t("Dashboard.Widgets.Label.Margin")}
                </p>
              </div>
            </div>
            <div className="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h4 className="fs-22 fw-semibold ff-secondary mb-4 d-flex align-items-center">
                  <span className="counter-value">
                    <DisplayPrice source={currencyCode} value={profitRoiMargin.profit} decimals={2} notation="abbreviated" countUp />
                  </span>
                  <span className="badge rounded-pill bg-success-subtle text-success ms-2 fs-13">
                    <CountUp start={0} decimals={0} duration={2} end={profitRoiMargin.roi} suffix="%" />

                    <span className="mx-1">
                      <i className="mdi mdi-slash-forward"></i>
                    </span>

                    <CountUp start={0} decimals={0} duration={2} end={profitRoiMargin.margin} suffix="%" />
                  </span>
                </h4>
                <div className="vr"></div>
              </div>
              <div className="avatar-sm flex-shrink-0">
                <span className="avatar-title rounded fs-3 bg-secondary-subtle">
                  <i className="text-secondary bx bx-wallet"></i>
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>

      <Col xl={3} md={6} className="same-height">
        <Card className="card-animate">
          <CardBody>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 overflow-hidden">
                <p className="fw-medium text-muted text-truncate mb-0">{t("Dashboard.Widgets.Label.Inventory")}</p>
              </div>

              {statistics.inventoryWidget.itemsMissingCost > 0 && (
                <div className="flex-shrink-0">
                  <Link id="linkItemsMissingCost" className="badge rounded-pill bg-danger-subtle link-danger d-flex align-items-center" to={"/inventory/updateInventory"}>
                    <i className="mdi mdi-information me-1 fs-12"></i>
                    {t("Dashboard.Widgets.Label.XProductsMissingCosts", {value: statistics.inventoryWidget.itemsMissingCost})}
                  </Link>
                  <DefaultUncontrolledTooltip target="linkItemsMissingCost">{t("Dashboard.Widgets.Info.ItemsMissingCost")}</DefaultUncontrolledTooltip>
                </div>
              )}
            </div>

            <div className="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h4 className="fs-22 fw-semibold ff-secondary mb-4 d-flex align-items-center">
                  <span className="counter-value">
                    <CountUp start={0} suffix=" SKUs" decimals={0} duration={2} end={inventoryWidget.totalSku} />
                  </span>
                  <span id="inventoryInStock" className="badge rounded-pill bg-success-subtle text-success ms-2 fs-15">
                    <CountUp start={0} suffix={` ${t("Dashboard.Widgets.Label.InStock")}`} decimals={0} duration={2} end={inventoryWidget.inStock} />
                  </span>
                  <DefaultUncontrolledTooltip target="inventoryInStock">
                    {t("Dashboard.Widgets.Label.EstimatedStockValue")}:{" "}
                    <b>
                      <u>
                        {numberFormatter(finance.estimatedStockValue)} {filter?.currencyCode}
                      </u>
                    </b>
                  </DefaultUncontrolledTooltip>
                </h4>
                <Link to="/inventory" className="link-secondary text-decoration-underline">
                  {t("Dashboard.Widgets.Link.ViewInventory")}
                </Link>
              </div>
              <div className="avatar-sm flex-shrink-0">
                <span className="avatar-title rounded fs-3 bg-warning-subtle">
                  <i className="text-warning bx bx-purchase-tag-alt"></i>
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default Totals;
