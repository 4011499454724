import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {DeleteSearchCommand, MarkSearchReviewedCommand} from "api/command";
import {ApiError, LoadingStates, PagedList} from "helpers/types";
import {UserSearch} from "models/user_search";

type SearchesLoadingStates = LoadingStates<"list" | "update" | "save" | "delete" | "filter" | "reanalyze" | "markReviewed">;

export type SearchesState = {
  searchesList: Partial<PagedList<UserSearch>>;
  loading: SearchesLoadingStates;
  error?: ApiError;
};

const initialState: SearchesState = {
  searchesList: {items: []},
  loading: {
    list: false,
    filter: true,
    update: false,
    save: false,
    delete: false,
    reanalyze: false,
    markReviewed: false,
  },
  error: {} as ApiError,
};

const SearchesSlice = createSlice({
  name: "Searches",
  initialState,
  reducers: {
    setSearchesList(state, action: PayloadAction<PagedList<UserSearch>>) {
      state.searchesList = action.payload;
    },
    markReviewedItem(state, action: PayloadAction<MarkSearchReviewedCommand>) {
      const {userSearchId, isReviewed} = action.payload;
      state.searchesList = {
        ...state.searchesList,
        items: state.searchesList.items?.map((item) => (item.userSearchId === userSearchId ? {...item, isReviewed: isReviewed} : item)),
      };
    },
    deleteOrRestoreItem(state, action: PayloadAction<DeleteSearchCommand>) {
      state.searchesList = {
        ...state.searchesList,
        items: state.searchesList.items?.map((item) => (item.userSearchId === action.payload.searchId ? {...item, deleted: action.payload.deleted} : item)),
      };
    },
    updateSearchItem(state, action: PayloadAction<UserSearch>) {
      const {userSearchId} = action.payload;
      state.searchesList = {
        ...state.searchesList,
        items: state.searchesList.items?.map((item) => (item.userSearchId === userSearchId ? action.payload : item)),
      }
    },
    loading(state, action: PayloadAction<[keyof SearchesLoadingStates, boolean]>) {
      const [operation, loadingState] = action.payload;
      state.loading[operation] = loadingState;
    },
    apiError(state, action: PayloadAction<ApiError>) {
      state.error = action.payload;
    },
    reset(state) {
      state.loading = initialState.loading;
      state.searchesList = initialState.searchesList;
      state.error = initialState.error;
    },
  },
});

export const {setSearchesList, markReviewedItem, deleteOrRestoreItem, updateSearchItem, loading, apiError, reset} = SearchesSlice.actions;

export default SearchesSlice.reducer;
