import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {Modal, ModalHeader, ModalBody, Col, Row, Button, ModalFooter, Form, Label, Spinner} from "reactstrap";
import {UserShipment} from "models/user_shipment";
import {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {editUserShipment} from "slices/thunks";
import {getToday} from "helpers/utilities";
import {ShipmentSlice} from "slices/shipment/selector";
import {ShipmentStatus} from "models/enums/shipment_status";
import {EditUserShipmentCommand} from "api/command";
import ValidatedDate from "Components/Common/ValidatedDate";
import ValidatedInput from "Components/Common/ValidatedInput";
import * as Yup from "yup";
import {createTypedModal} from "helpers/modal_helpers";

interface ModalData {
  shipment: UserShipment;
}
export const EditShipmentModal = createTypedModal<ModalData>("edit_shipment");

const EditShipment = () => {
  const {t} = useTranslation();
  const {open, data} = EditShipmentModal.useModal();
  const [mode, setMode] = useState<"sm" | "lg">("sm");
  const dispatch = useDispatch();

  const {loading} = useSelector(ShipmentSlice);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      shipmentName: data?.shipment?.name,
      amazonShipmentId: data?.shipment?.amazonShipmentId,
      shipDate: data?.shipment?.shipDate,
    },
    validationSchema: Yup.object().shape({
      shipmentName: Yup.string()
        .required(t("Shipments.NameRequired"))
        .max(100, t("Shipments.NameMaxLength")),
      amazonShipmentId: Yup.string().max(50, t("Shipments.AmazonShipmentIdMaxLength")),
      shipDate: Yup.date()
        .min(new Date("2023-01-01"), t("Start date must be later than January 01, 2023"))
        .max(getToday(), "End date cannot be later than today")
        .typeError(t("Invalid date type"))
        .test("required-ship-date", t("Shipments.ShipDateRequired"), (shipDate: any) => {
          if (data?.shipment?.status === ShipmentStatus.SHIPPED && !shipDate) {
            return false;
          } else {
            return true;
          }
        }),
    }),
    onSubmit: (values: any) => {
      let request: EditUserShipmentCommand = {
        userShipmentId: data!.shipment.userShipmentId,
        shipmentName: values.shipmentName,
        amazonShipmentId: values.amazonShipmentId,
        shipDate: values.shipDate,
      };

      const editPromise = editUserShipment(request)(dispatch);
      editPromise.then((isSuccess) => {
        if (isSuccess) {
          toggle();
        }
      });
    },
  });

  const toggle = () => {
    EditShipmentModal.close();
    validation.resetForm();
  };

  const onLoad = useCallback(() => {
    if (data?.shipment) {
      if (data?.shipment.status >= ShipmentStatus.SHIPPED && data?.shipment.status < ShipmentStatus.COMPLETED) {
        setMode("lg");
      } else {
        setMode("sm");
      }
    }
  }, [data]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <Modal id="showEditShipmentStatusModal" isOpen={open} toggle={toggle} fade={true} centered={true} size={mode}>
      <ModalHeader className="p-3" toggle={toggle}>
        {t("Shipments.EditShipment")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row className="g-3">
            <Col xs={12} lg={mode === "lg" ? 4 : 12}>
              <Label>{t("Shipments.Name")}</Label>
              <ValidatedInput validation={validation} type="text" field="shipmentName" maxLength={100} placeholder={t("Shipments.Name")} disableValidationUI />
            </Col>
            {mode === "lg" && (
              <>
                <Col xs={12} lg={4} md={6}>
                  <Label>{t("Shipments.AmazonShipmentId")}</Label>
                  <ValidatedInput validation={validation} type="text" field="amazonShipmentId" maxLength={50} placeholder={t("Shipments.AmazonShipmentId")} disableValidationUI />
                </Col>
                <Col xs={12} lg={4} md={6}>
                  <Label htmlFor="shipDate">{t("Shipments.ShipDate")}</Label>
                  <ValidatedDate field="shipDate" className="w-100" validation={validation} value={validation.values.shipDate}></ValidatedDate>
                </Col>
              </>
            )}
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <div className="hstack gap-2 justify-content-end">
          <Button type="button" className="btn btn-light" disabled={loading.update} onClick={toggle}>
            {t("Close")}
          </Button>
          <Button
            type="submit"
            className="btn btn-success"
            disabled={loading.update}
            onClick={() => {
              validation.setFieldTouched("shipDate");
              validation.setFieldTouched("amazonShipmentId");

              if (validation.isValid) {
                validation.handleSubmit();
              }
            }}
          >
            {loading.update && <Spinner size="sm" className="me-2 align-middle"></Spinner>}
            {t("Save")}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default EditShipment;
