import {useCallback, useEffect, useState} from "react";
import {AnalyzeType, ReAnalyzeModal} from "Components/Common/Modals/ReAnalyze";
import {useProfile} from "Components/Hooks/useProfile";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {getToday} from "helpers/utilities";
import {Badge, Button, Card, CardBody, Col, Spinner} from "reactstrap";
import {SummaryCard} from "pages/Searches/SearchResults/Item/Components/SummaryCard";
import {AmazonMarketplace} from "helpers/marketplace_helper";
import {ProductDetailsSlice} from "slices/product-details/selector";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import Moment from "react-moment";
import moment from "moment";
import classNames from "classnames";
import DisplayPrice from "Components/Common/DisplayPrice";
import GeneralCheckAndAlerts from "./_GeneralCheckAndAlerts";
import i18n from "i18n";
import _ from "lodash";
import {AmazonBusinessModel} from "models/enums/user_search_type";

interface AnalysisSummaryProps {
  sourceMarketplace?: AmazonMarketplace;
  destinationMarketplace?: AmazonMarketplace;
}
const AnalysisSummary = ({sourceMarketplace, destinationMarketplace}: AnalysisSummaryProps) => {
  const {t} = useTranslation();
  const {userProfile, isUserLoggedIn} = useProfile();
  const [_48HourPassed, set48HourPassed] = useState<boolean>(false);
  const {details, loading} = useSelector(ProductDetailsSlice);

  useEffect(() => {
    if (details) {
      if (getToday().diff(moment.tz(details.analysisSummary?.analysisDate, userProfile?.timezone!), "hours") > 48) {
        set48HourPassed(true);
      } else {
        set48HourPassed(false);
      }
    }
  }, [details]); //eslint-disable-line

  const getDecisionReasonCountLabel = useCallback(() => {
    const length = details.analysisSummary?.decisionReasons?.split(",").length || 0;
    return length === 1 ? t("ProductDetails.AnalysisSummary.Label.Reason", {value: length}) : t("ProductDetails.AnalysisSummary.Label.ReasonPlural", {value: length});
  }, [details.analysisSummary]); //eslint-disable-line

  return (
    <Card className="card-animate">
      <CardBody>
        <div className="mt-2">
          <div className="d-flex justify-content-center align-items-center fw-medium fs-16">
            {loading.list || sourceMarketplace === undefined || destinationMarketplace === undefined ? (
              <Spinner size="sm" className="mb-2" />
            ) : (
              <>
                <i className="bx bxs-location-plus text-success fs-20 me-1"></i>
                {details.productInfo?.amazonBusinessModel !== AmazonBusinessModel.WHOLESALE && (
                  <span>
                    <span className="text-nowrap">{sourceMarketplace?.countryName} </span>
                    <img src={sourceMarketplace?.flag} alt={`${sourceMarketplace?.countryName} Flag`} className="rounded-circle" height="20" />
                  </span>
                )}

                <span className="mx-2">
                  <i className="ri-arrow-right-line fs-20 align-bottom"></i>
                </span>
                <span className="text-nowrap">
                  <img src={destinationMarketplace?.flag} alt={`${destinationMarketplace?.countryName} Flag`} className="rounded-circle" height="20" /> {t(destinationMarketplace?.countryName ?? "")}
                  {details.productInfo?.storeName && <span className="text-nowrap">{` - ${t(details.productInfo?.storeName)}`} </span>}
                </span>
              </>
            )}
          </div>
          <div className="d-flex justify-content-center align-items-center fs-14 mt-1">
            <span className="me-1 text-muted">{t("ProductDetails.AnalysisSummary.Label.AnalysisDate")}:</span>
            <span className="d-flex align-items-center">
              {
                <Moment className={`fw-medium ${_48HourPassed ? "text-warning" : "text-success"}`} locale={i18n.language} fromNow>
                  {details.analysisSummary?.analysisDate}
                </Moment>
              }

              <i className={`text-success fs-18 ms-1 ${_48HourPassed ? "text-warning bx bx-calendar-x " : "text-success bx bx-calendar-check "}`}></i>
            </span>
            <div className="d-flex align-items-center ms-3">
              {isUserLoggedIn() && (
                <Button
                  className="btn btn-soft-secondary px-2 py-0 d-flex align-items-center fw-semibold"
                  onClick={() => {
                    ReAnalyzeModal.open({
                      asin: details.productInfo.asin!,
                      store: details.productInfo.userStoreId || _.toUpper(destinationMarketplace?.domain)!,
                      businessModel: details.productInfo.amazonBusinessModel,
                      type: AnalyzeType.SEARCH_PRODUCT,
                      searchName: details.productInfo.asin,
                    });
                  }}
                >
                  <i className="mdi mdi-chart-timeline-variant-shimmer fs-18 me-1"></i>
                  {t("ProductDetails.AnalysisSummary.Button.ReAnalyze")}
                </Button>
              )}
            </div>
          </div>
          <div className="my-4 d-flex justify-content-center">
            <div className="hstack d-flex flex-wrap justify-content-start gap-3">
              <SummaryCard
                icon="mdi mdi-chart-timeline-variant-shimmer"
                label={t("ProductDetails.AnalysisSummary.Label.Decision")}
                content={
                  <div className="d-flex align-items-center">
                    {details.analysisSummary?.decision ? (
                      <>
                        <Badge
                          color={details.analysisSummary.decision === "Sellable" ? "success" : details.analysisSummary.decision === "Recheck" ? "warning" : "danger"}
                          className="rounded-pill fs-11"
                        >
                          {t(details.analysisSummary.decision)}
                          {details.analysisSummary.decision === "Sellable" && <i className="bx bx-check ms-1"></i>}
                        </Badge>
                      </>
                    ) : (
                      t("NA")
                    )}
                  </div>
                }
              />

              <SummaryCard
                icon="mdi mdi-finance"
                label={t("ProductDetails.AnalysisSummary.Label.SaleCount")}
                content={
                  <div className="d-flex align-items-center">
                    {details?.analysisSummary.saleCount == null ? (
                      <span className="text-muted">{t("NA")}</span>
                    ) : (
                      <>
                        <span>{details?.analysisSummary?.saleCount}</span>
                        <small className="fs-13 text-muted">
                          <i className="mdi mdi-slash-forward"></i>
                          {t("month")}
                        </small>
                        <i
                          id="PopoverPrecisionSummary"
                          className={`ms-1 mdi ${
                            details?.salesAnalysis?.precision === "VeryClose"
                              ? "mdi-check-circle text-secondary"
                              : details?.salesAnalysis?.precision === "Medium"
                              ? "mdi-progress-check text-secondary fs-18"
                              : details?.salesAnalysis?.precision === "Prediction"
                              ? "mdi-progress-clock text-danger fs-18"
                              : ""
                          }`}
                        ></i>
                        <DefaultUncontrolledTooltip target={"PopoverPrecisionSummary"}>
                          [{t("SearchResults.Summary.SaleCount.Precision")}: {t("Very Close")}] {t(`SearchResults.Summary.SaleCount.Precisions.${details?.analysisSummary?.precision}`)}{" "}
                        </DefaultUncontrolledTooltip>
                      </>
                    )}
                  </div>
                }
              />
              <SummaryCard
                icon="mdi mdi-currency-usd"
                label={t("ProductDetails.AnalysisSummary.Label.ProfitRoi")}
                content={
                  <>
                    {details?.analysisSummary?.profit != null ? (
                      <span className={classNames({"text-danger": details?.analysisSummary?.profit < 0}, "float-start")}>
                        {<DisplayPrice source={details?.productInfo.currencyCodeTarget} value={details?.analysisSummary?.profit} decimals={2} notation="abbreviated" />}
                      </span>
                    ) : (
                      <span>{t("NA")}</span>
                    )}
                    {details?.analysisSummary?.roi != null ? (
                      <span className={`badge rounded-pill fs-15 ms-2 ${details?.analysisSummary?.roi < 0 ? "bg-danger-subtle text-danger" : "bg-success-subtle text-success"}`}>
                        {" "}
                        %{details?.analysisSummary?.roi}
                      </span>
                    ) : (
                      ""
                    )}
                  </>
                }
              />
              <SummaryCard
                icon="mdi mdi-layers-triple-outline"
                label={t("ProductDetails.AnalysisSummary.Label.Variation")}
                content={
                  <>
                    {details?.variationAnalysis?.variationCount != null ? (
                      <span
                        className={
                          details?.variationAnalysis.variationLevel === "Low"
                            ? "badge bg-success rounded-pill fs-14 common-css"
                            : details?.variationAnalysis.variationLevel === "High"
                            ? "badge bg-warning rounded-pill fs-14 common-css"
                            : ""
                        }
                      >
                        {details?.variationAnalysis.variationCount}
                        {details?.variationAnalysis.variationLevel === "Low" && <i className="bx bx-check ms-1"></i>}
                      </span>
                    ) : (
                      <span>{t("NA")}</span>
                    )}
                  </>
                }
              />
              <SummaryCard
                icon={
                  details?.competitorAnalysis?.competitionLevel === "Weak"
                    ? "mdi mdi-speedometer-slow text-success"
                    : details?.competitorAnalysis?.competitionLevel === "Medium"
                    ? "mdi mdi-speedometer-medium text-warning"
                    : "mdi mdi-speedometer text-danger"
                }
                label={t("ProductDetails.AnalysisSummary.Label.Competition")}
                content={
                  <>
                    {details?.competitorAnalysis?.competitionLevel ? (
                      <Badge
                        color={details?.competitorAnalysis.competitionLevel === "Weak" ? "success" : details?.competitorAnalysis.competitionLevel === "Medium" ? "warning" : "danger"}
                        className="rounded-pill fs-14"
                      >
                        {t(details?.competitorAnalysis.competitionLevel)}
                        {details?.competitorAnalysis.competitionLevel === "Weak" && <i className="bx bx-check ms-1"></i>}
                      </Badge>
                    ) : (
                      t("NA")
                    )}
                  </>
                }
              />

              <SummaryCard
                icon="mdi mdi-trophy-outline text-secondary"
                label={`BSR ${details?.analysisSummary.targetMarketplace ? " - " + details?.analysisSummary.targetMarketplace.replace("com.", "").toUpperCase() : ""}`}
                content={<>{details?.productInfo?.bsr ? details?.productInfo?.bsr?.toLocaleString() : t("NA")}</>}
              />
            </div>
          </div>

          {details.analysisSummary?.decisionReasons && (
            <div className="d-flex justify-content-center">
              <Col xxl={8} xl={10} sm={12}>
                <Card className="mt-3 shadow-lg">
                  <CardBody className="pb-0">
                    <h5 className="fs-15">
                      {t("ProductDetails.AnalysisSummary.Label.DecisionReasons")} <span className="ms-1 fs-12 text-secondary">{getDecisionReasonCountLabel()}</span>
                    </h5>
                    <div className="mt-3">
                      {details.analysisSummary?.decisionReasons?.split(",").map((reason, index) => {
                        // By default, use the normal name
                        let reasonName = t(`DecisionReasons.${reason}.Name`);
                        let reasonDescription = t(`DecisionReasons.${reason}.Description`);

                        // If reason is "VariationCountRecheck" and variationCount is between 2 and 5, use the special name
                        if (
                          reason === "VariationCountRecheck" &&
                          details?.variationAnalysis?.variationCount != null &&
                          details.variationAnalysis.variationCount >= 2 &&
                          details.variationAnalysis.variationCount <= 5
                        ) {
                          reasonName = t(`DecisionReasons.${reason}.Low.Name`);
                          reasonDescription = t(`DecisionReasons.${reason}.Low.Description`);
                        }

                        return (
                          <div key={index} className="d-flex">
                            <div className="flex-shrink-0 me-2">
                              <i className={`bx ${reason.endsWith("Recheck") || reason.endsWith("Warning") ? "bxs-error text-warning" : "bxs-x-circle text-danger"} fs-20`}></i>
                            </div>
                            <div className="flex-grow-1">
                              <h5>{reasonName}</h5>
                              <p className="text-muted">{reasonDescription}</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </div>
          )}

          <div className="d-flex justify-content-center">
            <Col xxl={8} xl={10} sm={12}>
              <GeneralCheckAndAlerts />
            </Col>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default AnalysisSummary;
