import React, { memo } from 'react';
import { RouteConfig } from './allRoutes';

interface RouteWrapperProps {
  route: RouteConfig;
}

const RouteWrapper: React.FC<RouteWrapperProps> = memo(({ route }) => {
  const Layout = route.layout;
  if (!Layout) {
    return <>{route.component}</>;
  }

  return <Layout>{route.component}</Layout>;
});

RouteWrapper.displayName = 'RouteWrapper';

export default RouteWrapper;