import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Col, Row, Spinner, Form, Label} from "reactstrap";
import {useFormik} from "formik";
import {RefObject} from "react";
import {useTranslation} from "react-i18next";
import {ShipmentListRef} from "..";
import {creteNewShipment} from "slices/thunks";
import {useDispatch, useSelector} from "react-redux";
import {CommonSliceSelector} from "slices/common/selector";
import {createTypedModal} from "helpers/modal_helpers";
import {ShipmentSlice} from "slices/shipment/selector";
import {ShareType} from "models/enums/share_type";
import {SaveNewUserShipmentCommand} from "api/command";
import ValidatedSelect from "Components/Common/ValidatedSelect";
import ValidatedInput from "Components/Common/ValidatedInput";
import AccessDropdown from "Components/Common/AccessDropdown";
import * as Yup from "yup";
import {useProfile} from "Components/Hooks/useProfile";
import { updateUIPreferences } from "slices/account/thunk";

interface ModalData {
  listRef: RefObject<ShipmentListRef>;
  targetMarketplace?: string;
}

export const NewShipmentModal = createTypedModal<ModalData>("new_shipment");
const NewShipment = () => {
  const {t} = useTranslation();
  const {open, data} = NewShipmentModal.useModal();
  const {userProfile} = useProfile();
  const dispatch = useDispatch();

  const {userStores, activeUserStoreOptions} = useSelector(CommonSliceSelector);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      shipmentName: "",
      userStoreId: "",
      shareType: userProfile?.shareType,
    },
    validationSchema: Yup.object().shape({
      shipmentName: Yup.string()
        .required(t("Shipments.NameRequired"))
        .max(50, t("Shipments.NameMaxLength")),
      userStoreId: Yup.string().required(t("Shipments.StoreRequired")),
      shareType: Yup.string(),
    }),
    onSubmit: (values: any) => {
      const store = userStores.find((store) => store.userStoreId === values.userStoreId);
      let saveNewUserShipmentRequest: SaveNewUserShipmentCommand = {
        shipmentName: values.shipmentName,
        marketplace: store?.marketplace || "",
        userStoreId: values.userStoreId,
        shareType: values.shareType,
      };

      const savePromise = creteNewShipment(saveNewUserShipmentRequest)(dispatch);
      savePromise.then((isSuccess) => {
        if (isSuccess) {
          data?.listRef.current?.reload();
          toggle();
        }
      });
    },
  });

  const {loading} = useSelector(ShipmentSlice);

  const toggle = () => {
    NewShipmentModal.close();
    validation.resetForm();
  };

  const handleAccessDropdownChange = (shareType: ShareType) => {
    if (validation.values.shareType !== shareType) {
      updateUIPreferences({shareType: shareType})(dispatch);
      validation.setFieldValue("shareType", shareType);
    }
  };

  return (
    <>
      <Modal id="showNewShipmentModal" className="modal-md" isOpen={open} toggle={toggle} fade={true} centered={true}>
        <ModalHeader className="p-3" toggle={toggle}>
          {t("Shipments.NewShipment")}
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row className="g-3">
              <Col xs={12}>
                <Label htmlFor="shipmentName">{t("Shipments.Name")}</Label>
                <ValidatedInput validation={validation} type="text" field="shipmentName" maxLength={100} placeholder={t("Shipments.Name")} disableValidationUI />
              </Col>
              <Col xs={12}>
                <Label htmlFor="userStoreId">{t("Marketplace")}</Label>
                <ValidatedSelect
                  options={data?.targetMarketplace ? [...activeUserStoreOptions.filter((store) => store.marketplace === data.targetMarketplace)] : [...activeUserStoreOptions]}
                  validation={validation}
                  field="userStoreId"
                  value={validation.values.userStoreId}
                  errorStyle="container"
                  optionStyle="marketplace"
                  valueStyle="marketplace"
                />
              </Col>
              {userProfile?.subscription === "Premium" && (
                <Col xs={12}>
                  <Label>{t("General Access")}</Label>
                  <AccessDropdown onChange={handleAccessDropdownChange} />
                </Col>
              )}
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <Button type="button" className="btn btn-light" disabled={loading.save} onClick={toggle}>
              {t("Close")}
            </Button>

            <Button
              type="submit"
              className="btn btn-success"
              disabled={loading.save}
              onClick={() => {
                validation.setFieldTouched("shipmentName");
                validation.setFieldTouched("userStoreId");

                if (validation.isValid) {
                  validation.handleSubmit();
                }
              }}
            >
              {loading.save && <Spinner size="sm" className="me-2 align-middle"></Spinner>}
              {t("Save")}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default NewShipment;
