import React, {useState} from "react";
import {Spinner} from "reactstrap"; // Assuming you're using reactstrap like in your code

interface ImageLoaderProps {
  src: string;
  alt: string;
  className?: string;
}

const ImageLoader: React.FC<ImageLoaderProps> = ({src, alt, className}) => {
  const [loading, setLoading] = useState(true);

  const handleImageLoaded = () => {
    setLoading(false);
  };

  return (
    <div className="position-relative w-100 h-100">
      {loading && (
        <div className="d-flex justify-content-center align-items-center w-100 h-100">
          <Spinner color="dark" size="sm" />
        </div>
      )}
      <img src={src} alt={alt} className={className} onLoad={handleImageLoaded} style={{opacity: loading ? 0.3 : 1}} />
    </div>
  );
};

export default ImageLoader;
