import Select, {SelectInstance} from "react-select";
import useSelectOptions, {SelectOptionsType} from "Components/Hooks/useSelectOptions";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useFormik} from "formik";
import {SearchResultFilter, SearchResultQuery} from "models/search_result";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {Button, Card, CardBody, Col, Collapse, Form, Label, Row, Spinner} from "reactstrap";
import {AmazonBusinessModel} from "models/enums/user_search_type";
import {changeDetailedModeVisibility, exportSearchResults} from "slices/search-result/thunk";
import {UserSearchResultsFilter} from "models/user_search_result_filter";
import {UserSearchStatus} from "models/enums/user_search_status";
import {AnalyzeType, ReAnalyzeModal} from "Components/Common/Modals/ReAnalyze";
import {FieldConfig, useUrlQuery} from "Components/Hooks/useUrlQuery";
import {DefinedFiltersModal} from "../Modals/DefinedFilter";
import {NumberRange} from "helpers/types";
import {ActiveMarketplaces} from "helpers/marketplace_helper";
import {SearchResultSlice} from "slices/search-result/selector";
import Restricted from "Components/Common/Restricted";
import ValidatedInput from "Components/Common/ValidatedInput";
import ValidatedMultiSelect from "Components/Common/ValidatedMultiSelect";
import ValidatedSelect from "Components/Common/ValidatedSelect";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import FilterChips from "Components/Common/FilterChips";
import ValidatedNumberRange from "Components/Common/ValidatedNumberRange";
import * as Yup from "yup";

interface FiltersProps {
  busy: boolean;
  fields: FieldConfig<SearchResultQuery>[];
}
const Filters = (props: FiltersProps) => {
  const {t} = useTranslation();
  const {searchId, favoriteId} = useParams();
  const dispatch = useDispatch();
  const selectRef = useRef<SelectInstance<SelectOptionsType | null>>(null);
  const {
    decisionSelectOptions,
    dateRangeSelectOptions,
    commonProductSelectOptions,
    restrictionCheckSelectOptions,
    priceAnalysisResultSelectOptions,
    saleCountPrecisionSelectOptions,
    competitionSelectOptions,
    businessModelSelectOptions,
    amazonCatalogStatusSelectOptions,
    isReviewedSelectOptions,
    multiSelectTranslations,
  } = useSelectOptions();
  const [definedFilterOptions, setDefinedFilterOptions] = useState<SelectOptionsType[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<UserSearchResultsFilter>();
  const [selectedFilterOption, setSelectedFilterOption] = useState<SelectOptionsType>();
  const [categoriesSelectOptions, setCategoriesSelectOptions] = useState<SelectOptionsType[]>([]);
  const [currencyTarget, setCurrencyTarget] = useState<string>("");
  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState<boolean>(false);
  const {loading, result, definedFilters, detailedModeVisibility} = useSelector(SearchResultSlice);

  const {search, favorite, categories} = result;
  const validation = useFormik({
    initialValues: {} as SearchResultQuery,
    enableReinitialize: false,
    validateOnChange: false,
    validationSchema: Yup.object<SearchResultFilter>({
      decision: Yup.array<String>().notRequired(),
      amazonCatalogStatus: Yup.array<String>().notRequired(),
      businessModels: Yup.array<String>().notRequired(),
      keyword: Yup.string().notRequired(),
      profitMarginRange: Yup.object<NumberRange>(),
      fbaSellerCountRange: Yup.object<NumberRange>(),
      saleCountRange: Yup.object<NumberRange>().notRequired(),
      priceChangeRange: Yup.object<NumberRange>().notRequired(),
      roiRange: Yup.object<NumberRange>().notRequired(),
      fbmSellerCountRange: Yup.object<NumberRange>().notRequired(),
      bsrRange: Yup.object<NumberRange>().notRequired(),
      weightRange: Yup.object<NumberRange>().notRequired(),
      profitRange: Yup.object<NumberRange>().notRequired(),
      remoteFbaSellerCountRange: Yup.object<NumberRange>().notRequired(),
      variationCountRange: Yup.object<NumberRange>().notRequired(),
      sellPriceRange: Yup.object<NumberRange>().notRequired(),
      purchasePriceRange: Yup.object<NumberRange>().notRequired(),
      amazonNotListedSince: Yup.string().notRequired(),
      competition: Yup.array<String>().notRequired(),
      priceAnalysisResult: Yup.array<String>().notRequired(),
      saleCountPrecision: Yup.array<String>().notRequired(),
      productAge: Yup.string(),
      potentialBrandOwner: Yup.string(),
      isAmazonExist: Yup.string(),
      sameProduct: Yup.string(),
      customsRestriction: Yup.string(),
      sizeIsEligible: Yup.string(),
      isOldProduct: Yup.string(),
      fbaEligibility: Yup.string(),
      restrictionCheck: Yup.string(),
      isReviewed: Yup.string(),
      categories: Yup.array<String>().notRequired(),
      sortBy: Yup.string().notRequired(),
    }),
    onSubmit: (values: SearchResultQuery) => {
      let query: SearchResultQuery = {
        action: "filtering",
        favoriteId: favoriteId,
        searchId: searchId,
        ...values,
      };
      updateQuery(query);
      setIsAdvancedSearchOpen(false);
    },
  });
  const {updateQuery} = useUrlQuery<SearchResultQuery>(props.fields, validation);

  const fieldSelectOptionsMap = useMemo(
    () => ({
      decision: decisionSelectOptions,
      amazonCatalogStatus: amazonCatalogStatusSelectOptions,
      competition: competitionSelectOptions,
      priceAnalysisResult: priceAnalysisResultSelectOptions,
      saleCountPrecision: saleCountPrecisionSelectOptions,
      amazonNotListedSince: dateRangeSelectOptions,
      productAge: dateRangeSelectOptions,
      potentialBrandOwner: commonProductSelectOptions,
      isAmazonExist: commonProductSelectOptions,
      sameProduct: commonProductSelectOptions,
      customsRestriction: commonProductSelectOptions,
      sizeIsEligible: commonProductSelectOptions,
      isOldProduct: commonProductSelectOptions,
      fbaEligibility: commonProductSelectOptions,
      restrictionCheck: restrictionCheckSelectOptions,
      isReviewed: isReviewedSelectOptions,
      amazonBusinessModels: businessModelSelectOptions,
      categories: categoriesSelectOptions,
    }),
    [
      decisionSelectOptions,
      amazonCatalogStatusSelectOptions,
      competitionSelectOptions,
      priceAnalysisResultSelectOptions,
      saleCountPrecisionSelectOptions,
      dateRangeSelectOptions,
      commonProductSelectOptions,
      businessModelSelectOptions,
      isReviewedSelectOptions,
      categoriesSelectOptions,
      restrictionCheckSelectOptions,
    ],
  );

  const prepareCategoriesOptions = useCallback(() => {
    if (categories) {
      let options: SelectOptionsType[] = categories.map((category) => {
        return {
          label: category.categoryName + " (" + category.count + ")",
          value: category.index.toString(),
        };
      });
      setCategoriesSelectOptions(options);
    }
  }, [categories]);

  const onLoad = useCallback(() => {
    validation.handleSubmit();
  }, []); // eslint-disable-line

  const handleOnSelectedFilterChange = useCallback(() => {
    if (selectedFilter) {
      const filter = definedFilters?.find((filter) => filter.userSearchResultsFilterId === selectedFilter?.userSearchResultsFilterId)?.filter;
      if (filter) {
        const filterObject: SearchResultQuery = JSON.parse(filter as any);
        let query = {
          action: "filtering",
          favoriteId: favoriteId,
          searchId: searchId,
          ...filterObject,
        } as SearchResultQuery;
        query.page = 1;
        query.pageSize = validation.values.pageSize;
        updateQuery(query);
      }
    }
  }, [selectedFilter]); // eslint-disable-line

  const handleClearDefinedFilter = () => {
    setSelectedFilter(undefined);
    setSelectedFilterOption(undefined);

    validation.resetForm();

    // Keep only necessary values like page, pageSize, etc.
    const newValues = {
      page: 1,
      pageSize: validation.values.pageSize,
      action: "filtering",
      searchId: searchId,
      favoriteId: favoriteId,
    } as SearchResultQuery;

    updateQuery(newValues);
    validation.setValues(newValues);
    validation.handleSubmit();
  };

  const updateDefinedFilterOptions = useCallback(() => {
    const array: SelectOptionsType[] = definedFilters.map((filter) => ({
      value: filter.filterName,
      label: filter.filterName,
    }));
    setDefinedFilterOptions(array);

    if (selectedFilter?.filterName && !array.find((filter) => filter.value === selectedFilter?.filterName)) {
      selectRef.current?.clearValue();
      setSelectedFilter(undefined);
      setSelectedFilterOption(undefined);
      validation.resetForm();
    }
  }, [definedFilters]); // eslint-disable-line

  const updateCurrencyTarget = useCallback(() => {
    let mp;
    if (search) {
      mp = ActiveMarketplaces.find((mp) => mp.marketplace === search.marketplaceTarget);
    } else if (favorite) {
      mp = ActiveMarketplaces.find((mp) => mp.marketplace === favorite.marketplace);
    }
    setCurrencyTarget(mp?.currency || "");
  }, [search, favorite]); // eslint-disable-line

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  useEffect(() => {
    prepareCategoriesOptions();
  }, [prepareCategoriesOptions]);

  useEffect(() => {
    updateDefinedFilterOptions();
  }, [updateDefinedFilterOptions]);

  useEffect(() => {
    updateCurrencyTarget();
  }, [updateCurrencyTarget]);

  useEffect(() => {
    handleOnSelectedFilterChange();
  }, [handleOnSelectedFilterChange]);

  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth >= 1024) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Initial check
    handleScroll();

    // Add scroll and resize event listeners
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);

    // Cleanup
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const offset = 60;
      const cardElement = document.querySelector("#search-results-filters-card")?.parentElement;

      // Only enable sticky behavior if window width is greater than 1024px (typical mobile breakpoint)
      if (cardElement && window.innerWidth >= 1024) {
        setIsSticky(window.scrollY > offset);
      } else {
        setIsSticky(false);
      }
    };

    // Initial check
    handleScroll();

    // Add scroll and resize event listeners
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);

    // Cleanup
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, []);

  return (
    <PlaceholderGlow busy={props.busy}>
      <Card id="search-results-filters-card" className={isSticky ? "shadow-lg" : ""} style={{position: isSticky ? "sticky" : "static", top: "115px", zIndex: 99}}>
        <CardBody>
          <div className="live-preview search-results-filters">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Row className="align-items-center d-flex gy-3">
                {/* If search page is rendered */
                searchId && (
                  <Col xl={3} lg={6} sm={12}>
                    <Select
                      ref={selectRef}
                      placeholder={t("SearchResults.Filters.DefinedFilterPlaceholder")}
                      options={definedFilterOptions}
                      onChange={(e: any) => {
                        if (e) {
                          const f = definedFilters?.find((f) => f.filterName === e.value);
                          setSelectedFilter(f);
                          setSelectedFilterOption({label: e.value, value: e.value});
                        } else {
                          handleClearDefinedFilter();
                        }
                      }}
                      value={selectedFilterOption}
                      isClearable
                    />
                  </Col>
                )}

                {/* If favorite page rendered */
                favoriteId && (
                  <Col xl={3} lg={6} sm={12}>
                    <ValidatedMultiSelect
                      options={businessModelSelectOptions}
                      validation={validation}
                      field="amazonBusinessModels"
                      value={validation.values.amazonBusinessModels}
                      translations={{...multiSelectTranslations, selectSomeItems: t("Business Model")}}
                      isClearable
                      hasSelectAll
                    />
                  </Col>
                )}
                <Col xl={3} lg={6} sm={12}>
                  <ValidatedMultiSelect
                    options={decisionSelectOptions}
                    validation={validation}
                    field="decision"
                    value={validation.values.decision}
                    translations={{...multiSelectTranslations, selectSomeItems: t("SearchResults.Filters.Decision")}}
                    submitOnChange
                    isClearable
                    hasSelectAll
                  />
                </Col>

                <Col>
                  <ValidatedInput type="text" placeholder={t("SearchResults.Filters.SearchPlaceholder")} validation={validation} field="keyword" maxLength={100} disableValidationUI />
                </Col>

                <Col xs="auto" className="ps-0 ps-xl-2 d-flex gap-2">
                  <Button
                    id="BtnExport"
                    type="button"
                    className="btn btn-light btn-icon waves-effect"
                    disabled={loading.export || loading.filter || loading.list || (search && search?.status !== UserSearchStatus.COMPLETED)}
                    onClick={() =>
                      exportSearchResults(
                        {searchId: searchId, favoriteId: favoriteId, filter: {...(validation.values as SearchResultFilter)}},
                        `SearchResults_${search?.name || favorite?.name || ""}`,
                      )(dispatch)
                    }
                  >
                    {loading.export ? <Spinner size="sm" className="align-middle"></Spinner> : <i className="ri-upload-2-fill fs-18 align-middle"></i>}
                  </Button>
                  <DefaultUncontrolledTooltip target="BtnExport">{search?.status !== UserSearchStatus.COMPLETED ? t("SearchResults.Tooltip.UnableToExport") : t("Export")}</DefaultUncontrolledTooltip>

                  <Button
                    id="BtnAdvancedFilter"
                    type="button"
                    color={isAdvancedSearchOpen ? "secondary" : "soft-secondary"}
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseAdvancedFilter"
                    className="btn-icon waves-effect waves-light"
                    onClick={() => setIsAdvancedSearchOpen(!isAdvancedSearchOpen)}
                  >
                    <i className="ri-filter-line fs-20 align-middle"></i>
                  </Button>
                  <DefaultUncontrolledTooltip target="BtnAdvancedFilter">{t("SearchResults.Filters.Button.AdvancedFilter")}</DefaultUncontrolledTooltip>

                  <Button
                    id="BtnDetailedView"
                    type="button"
                    color="soft-secondary"
                    className="btn-icon waves-effect waves-light"
                    onClick={() => changeDetailedModeVisibility(!detailedModeVisibility)(dispatch)}
                  >
                    {detailedModeVisibility ? <i className="ri-eye-fill fs-20 align-middle"></i> : <i className="ri-eye-off-fill align-bottom"></i>}
                  </Button>
                  {/* todo */}
                  <DefaultUncontrolledTooltip target="BtnDetailedView">
                    {detailedModeVisibility ? t("SearchResults.Filters.Button.DetailedViewOn") : t("SearchResults.Filters.Button.DetailedViewOff")}
                  </DefaultUncontrolledTooltip>

                  <Button
                    id="BtnReAnalyze"
                    type="button"
                    color="soft-secondary"
                    className="btn-icon waves-effect waves-light"
                    onClick={() => {
                      if (search) {
                        ReAnalyzeModal.open({
                          searchName: search?.name,
                          store: search.userStoreId || search.marketplaceTarget,
                          searchId: search.userSearchId,
                          businessModel: search.amazonBusinessModel,
                          type: AnalyzeType.SEARCH,
                        });
                      } else if (favorite) {
                        ReAnalyzeModal.open({
                          searchName: favorite.name,
                          businessModel: AmazonBusinessModel.CROSS_BORDER_ARBITRAGE,
                          store: favorite.marketplace,
                          favoriteId: favorite.userFavoriteId,
                          type: AnalyzeType.FAVORITE,
                        });
                      }
                    }}
                  >
                    <i className="mdi mdi-chart-timeline-variant-shimmer fs-20 align-middle"></i>
                  </Button>
                  <DefaultUncontrolledTooltip target="BtnReAnalyze">{t("SearchResults.Filters.Button.ReAnalyze")}</DefaultUncontrolledTooltip>
                </Col>
              </Row>
              <Collapse isOpen={isAdvancedSearchOpen} className="mt-4" id="collapseAdvancedFilter">
                <Row className="d-flex align-items-center gx-5 gy-1">
                  <Col xl={3} lg={6} sm={12} className="mb-2">
                    <Row className="d-flex align-items-center">
                      <Col xs={5}>
                        <Label size="sm">{t("SearchResults.Filters.ProfitMargin")}</Label>
                      </Col>
                      <Col xs={7}>
                        <ValidatedNumberRange field="profitMarginRange" validation={validation} size="sm" />
                      </Col>
                    </Row>
                  </Col>

                  <Col xl={3} lg={6} sm={12} className="mb-2">
                    <Row className="d-flex align-items-center">
                      <Col xs={5}>
                        <Label size="sm">{t("SearchResults.Filters.FBASellerCount")}</Label>
                      </Col>

                      <Col xs={7}>
                        <ValidatedNumberRange field="fbaSellerCountRange" validation={validation} size="sm" />
                      </Col>
                    </Row>
                  </Col>

                  <Col xl={3} lg={6} sm={12} className="mb-2">
                    <Row className="d-flex align-items-center">
                      <Col xs={5}>
                        <Label size="sm">{t("SearchResults.Filters.SaleCount")}</Label>
                      </Col>

                      <Col xs={7}>
                        <ValidatedNumberRange field="saleCountRange" validation={validation} size="sm" />
                      </Col>
                    </Row>
                  </Col>

                  <Col xl={3} lg={6} sm={12} className="mb-2">
                    <Row className="d-flex align-items-center">
                      <Col xs={5}>
                        <Label size="sm">{t("SearchResults.Filters.PriceChange")}</Label>
                      </Col>

                      <Col xs={7}>
                        <ValidatedNumberRange field="priceChangeRange" validation={validation} size="sm" />
                      </Col>
                    </Row>
                  </Col>

                  <Col xl={3} lg={6} sm={12} className="mb-2">
                    <Row className="d-flex align-items-center">
                      <Col xs={5}>
                        <Label size="sm">{t("SearchResults.Filters.ROI")}</Label>
                      </Col>

                      <Col xs={7}>
                        <ValidatedNumberRange field="roiRange" validation={validation} size="sm" />
                      </Col>
                    </Row>
                  </Col>

                  <Col xl={3} lg={6} sm={12} className="mb-2">
                    <Row className="d-flex align-items-center">
                      <Col xs={5}>
                        <Label size="sm">{t("SearchResults.Filters.FBM")}</Label>
                      </Col>

                      <Col xs={7}>
                        <ValidatedNumberRange field="fbmSellerCountRange" validation={validation} size="sm" />
                      </Col>
                    </Row>
                  </Col>

                  <Col xl={3} lg={6} sm={12} className="mb-2">
                    <Row className="d-flex align-items-center">
                      <Col xs={5}>
                        <Label size="sm">{t("SearchResults.Filters.BSR")}</Label>
                      </Col>

                      <Col xs={7}>
                        <ValidatedNumberRange field="bsrRange" validation={validation} size="sm" />
                      </Col>
                    </Row>
                  </Col>

                  <Col xl={3} lg={6} sm={12} className="mb-2">
                    <Row className="d-flex align-items-center">
                      <Col xs={5}>
                        <Label size="sm">{t("SearchResults.Filters.Weight")}</Label>
                      </Col>

                      <Col xs={7}>
                        <ValidatedNumberRange field="weightRange" validation={validation} size="sm" />
                      </Col>
                    </Row>
                  </Col>

                  <Col xl={3} lg={6} sm={12} className="mb-2">
                    <Row className="d-flex align-items-center">
                      <Col xs={5}>
                        <Label size="sm">{t("SearchResults.Filters.Profit", {marketplace: currencyTarget})}</Label>
                      </Col>

                      <Col xs={7}>
                        <ValidatedNumberRange field="profitRange" validation={validation} size="sm" />
                      </Col>
                    </Row>
                  </Col>

                  <Col xl={3} lg={6} sm={12} className="mb-2">
                    <Row className="d-flex align-items-center">
                      <Col xs={5}>
                        <Label size="sm">{t("SearchResults.Filters.RemoteFbaSeller")}</Label>
                      </Col>

                      <Col xs={7}>
                        <ValidatedNumberRange field="remoteFbaSellerCountRange" validation={validation} size="sm" />
                      </Col>
                    </Row>
                  </Col>

                  <Col xl={3} lg={6} sm={12} className="mb-2">
                    <Row className="d-flex align-items-center">
                      <Col xs={5}>
                        <Label size="sm">{t("SearchResults.Filters.VariationCount")}</Label>
                      </Col>

                      <Col xs={7}>
                        <ValidatedNumberRange field="variationCountRange" validation={validation} size="sm" />
                      </Col>
                    </Row>
                  </Col>

                  <Col xl={3} lg={6} sm={12} className="mb-2">
                    <Row className="d-flex align-items-center">
                      <Col xs={5}>
                        <Label size="sm">{t("SearchResults.Filters.SellPrice")}</Label>
                      </Col>

                      <Col xs={7}>
                        <ValidatedNumberRange field="sellPriceRange" validation={validation} size="sm" />
                      </Col>
                    </Row>
                  </Col>

                  <Col xl={3} lg={6} sm={12} className="mb-2">
                    <Row className="d-flex align-items-center">
                      <Col xs={5}>
                        <Label size="sm">{t("SearchResults.Filters.PurchasePrice")}</Label>
                      </Col>

                      <Col xs={7}>
                        <ValidatedNumberRange field="purchasePriceRange" validation={validation} size="sm" />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {// If search page rendered
                searchId && (
                  <Row className="d-flex align-items-center mt-4  gx-5">
                    <Col xl={2} lg={6} sm={12} className="mb-2">
                      <Label className="col-form-label-sm mb-0" htmlFor="amazonCatalogStatus">
                        {t("SearchResults.Filters.AmazonCatalogStatus")}
                      </Label>
                      <ValidatedMultiSelect
                        options={amazonCatalogStatusSelectOptions}
                        validation={validation}
                        field="amazonCatalogStatus"
                        value={validation.values.amazonCatalogStatus}
                        isClearable
                        hasSelectAll
                      />
                    </Col>
                    <Col xl={2} lg={6} sm={12} className="mb-2">
                      <Label className="col-form-label-sm mb-0" htmlFor="amazonNotListedSince">
                        {t("SearchResults.Filters.AmazonNotListedSince")}
                      </Label>
                      <ValidatedSelect
                        className="col-form-label-sm"
                        options={dateRangeSelectOptions}
                        validation={validation}
                        field="amazonNotListedSince"
                        value={validation.values.amazonNotListedSince}
                      />
                    </Col>

                    <Col xl={2} lg={6} sm={12} className="mb-2">
                      <Label className="col-form-label-sm mb-0" htmlFor="competition">
                        {t("SearchResults.Filters.Competition")}
                      </Label>
                      <ValidatedMultiSelect
                        className="col-form-label-sm"
                        options={competitionSelectOptions}
                        validation={validation}
                        field="competition"
                        value={validation.values.competition}
                        hasSelectAll
                      />
                    </Col>

                    <Col xl={2} lg={6} sm={12} className="mb-2">
                      <Label className="col-form-label-sm mb-0" htmlFor="priceAnalysisResult">
                        {t("SearchResults.Filters.PriceAnalysisResult")}
                      </Label>
                      <ValidatedMultiSelect
                        className="col-form-label-sm"
                        options={priceAnalysisResultSelectOptions}
                        validation={validation}
                        field="priceAnalysisResult"
                        value={validation.values.priceAnalysisResult}
                        hasSelectAll
                      />
                    </Col>

                    <Col xl={2} lg={6} sm={12} className="mb-2">
                      <Label className="col-form-label-sm mb-0" htmlFor="saleCountPrecision">
                        {t("SearchResults.Filters.SaleCountPrecision")}
                      </Label>
                      <ValidatedMultiSelect
                        className="col-form-label-sm"
                        options={saleCountPrecisionSelectOptions}
                        validation={validation}
                        field="saleCountPrecision"
                        value={validation.values.saleCountPrecision}
                        hasSelectAll
                      />
                    </Col>

                    <Col xl={2} lg={6} sm={12} className="mb-2">
                      <Label className="col-form-label-sm mb-0" htmlFor="productAge">
                        {t("SearchResults.Filters.ProductAge")}
                      </Label>
                      <ValidatedSelect className="col-form-label-sm" options={dateRangeSelectOptions} validation={validation} field="productAge" value={validation.values.productAge} />
                    </Col>

                    <Col xl={2} lg={6} sm={12} className="mb-2">
                      <Label className="col-form-label-sm mb-0" htmlFor="potentialBrandOwner">
                        {t("SearchResults.Filters.PotentialBrandOwner")}
                      </Label>
                      <ValidatedSelect
                        className="col-form-label-sm"
                        options={commonProductSelectOptions}
                        validation={validation}
                        field="potentialBrandOwner"
                        value={validation.values.potentialBrandOwner}
                      />
                    </Col>

                    <Col xl={2} lg={6} sm={12} className="mb-2">
                      <Label className="col-form-label-sm mb-0" htmlFor="isAmazonExist">
                        {t("SearchResults.Filters.IsAmazonExist")}
                      </Label>
                      <ValidatedSelect className="col-form-label-sm" options={commonProductSelectOptions} validation={validation} field="isAmazonExist" value={validation.values.isAmazonExist} />
                    </Col>

                    <Col xl={2} lg={6} sm={12} className="mb-2">
                      <Label className="col-form-label-sm mb-0" htmlFor="sameProduct">
                        {t("SearchResults.Filters.SameProduct")}
                      </Label>
                      <ValidatedSelect className="col-form-label-sm" options={commonProductSelectOptions} validation={validation} field="sameProduct" value={validation.values.sameProduct} />
                    </Col>
                    <Col xl={2} lg={6} sm={12} className="mb-2">
                      <Label className="col-form-label-sm mb-0" htmlFor="customsRestriction">
                        {t("SearchResults.Filters.CustomsRestriction")}
                      </Label>
                      <ValidatedSelect
                        className="col-form-label-sm"
                        options={commonProductSelectOptions}
                        validation={validation}
                        field="customsRestriction"
                        value={validation.values.customsRestriction}
                      />
                    </Col>

                    <Col xl={2} lg={6} sm={12} className="mb-2">
                      <Label className="col-form-label-sm mb-0" htmlFor="restrictionCheck">
                        {t("SearchResults.Filters.RestrictionCheck")}
                      </Label>
                      <ValidatedSelect
                        className="col-form-label-sm"
                        options={restrictionCheckSelectOptions}
                        validation={validation}
                        field="restrictionCheck"
                        value={validation.values.restrictionCheck}
                      />
                    </Col>

                    {search?.amazonBusinessModel !== AmazonBusinessModel.DROPSHIPPING && (
                      <Col xl={2} lg={6} sm={12} className="mb-2">
                        <Label className="col-form-label-sm mb-0" htmlFor="sizeIsEligible">
                          {t("SearchResults.Filters.SizeIsEligible")}
                        </Label>
                        <ValidatedSelect className="col-form-label-sm" options={commonProductSelectOptions} validation={validation} field="sizeIsEligible" value={validation.values.sizeIsEligible} />
                      </Col>
                    )}

                    <Col xl={2} lg={6} sm={12} className="mb-2">
                      <Label className="col-form-label-sm mb-0" htmlFor="isOldProduct">
                        {t("SearchResults.Filters.IsOldProduct")}
                      </Label>
                      <ValidatedSelect className="col-form-label-sm" options={commonProductSelectOptions} validation={validation} field="isOldProduct" value={validation.values.isOldProduct} />
                    </Col>
                    {search?.amazonBusinessModel !== AmazonBusinessModel.DROPSHIPPING && (
                      <Col xl={2} lg={6} sm={12} className="mb-2">
                        <Label className="col-form-label-sm mb-0" htmlFor="fbaEligibility">
                          {t("SearchResults.Filters.FbaEligibility")}
                        </Label>
                        <ValidatedSelect className="col-form-label-sm" options={commonProductSelectOptions} validation={validation} field="fbaEligibility" value={validation.values.fbaEligibility} />
                      </Col>
                    )}

                    <Col xl={2} lg={6} sm={12} className="mb-2">
                      <Label className="col-form-label-sm mb-0" htmlFor="isReviewed">
                        {t("SearchResults.Filters.IsReviewed")}
                      </Label>
                      <ValidatedSelect className="col-form-label-sm" options={isReviewedSelectOptions} validation={validation} field="isReviewed" value={validation.values.isReviewed} />
                    </Col>

                    <Col xl={3} lg={6} sm={12} className="mb-2">
                      <Label className="col-form-label-sm mb-0" htmlFor="categories">
                        {t("SearchResults.Filters.Categories")}
                      </Label>
                      <ValidatedMultiSelect
                        className="col-form-label-sm"
                        options={categoriesSelectOptions}
                        validation={validation}
                        field="categories"
                        value={validation.values.categories}
                        hasSelectAll
                      />
                    </Col>
                  </Row>
                )}

                <Col xs={12} className="d-flex justify-content-end align-items-end mt-3 mt-xl-0">
                  <div className="d-flex align-items-center gap-2">
                    <Restricted require="searchResult" create>
                      <>
                        <Button
                          color="info"
                          className="add-btn nowrap"
                          data-bs-toggle="modal"
                          data-bs-target="#showModal"
                          disabled={!validation.dirty}
                          onClick={() => {
                            DefinedFiltersModal.open({filterName: selectedFilter?.filterName || "", filters: {...(validation.values as SearchResultFilter)}});
                          }}
                        >
                          {selectedFilter ? (
                            <>
                              <i className="ri-pencil-line align-bottom me-1"></i> {t("SearchResults.Filters.Button.Update")}
                            </>
                          ) : (
                            <>
                              <i className="ri-save-2-fill align-bottom me-1"></i> {t("SearchResults.Filters.Button.Save")}
                            </>
                          )}
                        </Button>
                      </>
                    </Restricted>
                    <Button type="submit" color="secondary" disabled={loading.list} onClick={() => validation.setFieldValue("page", 1)}>
                      {loading.list ? <Spinner size="sm" className="me-2 align-middle"></Spinner> : <i className="ri-equalizer-fill me-1 align-bottom"></i>}
                      {t("SearchResults.Filters.Button.Apply")}
                    </Button>
                  </div>
                </Col>
              </Collapse>

              <FilterChips fields={props.fields} validation={validation} fieldSelectOptionsMap={fieldSelectOptionsMap} onClear={handleClearDefinedFilter}/>
            </Form>
          </div>
        </CardBody>
      </Card>
    </PlaceholderGlow>
  );
};

export default React.memo(Filters);
