import {ApplicationUser} from "models/application_user";
import {useMemo} from "react";
import {Navigate, Outlet, useLocation} from "react-router-dom";

interface PublicRoutesProps {
  user?: ApplicationUser;
  isTokenValid: boolean;
}
const PublicRoutes = ({user, isTokenValid}: PublicRoutesProps) => {
  const location = useLocation();

  const element = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    const auth = queryParams.get("auth");
    const returnUrl = queryParams.get("return_url");

    if (location.pathname === "/account/logout") {
      return <Outlet />;
    }

    if (user && isTokenValid) {
      if (location.pathname === "/account/login") {
        if (auth) {
          return <Outlet />;
        }
        return <Navigate to={returnUrl || "/"} replace />;
      }
    }

    return <Outlet />;
  }, [user, isTokenValid, location.pathname, location.search]);

  return element;
};

export default PublicRoutes;
