import React, {useCallback, useEffect, useState} from "react";
import {Button, Form, Input, Spinner} from "reactstrap";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import {useDebounce} from "Components/Hooks/useDebounce";
import {useDispatch, useSelector} from "react-redux";
import {getSearchQuery} from "slices/common/thunk";
import {SingleSearchModal} from "Components/Common/Modals/SingleSearch";
import {CommonSliceSelector} from "slices/common/selector";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import SimpleBar from "simplebar-react";
import errorImage from "assets/images/svg/product.svg";
import Highlight from "Components/Common/Highlight";
import _ from "lodash";
import { AmazonHelper } from "helpers/amazon_helper";

const ASIN_LENGTH = 10;
const AMAZON_ORDER_ID_LENGTH = 19;
const SearchOption = () => {
  const {t} = useTranslation();
  const [value, setValue] = useState<string>("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {loading, searchResponse} = useSelector(CommonSliceSelector);

  useEffect(() => {
    const searchOptions = document.getElementById("search-close-options") as HTMLElement;
    const dropdown = document.getElementById("search-dropdown") as HTMLElement;
    const searchInput = document.getElementById("search-options") as HTMLInputElement;

    const handleFocus = () => {
      const inputLength = searchInput.value.length;
      if (inputLength > 0) {
        dropdown.classList.add("show");
        searchOptions.classList.remove("d-none");
      } else {
        dropdown.classList.remove("show");
        searchOptions.classList.add("d-none");
      }
    };

    const handleKeyUp = () => {
      const inputLength = searchInput.value.length;
      if (inputLength > 0) {
        dropdown.classList.add("show");
        searchOptions.classList.remove("d-none");
      } else {
        dropdown.classList.remove("show");
        searchOptions.classList.add("d-none");
      }
    };

    const handleClick = () => {
      searchInput.value = "";
      dropdown.classList.remove("show");
      searchOptions.classList.add("d-none");
    };

    const handleBodyClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (target.getAttribute("id") !== "search-options") {
        dropdown.classList.remove("show");
        searchOptions.classList.add("d-none");
      }
    };

    searchInput.addEventListener("focus", handleFocus);
    searchInput.addEventListener("keyup", handleKeyUp);
    searchOptions.addEventListener("click", handleClick);
    document.body.addEventListener("click", handleBodyClick);

    return () => {
      searchInput.removeEventListener("focus", handleFocus);
      searchInput.removeEventListener("keyup", handleKeyUp);
      searchOptions.removeEventListener("click", handleClick);
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, []);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // If value is asin
    if (value.length === ASIN_LENGTH) {
      SingleSearchModal.open({asin: value});
      return;
    } else if (value.length === AMAZON_ORDER_ID_LENGTH) {
      // If value is amazon order id
      navigate(`/orders/${value}`);
      return;
    }
  };

  const debouncedLoadList = useDebounce(async () => {
    if (value !== "") {
      await getSearchQuery({query: value})(dispatch);
    }
  }, 300);

  const onValueChange = useCallback(
    () => {
      if(value != null &&  AmazonHelper.isValidAsin(value))
      {
        SingleSearchModal.open({asin: value});
      }
    },
    [value],
  )
  

  useEffect(() => {
    debouncedLoadList();
  }, [debouncedLoadList, value]);


  useEffect(() => {
    onValueChange()
  }, [onValueChange])
  

  return (
    <React.Fragment>
      <Form className="app-search d-none d-md-block" onSubmit={onSubmit}>
        <div className="position-relative">
          <Input
            type="text"
            className="form-control"
            placeholder={t("SearchPlaceholder")}
            id="search-options"
            value={value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setValue(_.toUpper(e.target.value));
            }}
          />
          <span className="mdi mdi-magnify search-widget-icon"></span>
          <span className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none" id="search-close-options"></span>
        </div>
        <div className="dropdown-menu dropdown-menu-lg " id="search-dropdown">
          <SimpleBar>
            {loading.search && (
              <div className="dropdown-item bg-transparent text-wrap d-flex justify-content-center">
                <Spinner color="secondary" size="sm"></Spinner>
                <span className="ms-2">{t("Loading...")}</span>
              </div>
            )}
            {!_.isEmpty(searchResponse) && searchResponse.products.length > 0 ? (
              <PlaceholderGlow busy={loading.search}>
                <div className="dropdown-header">
                  <h6 className="text-overflow text-muted mb-0 text-uppercase">{t("Products")}</h6>
                </div>
                {searchResponse.products.map((product) => {
                  const imagePath = `https://m.media-amazon.com/images/I/${product.image}`;
                  return (
                    <Button
                      className="dropdown-item notify-item py-2"
                      onClick={() => {
                        SingleSearchModal.open({asin: product.asin});
                      }}
                    >
                      <div className="d-flex">
                        <img
                          src={imagePath}
                          className="me-3 rounded-circle avatar-xs"
                          onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                            e.currentTarget.onerror = null;
                            e.currentTarget.src = errorImage;
                          }}
                          alt=""
                        />
                        <div className="flex-grow-1">
                          <h6 className="m-0">
                            <Highlight text={product.asin} highlight={value} />
                          </h6>
                          <p className="fs-11 mb-0 text-muted">{product.title.substring(0, 40)}</p>
                        </div>
                      </div>
                    </Button>
                  );
                })}
              </PlaceholderGlow>
            ) : !_.isEmpty(searchResponse) && value.length === ASIN_LENGTH && !loading.search ? (
              <div className="dropdown-item bg-transparent text-wrap">
                <Button type="submit" className="btn btn-soft-secondary btn-sm rounded-pill">
                  {t("SearchOption.Label.StartAnalyzeFor", {value: value})} <i className="mdi mdi-magnify ms-1"></i>
                </Button>
              </div>
            ) : (
              <div className="dropdown-item bg-transparent text-wrap">
                <span className="text-muted">{t("SearchOption.Label.NoResultFor", {value: value})}</span>
              </div>
            )}

            {!_.isEmpty(searchResponse) && searchResponse.orders.length > 0 && (
              <PlaceholderGlow busy={loading.search}>
                <div className="dropdown-header">
                  <h6 className="text-overflow text-muted mb-0 text-uppercase">{t("Orders.Title")}</h6>
                </div>
                {searchResponse.orders.map((order) => {
                  return (
                    <Link to={`/orders/${order.amazonOrderId}`} className="dropdown-item notify-item">
                      <i className="ri-shopping-cart-line align-middle fs-18 text-muted me-2"></i>
                      <span>
                        <Highlight text={order.amazonOrderId} highlight={value} />
                      </span>
                    </Link>
                  );
                })}
              </PlaceholderGlow>
            )}
            <div className="dropdown-header mt-2">
              <h6 className="text-overflow text-muted mb-1 text-uppercase">{t("Quick Access")}</h6>
            </div>

            <Link to="/dashboard" className="dropdown-item notify-item">
              <i className="bx bxs-dashboard align-middle fs-18 text-muted me-2"></i>
              <span>{t("Dashboard.Title")}</span>
            </Link>

            <Link to="https://help.sellthis.com" className="dropdown-item notify-item">
              <i className="ri-lifebuoy-line align-middle fs-18 text-muted me-2"></i>
              <span>{t("Account.Support.Title")}</span>
            </Link>

            <Link to="/account/profile" className="dropdown-item notify-item">
              <i className="ri-user-settings-line align-middle fs-18 text-muted me-2"></i>
              <span>{t("Profile")}</span>
            </Link>
          </SimpleBar>
        </div>
      </Form>
    </React.Fragment>
  );
};

export default SearchOption;
