import {useProfile} from "Components/Hooks/useProfile";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Card, CardBody, Col, Row} from "reactstrap";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {renderToString} from "react-dom/server";
import {getToday} from "helpers/utilities";
import Moment from "react-moment";
import DisplayNumber from "Components/Common/DisplayNumber";
import i18n from "i18n";
import {UserLimitType} from "models/enums/user_limit_type";

const UsageSummary = () => {
  const {t} = useTranslation();
  const {userProfile} = useProfile();
  const [dailyLimitSearch, setDailyLimitSearch] = useState<number>();
  const [dailyLimitScan, setDailyLimitScan] = useState<number>();
  const [usageSearch, setUsageSearch] = useState<number>();
  const [usageScan, setUsageScan] = useState<number>();
  const [remainingSearch, setRemainingSearch] = useState<number>();
  const [remainingScan, setRemainingScan] = useState<number>();

  const loginData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Login.loading,
    }),
  );
  const {loading} = useSelector(loginData);

  useEffect(() => {
    if (userProfile) {
      setDailyLimitSearch(userProfile.remainingLimits.daily.find((x) => x.type === UserLimitType.SEARCH)?.dailyValue);
      setDailyLimitScan(userProfile.remainingLimits.daily.find((x) => x.type === UserLimitType.CRAWLER_SCAN)?.dailyValue);
      setUsageSearch(userProfile.remainingLimits.today.find((x) => x.type === UserLimitType.SEARCH)?.dailyValue);
      setUsageScan(userProfile.remainingLimits.today.find((x) => x.type === UserLimitType.CRAWLER_SCAN)?.dailyValue);
      setRemainingSearch(userProfile.remainingLimits.remaining.find((x) => x.type === UserLimitType.SEARCH)?.dailyValue);
      setRemainingScan(userProfile.remainingLimits.remaining.find((x) => x.type === UserLimitType.CRAWLER_SCAN)?.dailyValue);
    }
  }, [userProfile]);

  const getRemainingTimeToRenew = (): string => {
    return renderToString(
      <Moment locale={i18n.language} tz={userProfile?.timezone} fromNow>
        {getToday()
          .tz(userProfile?.timezone!)
          .endOf("day")
          .toDate()}
      </Moment>,
    );
  };

  const calculatePercentage = (used: number = 0, total: number = 0) => {
    return total > 0 ? (used / total) * 100 : 0;
  };

  return (
    <div>
      <Row>
        <Col>
          <Card className={`${loading.limit ? "placeholder w-100" : ""}`}>
            <CardBody>
              <div className="d-flex justify-content-between align-items-center mb-4">
                <div>
                  <h5 className="fs-18 fw-semibold mb-1">{t("Account.LimitUsageHistory.Title")}</h5>
                  <p className="text-muted mb-0">{t("Account.LimitUsageHistory.Label.DailyLimitInfo")}</p>
                </div>
                <div className="reset-info">
                  <i className="ri-time-line me-1"></i>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("Account.LimitUsageHistory.Info.DailyResetMessage", {
                        value: getRemainingTimeToRenew(),
                      }),
                    }}
                  ></span>
                </div>
              </div>

              <div className="limit-summary-container">
                <div className="limit-summary-item shadow-sm">
                  <div className="limit-icon bg-success-subtle">
                    <i className="bx bx-search-alt fs-24"></i>
                  </div>
                  <div className="limit-info">
                    <h6 className="limit-title">{t("Account.LimitUsageHistory.Label.Analysis")}</h6>
                    <div className="limit-numbers">
                      <div className="limit-usage">
                        <span className="current">
                          <DisplayNumber value={usageSearch} />
                        </span>
                        <span className="total text-muted">
                          / <DisplayNumber value={dailyLimitSearch} />
                        </span>
                      </div>
                      <div className="limit-remaining text-success">
                        <i className="ri-arrow-right-line"></i>
                        <DisplayNumber value={remainingSearch} /> {t("Account.LimitUsageHistory.Label.Remaining")}
                      </div>
                    </div>
                    <div className="progress progress-sm">
                      <div className="progress-bar bg-success" role="progressbar" style={{width: `${calculatePercentage(usageSearch, dailyLimitSearch)}%`}}></div>
                    </div>
                  </div>
                </div>

                {/* Scan and Save Stats */}
                <div className="limit-summary-item shadow-sm">
                  <div className="limit-icon bg-warning-subtle">
                    <i className="ri-bug-2-line fs-24"></i>
                  </div>
                  <div className="limit-info">
                    <h6 className="limit-title">{t("Account.LimitUsageHistory.Label.ScanAndSave")}</h6>
                    <div className="limit-numbers">
                      <div className="limit-usage">
                        <span className="current">
                          <DisplayNumber value={usageScan} />
                        </span>
                        <span className="total text-muted">
                          / <DisplayNumber value={dailyLimitScan} />
                        </span>
                      </div>
                      <div className="limit-remaining text-warning">
                        <i className="ri-arrow-right-line"></i>
                        <DisplayNumber value={remainingScan} /> {t("Account.LimitUsageHistory.Label.Remaining")}
                      </div>
                    </div>
                    <div className="progress progress-sm">
                      <div className="progress-bar bg-warning" role="progressbar" style={{width: `${calculatePercentage(usageScan, dailyLimitScan)}%`}}></div>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default UsageSummary;
