import BreadCrumb, {BreadcrumbMenuItem} from "Components/Common/BreadCrumb";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Card, CardBody, CardHeader, Container, Form, FormFeedback, Input, Label, ListGroup, ListGroupItem, Spinner} from "reactstrap";
import {useFormik} from "formik";
import {createSelector} from "reselect";
import {useDispatch, useSelector} from "react-redux";
import {changePassword} from "slices/account/thunk";
import {RootState} from "slices";
import ProfileLayout from "Components/Common/Layout/ProfileLayout";
import * as Yup from "yup";
import "react-phone-input-2/lib/high-res.css";
import Enable2FA, {Enable2FAModal} from "./Modals/Enable2FA";
import {useProfile} from "Components/Hooks/useProfile";
import Disable2FA, {Disable2FAModal} from "./Modals/Disable2FA";

const Security = () => {
  const {t} = useTranslation();
  const {userProfile} = useProfile();
  const dispatch: any = useDispatch();
  const [oldPasswordShow, setOldPasswordShow] = useState<boolean>(false);
  const [newPasswordShow, setNewPasswordShow] = useState<boolean>(false);
  const accountData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Account.loading,
    }),
  );

  // Inside your component
  const {loading} = useSelector(accountData);
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      oldPassword: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required(t("Account.Security.Validation.OldPassword")),
      password: Yup.string()
        .required(t("Account.Security.Validation.Password"))
        .notOneOf([Yup.ref("oldPassword"), null], t("Account.Security.Validation.PasswordDifferent"))
        .min(8, t("Account.Security.Validation.PasswordLength"))
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{8,}$/, t("Account.Security.Validation.PasswordPattern")),
      confirmPassword: Yup.string()
        .required(t("Account.Security.Validation.ConfirmPassword"))
        .min(8, " ")
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{8,}$/, " ")
        .oneOf([Yup.ref("password"), ""], t("Account.Security.Validation.PasswordMatch")),
    }),
    onSubmit: (values) => {
      const changePromise = changePassword(values.oldPassword, values.password)(dispatch);

      changePromise.then((success) => {
        if (success) {
          validation.resetForm();
        }
      });
    },
  });

  document.title = t("PageTitles.Security");

  const breadcrumbMenus: BreadcrumbMenuItem[] = [
    {
      label: t("Account.Title"),
      url: "/account/security",
    },
    {
      label: t("Account.Security.Title"),
      url: "",
    },
  ];
  return (
    <div className="page-content">
      <Container fluid>
        <Enable2FA />
        <Disable2FA />
        <BreadCrumb title={t("Account.Title")} menus={breadcrumbMenus} />
        <ProfileLayout hasAnyChanges={validation.dirty} navigateTo="">
          <>
            <Card className="mb-4">
              <CardHeader>
                <h4 className="card-title mb-0 align-items-center d-flex">
                  <i className="mdi mdi-lock-outline fs-18 me-1"></i>
                  {t("Account.Security.Title")}
                </h4>
              </CardHeader>
              <CardBody>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                  className="needs-validation"
                  action="#"
                >
                  <div className="mb-3">
                    <Label htmlFor="password" className="form-label">
                      {t("Account.Security.OldPassword")} <span className="text-danger">*</span>
                    </Label>
                    <div className="position-relative auth-pass-inputgroup">
                      <Input
                        name="oldPassword"
                        type={oldPasswordShow ? "text" : "password"}
                        placeholder={t("Account.Security.OldPasswordPlaceholder")}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.oldPassword || ""}
                        invalid={validation.touched.oldPassword && validation.errors.oldPassword ? true : false}
                      />
                      {validation.touched.oldPassword && validation.errors.oldPassword ? (
                        <FormFeedback type="invalid">
                          <div>{t(validation.errors.oldPassword)}</div>
                        </FormFeedback>
                      ) : null}
                      <Button
                        color="link"
                        onClick={() => setOldPasswordShow(!oldPasswordShow)}
                        className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                        type="button"
                        id="password-addon"
                      >
                        <i className="ri-eye-fill align-middle"></i>
                      </Button>
                    </div>
                  </div>

                  <div className="mb-3">
                    <Label htmlFor="password" className="form-label">
                      {t("Account.Security.Password")} <span className="text-danger">*</span>
                    </Label>
                    <div className="position-relative auth-pass-inputgroup">
                      <Input
                        name="password"
                        type={newPasswordShow ? "text" : "password"}
                        placeholder={t("Account.Security.PasswordPlaceholder")}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.password || ""}
                        invalid={validation.touched.password && validation.errors.password ? true : false}
                      />
                      {validation.touched.password && validation.errors.password ? (
                        <FormFeedback type="invalid">
                          <div>{t(validation.errors.password)}</div>
                        </FormFeedback>
                      ) : null}
                      <Button
                        color="link"
                        onClick={() => setNewPasswordShow(!newPasswordShow)}
                        className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                        type="button"
                        id="password-addon"
                      >
                        <i className="ri-eye-fill align-middle"></i>
                      </Button>
                    </div>
                  </div>

                  <div className="mb-3">
                    <Label htmlFor="confirmPassword" className="form-label">
                      {t("Account.Security.ConfirmPassword")} <span className="text-danger">*</span>
                    </Label>
                    <div className="position-relative auth-pass-inputgroup">
                      <Input
                        name="confirmPassword"
                        type={newPasswordShow ? "text" : "password"}
                        placeholder={t("Account.Security.ConfirmPasswordPlaceholder")}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.confirmPassword || ""}
                        invalid={validation.touched.confirmPassword && validation.errors.confirmPassword ? true : false}
                      />
                      {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                        <FormFeedback type="invalid">
                          <div>{t(validation.errors.confirmPassword)}</div>
                        </FormFeedback>
                      ) : null}
                      <Button
                        color="link"
                        onClick={() => setNewPasswordShow(!newPasswordShow)}
                        className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                        type="button"
                        id="password-addon"
                      >
                        <i className="ri-eye-fill align-middle"></i>
                      </Button>
                    </div>
                  </div>

                  <div className="mb-3">
                    <Label className="form-label">{t("Account.Security.PasswordRequirements")}:</Label>
                    <ListGroup>
                      <ListGroupItem className="border-0 py-1 text-muted">
                        <i className="mdi mdi-check-bold align-middle lh-1 me-2"></i> {t("Account.Security.PasswordRequirementsList.1")}
                      </ListGroupItem>
                      <ListGroupItem className="border-0 py-1 text-muted">
                        <i className="mdi mdi-check-bold align-middle lh-1 me-2"></i> {t("Account.Security.PasswordRequirementsList.2")}
                      </ListGroupItem>
                      <ListGroupItem className="border-0 py-1 text-muted">
                        <i className="mdi mdi-check-bold align-middle lh-1 me-2"></i> {t("Account.Security.PasswordRequirementsList.3")}
                      </ListGroupItem>
                      <ListGroupItem className="border-0 py-1 text-muted">
                        <i className="mdi mdi-check-bold align-middle lh-1 me-2"></i> {t("Account.Security.PasswordRequirementsList.4")}
                      </ListGroupItem>
                      <ListGroupItem className="border-0 py-1 text-muted">
                        <i className="mdi mdi-check-bold align-middle lh-1 me-2"></i> {t("Account.Security.PasswordRequirementsList.5")}
                      </ListGroupItem>
                    </ListGroup>
                  </div>

                  <div className="hstack gap-2 justify-content-start">
                    <Button color="secondary" type="submit" disabled={loading.update}>
                      {loading.update ? <Spinner size="sm" className="me-2 align-middle"></Spinner> : null}
                      {t("Account.Security.Button.SaveChanges")}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <h4 className="card-title mb-0 align-items-center d-flex">
                  <i className="mdi mdi-shield-check-outline fs-18 me-1"></i>
                  {t("Account.Security.Label.TwoFactorAuthentication")}
                </h4>
              </CardHeader>
              <CardBody>
                <div className="d-flex flex-column">
                  <div className="mb-3">
                    <p className="text-muted mb-0">{t("Account.Security.Label.TwoFactorAuthenticationDescription")}</p>
                  </div>
                  {loading.twoFactorEnable || loading.twoFactorDisable ? (
                    <Spinner size="sm" className="align-middle"></Spinner>
                  ) : userProfile?.twoFactorEnabled ? (
                    <div>
                      <Button
                        color="danger"
                        size="sm"
                        onClick={() => {
                          Disable2FAModal.open({});
                        }}
                      >
                        {t("Account.Security.Button.DisableTwoFactorAuthentication")}
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <Button
                        color="secondary"
                        size="sm"
                        onClick={() => {
                          Enable2FAModal.open({});
                        }}
                      >
                        {t("Account.Security.Button.EnableTwoFactorAuthentication")}
                      </Button>
                    </div>
                  )}
                </div>
              </CardBody>
            </Card>
          </>
        </ProfileLayout>
      </Container>
    </div>
  );
};

export default Security;
