import {CSSProperties, useEffect} from "react";
import {useSelector} from "react-redux";
import {createSelector} from "reselect";
import {ButtonGroup} from "reactstrap";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import i18n from "i18n";
import withRouter from "../../Components/Common/withRouter";
import LanguageDropdown from "Components/Common/LanguageDropdown";

const TwoFactorLayout = ({children}: any) => {
  const {t} = useTranslation();
  const layoutData = createSelector(
    (state) => state.Layout.layoutModeType,
    (layoutModeType) => layoutModeType,
  );
  const layoutModeType = useSelector(layoutData);

  useEffect(() => {
    if (layoutModeType === "dark") {
      document.body.setAttribute("data-bs-theme", "dark");
    } else {
      document.body.setAttribute("data-bs-theme", "light");
    }
    return () => {
      document.body.removeAttribute("data-bs-theme");
    };
  }, [layoutModeType]);
  const helpCenterLink = i18n.language === "en" ? "https://help.sellthis.com/en" : i18n.language === "tr" ? "https://help.sellthis.com/tr" : "";

  const style: CSSProperties = {
    position: "fixed",
    right: "10px",
    bottom: "0px",
    zIndex: "2",
    display: "flex",
  };

  return (
    <div>
      <div>{children}</div>
      <div style={style}>
        <ButtonGroup className="ms-1 topbar-head-dropdown header-item">
          <Link to="/account/logout" className="me-2">
            {t("Logout")}
          </Link>
          <Link to={helpCenterLink} className="btn btn-light btn btn-icon btn-topbar btn-ghost-primary rounded-circle" target="_blank">
            <i className=" ri-question-line fs-22"></i>
          </Link>
        </ButtonGroup>
        <LanguageDropdown />
      </div>
    </div>
  );
};

export default withRouter(TwoFactorLayout);
