import React from "react";
import ReactApexChart from "react-apexcharts";
import {useTranslation} from "react-i18next";

interface OrdersSummaryChartProps {
  dataColors: string[];
  pendingCount: number;
  shippedCount: number;
  canceledCount: number;
}
const OrdersSummaryChart = (props: OrdersSummaryChartProps) => {
  const {t} = useTranslation();
  const series = [props.pendingCount, props.shippedCount, props.canceledCount];
  var options: any = {
    labels: [t("OrderStatus.PENDING"), t("OrderStatus.SHIPPED"), t("OrderStatus.CANCELED")],
    chart: {
      type: "donut",
      height: 224,
    },

    plotOptions: {
      pie: {
        size: 100,
        offsetX: 0,
        offsetY: 0,
        donut: {
          size: "70%",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "18px",
              offsetY: -5,
            },

            value: {
              show: true,
              fontSize: "20px",
              color: "#343a40",
              fontWeight: 500,
              offsetY: 5,
              formatter: function(val: any) {
                return val;
              },
            },
            total: {
              show: true,
              fontSize: "13px",
              label: t("Total"),
              color: "#9599ad",
              fontWeight: 500,
              formatter: function(w: any) {
                return w.globals.seriesTotals.reduce(function(a: any, b: any) {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    yaxis: {
      labels: {
        formatter: function(value: any) {
          return value;
        },
      },
    },
    stroke: {
      lineCap: "round",
      width: 2,
    },
    colors: props.dataColors,
  };
  return (
    <React.Fragment>
      <ReactApexChart dir="ltr" options={options} series={series} type="donut" height="180" className="apex-charts" />
    </React.Fragment>
  );
};

export default OrdersSummaryChart;
