import {Col, Row} from "reactstrap";
import {UserSearchProduct} from "models/user_search_product";
import PriceHistory from "./_PriceHistory";
import SellerHistory from "./_SellerHistory";
import BsrHistory from "./_BsrHistory";

interface HistoryGraphicsProps {
  item: UserSearchProduct;
}
const HistoryGraphics = ({item}: HistoryGraphicsProps) => {
  return (
    <Row>
      <Col xs={12}>
        <PriceHistory item={item} />
      </Col>
      <Col xs={12}>
        <SellerHistory item={item} />
      </Col>
      <Col xs={12}>
        <BsrHistory item={item} />
      </Col>
    </Row>
  );
};

export default HistoryGraphics;
