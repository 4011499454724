import {useMemo, useState} from "react";
import {ApexOptions} from "apexcharts";
import {CardHeader, Col, Row} from "reactstrap";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import i18n from "i18n";
import NoData from "Components/Common/NoData";
import DisplayNumber from "Components/Common/DisplayNumber";

interface PriceHistoryData {
  Date: string;
  Price: number;
}

interface PriceHistoryChartProps {
  priceHistoryJSON?: string;
  currencySign?: string;
  currencyCode?: string;
}

const PriceHistoryChart = ({priceHistoryJSON, currencySign = "", currencyCode = ""}: PriceHistoryChartProps) => {
  const [historyDay, setHistoryDay] = useState<number>(0);
  // Memoize data parsing
  const priceHistoryData = useMemo(() => {
    if (!priceHistoryJSON) {
      return [];
    }

    try {
      const parsedData: PriceHistoryData[] = JSON.parse(priceHistoryJSON);
      const sortedData = parsedData.sort((a, b) => new Date(a.Date).getTime() - new Date(b.Date).getTime());

      let dayDifference = 0;
      if (sortedData.length >= 2) {
        const minDate = new Date(sortedData[0].Date);
        const maxDate = new Date(sortedData[sortedData.length - 1].Date);
        const diffTime = Math.abs(maxDate.getTime() - minDate.getTime());
        dayDifference = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      }

      setHistoryDay(dayDifference);

      return sortedData;
    } catch (error) {
      console.error("Error parsing price history data:", error);
      return [];
    }
  }, [priceHistoryJSON]);

  const formatDateForXAxis = (value: number) => {
    moment.locale(i18n.language);
    return moment(value).format("MMM D");
  };

  const formatDateTimeForTooltip = (value: number) => {
    return moment(value).format("DD MMM YYYY HH:mm");
  };

  const formatPrice = (price: number) => {
    return `${currencySign}${price.toFixed(2)} ${currencyCode}`.trim();
  };

  const series = [
    {
      name: "Buy Box",
      data: priceHistoryData.map((item) => [
        new Date(item.Date).getTime(), // x: timestamp
        item.Price, // y: price
      ]),
    },
  ];

  const options: ApexOptions = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: false,
      },
      width: "100%",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    colors: ["#ed3ac5"],
    xaxis: {
      type: "datetime", // Changed to datetime instead of category
      labels: {
        formatter: (value: string) => formatDateForXAxis(Number(value)),
        rotate: 0,
        rotateAlways: false,
        hideOverlappingLabels: true,
        style: {
          fontSize: "10px",
        },
      },
      axisBorder: {
        show: true,
        strokeWidth: 1,
        color: "#78909C",
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "#78909C",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
    },
    yaxis: {
      tickAmount: 5,
      forceNiceScale: true,
      decimalsInFloat: 0,
      floating: false,
      labels: {
        formatter: (value) => {
          // Yuvarlama işlemini ApexCharts yapacak
          return currencySign ? `${currencySign}${Math.round(value)}` : Math.round(value).toString();
        },
        style: {
          fontSize: "10px",
        },
        minWidth: 0,
        maxWidth: 160,
        offsetX: 0,
        offsetY: 0,
      },
      axisBorder: {
        show: true,
        color: "#78909C",
        width: 1,
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: true,
        color: "#78909C",
        width: 6,
        offsetX: 0,
        offsetY: 0,
      },
    },
    tooltip: {
      enabled: true,
      followCursor: true,
      intersect: false,
      shared: true,
      x: {
        formatter: function(value) {
          // Return full formatted date+time for the datapoint
          return "<strong>" + formatDateTimeForTooltip(value) + "</strong>";
        },
      },
      y: {
        formatter: (value) => {
          // Format price with currency
          return formatPrice(value);
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      size: 0, // Set marker size to 0 to hide the data points
      hover: {
        size: 5, // Show markers on hover
        sizeOffset: 3,
      },
    },
    annotations: {
      yaxis: [],
    },
  };

  // Early return for no data
  if (priceHistoryData.length === 0) {
    return <NoData icon="mdi mdi-chart-line" style={{minHeight: 100}} />;
  }

  return (
    <>
      <CardHeader className="p-0">
        <Row className="mb-2">
          <Col>
            <h5>
              {i18n.t("SearchResults.Analyzes.PriceHistory.Title")}
              <span className="text-muted fs-12 align-middle ms-1">
                <DisplayNumber
                  value={historyDay}
                  prefix="("
                  suffix={`
                    ${historyDay > 1 ? i18n.t("days") + ")" : i18n.t("day") + ")"}`}
                />
              </span>
            </h5>
          </Col>
        </Row>
      </CardHeader>
      <div className="chart-container">
        <ReactApexChart options={options} series={series} type="line" height={150} />
      </div>
    </>
  );
};

export default PriceHistoryChart;
