import {Card, CardBody, Col} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {AmazonMarketplace} from "helpers/marketplace_helper";
import {ShipmentSlice} from "slices/shipment/selector";
import React from "react";
import DisplayPrice from "Components/Common/DisplayPrice";

interface FinanceStatsProps {
  destinationMarketplace?: AmazonMarketplace;
}
const FinanceStats = ({destinationMarketplace}: FinanceStatsProps) => {
  const {t} = useTranslation();
  const {currentUserShipment} = useSelector(ShipmentSlice);
  return (
    <React.Fragment>
      <Col xl={3} className="same-height">
        <Card className="card-animate">
          <CardBody className="d-flex flex-column justify-content-center">
            <div className="d-flex align-items-center ms-3">
              <div className="avatar-sm me-2">
                <div className="avatar-title rounded bg-transparent text-success fs-24">
                  <i className="mdi mdi-bank-check"></i>
                </div>
              </div>
              <div className="flex-grow-1 align-items-center">
                <p className="text-muted mb-0 fs-16">{t("Shipments.ShippedOrCompleted.Stats.Label.Revenue")}</p>
                <h4 className="fs-20 fw-semibold ff-secondary mb-0 mt-1 d-flex align-items-center">
                  {destinationMarketplace && (
                    <DisplayPrice source={destinationMarketplace.currency} value={currentUserShipment.userShipmentFinance.actualSoldRevenue} decimals={2} notation="decimal" />
                  )}
                </h4>
              </div>
            </div>

            <hr className="my-4" />
            <div className="d-flex align-items-center ms-3">
              <div className="avatar-sm me-2">
                <div className="avatar-title rounded bg-transparent text-secondary fs-24">
                  <i className="bx bx-wallet"></i>
                </div>
              </div>
              <div className="flex-grow-1 align-items-center">
                <p className="text-muted mb-0 fs-16">{t("Shipments.ShippedOrCompleted.Stats.Label.Profit")}</p>
                <h4 className="fs-20 fw-semibold ff-secondary mb-0 mt-1 d-flex align-items-center">
                  <span className="counter-value">
                    {destinationMarketplace && (
                      <DisplayPrice source={destinationMarketplace.currency} value={currentUserShipment.userShipmentFinance.actualSoldProfit} decimals={2} notation="decimal" countUp />
                    )}
                  </span>
                </h4>
              </div>
            </div>

            <hr className="my-4" />
            <div className="d-flex align-items-center ms-3">
              <div className="avatar-sm me-2">
                <div className="avatar-title rounded bg-transparent text-secondary fs-24">
                  <i className="ri-exchange-dollar-line"></i>
                </div>
              </div>
              <div className="flex-grow-1 align-items-center">
                <p className="text-muted mb-0 fs-16">
                  {t("Shipments.ShippedOrCompleted.Stats.Label.Roi")}
                  <i className="mdi mdi-slash-forward"></i>
                  {t("Shipments.ShippedOrCompleted.Stats.Label.Margin")}
                </p>
                <h4 className="fs-20 fw-semibold ff-secondary mb-0 mt-1 d-flex align-items-center">
                  <span className="badge rounded-pill bg-success-subtle text-success fs-16">
                    {currentUserShipment.userShipmentFinance.actualSoldROI && t("PercentSign", {value: currentUserShipment.userShipmentFinance.actualSoldROI})}
                    <span className="mx-1">
                      <i className="mdi mdi-slash-forward"></i>
                    </span>
                    {currentUserShipment.userShipmentFinance.actualSoldMargin && t("PercentSign", {value: currentUserShipment.userShipmentFinance.actualSoldMargin})}
                  </span>
                </h4>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default FinanceStats;
