import { createSelector } from "reselect";
import { RootState } from "slices";

export const LayoutSlice = createSelector(
  (state: RootState) => state,
  (state) => ({
    layoutType: state.Layout.layoutType,
    layoutModeType: state.Layout.layoutModeType,
    leftSidebarType: state.Layout.leftSidebarType,
    layoutWidthType: state.Layout.layoutWidthType,
    layoutPositionType: state.Layout.layoutPositionType,
    topbarThemeType: state.Layout.topbarThemeType,
    leftSidebarSizeType: state.Layout.leftSidebarSizeType,
    leftSidebarViewType: state.Layout.leftSidebarViewType,
    leftSidebarImageType: state.Layout.leftSidebarImageType,
    preloader: state.Layout.preloader,
    sidebarVisibilitytype: state.Layout.sidebarVisibilitytype,
  }),
);
