import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {SaleSummary} from "models/dashboard";
import {useSelector} from "react-redux";
import {Card, CardBody, CardFooter, Col} from "reactstrap";
import {getToday} from "helpers/utilities";
import {DashboardSlice} from "slices/dashboard/selector";
import DisplayPrice from "Components/Common/DisplayPrice";
import CountUp from "react-countup";
import Moment from "react-moment";
import i18n from "i18n";
import NoData from "Components/Common/NoData";

const SalesSummary = () => {
  const {t} = useTranslation();
  const [saleSummary, setSaleSummary] = useState<SaleSummary[]>();
  const [today, setToday] = useState<SaleSummary>({} as SaleSummary);
  const [yesterday, setYesterday] = useState<SaleSummary>({} as SaleSummary);
  const [_7Days, set_7Days] = useState<SaleSummary>({} as SaleSummary);
  const [_15Days, set_15Days] = useState<SaleSummary>({} as SaleSummary);
  const [_30Days, set_30Days] = useState<SaleSummary>({} as SaleSummary);
  const [_90Days, set_90Days] = useState<SaleSummary>({} as SaleSummary);
  const [_365Days, set_365Days] = useState<SaleSummary>({} as SaleSummary);
  const [lastUpdate, setLastUpdate] = useState<Date>(getToday().toDate());

  const {filter, statistics} = useSelector(DashboardSlice);

  useEffect(() => {
    if (statistics.saleSummary && statistics.saleSummary.length > 0) {
      const todaySummary = statistics.saleSummary?.find((item) => item.dateRange === 0) || {dateRange: 0, units: 0, revenue: 0, orderCount: 0, updateDate: getToday().toDate()};
      setToday(todaySummary);

      const yesterdaySummary = statistics.saleSummary?.find((item) => item.dateRange === 1) || {dateRange: 1, units: 0, revenue: 0, orderCount: 0, updateDate: getToday().toDate()};
      setYesterday(yesterdaySummary);

      const _7DaysSummary = statistics.saleSummary?.find((item) => item.dateRange === 7) || {dateRange: 7, units: 0, revenue: 0, orderCount: 0, updateDate: getToday().toDate()};
      set_7Days(_7DaysSummary);

      const _15DaysSummary = statistics.saleSummary?.find((item) => item.dateRange === 15) || {dateRange: 15, units: 0, revenue: 0, orderCount: 0, updateDate: getToday().toDate()};
      set_15Days(_15DaysSummary);

      const _30DaysSummary = statistics.saleSummary?.find((item) => item.dateRange === 30) || {dateRange: 30, units: 0, revenue: 0, orderCount: 0, updateDate: getToday().toDate()};
      set_30Days(_30DaysSummary);

      const _90DaysSummary = statistics.saleSummary?.find((item) => item.dateRange === 90) || {dateRange: 90, units: 0, revenue: 0, orderCount: 0, updateDate: getToday().toDate()};
      set_90Days(_90DaysSummary);

      const _365DaysSummary = statistics.saleSummary?.find((item) => item.dateRange === 365) || {dateRange: 365, units: 0, revenue: 0, orderCount: 0, updateDate: getToday().toDate()};
      set_365Days(_365DaysSummary);

      setLastUpdate(statistics.saleSummary[0].updateDate);
      setSaleSummary(statistics.saleSummary);
    }
  }, [statistics]);

  const getCountUpValues = (type: "unit" | "revenue" | "orderCount", item: SaleSummary): JSX.Element => {
    if (type === "revenue") {
      switch (item.dateRange) {
        case 0:
          return <DisplayPrice source={filter?.currencyCode} value={today.revenue} decimals={2} notation="abbreviated" />;
        case 1:
          return <DisplayPrice source={filter?.currencyCode} value={yesterday.revenue} decimals={2} notation="abbreviated" />;
        case 7:
          return <DisplayPrice source={filter?.currencyCode} value={_7Days.revenue} decimals={2} notation="abbreviated" />;
        case 15:
          return <DisplayPrice source={filter?.currencyCode} value={_15Days.revenue} decimals={2} notation="abbreviated" />;
        case 30:
          return <DisplayPrice source={filter?.currencyCode} value={_30Days.revenue} decimals={2} notation="abbreviated" />;
        case 90:
          return <DisplayPrice source={filter?.currencyCode} value={_90Days.revenue} decimals={2} notation="abbreviated" />;
        case 365:
          return <DisplayPrice source={filter?.currencyCode} value={_365Days.revenue} decimals={2} notation="abbreviated" />;
        default:
          return <></>;
      }
    } else if (type === "unit") {
      switch (item.dateRange) {
        case 0:
          return <CountUp start={0} end={today.units} decimals={0} duration={2} />;
        case 1:
          return <CountUp start={0} end={yesterday.units} decimals={0} duration={2} />;
        case 7:
          return <CountUp start={0} end={_7Days.units} decimals={0} duration={2} />;
        case 15:
          return <CountUp start={0} end={_15Days.units} decimals={0} duration={2} />;
        case 30:
          return <CountUp start={0} end={_30Days.units} decimals={0} duration={2} />;
        case 90:
          return <CountUp start={0} end={_90Days.units} decimals={0} duration={2} />;
        case 365:
          return <CountUp start={0} end={_365Days.units} decimals={0} duration={2} />;
        default:
          return <></>;
      }
    } else if (type === "orderCount") {
      switch (item.dateRange) {
        case 0:
          return <CountUp start={0} end={today.orderCount} decimals={0} duration={2} />;
        case 1:
          return <CountUp start={0} end={yesterday.orderCount} decimals={0} duration={2} />;
        case 7:
          return <CountUp start={0} end={_7Days.orderCount} decimals={0} duration={2} />;
        case 15:
          return <CountUp start={0} end={_15Days.orderCount} decimals={0} duration={2} />;
        case 30:
          return <CountUp start={0} end={_30Days.orderCount} decimals={0} duration={2} />;
        case 90:
          return <CountUp start={0} end={_90Days.orderCount} decimals={0} duration={2} />;
        case 365:
          return <CountUp start={0} end={_365Days.orderCount} decimals={0} duration={2} />;
        default:
          return <></>;
      }
    }
    return <></>;
  };
  return (
    <React.Fragment>
      <Col xl={4} className="same-height">
        <Card className="card-height-100 card-animate">
          <div className="align-items-center d-flex card-header">
            <h4 className="card-title mb-0 flex-grow-1">{t("Dashboard.SalesSummary.Title")}</h4>
          </div>
          <CardBody>
            {saleSummary && saleSummary.length > 0 ? (
              <div className="table-responsive table-card">
                <table className="table-hover table table-centered align-middle mb-0 table-sm">
                  <thead className="text-muted table-light align-middle">
                    <tr style={{height: "40px"}}>
                      <th scope="col"></th>
                      <th scope="col">{t("Dashboard.SalesSummary.TableColumn.Revenue")}</th>
                      <th scope="col">{t("Dashboard.SalesSummary.TableColumn.Orders")}</th>
                      <th scope="col">{t("Dashboard.SalesSummary.TableColumn.Units")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {saleSummary?.map((item: SaleSummary, index: number) => {
                      return (
                        <tr key={index}>
                          <td>
                            <h6 className="my-2">{t(`Dashboard.SalesSummary.Label.Day_${item.dateRange}`)}</h6>
                          </td>
                          <td>
                            <span className="badge rounded-pill bg-success-subtle text-success fs-12">{getCountUpValues("revenue", item)}</span>
                          </td>
                          <td>
                            <span className="badge rounded-pill bg-secondary-subtle text-secondary fs-12">{getCountUpValues("orderCount", item)}</span>
                          </td>
                          <td>
                            <span className="badge rounded-pill bg-secondary-subtle text-secondary fs-12">{getCountUpValues("unit", item)}</span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <NoData icon="mdi mdi-note-search-outline" />
            )}
          </CardBody>
          <CardFooter className="border-0">
            <p className="card-text">
              <small className="fs-12 text-muted d-flex align-items-center">
                <i className="ri-time-line me-1"></i>
                {t("Dashboard.SalesSummary.Label.LastUpdated")}:
                <Moment className="text-muted ms-1" locale={i18n.language} fromNow>
                  {lastUpdate}
                </Moment>
              </small>
            </p>
          </CardFooter>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default SalesSummary;
