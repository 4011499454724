import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {Modal, ModalHeader, ModalBody, Col, Row, Button, ModalFooter, Form, Label, Spinner} from "reactstrap";
import {memo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useParams} from "react-router-dom";
import {SendReportErrorRequest} from "models/user";
import {sendReportError} from "slices/search-result/thunk";
import {createTypedModal} from "helpers/modal_helpers";
import {SearchResultSlice} from "slices/search-result/selector";
import ValidationWrapper from "Components/Common/ValidationWrapper";
import Checkbox from "Components/Common/Checkbox";
import ValidatedInput from "Components/Common/ValidatedInput";
import i18n from "i18n";
import * as Yup from "yup";

interface ReportForm {
  profitAnalysisError: boolean;
  priceAnalysisError: boolean;
  saleAnalysisError: boolean;
  sellerAnalysisError: boolean;
  productSizeError: boolean;
  restrictionCheckError: boolean;
  variationAnalysisError: boolean;
  saleCountError: boolean;
  competitorAnalysisError: boolean;
  additionalInformation: string;
}
type SupportStatus = "initial" | "requestSent";

interface ModalData {
  asin: string;
  marketplaceTarget: string;
}

export const ReportErrorModal = createTypedModal<ModalData>("report_error");

const ReportError = () => {
  const {open, data} = ReportErrorModal.useModal();
  if (!open) return null;

  return <ReportErrorContent data={data} />;
};

const ReportErrorContent = ({data}: {data: ModalData | undefined}) => {
  const {t} = useTranslation();
  const {searchId} = useParams();
  const [status, setStatus] = useState<SupportStatus>("initial");
  const [hasError, setHasError] = useState<boolean>(false);
  const dispatch: any = useDispatch();

  const {loading} = useSelector(SearchResultSlice);

  const helpCenterLink = i18n.language === "en" ? "https://help.sellthis.com/en" : i18n.language === "tr" ? "https://help.sellthis.com/tr" : "";
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      profitAnalysisError: false,
      priceAnalysisError: false,
      saleAnalysisError: false,
      sellerAnalysisError: false,
      productSizeError: false,
      restrictionCheckError: false,
      variationAnalysisError: false,
      saleCountError: false,
      competitorAnalysisError: false,
      additionalInformation: "",
    },
    validationSchema: Yup.object<ReportForm>({
      profitAnalysisError: Yup.boolean(),
      priceAnalysisError: Yup.boolean(),
      saleAnalysisError: Yup.boolean(),
      sellerAnalysisError: Yup.boolean(),
      productSizeError: Yup.boolean(),
      restrictionCheckError: Yup.boolean(),
      variationAnalysisError: Yup.boolean(),
      saleCountError: Yup.boolean(),
      competitorAnalysisError: Yup.boolean(),
      additionalInformation: Yup.string(),
    }).test("at-least-one-true", t("SearchResults.Dialog.ReportError.Validation.SelectMinimumOneOption"), (values) => {
      const {additionalInformation, ...booleanFields} = values as ReportForm;
      if (!values) return false;
      else return Object.values(booleanFields).some((value) => value === true);
    }),
    onSubmit: (values: ReportForm) => {
      const {additionalInformation, ...booleanFields} = values;
      const hasAnyOption = Object.values(booleanFields).some((value) => value === true);
      if (!hasAnyOption) {
        setHasError(true);
      } else {
        setHasError(false);
        if (searchId) {
          var reportParameters: SendReportErrorRequest = {
            profitAnalysisError: values.profitAnalysisError,
            priceAnalysisError: values.priceAnalysisError,
            saleAnalysisError: values.saleAnalysisError,
            sellerAnalysisError: values.sellerAnalysisError,
            productSizeError: values.productSizeError,
            restrictionCheckError: values.restrictionCheckError,
            variationAnalysisError: values.variationAnalysisError,
            saleCountError: values.saleCountError,
            competitorAnalysisError: values.competitorAnalysisError,
            additionalInformation: values.additionalInformation,
            asin: data?.asin!,
            searchId: searchId!,
            marketplaceName: data?.marketplaceTarget!,
          };
          const reportErrorPromise = sendReportError(reportParameters)(dispatch);
          reportErrorPromise.then((success) => {
            if (success) {
              setStatus("requestSent");
            }
          });
        }
      }
    },
  });

  const toggle = () => {
    ReportErrorModal.close();
    validation.resetForm();
    setStatus("initial");
  };

  return (
    <>
      <Modal backdrop="static" id="reportItemModal" isOpen={true} toggle={toggle} fade={true} centered={true}>
        <div className="placeholder-glow">
          <Form onSubmit={validation.handleSubmit}>
            <ModalHeader className="p-3" toggle={toggle}>
              {t("SearchResults.Dialog.ReportError.Title")}
            </ModalHeader>
            <ModalBody>
              {status === "initial" ? (
                <>
                  <Row>
                    <Col>
                      <h5>{t("SearchResults.Dialog.ReportError.Subtitle")}</h5>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col xs={12}>
                      <ValidationWrapper validation={validation} field="profitAnalysisError">
                        <Checkbox label={t("SearchResults.Dialog.ReportError.Option.ProfitAnalysisError")} />
                      </ValidationWrapper>
                    </Col>
                    <Col xs={12}>
                      <ValidationWrapper validation={validation} field="priceAnalysisError">
                        <Checkbox label={t("SearchResults.Dialog.ReportError.Option.PriceAnalysisError")} />
                      </ValidationWrapper>
                    </Col>
                    <Col xs={12}>
                      <ValidationWrapper validation={validation} field="saleAnalysisError">
                        <Checkbox label={t("SearchResults.Dialog.ReportError.Option.SaleAnalysisError")} />
                      </ValidationWrapper>
                    </Col>
                    <Col xs={12}>
                      <ValidationWrapper validation={validation} field="sellerAnalysisError">
                        <Checkbox label={t("SearchResults.Dialog.ReportError.Option.SellerAnalysisError")} />
                      </ValidationWrapper>
                    </Col>
                    <Col xs={12}>
                      <ValidationWrapper validation={validation} field="productSizeError">
                        <Checkbox label={t("SearchResults.Dialog.ReportError.Option.ProductSizeError")} />
                      </ValidationWrapper>
                    </Col>
                    <Col xs={12}>
                      <ValidationWrapper validation={validation} field="restrictionCheckError">
                        <Checkbox label={t("SearchResults.Dialog.ReportError.Option.RestrictionCheckError")} />
                      </ValidationWrapper>
                    </Col>
                    <Col xs={12}>
                      <ValidationWrapper validation={validation} field="variationAnalysisError">
                        <Checkbox label={t("SearchResults.Dialog.ReportError.Option.VariationAnalysisError")} />
                      </ValidationWrapper>
                    </Col>
                    <Col xs={12}>
                      <ValidationWrapper validation={validation} field="saleCountError">
                        <Checkbox label={t("SearchResults.Dialog.ReportError.Option.SaleCountError")} />
                      </ValidationWrapper>
                    </Col>
                    <Col xs={12}>
                      <ValidationWrapper validation={validation} field="competitorAnalysisError">
                        <Checkbox label={t("SearchResults.Dialog.ReportError.Option.CompetitorAnalysisError")} />
                      </ValidationWrapper>
                    </Col>

                    {hasError && <div className="text-danger mt-3">{t("SearchResults.Dialog.ReportError.Validation.SelectMinimumOneOption")}</div>}

                    <Col xs={12} className="mt-4">
                      <Row className="g-1">
                        <Col xs="12">
                          <Label htmlFor="lastName" className="form-label float-start">
                            {t("SearchResults.Dialog.ReportError.Label.AdditionalInformation.Title")}
                          </Label>
                        </Col>
                        <Col xs="12">
                          <span className="text-muted float-start">{t("SearchResults.Dialog.ReportError.Label.AdditionalInformation.Description")}</span>
                        </Col>
                        <Col xs="12">
                          <ValidatedInput validation={validation} type="textarea" field="additionalInformation" maxLength={500} disableValidationUI />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </>
              ) : (
                <Row className="mt-4 justify-content-center ">
                  <Col xs={12}>
                    <div className="mb-4 d-flex justify-content-center ">
                      <i className=" bx bx-check-circle display-1 text-success"></i>
                    </div>
                    <h5 className="text-center">{t("Account.Support.Success.Title")}</h5>
                    <p className="text-muted mb-4 text-center">{t("Account.Support.Success.Description")}</p>

                    <div className="hstack justify-content-center gap-2">
                      <Link className="btn btn-secondary btn-sm" to={helpCenterLink} target="_blank">
                        <i className="mdi mdi-lifebuoy fs-16 align-middle me-1"></i>
                        <span className="align-middle ">{t("Account.Support.Button.HelpCenter")}</span>
                      </Link>
                    </div>
                  </Col>
                </Row>
              )}
            </ModalBody>
            <ModalFooter>
              <div className="hstack gap-2 justify-content-end">
                {status === "initial" && (
                  <>
                    <Button type="button" className="btn btn-light" onClick={toggle} disabled={loading.save}>
                      {t("SearchResults.Dialog.ReportError.Button.Close")}
                    </Button>
                    <Button type="submit" className="btn btn-success" disabled={loading.save}>
                      {loading.save && <Spinner size="sm" className="me-2 align-middle"></Spinner>}
                      {t("SearchResults.Dialog.ReportError.Button.Send")}
                    </Button>
                  </>
                )}
              </div>
            </ModalFooter>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default memo(ReportError);
