import {Link} from "react-router-dom";
import {Button, Card, CardBody, Col, Row, Table, UncontrolledPopover} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useProfile} from "Components/Hooks/useProfile";
import {AmazonBusinessModel} from "models/enums/user_search_type";
import {SellThisModal} from "Components/Common/SellThis";
import {CheckListModal} from "pages/Searches/SearchResults/Modals/CheckList";
import {IneligibilityReasonListModal} from "pages/Searches/SearchResults/Modals/IneligibilityReasonList";
import {AmazonMarketplace} from "helpers/marketplace_helper";
import {ProductDetailsSlice} from "slices/product-details/selector";
import {AnalyzeType, ReAnalyzeModal} from "Components/Common/Modals/ReAnalyze";
import {ReportErrorModal} from "pages/Searches/SearchResults/Modals/ReportError";
import {NotesModal} from "pages/Searches/SearchResults/Modals/Notes";
import {markSearchProductReviewed, removeFavoriteItem} from "slices/search-result/thunk";
import {preventScrollUp} from "helpers/utilities";
import {AddToFavoritesModal} from "pages/Searches/SearchResults/Modals/AddToFavorites";
import {ShareType} from "models/enums/share_type";
import Rating from "react-rating";
import CopyWidget from "Components/Common/CopyWidget";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import DomainToFlag from "Components/Common/DomainToFlag";
import Moment from "react-moment";
import logoDarkTransparent from "assets/images/logo-dark-transparent.png";
import errorImage from "assets/images/svg/product.svg";
import i18n from "i18n";
import DisplayNumber from "Components/Common/DisplayNumber";
import RestrictionCheck from "./_RestrictionCheck";
import moment from "moment";

interface DecisionReasonsLinkProps {
  items: string[];
  isWarning?: boolean | false;
}
const DecisionReasonsLink = ({items, isWarning}: DecisionReasonsLinkProps) => {
  const {t} = useTranslation();
  return items.length === 1 ? t("SearchResults.Summary.Reason", {count: items.length}) : t("SearchResults.Summary.ReasonPlural", {count: items.length});
};

interface ProductInfoProps {
  sourceMarketplace?: AmazonMarketplace;
  destinationMarketplace?: AmazonMarketplace;
}
const ProductInfo = ({sourceMarketplace, destinationMarketplace}: ProductInfoProps) => {
  const {t} = useTranslation();
  const {isUserLoggedIn} = useProfile();
  const [dimensionInMetric, setDimensionInMetric] = useState("");
  const [weightInMetric, setWeightInMetric] = useState("");
  const [dimensionalWeightInMetric, setDimensionalWeightInMetric] = useState("");
  const [markReviewLoading, setMarkReviewLoading] = useState<boolean>(false);
  const [addingOrRemovingFromFavorites, setAddingOrRemovingFromFavorites] = useState<boolean>(false);
  const dispatch = useDispatch();
  const LIBRE_UNIT = 0.45359237; // 1lb equals to 0.45359237kg
  const INCH_UNIT = 2.54; // 1 inch equals to 2.54cm

  const {details, loading} = useSelector(ProductDetailsSlice);

  const onLoad = useCallback(() => {
    if (details.productInfo) {
      const splitDimension = details.productInfo.dimensions?.replaceAll(" ", "").split("*");
      if (splitDimension) {
        let length = parseFloat(splitDimension[0]);
        let width = parseFloat(splitDimension[1]);
        let height = parseFloat(splitDimension[2]);

        if (length && width && height) {
          length = length * INCH_UNIT;
          width = width * INCH_UNIT;
          height = height * INCH_UNIT;

          setDimensionInMetric(`${length.toFixed(2)} * ${width.toFixed(2)} * ${height.toFixed(2)}`);
        }
      }

      if (details.productInfo.weight) {
        setWeightInMetric((details.productInfo.weight * LIBRE_UNIT).toFixed(2).toString());
      }
      if (details.productInfo.dimensionalWeight) {
        setDimensionalWeightInMetric((details.productInfo.dimensionalWeight * LIBRE_UNIT).toFixed(2).toString());
      }
    }
  }, [details]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const renderDimensions = (showIcons: boolean, value?: string, statusValue?: boolean): JSX.Element => {
    return (
      <>
        {value != null ? (
          <Row>
            <Col className="d-flex justify-content-end pe-0">{`${value}`}</Col>
            <Col xs="auto" className="ps-0">
              {showIcons && (statusValue ? <i className="mdi mdi-check text-success ms-1"></i> : <i className="mdi mdi-alert align-middle text-warning ms-1"></i>)}
            </Col>
          </Row>
        ) : (
          <span>{t("NA")}</span>
        )}
      </>
    );
  };

  const handleMarkAsReviewed = async () => {
    setMarkReviewLoading(true);
    if (details.productInfo.isReviewed === true) {
      await markSearchProductReviewed({userSearchId: details.userSearchId, userSearchProductId: details.productInfo.userSearchProductId!, isReviewed: false}, "ProductDetails")(dispatch);
    } else {
      await markSearchProductReviewed({userSearchId: details.userSearchId, userSearchProductId: details.productInfo.userSearchProductId!, isReviewed: true}, "ProductDetails")(dispatch);
    }
    setMarkReviewLoading(false);
  };

  const handleAddToFavoriteList = async () => {
    preventScrollUp();
    const {asin, userFavorite, targetMarketplace, userSearchProductId} = details.productInfo;

    if (userFavorite) {
      setAddingOrRemovingFromFavorites(true);
      await removeFavoriteItem({userSearchProductId: userSearchProductId!, userFavoriteId: userFavorite.userFavoriteId, asin: asin!, marketplace: targetMarketplace}, true, "ProductDetails")(dispatch);
      setAddingOrRemovingFromFavorites(false);
    } else {
      AddToFavoritesModal.open({userSearchProductId: details.productInfo.userSearchProductId!, asin: asin!, marketplaceTarget: targetMarketplace});
    }
  };

  return (
    <Card className="card-animate">
      <CardBody className="common-css pb-0">
        <Row className="g-4">
          <Col className="mx-auto">
            <div className="product-img-slider product-img-container">
              <img src={details?.productInfo?.productImage ? `https://m.media-amazon.com/images/I/${details?.productInfo?.productImage}` : errorImage} alt="" className="rounded w-100" />
            </div>
            <div className="mt-2 mx-auto" style={{width: "80%"}}>
              {isUserLoggedIn() && details?.productInfo?.amazonBusinessModel !== AmazonBusinessModel.DROPSHIPPING && (
                <>
                  <Button
                    className={"rounded-pill p-0 btn-secondary"}
                    id={`SellThisButton`}
                    onClick={() => {
                      if (details.showCheckList) {
                        CheckListModal.open({
                          amazonBusinessModel: details.productInfo?.amazonBusinessModel!,
                          asin: details.productInfo?.asin!,
                          checkList: details.productInfo.checkList,
                          marketplaceTarget: details.productInfo?.targetMarketplace!,
                          purchasePrice: details.productInfo.amazonBusinessModel === AmazonBusinessModel.WHOLESALE ? details.productInfo?.purchasePriceSource : undefined,
                          exchangeRateSource: details.productInfo.amazonBusinessModel === AmazonBusinessModel.WHOLESALE ? details.profitAnalysis?.exchangeRateSource : undefined,
                        });
                      } else {
                        SellThisModal.open({
                          asin: details.productInfo?.asin!,
                          marketplace: details.productInfo?.targetMarketplace!,
                          purchasePrice: details.productInfo.amazonBusinessModel === AmazonBusinessModel.WHOLESALE ? details.productInfo?.purchasePriceSource : undefined,
                          exchangeRateSource: details.productInfo.amazonBusinessModel === AmazonBusinessModel.WHOLESALE ? details.profitAnalysis?.exchangeRateSource : undefined,
                        });
                        CheckListModal.close();
                      }
                    }}
                  >
                    <img src={logoDarkTransparent} alt="" width="100%" className="px-2 py-1" />
                  </Button>
                  <DefaultUncontrolledTooltip target={`SellThisButton`}>{t("SearchResults.SellThisButton")}</DefaultUncontrolledTooltip>
                </>
              )}
            </div>
          </Col>

          <Col xl={11} lg={10} sm={9}>
            <div className="mt-xl-0">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <Row>
                    <Col className="col-md">
                      <h4 className="ellipsis-single-line mb-0">{details?.productInfo?.productName}</h4>
                      <div className="text-muted mb-2">
                        {details?.productInfo?.categoryPath
                          ? details?.productInfo?.categoryPath.replaceAll("|", " > ")
                          : details?.productInfo?.categoryName
                          ? details?.productInfo.categoryName
                          : t("Category") + ": " + t("NA")}
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-1">
                    <Col xxl={3} xl={6} sm={12} className="d-flex vstack same-height">
                      <Card>
                        <CardBody className="gap-1 vstack justify-content-between">
                          <div className="d-flex align-items-center justify-content-between">
                            <span className="text-muted">{t("ProductDetails.ProductInfo.Label.Asin")}</span>
                            <div className="hstack gap-3 flex-wrap">
                              <CopyWidget text={details.productInfo?.asin} index={`CopyAsin`} bold={true} />

                              <div className="d-flex align-items-center">
                                {details.productInfo.amazonBusinessModel !== AmazonBusinessModel.WHOLESALE && (
                                  <>
                                    &nbsp;&nbsp;
                                    <DomainToFlag marketplace={sourceMarketplace?.marketplace || ""} />
                                    <Link
                                      to={`https://www.amazon.com/dp/${details.productInfo?.asin}?th=1&psc=1`}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="link-secondary link-offset-2 text-decoration-underline"
                                    >
                                      .com
                                      <i className="ri-arrow-right-up-line"></i>
                                    </Link>
                                    &nbsp;&nbsp;
                                  </>
                                )}
                                <DomainToFlag marketplace={destinationMarketplace?.marketplace || ""} />
                                <Link
                                  to={`https://www.amazon.${destinationMarketplace?.domain}/dp/${details.productInfo?.asin}?th=1&psc=1`}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="link-secondary link-offset-2 text-decoration-underline"
                                >
                                  .{destinationMarketplace?.marketplace?.toLowerCase()}
                                  <i className="ri-arrow-right-up-line"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-between">
                            <span className="text-muted">{t("ProductDetails.ProductInfo.Label.SKU")}</span>
                            <div className="hstack gap-3 flex-wrap">
                              {details.productInfo.sku ? <CopyWidget text={details.productInfo.sku} index={details.productInfo.sku} bold={true} /> : t("NA")}
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-between">
                            <span className="text-muted">{t("ProductDetails.ProductInfo.Label.Stock")}</span>
                            <div className="d-flex align-items-center">
                              <Button id="Stock" color="link" className="p-0 link-body-emphasis link-opacity-75-hover fw-medium d-flex align-items-center">
                                <i className="ri-bar-chart-fill text-success fs-16 me-1"></i>
                                <span className="fw-medium">
                                  <DisplayNumber value={details.productInfo.stockAvailable} renderOnEmpty={<span>0</span>} />
                                </span>
                                <i className="ri-arrow-down-s-line fs-16 ms-1 text-info"></i>
                              </Button>
                              <UncontrolledPopover trigger="legacy" placement="right" target="Stock">
                                <Table className="align-middle table table-hover mb-0">
                                  <thead className="table-light">
                                    <tr>
                                      <th>{t("Inventory.TableColumn.Stock.Tooltip.QuantityType")}</th>
                                      <th>{t("Inventory.TableColumn.Stock.Tooltip.Units")}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>{t("Inventory.TableColumn.Stock.Tooltip.Available")}</td>
                                      <td>
                                        <DisplayNumber value={details.productInfo.stockAvailable} renderOnEmpty={<span className="text-muted">{t("NA")}</span>} />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>{t("Inventory.TableColumn.Stock.Tooltip.Inbound")}</td>
                                      <td>
                                        <DisplayNumber value={details.productInfo.stockInbound} renderOnEmpty={<span className="text-muted">{t("NA")}</span>} />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>{t("Inventory.TableColumn.Stock.Tooltip.Reserved")}</td>
                                      <td>
                                        <DisplayNumber value={details.productInfo.stockReserved} renderOnEmpty={<span className="text-muted">{t("NA")}</span>} />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>{t("Inventory.TableColumn.Stock.Tooltip.Unfulfillable")}</td>
                                      <td>
                                        <DisplayNumber value={details.productInfo.stockUnfulfillable} renderOnEmpty={<span className="text-muted">{t("NA")}</span>} />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>{t("Inventory.TableColumn.Stock.Tooltip.Researching")}</td>
                                      <td>
                                        <DisplayNumber value={details.productInfo.stockResearching} renderOnEmpty={<span className="text-muted">{t("NA")}</span>} />
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">{t("Inventory.TableColumn.Stock.Tooltip.Total")}</th>
                                      <th scope="row">
                                        <DisplayNumber value={details.productInfo.stockTotal} renderOnEmpty={<span className="text-muted">{t("NA")}</span>} />
                                      </th>
                                    </tr>
                                  </tbody>
                                </Table>
                              </UncontrolledPopover>
                            </div>
                          </div>
                          {details?.productInfo?.amazonBusinessModel !== AmazonBusinessModel.DROPSHIPPING && (
                            <div className="d-flex align-items-center justify-content-between">
                              <span className="text-muted">{t("ProductDetails.ProductInfo.Label.FbaEligibility")}</span>
                              <div className="d-flex align-items-center">
                                {details?.productInfo.isFBAEligible !== null ? (
                                  <>
                                    {details?.productInfo?.isFBAEligible ? (
                                      <span className="d-flex align-items-center fw-medium">
                                        {t("ProductDetails.ProductInfo.Label.FbaEligible")}
                                        <i className="bx bx-check fs-18 text-success"></i>
                                      </span>
                                    ) : (
                                      <span className="d-flex align-items-center">
                                        <span className="text-danger">{t("ProductDetails.ProductInfo.Label.FbaNotEligible")}</span>
                                        <i className="bx bx-x fs-18 text-danger"></i>
                                        <Button
                                          color="link"
                                          id={`PopoverIneligibilityReasonList-${details?.productInfo?.asin}`}
                                          className="p-0 ms-1 fs-12 link-secondary link-offset-1 text-decoration-underline common-css"
                                          onClick={() => {
                                            IneligibilityReasonListModal.open({
                                              asin: details?.productInfo?.asin!,
                                              ineligibilityReasonList: details?.salesAnalysis?.ineligibilityReasonList,
                                            });
                                          }}
                                        >
                                          <DecisionReasonsLink items={details?.salesAnalysis?.ineligibilityReasonList?.split(",") ?? []} />
                                        </Button>
                                        <DefaultUncontrolledTooltip target={`PopoverIneligibilityReasonList-${details?.productInfo?.asin}`}>{t("Click for details")}</DefaultUncontrolledTooltip>
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  <span className="text-muted">{t("NA")}</span>
                                )}
                              </div>
                            </div>
                          )}

                          <div className="d-flex align-items-center justify-content-between">
                            <span className="text-muted">{t("ProductDetails.ProductInfo.Label.Review")}</span>
                            <span className="fw-medium">
                              {details.productInfo?.reviewCount != null ? (
                                <>
                                  <DisplayNumber className="me-2 text-muted" value={details.productInfo?.reviewRating} decimals={1} />
                                  <Rating
                                    initialRating={details.productInfo?.reviewRating ?? 0}
                                    fractions={2}
                                    emptySymbol="mdi mdi-star-outline fs-16 text-muted"
                                    fullSymbol="mdi mdi-star fs-16 text-warning"
                                    readonly={true}
                                  />
                                  <span className="text-muted">
                                    {details.productInfo?.reviewCount > 1
                                      ? t("ProductDetails.ProductInfo.Label.ReviewCountPlural", {value: details.productInfo?.reviewCount})
                                      : t("ProductDetails.ProductInfo.Label.ReviewCount", {value: details.productInfo?.reviewCount})}
                                  </span>
                                </>
                              ) : (
                                t("NA")
                              )}
                            </span>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col xxl={3} xl={6} sm={12} className="d-flex vstack same-height">
                      <Card>
                        <CardBody className="gap-1 justify-content-between vstack">
                          <div className="d-flex align-items-center justify-content-between">
                            <span className="text-muted d-flex align-items-center">
                              {t("ProductDetails.ProductInfo.Label.Dimensions", {value: "(inch)"})}
                              <i id="PopoverDimensions-inch" className="ms-1 bx bxs-info-circle text-secondary"></i>
                              <DefaultUncontrolledTooltip target="PopoverDimensions-inch">{t("ProductDetails.ProductInfo.Tooltip.Dimensions")}</DefaultUncontrolledTooltip>
                            </span>
                            <span className="d-flex align-items-center fw-medium">
                              {renderDimensions(
                                details?.productInfo?.amazonBusinessModel !== AmazonBusinessModel.DROPSHIPPING,
                                `${details.productInfo?.dimensions} inch`,
                                details.productInfo?.dimensionStatus,
                              )}
                            </span>
                          </div>
                          <div className="d-flex align-items-center justify-content-between">
                            <span className="text-muted d-flex align-items-center">
                              {t("ProductDetails.ProductInfo.Label.Dimensions", {value: "(cm)"})}
                              <i id="PopoverDimensions-cm" className="ms-1 bx bxs-info-circle text-secondary"></i>
                              <DefaultUncontrolledTooltip target="PopoverDimensions-cm">{t("ProductDetails.ProductInfo.Tooltip.Dimensions")}</DefaultUncontrolledTooltip>
                            </span>
                            <span className="d-flex align-items-center fw-medium">
                              {renderDimensions(details?.productInfo?.amazonBusinessModel !== AmazonBusinessModel.DROPSHIPPING, `${dimensionInMetric} cm`, details.productInfo?.dimensionStatus)}
                            </span>
                          </div>
                          <div className="d-flex align-items-center justify-content-between">
                            <span className="text-muted">{t("ProductDetails.ProductInfo.Label.Weight", {value: "(lb)"})}</span>
                            <span className="d-flex align-items-center fw-medium">
                              {renderDimensions(
                                details?.productInfo?.amazonBusinessModel !== AmazonBusinessModel.DROPSHIPPING,
                                `${details.productInfo?.weight} lb (${weightInMetric} kg)`,
                                details.productInfo?.weightStatus,
                              )}
                            </span>
                          </div>
                          <div className="d-flex align-items-center justify-content-between">
                            <span className="text-muted">
                              {t("ProductDetails.ProductInfo.Label.DimensionalWeight", {value: "(lb)"})}
                              <i id="PopoverDimensionalWeight" className="ms-1 bx bxs-info-circle text-secondary"></i>
                              <DefaultUncontrolledTooltip target="PopoverDimensionalWeight" placement={"bottom"}>
                                {t("Definitions.DimensionalWeight")}
                              </DefaultUncontrolledTooltip>
                            </span>
                            {renderDimensions(
                              details?.productInfo?.amazonBusinessModel !== AmazonBusinessModel.DROPSHIPPING,
                              `${details.productInfo?.dimensionalWeight} lb (${dimensionalWeightInMetric} kg)`,
                              details.productInfo?.dimensionalWeightStatus,
                            )}
                          </div>
                          {(() => {
                            const diff = moment().diff(details?.productInfo.launchDate, "days");
                            const cssStyle = diff >= 90 ? "text-success" : "text-danger";
                            const icon = diff >= 90 ? "mdi mdi-check-circle" : "mdi mdi-close-circle";
                            return (
                              <>
                                <div className="d-flex align-items-center justify-content-between">
                                  <span className="text-muted">{t("ProductDetails.ProductInfo.Label.ListingLaunchDate")}</span>
                                  <span className={`${cssStyle} fw-medium`}>
                                    <Moment locale={i18n.language} fromNow>
                                      {details.productInfo?.launchDate}
                                    </Moment>
                                    <i className={`${icon} fs-14 align-middle ms-1`}></i>
                                  </span>
                                </div>
                              </>
                            );
                          })()}
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xxl={3} xl={6} sm={12} className="d-flex vstack same-height xs-hstack">
                      <RestrictionCheck />
                    </Col>
                    <Col xs="auto" className="d-flex same-height mb-3 ps-0">
                      <div className="gap-1 d-flex justify-content-end flex-row flex-xl-column mb-3 mb-xxl-0 ">
                        <Button
                          type="button"
                          id={`MarkReviewButton-${details.productInfo.userSearchProductId}`}
                          className="p-0 px-1"
                          color={details.productInfo.isReviewed ? "light" : "ghost-dark"}
                          onClick={handleMarkAsReviewed}
                          disabled={markReviewLoading}
                        >
                          <i className={`ri-check-double-fill fs-20  ${details.productInfo.isReviewed ? "text-secondary" : "text-primary-emphasis"}`}></i>
                        </Button>
                        <DefaultUncontrolledTooltip target={`MarkReviewButton-${details.productInfo.userSearchProductId}`}>
                          {details.productInfo.isReviewed ? t("SearchResults.Summary.Tooltip.NotReviewed") : t("SearchResults.Summary.Tooltip.IsReviewed")}
                        </DefaultUncontrolledTooltip>

                        <Button
                          id={`ReAnalyzeProductButton-${details.productInfo.userSearchProductId}`}
                          type="button"
                          color="ghost-secondary"
                          className="p-0 px-1"
                          onClick={() => {
                            ReAnalyzeModal.open({
                              asin: details.productInfo.asin,
                              store: details.productInfo?.userStoreId ?? details.productInfo.targetMarketplace,
                              businessModel: details.productInfo.amazonBusinessModel,
                              currency: details.productInfo.currencyCodeTarget,
                              price: details.productInfo.purchasePriceSource?.toFixed(2).replace(",", ".") || "",
                              type: AnalyzeType.SEARCH_PRODUCT,
                              searchName: details.productInfo.asin,
                            });
                          }}
                          disabled={loading.save}
                        >
                          <i className="mdi mdi-chart-timeline-variant-shimmer fs-20 text-info"></i>
                        </Button>
                        <DefaultUncontrolledTooltip target={`ReAnalyzeProductButton-${details.productInfo.userSearchProductId}`}>
                          {t("SearchResults.Summary.Tooltip.ReanalyzeProduct")}
                        </DefaultUncontrolledTooltip>

                        <Button
                          type="button"
                          id={`AddToFavoriteButton-${details.productInfo.userSearchProductId}`}
                          color={details.productInfo.userFavorite ? "light" : "ghost-dark"}
                          className="p-0 px-1"
                          onClick={handleAddToFavoriteList}
                          disabled={addingOrRemovingFromFavorites}
                        >
                          <i
                            className={`mdi ${details.productInfo.userFavorite?.shareType === ShareType.SHARED ? "mdi-account-star" : "mdi-star"}  fs-20 ${details.productInfo.userFavorite &&
                              "text-warning"}`}
                          ></i>
                        </Button>
                        <DefaultUncontrolledTooltip target={`AddToFavoriteButton-${details.productInfo.userSearchProductId}`}>
                          {details.productInfo.userFavorite ? t("SearchResults.Summary.Tooltip.RemoveFromFavorites") : t("SearchResults.Summary.Tooltip.AddToFavorites")}
                        </DefaultUncontrolledTooltip>

                        <Button
                          type="button"
                          id={`NotesButton-${details.productInfo.userSearchProductId}`}
                          color={details.productInfo.userSearchNote?.deleted === false ? "light" : "ghost-primary"}
                          className="p-0 px-1"
                          onClick={() =>
                            NotesModal.open({
                              asin: details.productInfo.asin ?? "",
                              userSearchId: details.userSearchId,
                              marketplaceTarget: details.productInfo.targetMarketplace,
                              note: details.productInfo.userSearchNote,
                              from: "ProductDetails",
                            })
                          }
                        >
                          <i className={`mdi mdi-note-edit-outline fs-20 ${details.productInfo.userSearchNote?.deleted === false && "text-info"}`}></i>
                        </Button>
                        <DefaultUncontrolledTooltip target={`NotesButton-${details.productInfo.userSearchProductId}`}>
                          {details.productInfo.userSearchNote?.deleted === false ? t("SearchResults.Summary.Tooltip.YouHaveNote") : t("SearchResults.Summary.Tooltip.CreateNote")}
                        </DefaultUncontrolledTooltip>

                        <Button
                          type="button"
                          id={`ReportABugButton-${details.productInfo.userSearchProductId}`}
                          color="ghost-dark"
                          className="p-0 px-1"
                          onClick={async () => ReportErrorModal.open({asin: details.productInfo.asin ?? "", marketplaceTarget: details.productInfo.targetMarketplace})}
                        >
                          <i className="ri-bug-fill fs-18 text-primary-emphasis"></i>
                        </Button>
                        <DefaultUncontrolledTooltip target={`ReportABugButton-${details.productInfo.userSearchProductId}`}>{t("SearchResults.Summary.Tooltip.ReportError")}</DefaultUncontrolledTooltip>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default ProductInfo;
