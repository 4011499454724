import {useMemo, useCallback} from "react";
import {formatCurrency} from "helpers/utilities";
import CountUp from "react-countup";
import _ from "lodash";
import classNames from "classnames";
import {AllMarketplaces} from "helpers/marketplace_helper";

interface DisplayPriceProps {
  source?: string;
  value?: number;
  decimals: number;
  countUp?: boolean;
  prefix?: string;
  suffix?: string;
  className?: string;
  renderOnEmpty?: JSX.Element;
  notation: "decimal" | "abbreviated";
  busy?: boolean;
  id?: string;
}

const DisplayPrice = (props: DisplayPriceProps) => {
  const hasPlaceholder = props.busy !== undefined && props.busy ? "placeholder placeholder-glow" : "";

  // Improved toFixedFloor function to strictly round down
  const toFixedFloor = (value: number, decimals: number): number => {
    if (isNaN(value) || value === undefined) return 0;
    const factor = Math.pow(10, decimals);
    // Using trunc instead of floor to handle negative numbers properly
    return Math.trunc(value * factor) / factor;
  };

  const getCurrencySymbol = useMemo((): string => {
    return AllMarketplaces.find((amazonMarketplaceInfo: any) => amazonMarketplaceInfo.currency === props.source)?.priceSign || "";
  }, [props.source]);

  // Prepare the display value by flooring it before any formatting
  const displayValue = useMemo(() => {
    if (_.isNil(props.value)) return undefined;
    return toFixedFloor(props.value, props.decimals);
  }, [props.value, props.decimals]);

  const formattingFn = useCallback(
    (value: number) => {
      // Use the already floored value in formatCurrency
      const flooredValue = toFixedFloor(value, props.decimals);
      return formatCurrency(getCurrencySymbol, props.decimals, props.notation, flooredValue, props.source);
    },
    [getCurrencySymbol, props.decimals, props.source, props.notation],
  );

  return (
    <span id={props.id} className={classNames("text-nowrap", props.className, hasPlaceholder)}>
      {props.source &&
        (props.countUp ? (
          !_.isNil(displayValue) ? (
            <CountUp
              start={0}
              prefix={`${props.prefix || ""}${getCurrencySymbol}`}
              suffix={` ${props.source}${props.suffix || ""}`}
              decimals={props.decimals}
              duration={2}
              end={displayValue}
              formattingFn={formattingFn}
            />
          ) : (
            props.renderOnEmpty
          )
        ) : !_.isNil(displayValue) ? (
          `${props.prefix || ""}${formatCurrency(getCurrencySymbol, props.decimals, props.notation, displayValue, props.source)}${props.suffix || ""}`
        ) : (
          props.renderOnEmpty
        ))}
    </span>
  );
};

export default DisplayPrice;
