import { createSelector } from "reselect";
import { RootState } from "slices";

export const DashboardSlice = createSelector(
  (state: RootState) => state,
  (state) => ({
      loading: state.Dashboard.loading,
      statistics: state.Dashboard.statistics,
      filter: state.Dashboard.filter,
      error: state.Dashboard.error,
  }),
);
