import Dialog, {DialogRef} from "../Dialog";
import {Card, CardBody, Col, Nav, NavItem, NavLink, Row} from "reactstrap";
import {createSelector} from "reselect";
import {useLocation, useNavigate} from "react-router-dom";
import {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {RootState} from "slices";
import {useSelector} from "react-redux";
import classnames from "classnames";
import Restricted from "../Restricted";

interface ProfileLayoutProps {
  hasAnyChanges: boolean;
  navigateTo: string;
  children: JSX.Element;
}
const ProfileLayout = (props: ProfileLayoutProps) => {
  const {t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const unsavedChangesDialogRef = useRef<DialogRef>(null);
  const [redirectUrl, setRedirectUrl] = useState<string>("");
  const settingsPageData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Settings.loading,
    }),
  );
  const {loading} = useSelector(settingsPageData);

  const handleNavigate = (path: string) => {
    // Don't navigate if we're already on the path
    if (location.pathname === path) {
      return;
    }

    setRedirectUrl(path);
    if (props.hasAnyChanges) {
      unsavedChangesDialogRef.current?.show();
    } else {
      navigate(path, { state: { from: location.pathname } });
    }
  };

  return (
    <div>
      <Row>
        <Col xxl={3}>
          <Card>
            <CardBody className="p-4">
              <Nav vertical pills card>
                <NavItem>
                  <NavLink className={classnames({active: location.pathname === "/account/profile"}, "align-items-center d-flex")} onClick={() => handleNavigate("/account/profile")} role="button">
                    <i className="mdi mdi-account-outline fs-16 me-2"></i>
                    {t("Account.ProfileInformation.Title")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({active: location.pathname === "/account/security"}, "align-items-center d-flex")}
                    onClick={() => handleNavigate("/account/security")}
                    role="button"
                  >
                    <i className="mdi mdi-lock-outline fs-16 me-2"></i>
                    {t("Account.Security.Title")}
                  </NavLink>
                </NavItem>
                <Restricted require="account.subscription" read>
                  <NavItem>
                    <NavLink
                      className={classnames({active: location.pathname.startsWith("/account/subscription")}, "align-items-center d-flex")}
                      onClick={() => handleNavigate("/account/subscription")}
                      role="button"
                    >
                      <i className="mdi mdi-credit-card-outline fs-16 me-2"></i>
                      {t("Account.Subscription.Title")}
                    </NavLink>
                  </NavItem>
                </Restricted>

                <Restricted require="account.paymentHistory" read>
                  <NavItem>
                    <NavLink
                      className={classnames({active: location.pathname === "/account/payment-history"}, "align-items-center d-flex")}
                      onClick={() => handleNavigate("/account/payment-history")}
                      role="button"
                    >
                      <i className="mdi mdi-history fs-16 me-2"></i>
                      {t("Account.PaymentHistory.Title")}
                    </NavLink>
                  </NavItem>
                </Restricted>

                <Restricted require="account.team" read>
                  <NavItem>
                    <NavLink className={classnames({active: location.pathname === "/account/team"}, "align-items-center d-flex")} onClick={() => handleNavigate("/account/team")} role="button">
                      <i className="mdi mdi-account-group fs-16 me-2"></i>
                      {t("Account.Team.Title")}
                    </NavLink>
                  </NavItem>
                </Restricted>

                <Restricted require="account.notification" read>
                  <NavItem>
                    <NavLink
                      className={classnames({active: location.pathname.startsWith("/account/notifications")}, "align-items-center d-flex")}
                      onClick={() => handleNavigate("/account/notifications")}
                      role="button"
                    >
                      <i className="bx bxs-bell fs-16 me-2"></i>
                      {t("Account.Notifications.Title")}
                    </NavLink>
                  </NavItem>
                </Restricted>

                <Restricted require="account.limitUsageHistory" read>
                  <NavItem>
                    <NavLink
                      className={classnames({active: location.pathname.startsWith("/account/limit-usage-history")}, "align-items-center d-flex")}
                      onClick={() => handleNavigate("/account/limit-usage-history")}
                      role="button"
                    >
                      <i className="mdi mdi-speedometer fs-16 me-2"></i>
                      {t("Account.LimitUsageHistory.Title")}
                    </NavLink>
                  </NavItem>
                </Restricted>

                <NavItem>
                    <NavLink
                      className={classnames({active: location.pathname.startsWith("/account/support")}, "align-items-center d-flex")}
                      onClick={() => handleNavigate("/account/support")}
                      role="button"
                    >
                      <i className="mdi mdi-lifebuoy  fs-16 me-2"></i>
                      {t("Account.Support.Title")}
                    </NavLink>
                  </NavItem>
              </Nav>
            </CardBody>
          </Card>
        </Col>
        <Col xxl={9}>{props.children}</Col>
      </Row>
      <Dialog
        ref={unsavedChangesDialogRef}
        color="info"
        buttons={["yes", "no"]}
        busy={loading.update}
        iconClass="ri-alert-fill"
        message={t("Account.Dialog.UnsavedChanges.Description")}
        title={t("Account.Dialog.UnsavedChanges.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            navigate(redirectUrl);
          }
          unsavedChangesDialogRef.current?.hide();
        }}
      />
    </div>
  );
};

export default ProfileLayout;
