import Dialog, {DialogRef} from "Components/Common/Dialog";
import BreadCrumb, {BreadcrumbMenuItem} from "Components/Common/BreadCrumb";
import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {deleteUserPaymentMethod, getUserPaymentMethods, getUserSubscriptionPackage, resumeUserSubscription, retrySubscriptionPayment, setAsDefaultPaymentMethod} from "slices/account/thunk";
import {Button, Card, CardBody, CardHeader, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown} from "reactstrap";
import {Link, useNavigate} from "react-router-dom";
import {UserSubscriptionStatus} from "models/enums/user_subscription_status";
import {UserPaymentMethod} from "models/user_payment_methods";
import {RootState} from "slices";
import {createSelector} from "reselect";
import {useProfile} from "Components/Hooks/useProfile";
import {convertTimeByTimeZone, getToday} from "helpers/utilities";
import {CancelSubscriptionModal} from "../CancelSubscription/Modals/CancelSubscription";
import CreateUserPaymentMethod from "./Modal/CreateUserPaymentMethod";
import UpdateUserPaymentMethod from "./Modal/UpdateUserPaymentMethod";
import Loader from "Components/Common/Loader";
import visaLogo from "assets/images/brands/visa.png";
import mastercardLogo from "assets/images/brands/mastercard.png";
import americanExpressLogo from "assets/images/brands/american_express.png";
import discoverLogo from "assets/images/brands/discover.png";
import jcbLogo from "assets/images/brands/jcb.png";
import dinersClubLogo from "assets/images/brands/diners_club.png";
import unionPayLogo from "assets/images/brands/union_pay.png";
import moment from "moment";
import ProfileLayout from "Components/Common/Layout/ProfileLayout";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";
import _ from "lodash";
import DisplayPrice from "Components/Common/DisplayPrice";

export type UserPaymentMethodListRef = {
  reload: VoidFunction;
};
const PAGE_IDENTIFIER = "account.subscription";
const Subscription = () => {
  const {t} = useTranslation();
  const {userProfile, hasPermission} = useProfile();
  const [trialRemainingDay, setTrialRemainingDay] = useState<number>(0);
  const [paymentMethod, setPaymentMethod] = useState<UserPaymentMethod>();
  const [subscriptionEndDate, setSubscriptionEndDate] = useState<string>("");
  const [isEditing, setIsEditing] = useState(false);
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const setAsDefaultDialogRef = useRef<DialogRef>(null);
  const deleteDialogRef = useRef<DialogRef>(null);
  const resumeSubscriptionDialogRef = useRef<DialogRef>(null);
  const retrySubscriptionPaymentDialogRef = useRef<DialogRef>(null);

  const accountData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Account.loading,
      paymentMethods: state.Account.paymentMethods,
      userSubscription: state.Account.userSubscription,
      userBalance: state.Account.userBalance,
    }),
  );

  const {loading, paymentMethods, userSubscription, userBalance} = useSelector(accountData);

  useEffect(() => {
    if (hasPermission(PAGE_IDENTIFIER)) {
      dispatch(getUserSubscriptionPackage());
      dispatch(getUserPaymentMethods());
    }
  }, [dispatch, navigate]); //eslint-disable-line

  useEffect(() => {
    if (userProfile && userSubscription) {
      const date = moment(convertTimeByTimeZone(userSubscription.endDate, userProfile?.timezone)).format("D MMM YYYY HH:mm");
      setSubscriptionEndDate(date);
    }
  }, [userProfile, userSubscription]);

  useEffect(() => {
    if (userSubscription) {
      if (userSubscription.status === UserSubscriptionStatus.Trial) {
        const startMoment = moment(getToday().toDate());
        const endMoment = moment(userSubscription.endDate);
        const differenceInDays = endMoment.diff(startMoment, "days");
        setTrialRemainingDay(differenceInDays);
      }
    }
  }, [userSubscription]);

  document.title = t("PageTitles.Subscription");

  const breadcrumbMenus: BreadcrumbMenuItem[] = [
    {
      label: t("Account.Title"),
      url: "/account/subscription",
    },
    {
      label: t("Account.Subscription.Title"),
      url: "",
    },
  ];
  return (
    <Restricted require={PAGE_IDENTIFIER} read fallback={() => <Unauthorized />}>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Account.Title")} menus={breadcrumbMenus} />
          <ProfileLayout hasAnyChanges={false} navigateTo="">
            <>
              <Row>
                <Col xs={12}>
                  <Card className="subscription-card border-0 shadow-none">
                    <CardBody>
                      {loading.userSubscription ? (
                        <Loader />
                      ) : (
                        userSubscription && (
                          <>
                            {(userSubscription.status === UserSubscriptionStatus.Active ||
                              userSubscription.status === UserSubscriptionStatus.Trial ||
                              userSubscription.status === UserSubscriptionStatus.CanceledAtPeriodEnd) && (
                              <div className="subscription-wrapper">
                                <div className="subscription-header position-relative mb-4 pb-4 border-bottom">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                      <div>
                                        <div className="d-flex align-items-center gap-2">
                                          <h3 className="mb-0">{userSubscription.subscription.name}</h3>
                                          {userSubscription.status === UserSubscriptionStatus.Trial && trialRemainingDay > 0 && (
                                            <div className="badge bg-warning bg-opacity-10 text-warning rounded-pill px-2">
                                              <i className="ri-timer-line me-1 align-middle"></i>
                                              {t("Account.Subscription.TrialDay", {day: trialRemainingDay})}
                                            </div>
                                          )}
                                        </div>
                                        <p className="text-muted mb-0 mt-1">
                                          {userSubscription.billingPeriodMonth === 1
                                            ? t("Account.Subscription.Monthly")
                                            : userSubscription.billingPeriodMonth === 6
                                            ? t("Account.Subscription.6-Month")
                                            : t("Account.Subscription.12-Month")}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="text-end">
                                      <div className="price-tag">
                                        <h2 className="mb-0 fw-semibold">
                                          $
                                          {userSubscription.billingPeriodMonth === 1
                                            ? userSubscription.subscription.priceMonth
                                            : userSubscription.billingPeriodMonth === 6
                                            ? userSubscription.subscription.priceSixMonth
                                            : userSubscription.subscription.priceYear}
                                        </h2>
                                        <span className="text-muted fs-13">
                                          per {userSubscription.billingPeriodMonth === 1 ? "month" : userSubscription.billingPeriodMonth === 6 ? "6 months" : "year"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="subscription-stats mb-4">
                                  <Row className="g-4">
                                    <Col sm={4} className="stat-item">
                                      <div className="d-flex align-items-center">
                                        <div className="stat-icon me-3">
                                          <div className="rounded bg-secondary bg-opacity-10 p-2">
                                            <i className="ri-shield-star-line fs-20 text-secondary"></i>
                                          </div>
                                        </div>
                                        <div>
                                          <h4 className="mb-0 fw-semibold">
                                            {userSubscription.status === UserSubscriptionStatus.Active && <span className="text-success">{t("Account.Subscription.ActiveStatus")}</span>}
                                            {userSubscription.status === UserSubscriptionStatus.Trial && <span className="text-warning">{t("Account.Subscription.TrialStatus")}</span>}
                                            {userSubscription.status === UserSubscriptionStatus.CanceledAtPeriodEnd && <span className="text-danger">{t("Account.Subscription.CanceledStatus")}</span>}
                                          </h4>
                                          <p className="text-muted mb-0 fs-13">{t("Account.Subscription.Status")}</p>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col sm={4} className="stat-item">
                                      <div className="d-flex align-items-center">
                                        <div className="stat-icon me-3">
                                          <div className="rounded bg-info bg-opacity-10 p-2">
                                            <i className="ri-calendar-check-line fs-20 text-info"></i>
                                          </div>
                                        </div>
                                        <div>
                                          <h4 className="mb-0 fw-semibold">{subscriptionEndDate}</h4>
                                          <p className="text-muted mb-0 fs-13">
                                            {t("Account.Subscription.NextBilling")} - <DisplayPrice value={userSubscription.nextBillingAmount} decimals={2} source="USD" notation="decimal" />
                                          </p>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col sm={4} className="stat-item">
                                      <div className="d-flex align-items-center">
                                        <div className="stat-icon me-3">
                                          <div className="rounded bg-success bg-opacity-10 p-2">
                                            <i className="ri-wallet-3-line fs-20 text-success"></i>
                                          </div>
                                        </div>
                                        <div>
                                          <h4 className="mb-0 fw-semibold">
                                            <DisplayPrice value={userBalance} decimals={2} source="USD" notation="decimal" />
                                          </h4>
                                          <p className="text-muted mb-0 fs-13">{t("Account.Subscription.YourBalance")}</p>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>

                                {(userSubscription.status === UserSubscriptionStatus.Active || userSubscription.status === UserSubscriptionStatus.Trial) && (
                                  <Restricted require="account.subscription" update>
                                    <div className="subscription-actions pt-2">
                                      <div className="d-flex gap-3">
                                        <Button color="light" className="btn-sm fw-medium" onClick={() => navigate("/account/subscription/cancel")}>
                                          <i className="ri-close-circle-line align-middle me-1"></i>
                                          {t("Account.Subscription.Button.CancelSubscription")}
                                        </Button>
                                        <Link to="/account/subscription/change" className="btn btn-secondary btn-sm fw-medium">
                                          <i className="ri-exchange-line align-middle me-1"></i>
                                          {t("Account.Subscription.Button.SwitchPlan")}
                                        </Link>
                                      </div>
                                    </div>
                                  </Restricted>
                                )}
                              </div>
                            )}

                            {(userSubscription.status === UserSubscriptionStatus.Canceled || _.isEmpty(userSubscription)) && (
                              <div className="subscription-empty text-center vstack gap-3 align-items-center p-3 mt-5">
                                <div className="empty-icon avatar-sm">
                                  <div className="avatar-title bg-warning-subtle text-warning rounded-circle fs-24">
                                    <i className="ri-error-warning-line fs-2 text-warning"></i>
                                  </div>
                                </div>
                                <h4 className="mb-0">{t("Account.Subscription.Error.NoActiveSubscription")}</h4>
                                <Link to="/account/subscription/change" className="btn btn-secondary btn-sm fw-medium">
                                  <i className="ri-add-circle-line me-1 align-middle"></i>
                                  {t("Account.Subscription.CreateSubscription")}
                                </Link>
                                <div className="mt-4">
                                  <span className="text-muted d-block mb-1">{t("Account.Subscription.YourBalance")}</span>
                                  <h3 className="text-primary mb-0">${userBalance}</h3>
                                </div>
                              </div>
                            )}

                            {userSubscription.status === UserSubscriptionStatus.PaymentFailed && (
                              <div className="subscription-error text-center vstack gap-4 align-items-center p-5">
                                <div className="error-icon avatar-sm">
                                  <div className="avatar-title bg-danger-subtle text-danger rounded-circle fs-24">
                                    <i className="ri-error-warning-line fs-2 text-danger"></i>
                                  </div>
                                </div>
                                <h4 className="mb-0">{t("Account.Subscription.Error.PaymentFailed")}</h4>
                                <div className="d-flex gap-3 justify-content-center">
                                  <Button color="secondary" className="btn-sm fw-medium" onClick={() => retrySubscriptionPaymentDialogRef?.current?.show()}>
                                    <i className="ri-restart-line me-1 align-middle"></i>
                                    {t("Account.Subscription.TryPaymentAgain")}
                                  </Button>
                                  <Button
                                    color="danger"
                                    className="btn-sm fw-medium"
                                    onClick={() => {
                                      CancelSubscriptionModal.open({subscriptionId: userSubscription.subscriptionId, cancelAtPeriodEnd: false});
                                    }}
                                  >
                                    <i className="ri-close-circle-line me-1 align-middle"></i>
                                    {t("Account.Subscription.Button.CancelSubscription")}
                                  </Button>
                                </div>
                              </div>
                            )}

                            {userSubscription.status === UserSubscriptionStatus.CanceledAtPeriodEnd && (
                              <div className="subscription-canceled text-center vstack gap-3 align-items-center p-3 mt-5">
                                <div className="canceled-icon avatar-sm">
                                  <div className="avatar-title bg-danger-subtle text-danger rounded-circle fs-24">
                                    <i className="ri-error-warning-line fs-2 text-danger"></i>
                                  </div>
                                </div>
                                <h5 className="mb-0">
                                  {t("Account.Subscription.SubscriptionCancelled", {
                                    date: subscriptionEndDate,
                                  })}
                                </h5>
                                <Button color="secondary" className="btn-sm fw-medium" onClick={() => resumeSubscriptionDialogRef?.current?.show()}>
                                  <i className="bx bx-history me-1 align-middle"></i>
                                  {t("Account.Subscription.Button.ReactivateSubscription")}
                                </Button>
                              </div>
                            )}
                          </>
                        )
                      )}
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} className="mt-0">
                  <Card className="payment-methods-card shadow-sm">
                    <CardHeader className="border-0 bg-transparent">
                      <div className="d-flex align-items-center justify-content-between">
                        <h5 className="mb-0 d-flex align-items-center">
                          <i className="ri-bank-card-line fs-18 me-1 text-primary"></i>
                          {t("Account.Subscription.PaymentMethods")}
                        </h5>
                        <CreateUserPaymentMethod reload={() => dispatch(getUserPaymentMethods())} />
                      </div>
                    </CardHeader>
                    <CardBody className="pt-0">
                      {loading.paymentMethodList ? (
                        <Loader />
                      ) : (
                        <Row className="g-3">
                          {paymentMethods.map((method: UserPaymentMethod) => (
                            <Col key={method.userPaymentMethodId} lg={4} sm={6}>
                              <div className="payment-method-item p-3 rounded-3 bg-light h-100 d-flex align-items-center">
                                <div className="d-flex align-items-center w-100">
                                  <div className="flex-grow-1 d-flex align-items-center">
                                    <div className="avatar-xs bg-white rounded p-1">
                                      <img
                                        src={
                                          method.brand === "visa"
                                            ? visaLogo
                                            : method.brand === "mastercard"
                                            ? mastercardLogo
                                            : method.brand === "amex"
                                            ? americanExpressLogo
                                            : method.brand === "discover"
                                            ? discoverLogo
                                            : method.brand === "jcb"
                                            ? jcbLogo
                                            : method.brand === "diners"
                                            ? dinersClubLogo
                                            : method.brand === "unionpay"
                                            ? unionPayLogo
                                            : ""
                                        }
                                        alt="card-img"
                                        className="img-fluid"
                                      />
                                    </div>
                                    <div className="ms-3">
                                      <div className="d-flex align-items-start mb-1">
                                        <h6 className="mb-0">
                                          {_.toUpper(method.brand)} **** {method.last4}
                                        </h6>
                                        {method.isDefault && (
                                          <span className="badge bg-success-subtle text-success ms-2">
                                            <i className="ri-check-line me-1 align-middle"></i>
                                            {t("Account.Subscription.Badge.DefaultPaymentMethod")}
                                          </span>
                                        )}
                                      </div>
                                      <p className="text-muted small mb-0">
                                        {t("Account.Subscription.ExpireDate")}: {method.expireMonth}
                                        <i className="mdi mdi-slash-forward"></i>
                                        {method.expireYear}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="flex-shrink-0">
                                    <Restricted require="account.subscription" update>
                                      <UncontrolledDropdown>
                                        <DropdownToggle tag="a" className="text-muted ms-2 cursor-pointer">
                                          <i className="ri-more-2-fill fs-16"></i>
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-end">
                                          <DropdownItem
                                            onClick={() => {
                                              setPaymentMethod(method);
                                              setAsDefaultDialogRef.current?.show();
                                            }}
                                            disabled={method.isDefault}
                                          >
                                            <i className="ri-markup-fill align-bottom me-2 text-muted"></i>
                                            {t("Account.Subscription.ContextMenu.SetAsDefault")}
                                          </DropdownItem>
                                          <DropdownItem
                                            onClick={() => {
                                              setPaymentMethod(method);
                                              setIsEditing(true);
                                            }}
                                          >
                                            <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                                            {t("Account.Subscription.ContextMenu.Edit")}
                                          </DropdownItem>
                                          <DropdownItem divider />
                                          <DropdownItem
                                            className={method.isDefault !== true ? "text-danger" : ""}
                                            disabled={method.isDefault}
                                            onClick={() => {
                                              setPaymentMethod(method);
                                              if (paymentMethods.length > 1) {
                                                deleteDialogRef.current?.show();
                                              }
                                            }}
                                          >
                                            <i className="ri-delete-bin-fill align-bottom me-2"></i>
                                            {t("Delete")}
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </Restricted>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      )}
                      <UpdateUserPaymentMethod reload={() => dispatch(getUserPaymentMethods())} paymentMethod={paymentMethod} isOpen={isEditing} toggle={() => setIsEditing(false)} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          </ProfileLayout>
        </Container>
      </div>
      <Dialog
        ref={setAsDefaultDialogRef}
        color="success"
        buttons={["yes", "no"]}
        busy={loading.update}
        iconClass="ri-checkbox-circle-fill"
        message={t("Account.Subscription.Dialog.SetAsDefaultPaymentMethod.Description")}
        title={t("Account.Subscription.Dialog.SetAsDefaultPaymentMethod.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            if (paymentMethod) {
              await setAsDefaultPaymentMethod(paymentMethod.userPaymentMethodId)(dispatch);
              setAsDefaultDialogRef.current?.hide();
              dispatch(getUserPaymentMethods());
            }
          }
          setAsDefaultDialogRef.current?.hide();
        }}
      />
      <Dialog
        ref={deleteDialogRef}
        color="danger"
        buttons={["yes", "no"]}
        busy={loading.delete}
        iconClass="ri-delete-bin-line"
        message={t("Account.Subscription.Dialog.DeletePaymentMethod.Description")}
        title={t("Account.Subscription.Dialog.DeletePaymentMethod.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            if (paymentMethod) {
              await deleteUserPaymentMethod(paymentMethod.userPaymentMethodId)(dispatch);
              deleteDialogRef.current?.hide();
              dispatch(getUserPaymentMethods());
            }
          }
          deleteDialogRef.current?.hide();
        }}
      />
      <Dialog
        ref={resumeSubscriptionDialogRef}
        color="success"
        buttons={["yes", "no"]}
        busy={loading.resumeSubscription}
        iconClass="ri-play-circle-fill"
        message={t("Account.Subscription.Dialog.ResumeSubscription.Description")}
        title={t("Account.Subscription.Dialog.ResumeSubscription.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            await resumeUserSubscription(userSubscription.userSubscriptionId)(dispatch);
          }
          resumeSubscriptionDialogRef.current?.hide();
        }}
      />

      <Dialog
        ref={retrySubscriptionPaymentDialogRef}
        color="success"
        buttons={["yes", "no"]}
        busy={loading.retryPayment}
        iconClass="ri-play-circle-fill"
        message={t("Account.Subscription.Dialog.RetryPayment.Description")}
        title={t("Account.Subscription.Dialog.RetryPayment.Title")}
        onButtonClick={async (button, hide) => {
          if (button === "yes") {
            await retrySubscriptionPayment(userSubscription.userSubscriptionId)(dispatch);
            retrySubscriptionPaymentDialogRef.current?.hide();
          }
          retrySubscriptionPaymentDialogRef.current?.hide();
        }}
      />
    </Restricted>
  );
};

export default Subscription;
