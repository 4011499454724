import ProfitCalculator, {ProfitCalculatorProps} from "Components/Common/Calculator/_ProfitCalculator";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {createTypedModal} from "helpers/modal_helpers";
import {useTranslation} from "react-i18next";

export const ProfitCalculatorModal = createTypedModal<ProfitCalculatorProps>("profit_calculator");
const ProfitCalculatorContent = () => {
  const {t} = useTranslation();
  const {open, data} = ProfitCalculatorModal.useModal();

  const toggle = () => ProfitCalculatorModal.close();
  if (!open) return null;
  else
    return (
      <Modal isOpen={open} toggle={toggle} centered size="md">
        <ModalHeader className="p-3" toggle={toggle}>
          {t("Shipments.ShippedOrCompleted.Dialog.ProfitCalculator.Title")}
        </ModalHeader>
        <ModalBody className="p-0">
          <ProfitCalculator
            title={data?.title ?? ""}
            amazonBusinessModel={data!.amazonBusinessModel}
            itemProfitAnalysis={data!.itemProfitAnalysis}
            sourceMarketplace={data!.sourceMarketplace}
            destinationMarketplace={data!.destinationMarketplace}
            buyBoxType={data!.buyBoxType}
            offerCount={data!.offerCount}
            userSearchProductId={data!.userSearchProductId}
            inPopup={true}
          />
        </ModalBody>
        <ModalFooter>
          <div className="hstack justify-content-end">
            <Button type="button" className="btn btn-light" onClick={toggle}>
              {t("Close")}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
};

export default ProfitCalculatorContent;
