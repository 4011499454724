import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {MenuItem} from "models/menu";
import {useProfile} from "Components/Hooks/useProfile";
import {ConstantPage} from "helpers/permission_helper";
import {getMenuModeLocalData} from "helpers/local_storage";

const NavData = () => {
  const navigate = useNavigate();

  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isStores, setIsStores] = useState(false);
  const [isAnalyze, setIsAnalyze] = useState(false);
  const [isShipments, setIsShipments] = useState(false);
  const [isInventory, setIsInventory] = useState(false);
  const [isOrders, setIsOrders] = useState(false);
  const [isUsers, setIsUsers] = useState(false);
  const [isAdminSearch, setIsAdminSearch] = useState(false);
  const [isAdminScanAndSave, setIsAdminScanAndSave] = useState(false);
  const [isAdminUsers, setIsAdminUsers] = useState(false);
  const [isAdminSystemMonitoring, setIsAdminSystemMonitoring] = useState(false);

  const {hasPermission, isAdmin} = useProfile();
  const [isCurrentState, setIsCurrentState] = useState<string>("Dashboard");
  const [menuArray, setMenuArray] = useState<MenuItem[]>([]);

  function updateIconSidebar(e: any) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul: any = document.getElementById("two-column-menu");
      const iconItems: any = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        const getID = document.getElementById(id) as HTMLElement;
        if (getID) getID.classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (isCurrentState !== "Dashboard") setIsDashboard(false);
    else setIsDashboard(true);
    if (isCurrentState !== "Stores") setIsStores(false);
    else setIsStores(true);
    if (isCurrentState !== "Analyze") setIsAnalyze(false);
    else setIsAnalyze(true);
    if (isCurrentState !== "Shipments") setIsShipments(false);
    else setIsShipments(true);
    if (isCurrentState !== "Inventory") setIsInventory(false);
    else setIsInventory(true);
    if (isCurrentState !== "Orders") setIsOrders(false);
    else setIsOrders(true);
    if (isCurrentState !== "Users") setIsUsers(false);
    else setIsUsers(true);
    if (isCurrentState !== "AdminSearch") setIsAdminSearch(false);
    else setIsAdminSearch(true);
    if (isCurrentState !== "AdminScanAndSave") setIsAdminScanAndSave(false);
    else setIsAdminScanAndSave(true);
    if (isCurrentState !== "AdminUsers") setIsAdminUsers(false);
    else setIsAdminUsers(true);
    if (isCurrentState !== "AdminSystemMonitoring") setIsAdminSystemMonitoring(false);
    else setIsAdminSystemMonitoring(true);
  }, [navigate, isCurrentState, isDashboard, isStores, isAnalyze, isShipments, isInventory, isOrders, isUsers, isAdminSearch, isAdminScanAndSave, isAdminUsers, isAdminSystemMonitoring]);

  const customerMenu: MenuItem[] = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "dashboard",
      label: "Dashboard.Title",
      icon: "bx bxs-dashboard",
      link: "/dashboard",
      stateVariables: isDashboard,
      click: function(e: any) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIsCurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },
    {
      label: "Analyze",
      icon: "mdi mdi-chart-timeline-variant-shimmer",
      link: "/#",
      click: function(e: any) {
        e.preventDefault();
        setIsAnalyze(!isAnalyze);
        setIsCurrentState("Analyze");
        updateIconSidebar(e);
      },
      stateVariables: isAnalyze,
      subItems: [
        {id: "search", label: "Searches.Title", link: "/searches", icon: "bx bx-search-alt", parentId: "analyze"},
        {id: "scanAndSave", label: "ScanAndSave.Title", link: "/scan-and-save", icon: "ri-bug-2-line", parentId: "analyze"},
        {id: "favorites", label: "Favorites.Title", link: "/favorites", icon: "mdi mdi-star-outline ", parentId: "analyze"},
      ],
    },
    {
      id: "stores",
      label: "Stores.Title",
      icon: " bx bxl-amazon",
      link: "/stores",
      isChildItem: true,
      click: function(e: any) {
        e.preventDefault();
        setIsStores(!isStores);
        setIsCurrentState("Stores");
        updateIconSidebar(e);
      },
      stateVariables: isStores,
    },
    {
      id: "shipments",
      label: "Shipments.Title",
      icon: "bx bx-package",
      link: "/shipments",
      stateVariables: isShipments,
      click: function(e: any) {
        e.preventDefault();
        setIsShipments(!isShipments);
        setIsCurrentState("Shipments");
        updateIconSidebar(e);
      },
    },
    {
      id: "inventory",
      label: "Inventory.Title",
      icon: "bx bx-purchase-tag-alt",
      link: "/inventory",
      stateVariables: isInventory,
      click: function(e: any) {
        e.preventDefault();
        setIsInventory(!isInventory);
        setIsCurrentState("Inventory");
        updateIconSidebar(e);
      },
    },
    {
      id: "orders",
      label: "Orders.Title",
      icon: "bx bx-basket",
      link: "/orders",
      stateVariables: isOrders,
      click: function(e: any) {
        e.preventDefault();
        setIsOrders(!isOrders);
        setIsCurrentState("Orders");
        updateIconSidebar(e);
      },
    },
  ];

  const adminMenu: MenuItem[] = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "adminSystemMonitoring",
      label: "Admin.SystemMonitoring.Title",
      icon: "mdi mdi-speedometer",
      link: "/admin/system-monitoring",
      stateVariables: isAdminSystemMonitoring,
      click: function(e: any) {
        e.preventDefault();
        setIsAdminSystemMonitoring(!isAdminSystemMonitoring);
        setIsCurrentState("AdminSystemMonitoring");
        updateIconSidebar(e);
      },
    },
    {
      id: "adminSearch",
      label: "Admin.Searches.Title",
      icon: "bx bx-search-alt",
      link: "/admin/searches",
      stateVariables: isAdminSearch,
      click: function(e: any) {
        e.preventDefault();
        setIsAdminSearch(!isAdminSearch);
        setIsCurrentState("AdminSearch");
        updateIconSidebar(e);
      },
    },
    {
      id: "adminScanAndSave",
      label: "Admin.ScanAndSave.Title",
      icon: "ri-bug-2-line",
      link: "/admin/scan-and-save",
      stateVariables: isShipments,
      click: function(e: any) {
        e.preventDefault();
        setIsAdminScanAndSave(!isAdminScanAndSave);
        setIsCurrentState("AdminScanAndSave");
        updateIconSidebar(e);
      },
    },
    {
      id: "adminUsers",
      label: "Admin.Users.Title",
      icon: "mdi mdi-account-group",
      link: "/admin/users",
      stateVariables: isAdminUsers,
      click: function(e: any) {
        e.preventDefault();
        setIsAdminUsers(!isAdminUsers);
        setIsCurrentState("AdminUsers");
        updateIconSidebar(e);
      },
    },
  ];
  useEffect(() => {
    const menuMode = getMenuModeLocalData();
    if (isAdmin() === true && menuMode === "Admin") {
      setMenuArray(adminMenu);
    } else {
      setMenuArray(customerMenu);
    }
  }, [isAdmin()]); //eslint-disable-line

  const filterMenuItems = (menuItems: MenuItem[]): MenuItem[] => {
    return menuItems
      .map((item) => {
        if (item.subItems) {
          // Filter subItems based on permission
          const filteredSubItems = item.subItems.filter((subItem) => hasPermission(subItem.id));
          return {...item, subItems: filteredSubItems};
        }
        return item;
      })
      .filter((item) => {
        const page: ConstantPage | undefined = item.id;
        if (item.isHeader) {
          return true;
        } else if (page && hasPermission(page)) {
          return true;
        } else if (!page && item.subItems && item.subItems.length > 0) {
          return true;
        } else {
          return false;
        }
      });
  };

  const filteredItems = filterMenuItems(menuArray);
  return <React.Fragment>{filteredItems as any}</React.Fragment>;
};
export default NavData;
