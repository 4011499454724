import {useSelector} from "react-redux";
import {useCallback, useEffect, useState} from "react";
import {UserStore} from "models/user_stores";
import {UserStoreStatusReason} from "models/enums/user_store_status_reason";
import {CommonSliceSelector} from "slices/common/selector";
import AlertComponent from "./AlertComponent";

const DisconnectedStoreAlert = () => {
  //const {userProfile} = useProfile();
  const [disconnectedStores, setDisconnectedStores] = useState<UserStore[]>();

  const {loading, userStores} = useSelector(CommonSliceSelector);

  const loadDisconnectedUserStores = useCallback(
    () => {
      if (userStores) {
        // const userTimezone = userProfile?.timezone || "UTC";
        // const twoHoursAgo = Moment()
        //   .tz(userTimezone)
        //   .subtract(2, "hours");

        const disconnectedStoreList = userStores.filter((store) => {
          // const updateDate = Moment(store.updateDate).tz(userTimezone);
          return store.status === false && (store.statusReason === UserStoreStatusReason.DISCONNECTED_BY_SELLTHIS || store.statusReason === UserStoreStatusReason.DISCONNECTED_BY_USER); //&& updateDate.isBefore(twoHoursAgo);
        });

        setDisconnectedStores(disconnectedStoreList);
      }
    },
    [userStores], // eslint-disable-line
  );

  useEffect(() => {
    loadDisconnectedUserStores();
  }, [loadDisconnectedUserStores]);

  if (loading.userStore) {
    return null;
  }

  return disconnectedStores && disconnectedStores.length > 0 ? (
    <AlertComponent
      alertType={{color: "danger", icon: "ri-alert-line", translationKey: "DisconnectedStoreAlert"}}
      replaceVariables={{storeNames: disconnectedStores.map((store) => store.name).join(", ")}}
    />
  ) : (
    ""
  );
};

export default DisconnectedStoreAlert;
