import React, {useEffect, useState} from "react";
import {Card, CardBody, Col} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {StrokedCircularRadial} from "Components/Common/Charts/StrokedCircularRadial";
import {DashboardSlice} from "slices/dashboard/selector";
import CountUp from "react-countup";
import DisplayPrice from "Components/Common/DisplayPrice";
import DisplayNumber from "Components/Common/DisplayNumber";

const SalesOverview = () => {
  const {t} = useTranslation();
  const [revenue, setRevenue] = useState<number | undefined>(0);
  const [soldItemsCount, setSoldItemsCount] = useState<number | undefined>(0);
  const [cost, setCost] = useState<number | undefined>(0);
  const [fees, setFees] = useState<number | undefined>(0);
  const [profit, setProfit] = useState<number | undefined>(0);
  const [profitPerItem, setProfitPerItem] = useState<number | undefined>(0);
  const [roi, setRoi] = useState<number | undefined>(0);
  const [margin, setMargin] = useState<number | undefined>(0);

  const {filter, statistics, loading} = useSelector(DashboardSlice);

  useEffect(() => {
    if (statistics.salesOverview) {
      setRevenue(statistics.salesOverview.revenue);
      setSoldItemsCount(statistics.salesOverview.soldItemsCount);
      setCost(statistics.salesOverview.totalCosts);
      setFees(statistics.salesOverview.totalFees);
      setProfit(statistics.salesOverview.profit);
      setProfitPerItem(statistics.salesOverview.profitPerItem);
      setRoi(statistics.salesOverview.roi);
      setMargin(statistics.salesOverview.margin);
    }
  }, [statistics]);

  const metrics = [
    {
      icon: "bx bx-dollar-circle",
      label: t("Dashboard.SalesOverview.Label.Revenue"),
      value: <DisplayPrice source={filter?.currencyCode} value={revenue} decimals={2} notation="abbreviated" busy={loading.list} />,
      color: "secondary",
    },
    {
      icon: "bx bx-shopping-bag",
      label: t("Dashboard.SalesOverview.Label.SoldItems"),
      value: <DisplayNumber value={soldItemsCount} decimals={0} busy={loading.list} />,
      color: "success",
    },
    {
      icon: "bx bxs-credit-card",
      label: t("Dashboard.SalesOverview.Label.Costs"),
      value: <DisplayPrice source={filter?.currencyCode} value={cost} decimals={2} notation="abbreviated" busy={loading.list} />,
      color: "warning",
    },
    {
      icon: "bx bxs-bank",
      label: t("Dashboard.SalesOverview.Label.Fees"),
      value: <DisplayPrice source={filter?.currencyCode} value={fees} decimals={2} notation="abbreviated" busy={loading.list} />,
      color: "danger",
    },
    {
      icon: "bx bxs-wallet",
      label: t("Dashboard.SalesOverview.Label.Profit"),
      value: <DisplayPrice source={filter?.currencyCode} value={profit} decimals={2} notation="abbreviated" busy={loading.list} />,
      color: "success",
    },
    {
      icon: "bx bxs-purchase-tag",
      label: t("Dashboard.SalesOverview.Label.ProfitPerItem"),
      value: <DisplayPrice source={filter?.currencyCode} value={profitPerItem} decimals={2} notation="abbreviated" busy={loading.list} />,
      color: "info",
    },
  ];

  return (
    <React.Fragment>
      <Col xl={3} className="same-height">
        <Card className="sales-overview-card card-height-100 card-animate">
          <div className="align-items-center d-flex card-header">
            <h4 className="card-title mb-0 flex-grow-1">{t("Dashboard.SalesOverview.Title")}</h4>
          </div>
          <CardBody className="p-0">
            <div className="p-3">
              <div>
                <StrokedCircularRadial progress={roi ?? 0} label="ROI" height={220} />
              </div>
              <div className="d-flex justify-content-between align-items-center mb-3 p-2 mt-n3 bg-light rounded">
                <span className="fw-medium">{t("Dashboard.SalesOverview.Label.RoiMargin")}</span>
                <div className="d-flex gap-3">
                  <div className="d-flex align-items-center">
                    <i className="bx bx-trending-up text-success fs-18 me-1"></i>
                    <CountUp start={0} end={roi ?? 0} decimals={0} duration={2} suffix="%" className="fw-semibold" />
                  </div>
                  <div className="vr"></div>
                  <div className="d-flex align-items-center">
                    <i className="bx bx-chart text-primary fs-18 me-1"></i>
                    <CountUp start={0} end={margin ?? 0} decimals={0} duration={2} suffix="%" className="fw-semibold" />
                  </div>
                </div>
              </div>
              <div className="metrics-container">
                {metrics.map((metric, index) => (
                  <div key={index} className={`metric-item d-flex align-items-center p-2 rounded ${index === metrics.length - 1 ? "mb-0" : "mb-2"}`}>
                    <div className={`metric-icon bg-${metric.color}-subtle rounded-circle p-2 me-3`}>
                      <i className={`${metric.icon} text-${metric.color} fs-20`}></i>
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mb-0 text-muted">{metric.label}</h6>
                      <h5 className="mb-0 mt-1">{metric.value}</h5>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default SalesOverview;
