import {generateError, renderSuccessToast} from "helpers/utilities";
import {ShipmentOrder, UserShipment} from "models/user_shipment";
import {
  postAddUserShipmentItem,
  postDeleteUserShipment,
  postDeleteUserShipmentItem,
  postEditUserShipment,
  postGetUserShipmentDetails,
  postGetUserShipments,
  postRefreshUserShipmentItem,
  postAddToAmazonInventory,
  postSaveNewUserShipment,
  postChangeShipmentVisibility,
  postChangeUserShipmentStatus,
  postUpdateUserShipmentItem,
  postUpdateUserShipmentFinance,
  postImplementUserShipmentSettings,
} from "services/shipment_service";
import {ShipmentLoadingState, apiError, deleteOrRestoreItem, loading, reset, setCurrentUserShipment, setCurrentUserShipmentOrder, setUserShipmentList, updateItem} from "./reducer";
import {ResponseModel} from "models/response_model";
import {PagedList} from "helpers/types";
import i18n from "i18n";
import {
  AddToAmazonInventoryCommand,
  AddUserShipmentItemCommand,
  AnalyzeShipmentCommand,
  ChangeShipmentVisibilityCommand,
  ChangeUserShipmentStatusCommand,
  DeleteUserShipmentCommand,
  DeleteUserShipmentItemCommand,
  EditUserShipmentCommand,
  ImplementUserShipmentSettingsCommand,
  RefreshUserShipmentItemCommand,
  SaveNewUserShipmentCommand,
  UpdateUserShipmentFinanceCommand,
  UpdateUserShipmentItemCommand,
} from "api/command";
import {postAnalyzeShipment} from "services/search_service";
import {refreshRemainingLimits} from "slices/thunks";
import {renderShowAnalyzeToast} from "Components/Common/ToastShowAnalyzeResult";
import {UserSearch} from "models/user_search";
import {GetUserShipmentDetailsQuery, UserShipmentsQuery} from "api/query";
import { updateShipmentInfoItem } from "slices/common/reducer";

export const getUserShipments = (query: UserShipmentsQuery) => async (dispatch: any) => {
  const loadingType = query.action === "filtering" ? "filter" : "list";
  try {
    dispatch(loading([loadingType, true]));
    const result: ResponseModel = await postGetUserShipments(query);
    const pagedResult: PagedList<UserShipment> = result.data;
    dispatch(setUserShipmentList(pagedResult));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading([loadingType, false]));
  }
};

export const getUserShipmentDetails = (getUserShipmentDetailsRequest: GetUserShipmentDetailsQuery) => async (dispatch: any) => {
  try {
    dispatch(loading(["list", true]));
    const result: ResponseModel = await postGetUserShipmentDetails(getUserShipmentDetailsRequest);
    const shipment: UserShipment = result.data.userShipment;
    const shipmentOrder: ShipmentOrder[] = result.data.shipmentOrders;
    dispatch(setCurrentUserShipment(shipment));
    dispatch(setCurrentUserShipmentOrder(shipmentOrder));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["list", false]));
  }
};

export const creteNewShipment = (data: SaveNewUserShipmentCommand) => async (dispatch: any) => {
  try {
    dispatch(loading(["save", true]));
    await postSaveNewUserShipment(data);
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["save", false]));
  }
};
export const editUserShipment = (editUserShipmentRequest: EditUserShipmentCommand) => async (dispatch: any) => {
  try {
    dispatch(loading(["update", true]));
    const result: ResponseModel = await postEditUserShipment(editUserShipmentRequest);
    const updatedResult: UserShipment = result.data;
    dispatch(setCurrentUserShipment(updatedResult));
    dispatch(updateItem(updatedResult));
    renderSuccessToast(i18n.t(result.message));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["update", false]));
  }
};

export const deleteUserShipment = (command: DeleteUserShipmentCommand, shipmentName: string) => async (dispatch: any) => {
  try {
    dispatch(loading(["delete", true]));
    await postDeleteUserShipment(command);
    dispatch(deleteOrRestoreItem(command));
    if (command.deleted) {
      renderSuccessToast(i18n.t("Shipments.Toast.DeleteSuccess", {value: shipmentName}));
    }
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["delete", false]));
  }
};

export const deleteUserShipmentItem = (productInfos: DeleteUserShipmentItemCommand) => async (dispatch: any) => {
  try {
    dispatch(loading(["delete", true]));
    const result: ResponseModel = await postDeleteUserShipmentItem(productInfos);
    const updatedResult: UserShipment = result.data;
    dispatch(setCurrentUserShipment(updatedResult));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["delete", false]));
  }
};

export const addUserShipmentItem = (productInfos: AddUserShipmentItemCommand) => async (dispatch: any) => {
  try {
    dispatch(loading(["save", true]));
    const result: ResponseModel = await postAddUserShipmentItem(productInfos);
    const updatedResult: UserShipment = result.data;
    dispatch(setCurrentUserShipment(updatedResult));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["save", false]));
  }
};

export const implementUserShipmentSettings = (command: ImplementUserShipmentSettingsCommand) => async (dispatch: any) => {
  try {
    dispatch(loading(["implementSettings", true]));
    const result: ResponseModel = await postImplementUserShipmentSettings(command);
    const updatedResult: UserShipment = result.data;
    renderSuccessToast(i18n.t(result.message));
    dispatch(setCurrentUserShipment(updatedResult));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["implementSettings", false]));
  }
};

export const updateUserShipmentItem = (command: UpdateUserShipmentItemCommand, loadingType: keyof ShipmentLoadingState) => async (dispatch: any) => {
  try {
    dispatch(loading([loadingType, true]));
    const result: ResponseModel = await postUpdateUserShipmentItem(command);
    const updatedResult: UserShipment = result.data;
    renderSuccessToast(i18n.t(result.message));
    dispatch(setCurrentUserShipment(updatedResult));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading([loadingType, false]));
  }
};

export const updateUserShipmentFinance = (command: UpdateUserShipmentFinanceCommand, loadingType: keyof ShipmentLoadingState) => async (dispatch: any) => {
  try {
    dispatch(loading([loadingType, true]));
    const result: ResponseModel = await postUpdateUserShipmentFinance(command);
    const updatedResult: UserShipment = result.data;
    renderSuccessToast(i18n.t(result.message));
    dispatch(setCurrentUserShipment(updatedResult));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading([loadingType, false]));
  }
};

export const changeUserShipmentStatus = (command: ChangeUserShipmentStatusCommand) => async (dispatch: any) => {
  try {
    dispatch(loading(["changeStatus", true]));
    const result: ResponseModel = await postChangeUserShipmentStatus(command);
    const updatedResult: UserShipment = result.data;
    dispatch(setCurrentUserShipment(updatedResult));
    renderSuccessToast(i18n.t("Shipments.ShipmentDetails.Toast.StatusUpdateSuccess"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["changeStatus", false]));
  }
};

export const refreshUserShipmentItem = (productInfos: RefreshUserShipmentItemCommand) => async (dispatch: any) => {
  try {
    dispatch(loading(["update", true]));
    const result: ResponseModel = await postRefreshUserShipmentItem(productInfos);
    const updatedResult: UserShipment = result.data;
    dispatch(setCurrentUserShipment(updatedResult));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["update", false]));
  }
};

export const addToAmazonInventory = (productInfos: AddToAmazonInventoryCommand) => async (dispatch: any) => {
  try {
    dispatch(loading(["update", true]));
    const result: ResponseModel = await postAddToAmazonInventory(productInfos);
    const updatedResult: UserShipment = result.data;
    dispatch(setCurrentUserShipment(updatedResult));
    renderSuccessToast(i18n.t(result.message));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["update", false]));
  }
};

export const analyzeUserShipment = (command: AnalyzeShipmentCommand, navigate: any) => async (dispatch: any) => {
  try {
    dispatch(loading(["analyzeShipment", true]));
    const result: ResponseModel = await postAnalyzeShipment(command);
    const search: UserSearch = result.data;
    dispatch(refreshRemainingLimits());
    renderShowAnalyzeToast(i18n.t("Shipments.ShipmentDetails.Toast.AnalyzeSuccess"), `/searches/search-results/${search.userSearchId}`, navigate);
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    dispatch(loading(["analyzeShipment", false]));
  }
};

export const changeShipmentVisibility = (command: ChangeShipmentVisibilityCommand, from: "Shipments" | "SellThisModal") => async (dispatch: any) => {
  try {
    const response: ResponseModel = await postChangeShipmentVisibility(command);
    const updatedItem: UserShipment = response.data;
    if (from === "Shipments") {
      dispatch(updateItem(updatedItem));
    } else if (from === "SellThisModal") {
      dispatch(updateShipmentInfoItem(updatedItem));
    }
    renderSuccessToast(i18n.t("Common.Dialog.SelectVisibility.Toast.AccessSettingsUpdated"));
    return true;
  } catch (error) {
    const errorObject = generateError(error, true);
    dispatch(apiError(errorObject));
    return false;
  } finally {
    return false;
  }
};

export const setLoading = (loadingType: keyof ShipmentLoadingState, isLoading: boolean) => async (dispatch: any) => {
  dispatch(loading([loadingType, isLoading]));
};
export const resetShipmentState = () => async (dispatch: any) => {
  dispatch(reset());
};
