import {useProfile} from "Components/Hooks/useProfile";
import {getMenuModeLocalData} from "helpers/local_storage";
import {Navigate, Outlet, useLocation} from "react-router-dom";
import moment from "moment";

const ProtectedRoutes = () => {
  const {userProfile, isAdmin} = useProfile();
  const location = useLocation();
  const hasSubscription = userProfile?.hasSubscription;
  const isTokenValid = userProfile && new Date(userProfile.tokenInfo.expiresAt) > moment().toDate();
  const requires2FA = userProfile?.tokenInfo.isTempToken;

  // If user is not logged in or token is expired, redirect to login
  if (!isTokenValid) {
    if (location.pathname !== "/") {
      return <Navigate to={`/account/login?return_url=${location.pathname}${location.search}`} />;
    } else {
      return <Navigate to="/account/login" />;
    }
  }

  // Handle 2FA requirement - this should take precedence over subscription check
  if (requires2FA && location.pathname !== "/account/verify-2fa") {
    return (
      <Navigate
        to="/account/verify-2fa"
        state={{
          tempToken: userProfile.tokenInfo.signature,
          returnUrl: location.pathname !== "/account/verify-2fa" ? location.pathname : undefined,
        }}
      />
    );
  }

  // Skip subscription checks if 2FA is required
  if (!requires2FA) {
    // If user has no subscription and is not trying to get one
    if (!hasSubscription && location.pathname !== "/account/subscription/new") {
      return <Navigate to="/account/subscription/new" />;
    }

    // If user has subscription and is trying to access "/account/subscription/new"
    if (hasSubscription && location.pathname === "/account/subscription/new") {
      return <Navigate to="/" />;
    }
  }

  // If user trying to access login page when already logged in
  if (location.pathname === "/" || location.pathname === "/account/login") {
    const menuMode = getMenuModeLocalData();
    if (menuMode === "Admin" && isAdmin()) return <Navigate to="/admin/system-monitoring" />;
    else return <Navigate to="/dashboard" />;
  }

  return <Outlet />;
};

export default ProtectedRoutes;
