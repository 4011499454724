import {createTypedModal} from "helpers/modal_helpers";
import {ShareType} from "models/enums/share_type";
import React, {useCallback, useEffect, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Input, Spinner} from "reactstrap";

interface ModalData {
  id: string;
  status: ShareType;
}

export const SelectVisibilityModal = createTypedModal<ModalData>("select_visibility");

interface SelectVisibilityProps {
  onChange: (id: string, visibility: ShareType) => void;
}
const SelectVisibility = (props: SelectVisibilityProps) => {
  const {t} = useTranslation();
  const {open, data} = SelectVisibilityModal.useModal();
  const [selectedVisibility, setSelectedVisibility] = useState<ShareType>(data?.status == null ? ShareType.PRIVATE : data?.status);
  const [isChanging, setIsChanging] = useState<boolean>(false);

  const onLoad = useCallback(() => {
    if (open) {
      setSelectedVisibility(data!.status);
    }
  }, [open]); // eslint-disable-line

  const toggle = () => {
    SelectVisibilityModal.close();
  };

  const handleSubmit = async () => {
    //toggle();
    setIsChanging(true);
    await props.onChange(data!.id, selectedVisibility);
    setIsChanging(false);
    toggle();
  };

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <Modal size="md" isOpen={open} toggle={toggle} centered={true} backdrop="static">
      <ModalHeader className="p-3" toggle={toggle}>
        {t("Common.Dialog.SelectVisibility.Title")}
      </ModalHeader>
      <ModalBody>
        <Row className="g-3">
          <Col xs={12}>
            <h5>{t("Common.Dialog.SelectVisibility.Description1")}</h5>
            <p className="text-muted">
              <Trans i18nKey={"Common.Dialog.SelectVisibility.Description2"}></Trans>
            </p>
          </Col>

          <Col xs={12}>
            <Button color="white" className="w-100 text-start p-0" onClick={() => setSelectedVisibility(ShareType.PRIVATE)}>
              <Row className="align-items-center">
                <Col xs="auto">
                  <div className="d-flex align-items-center justify-content-center rounded-circle rounded-circle-md bg-light">
                    <i className="mdi mdi-lock text-dark fs-2 align-middle" />
                  </div>
                </Col>
                <Col>
                  <h6 className="mb-1">{t("Private")}</h6>
                  <p className="text-muted mb-0">{t("Common.Dialog.SelectVisibility.PrivateDescription")}</p>
                </Col>
                <Col xs="auto">
                  <Input
                    type="radio"
                    id="visibilityPrivate"
                    name="visibility"
                    className="form-check-input"
                    checked={selectedVisibility === ShareType.PRIVATE}
                    onChange={() => setSelectedVisibility(ShareType.PRIVATE)}
                    onClick={(e) => e.stopPropagation()}
                  />
                </Col>
              </Row>
            </Button>
          </Col>

          <Col xs={12}>
            <Button color="white" className="w-100 text-start p-0" onClick={() => setSelectedVisibility(ShareType.SHARED)}>
              <Row className="align-items-center">
                <Col xs="auto">
                  <div className="d-flex align-items-center justify-content-center rounded-circle rounded-circle-md bg-light">
                    <i className="mdi mdi-account-supervisor text-dark fs-2 align-middle" />
                  </div>
                </Col>
                <Col>
                  <h6 className="mb-1">{t("Shared")}</h6>
                  <p className="text-muted mb-0">{t("Common.Dialog.SelectVisibility.SharedDescription")}</p>
                </Col>
                <Col xs="auto">
                  <Input
                    type="radio"
                    id="visibilityShared"
                    name="visibility"
                    className="form-check-input"
                    checked={selectedVisibility === ShareType.SHARED}
                    onChange={() => setSelectedVisibility(ShareType.SHARED)}
                    onClick={(e) => e.stopPropagation()}
                  />
                </Col>
              </Row>
            </Button>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <div className="hstack gap-2 justify-content-end">
          <Button type="button" color="light" onClick={toggle} disabled={isChanging}>
            {t("Cancel")}
          </Button>
          <Button type="button" color="success" className="d-flex align-items-center" onClick={handleSubmit} disabled={isChanging}>
            {isChanging && <Spinner size="sm" className="me-2"/>}
            {t("Save")}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default SelectVisibility;
