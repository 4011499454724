import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {Modal, ModalHeader, ModalBody, Col, Row, Button, ModalFooter, Form, Label, Spinner} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {RootState} from "slices";
import ValidatedInput from "Components/Common/ValidatedInput";
import * as Yup from "yup";
import {createTypedModal} from "helpers/modal_helpers";
import {EditUserFavoriteListCommand, UserFavorite} from "models/user_favorites";
import {updateFavoriteList} from "slices/favorites/thunk";

interface ModalData {
  favorite: UserFavorite;
}
export const UpdateFavoriteListModal = createTypedModal<ModalData>("update_favorite");

const EditUserFavoriteList = () => {
  const {t} = useTranslation();
  const {open, data} = UpdateFavoriteListModal.useModal();

  const dispatch: any = useDispatch();
  const searchResultsData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.Favorites.loading,
    }),
  );

  const {loading} = useSelector(searchResultsData);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {name: data?.favorite.name, description: data?.favorite.description} as EditUserFavoriteListCommand,
    validationSchema: Yup.object<EditUserFavoriteListCommand>().shape({
      name: Yup.string().required(t("Favorites.Validation.ListName")),
      description: Yup.string().notRequired(),
    }),
    onSubmit: (values: EditUserFavoriteListCommand) => {
      const updatePromise = updateFavoriteList({
        name: values.name,
        description: values.description,
        userFavoriteId: data!.favorite.userFavoriteId,
      })(dispatch);
      updatePromise.then((success) => {
        if (success) {
          toggle();
        }
      });
    },
  });
  const toggle = () => {
    UpdateFavoriteListModal.close();
    validation.resetForm();
  };
  return (
    <>
      <Modal backdrop="static" id="reportItemModal" isOpen={open} toggle={toggle} fade={true} centered={true}>
        <div className="placeholder-glow">
          <ModalHeader className="p-3" toggle={toggle}>
            {data?.favorite ? t("Edit") : t("SearchResults.Dialog.AddToFavorites.Dialog.Create.Title")}
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={validation.handleSubmit}>
              <div className="d-flex justify-content-center flex-column gap-3">
                <Row>
                  <Col xs="12">
                    <Label htmlFor="favoriteListName">{t("SearchResults.Dialog.AddToFavorites.Label.ListName")}</Label>
                    <ValidatedInput validation={validation} type="text" field="name" placeholder={t("SearchResults.Dialog.AddToFavorites.Label.ListName")} maxLength={100} disableValidationUI />
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <Label htmlFor="description">{t("SearchResults.Dialog.AddToFavorites.Label.Description")}</Label>
                    <ValidatedInput
                      validation={validation}
                      type="textarea"
                      field="description"
                      placeholder={t("SearchResults.Dialog.AddToFavorites.Label.Description")}
                      maxLength={100}
                      disableValidationUI
                    />
                  </Col>
                </Row>
              </div>
            </Form>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <Button type="button" className="btn btn-light" onClick={toggle} disabled={loading.update}>
                {t("Close")}
              </Button>
              <Button
                type="submit"
                className="btn btn-success"
                disabled={loading.update}
                onClick={() => {
                  if (validation.isValid) {
                    validation.handleSubmit();
                  } else {
                    Object.keys(validation.errors).forEach((key) => {
                      validation.setFieldTouched(key, true);
                    });
                  }
                }}
              >
                {loading.update && <Spinner size="sm" className="me-2 align-middle"></Spinner>}
                {t("Save")}
              </Button>
            </div>
          </ModalFooter>
        </div>
      </Modal>
    </>
  );
};

export default EditUserFavoriteList;
