import DataTable, {DataTableRef} from "Components/Common/DataTable";
import Dialog, {DialogRef} from "Components/Common/Dialog";
import {useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {Badge, Button, Card, CardBody, Col, Container, Row} from "reactstrap";
import {useTranslation} from "react-i18next";
import {ColumnDef} from "@tanstack/react-table";
import {User, UserStatus} from "models/user";
import {createSelector} from "reselect";
import {RootState} from "slices";
import {useDispatch, useSelector} from "react-redux";
import {useProfile} from "Components/Hooks/useProfile";
import {useImmer} from "use-immer";
import {useDebounce} from "Components/Hooks/useDebounce";
import {ConstantPage} from "helpers/permission_helper";
import {getUsers, blockUser, unblockUser, resetUserPassword} from "slices/admin/users/thunk";
import {AdminGetUsersQuery} from "api/query";
import {UserSubscriptionStatus} from "models/enums/user_subscription_status";
import {UserLimitType} from "models/enums/user_limit_type";
import {getTags} from "slices/common/thunk";
import BreadCrumb from "Components/Common/BreadCrumb";
import Restricted from "Components/Common/Restricted";
import Unauthorized from "pages/Errors/_Unauthorized";
import Loader from "Components/Common/Loader";
import Filters from "./Filters";
import Moment from "react-moment";
import NoResult from "Components/Common/NoResult";
import PaymentHistoryModal from "./Modals/PaymentHistory";
import defaultAvatar from "assets/images/user-dummy-img.jpg";
import i18n from "i18n";
import LogHistoryModal from "./Modals/LogHistory";
import LoginAsModal from "./Modals/LoginAs";
import DisplayNumber from "Components/Common/DisplayNumber";
import DefaultUncontrolledTooltip from "Components/Common/DefaultUncontrolledTooltip";
import DisplayPrice from "Components/Common/DisplayPrice";
import DisplayDate from "Components/Common/DisplayDate";
import AddTag, {AddTagModal} from "./Modals/AddTag";
import TagListPreview from "Components/Common/TagListPreview";
import {FieldConfig, useUrlQuery} from "Components/Hooks/useUrlQuery";
import useSelectOptions from "Components/Hooks/useSelectOptions";

export type UserListRef = {
  reload: VoidFunction;
};

const PAGE_IDENTIFIER: ConstantPage = "adminUsers";
const Users = () => {
  const {t} = useTranslation();
  const {userProfile, hasPermission} = useProfile();
  const [selectedUser, setSelectedUser] = useState<User>({} as User);
  const [isLoginAsModalOpen, setIsLoginAsModalOpen] = useState<boolean>(false);
  const [isPaymentHistoryModalOpen, setIsPaymentHistoryModalOpen] = useState<boolean>(false);
  const [isLogHistoryModalOpen, setIsLogHistoryModalOpen] = useState<boolean>(false);
  const tableRef = useRef<DataTableRef>(null);
  const listRef = useRef<UserListRef>(null);
  const dispatch: any = useDispatch();
  const resetPasswordDialog = useRef<DialogRef>(null);
  const blockUserDialog = useRef<DialogRef>(null);
  const unblockUserDialog = useRef<DialogRef>(null);
  const {subscriptionStatusSelectOptions, storeStatusSelectOptions} = useSelectOptions();
  const [localQuery, updateLocalQuery] = useImmer<AdminGetUsersQuery>({
    action: "filtering",
    page: 1,
    pageSize: 10,
  });

  let filterFields: FieldConfig<AdminGetUsersQuery>[] = [
    {field: "search", queryParam: "search", type: "string"},
    {field: "registerDateRange", queryParam: "registerDateRange", type: "dateRange"},
    {field: "lastLoginDateRange", queryParam: "lastLoginDateRange", type: "dateRange"},
    {field: "subscriptionIds", queryParam: "subscriptionIds", type: "array"},
    {field: "subscriptionStatus", queryParam: "subscriptionStatus", type: "string", defaultValue: subscriptionStatusSelectOptions[0].value},
    {field: "storeStatus", queryParam: "storeStatus", type: "string", defaultValue: storeStatusSelectOptions[0].value},
    {field: "userStatus", queryParam: "userStatus", type: "array", defaultValue: undefined},
    {field: "showTeamUsers", queryParam: "showTeamUsers", type: "bool", defaultValue: false},
    {field: "userTagIds", queryParam: "userTagIds", type: "array"},
    {field: "page", queryParam: "page", type: "number", defaultValue: localQuery.page},
    {field: "pageSize", queryParam: "pageSize", type: "number", defaultValue: localQuery.pageSize},
    {field: "sortBy", queryParam: "sortBy", type: "string", defaultValue: localQuery.sortBy},
    {field: "sortingOrder", queryParam: "sortingOrder", type: "number", defaultValue: localQuery.sortingOrder},
    {field: "action", queryParam: "action", type: "string", defaultValue: "filtering"},
  ];
  const {readQueryParams, updateQuery, searchParams} = useUrlQuery<AdminGetUsersQuery>(filterFields);

  const debouncedLoadList = useDebounce(() => {
    getTags()(dispatch);
    getUsers(localQuery)(dispatch).then(() => {
      tableRef.current?.resetSelection();
    });
  }, 200);

  useEffect(() => {
    if (hasPermission("adminUsers") && searchParams.has("action")) {
      const params = readQueryParams();
      updateLocalQuery(params as AdminGetUsersQuery);
      debouncedLoadList();
    }
  }, [debouncedLoadList, searchParams]); // eslint-disable-line

  useImperativeHandle(
    listRef,
    () => {
      return {
        reload: () => {
          debouncedLoadList();
        },
      };
    },
    [debouncedLoadList],
  );

  const userData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.AdminUsers.loading,
      list: state.AdminUsers.list,
    }),
  );
  const {loading, list} = useSelector(userData);

  const columns = useMemo<ColumnDef<User, any>[]>(
    () => [
      {
        header: t("Admin.Users.TableColumn.Actions"),
        enableSorting: false,
        size: 150,
        cell: (cellProps) => {
          const row = cellProps.row.original as User;
          return (
            <>
              {row.userId !== userProfile?.userId && (
                <>
                  <Button
                    id={`LoginAs-${row.userId}`}
                    color="link"
                    className="btn btn-ghost-secondary px-1 py-0 fs-18"
                    onClick={() => {
                      setSelectedUser(row);
                      //loginAsDialog.current?.show();
                      setIsLoginAsModalOpen(true);
                    }}
                  >
                    <i className="mdi mdi-account-key align-middle"></i>
                  </Button>
                  <DefaultUncontrolledTooltip target={`LoginAs-${row.userId}`}>{t("Admin.Users.Tooltip.LoginAs")}</DefaultUncontrolledTooltip>
                </>
              )}

              <Button
                id={`Logs-${row.userId}`}
                color="link"
                className="btn btn-ghost-secondary px-1 py-0 fs-18"
                onClick={() => {
                  setSelectedUser(row);
                  setIsLogHistoryModalOpen(true);
                }}
              >
                <i className="mdi mdi-text-box align-middle"></i>
              </Button>
              <DefaultUncontrolledTooltip target={`Logs-${row.userId}`}>{t("Admin.Users.Tooltip.Logs")}</DefaultUncontrolledTooltip>

              <Button
                id={`PaymentHistory-${row.userId}`}
                color="link"
                className="btn btn-ghost-success px-1 py-0 fs-18"
                onClick={() => {
                  setSelectedUser(row);
                  setIsPaymentHistoryModalOpen(true);
                }}
              >
                <i className="ri-refund-2-line align-middle"></i>
              </Button>
              <DefaultUncontrolledTooltip target={`PaymentHistory-${row.userId}`}>{t("Admin.Users.Tooltip.PaymentHistory")}</DefaultUncontrolledTooltip>

              <Button
                id={`PasswordReset-${row.userId}`}
                color="link"
                className="btn btn-ghost-info px-1 py-0 fs-18"
                onClick={() => {
                  setSelectedUser(row);
                  resetPasswordDialog.current?.show();
                }}
              >
                <i className="mdi mdi-lock align-middle"></i>
              </Button>
              <DefaultUncontrolledTooltip target={`PasswordReset-${row.userId}`}>{t("Admin.Users.Tooltip.PasswordReset")}</DefaultUncontrolledTooltip>

              {row.status !== UserStatus.BANNED ? (
                <>
                  {row.userId !== userProfile?.userId && (
                    <>
                      <Button
                        id={`Block-${row.userId}`}
                        color="link"
                        className="btn btn-ghost-danger px-1 py-0 fs-18"
                        onClick={() => {
                          setSelectedUser(row);
                          blockUserDialog.current?.show();
                        }}
                      >
                        <i className="mdi mdi-close-circle align-middle"></i>
                      </Button>
                      <DefaultUncontrolledTooltip target={`Block-${row.userId}`}>{t("Admin.Users.Tooltip.Block")}</DefaultUncontrolledTooltip>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Button
                    id={`Unblock-${row.userId}`}
                    color="link"
                    className="btn btn-ghost-success px-1 py-0 fs-18"
                    onClick={() => {
                      setSelectedUser(row);
                      unblockUserDialog.current?.show();
                    }}
                  >
                    <i className="mdi mdi-check-circle align-middle"></i>
                  </Button>
                  <DefaultUncontrolledTooltip target={`Unblock-${row.userId}`}>{t("Admin.Users.Tooltip.Unblock")}</DefaultUncontrolledTooltip>
                </>
              )}
            </>
          );
        },
      },
      {
        header: t("Admin.Users.TableColumn.EmailAndName"),
        enableSorting: true,
        accessorKey: "email",
        size: 200,
        cell: (cellProps) => {
          const row = cellProps.row.original as User;
          const [hovered, setHovered] = useState(false); //eslint-disable-line
          return (
            <div className="d-flex align-items-center" onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
              <div className="flex-shrink-0 me-2 position-relative">
                <img src={row.userUiPreferences?.avatar || defaultAvatar} className="rounded-circle avatar-sm img-thumbnail user-profile-image object-fit-cover" alt="user-profile" />
              </div>
              <div className="flex-grow-1 ellipsis-two-lines">
                <div className="fw-semibold d-flex align-items-center" style={{height: "20px"}}>
                  {row.fullName}
                  {row.userId === userProfile?.userId && <Badge className="ms-1">{t("You")}</Badge>}
                  {row.parentUserId && <i className="mdi mdi-account-multiple fs-16 text-secondary ms-1"></i>}
                  {hovered && (
                    <>
                      <Button id={`AddTagButton-${row.userId}`} color="link" className="p-0" onClick={() => AddTagModal.open({userId: row.userId})}>
                        <i className="mdi mdi-tag-plus fs-16 ms-1 text-success" />
                      </Button>
                      <DefaultUncontrolledTooltip target={`AddTagButton-${row.userId}`}>{t("Admin.Users.Tooltip.AddTag")}</DefaultUncontrolledTooltip>
                    </>
                  )}
                </div>
                <div className="text-muted" title={row.email}>
                  {row.email}
                </div>
                <div className={row.userTags?.length > 0 ? "mt-1" : ""}>
                  <TagListPreview
                    tags={row.userTags?.map((x) => ({
                      id: x.userTagId,
                      color: x.tag.color,
                      name: x.tag.name,
                    }))}
                    maxVisible={2}
                  />
                </div>
              </div>
            </div>
          );
        },
      },
      {
        header: t("Admin.Users.TableColumn.RegisterDate"),
        enableSorting: true,
        accessorKey: "registrationDate",
        size: 120,
        cell: (cellProps) => {
          const row = cellProps.row.original as User;

          let badgeColor = "";
          let subscriptionStatus: string | undefined = undefined;

          if (row.userSubscriptions && row.userSubscriptions.length > 0) {
            var activeSubscription = row.userSubscriptions?.find((x) => x.isActive);
            if (activeSubscription) {
              switch (activeSubscription.status) {
                case UserSubscriptionStatus.Trial:
                  badgeColor = "badge-gradient-info";
                  subscriptionStatus = t("Admin.Users.Filters.Dropdown.SubscriptionStatus.Trial");
                  break;
                case UserSubscriptionStatus.Active:
                  badgeColor = "badge-gradient-success";
                  subscriptionStatus = t("Admin.Users.Filters.Dropdown.SubscriptionStatus.Active");
                  break;
                case UserSubscriptionStatus.CanceledAtPeriodEnd:
                  badgeColor = "badge-gradient-warning";
                  subscriptionStatus = t("Admin.Users.Filters.Dropdown.SubscriptionStatus.Canceled");
                  break;
                case UserSubscriptionStatus.PaymentFailed:
                  badgeColor = "badge-gradient-danger";
                  subscriptionStatus = t("Admin.Users.Filters.Dropdown.SubscriptionStatus.PaymentFailed");
                  break;
              }
            } else {
              badgeColor = "badge-gradient-primary";
              subscriptionStatus = t("Admin.Users.Filters.Dropdown.SubscriptionStatus.NoSubscription");
            }
          } else {
            badgeColor = "badge-gradient-secondary";
            subscriptionStatus = t("Admin.Users.Filters.Dropdown.SubscriptionStatus.TrialNotStarted");
          }

          return (
            <Row>
              <Col xs={12}>
                <DisplayDate id={`DisplayDateUserRegistrationDate-${row.userId}`} value={row.registrationDate} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} />
              </Col>

              <Col xs={12}>
                <span className={`badge rounded-pill ${badgeColor}`}>{subscriptionStatus}</span>
              </Col>
            </Row>
          );
        },
      },
      {
        header: t("Admin.Users.TableColumn.Subscription"),
        enableSorting: false,
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as User;
          const userSubscription = row.userSubscriptions?.find((x) => x.isActive);

          const duration = (userSubscription?.billingPeriodMonth ?? 0) > 1 ? `(${userSubscription?.billingPeriodMonth} ${t("Months")})` : `(${userSubscription?.billingPeriodMonth} ${t("Month")})`;
          return (
            <>
              {userSubscription?.subscription ? (
                <>
                  {userSubscription?.subscription.name} {duration}
                </>
              ) : (
                <span className="text-muted">—</span>
              )}
            </>
          );
        },
      },
      {
        header: t("Admin.Users.TableColumn.SubscriptionRenew"),
        enableSorting: true,
        accessorKey: "subscriptionRenew",
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as User;
          const userSubscription = row.userSubscriptions?.find((x) => x.isActive);
          return (
            <>
              {userSubscription?.status === UserSubscriptionStatus.Active && userSubscription?.endDate ? (
                <Row>
                  <Col xs={12}>
                    <Moment locale={i18n.language} format="D MMM YYYY HH:mm" tz={userProfile?.timezone}>
                      {userSubscription?.endDate}
                    </Moment>
                  </Col>

                  <Col xs={12}>
                    <span className="badge rounded-pill bg-info-subtle text-info">
                      <Moment locale={i18n.language} tz={userProfile?.timezone} fromNow>
                        {userSubscription?.endDate}
                      </Moment>
                    </span>
                  </Col>
                </Row>
              ) : (
                <span className="text-muted">—</span>
              )}
            </>
          );
        },
      },
      {
        header: t("Admin.Users.TableColumn.LastLoginDate"),
        size: 100,
        enableSorting: true,
        accessorKey: "lastLogin",
        cell: (cellProps) => {
          const row = cellProps.row.original as User;
          return (
            <>
              {row.lastLogin ? (
                <DisplayDate id={`DisplayDateLastLogin-${row.userId}`} value={row.lastLogin} format="D MMM YYYY HH:mm" tz={userProfile?.timezone} />
              ) : (
                t("Admin.Users.TableColumn.NoLoginMessage")
              )}
            </>
          );
        },
      },
      {
        header: t("Admin.Users.TableColumn.CurrentLimits"),
        accessorKey: "currentLimits",
        enableSorting: true,
        size: 100,
        cell: (cellProps) => {
          const row = cellProps.row.original as User;
          const userSubscription = row.userSubscriptions?.find((x) => x.isActive);
          const searchLimit = userSubscription?.limits.find((x) => x.type === UserLimitType.SEARCH);
          const scanAndSaveLimit = userSubscription?.limits.find((x) => x.type === UserLimitType.CRAWLER_SCAN);
          return (
            <>
              <Row>
                <Col xs={12} className="d-flex flex-row">
                  {searchLimit?.dailyRemaining && (
                    <>
                      <span className="me-2">{t("Analysis")}:</span>
                      <span>
                        <DisplayNumber value={searchLimit?.dailyRemaining} />
                      </span>
                    </>
                  )}
                </Col>
                <Col xs={12}>
                  {scanAndSaveLimit?.dailyRemaining && (
                    <>
                      <span className="me-2">{t("Scan&Save")}:</span>
                      <span>
                        <DisplayNumber value={scanAndSaveLimit?.dailyRemaining} />
                      </span>
                    </>
                  )}
                </Col>
                {!searchLimit?.dailyRemaining && !scanAndSaveLimit?.dailyRemaining && <Col>-</Col>}
              </Row>
            </>
          );
        },
      },
      {
        header: t("Admin.Users.TableColumn.TotalSpent"),
        enableSorting: true,
        accessorKey: "stripeTotalSpend",
        size: 100,
        accessorFn: (item) => item.stripeTotalSpend,
        sortingFn: "alphanumeric",
        cell: (cellProps) => {
          const row = cellProps.row.original as User;
          return (
            <>
              <DisplayPrice value={row.stripeTotalSpend} decimals={2} source="USD" notation="abbreviated" />
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  );

  document.title = t("PageTitles.Users");

  return (
    <Restricted require={PAGE_IDENTIFIER} read fallback={() => <Unauthorized />}>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Admin.Users.Title")} menus={[{label: t("Admin.Users.Title")}]} />
          <>
            <Filters busy={loading.filter} fields={filterFields} />
          </>
          <Card>
            <CardBody>
              {loading.filter ? (
                <>
                  <Loader />
                </>
              ) : list.items && list.items.length > 0 ? (
                <Restricted require={"adminUsers"} read>
                  <LoginAsModal show={isLoginAsModalOpen} toggle={() => setIsLoginAsModalOpen(!isLoginAsModalOpen)} user={selectedUser} />
                  <PaymentHistoryModal
                    show={isPaymentHistoryModalOpen}
                    toggle={() => {
                      setIsPaymentHistoryModalOpen(!isPaymentHistoryModalOpen);
                    }}
                    user={selectedUser}
                  />
                  <LogHistoryModal
                    show={isLogHistoryModalOpen}
                    toggle={() => {
                      setIsLogHistoryModalOpen(!isLogHistoryModalOpen);
                    }}
                    user={selectedUser}
                  />
                  <DataTable
                    ref={tableRef}
                    busy={loading.list}
                    columns={columns}
                    data={list.items || []}
                    totalDataLength={list.totalCount}
                    pagination={{
                      pageIndex: localQuery.page - 1,
                      pageSize: localQuery.pageSize,
                    }}
                    onPaginationChanged={(pagination) => {
                      updateQuery({...localQuery, page: pagination.pageIndex + 1, pageSize: pagination.pageSize, action: "paginating"});
                    }}
                    onSortingChanged={(sorting) => {
                      updateQuery({...localQuery, sortBy: sorting?.sortBy, sortingOrder: sorting?.sortingOrder, action: "sorting"});
                    }}
                    hovered
                  />
                </Restricted>
              ) : (
                <NoResult title={t("Admin.Users.NoResult.Title")} description={t("Admin.Users.NoResult.Description")} />
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <Dialog
        ref={resetPasswordDialog}
        color="info"
        buttons={["yes", "no"]}
        busy={loading.unblock}
        iconClass="ri-alert-fill"
        message={t("Admin.Users.Dialog.ResetPassword.Description")}
        title={t("Admin.Users.Dialog.ResetPassword.Title", {value: selectedUser?.email})}
        onButtonClick={async (button, hide) => {
          if (button === "yes" && selectedUser) {
            await dispatch(resetUserPassword(selectedUser));
          }
          resetPasswordDialog.current?.hide();
        }}
      />
      <Dialog
        ref={blockUserDialog}
        color="danger"
        buttons={["yes", "no"]}
        busy={loading.block}
        iconClass="ri-alert-fill"
        message={t("Admin.Users.Dialog.BlockUser.Description")}
        title={t("Admin.Users.Dialog.BlockUser.Title", {value: selectedUser?.email})}
        onButtonClick={async (button, hide) => {
          if (button === "yes" && selectedUser) {
            await dispatch(blockUser(selectedUser));
          }
          blockUserDialog.current?.hide();
        }}
      />
      <Dialog
        ref={unblockUserDialog}
        color="success"
        buttons={["yes", "no"]}
        busy={loading.unblock}
        iconClass="ri-alert-fill"
        message={t("Admin.Users.Dialog.UnblockUser.Description")}
        title={t("Admin.Users.Dialog.UnblockUser.Title", {value: selectedUser?.email})}
        onButtonClick={async (button, hide) => {
          if (button === "yes" && selectedUser) {
            await dispatch(unblockUser(selectedUser));
          }
          unblockUserDialog.current?.hide();
        }}
      />
      <AddTag />
    </Restricted>
  );
};

export default Users;
