import {useEffect} from "react";
import {useFormik} from "formik";
import {Button, Col, Form, Label, Row, Spinner} from "reactstrap";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {RootState} from "slices";
import {createSelector} from "reselect";
import {User} from "models/user";
import {DateRange} from "helpers/types";
import {getRelativeDate, getToday} from "helpers/utilities";
import {GetUserLogHistoryQuery} from "api/query";
import ValidatedInput from "Components/Common/ValidatedInput";
import PlaceholderGlow from "Components/Common/PlaceholderGlow";
import DateRangeInput from "Components/Common/DateRangeInput";
import ValidatedSelect from "Components/Common/ValidatedSelect";
import useSelectOptions from "Components/Hooks/useSelectOptions";
import * as Yup from "yup";

interface FiltersProps {
  busy: boolean;
  page: number;
  pageSize: number;
  handleFilter: (filter: GetUserLogHistoryQuery) => void;
  user: User;
}
const LogHistoryFilters = (props: FiltersProps) => {
  const {t} = useTranslation();
  const {microserviceSelectOptions, logOperationSelectOptions} = useSelectOptions();

  useEffect(() => {
    var payload: GetUserLogHistoryQuery = {
      page: 1,
      pageSize: props.pageSize,
      action: "filtering",
      userId: props.user.userId,
      createDateRange: {
        start: getRelativeDate(30),
        end: getToday().tz('Etc/GMT').endOf('day').toDate(),
      },
    };

    validation.setValues(payload);
    props.handleFilter(payload);
  }, []); // eslint-disable-line

  const usersData = createSelector(
    (state: RootState) => state,
    (state) => ({
      loading: state.AdminUsers.loading,
    }),
  );
  const {loading} = useSelector(usersData);

  const validation = useFormik({
    initialValues: {} as GetUserLogHistoryQuery,
    validationSchema: Yup.object<GetUserLogHistoryQuery>({
      createDateRange: Yup.object<DateRange>(),
      operationType: Yup.string().notRequired(),
      search: Yup.string().notRequired(),
      service: Yup.string().notRequired(),
    }),
    onSubmit: (values: GetUserLogHistoryQuery) => {
      const payload: GetUserLogHistoryQuery = {
        page: 1,
        pageSize: props.pageSize,
        search: values.search,
        createDateRange: values.createDateRange,
        operationType: values.operationType,
        service: values.service,
        action: "filtering",
        userId: props.user.userId,
      };
      props.handleFilter(payload);
    },
  });

  return (
    <>
      <PlaceholderGlow busy={props.busy}>
        <div className="live-preview mb-4">
          <Form onSubmit={validation.handleSubmit}>
            <Row className="align-items-center">
              <Col>
                <div className="flex-shrink-0">
                  <Label className="form-label text-muted">{t("Admin.Users.Dialog.LogHistory.Filters.Title")}</Label>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Row className=" mb-3">
                <Col xs={12} sm={4}>
                  <Label htmlFor="search">{t("Admin.Users.Dialog.LogHistory.Filters.Label.CreateDate")}</Label>
                  <DateRangeInput value={validation.values.createDateRange} onChange={(val: DateRange) => validation.setFieldValue("createDateRange", val)} />
                </Col>

                <Col xs={12} sm={4}>
                  <Label htmlFor="search">{t("Admin.Users.Dialog.LogHistory.Filters.Label.OperationType")}</Label>
                  <ValidatedSelect
                    className="w-100"
                    options={logOperationSelectOptions}
                    validation={validation}
                    field="operationType"
                    value={validation.values.operationType}
                    errorStyle="solid"
                    isClearable
                  />
                </Col>

                <Col xs={12} sm={4}>
                  <Label htmlFor="search">{t("Admin.Users.Filters.Label.Search")}</Label>
                  <ValidatedInput validation={validation} field="search" maxLength={100} placeholder={t("Admin.Users.Dialog.LogHistory.Filters.Label.SearchPlaceholder")} disableValidationUI />
                </Col>

                <Col xs={12} sm={4} className="mt-sm-3">
                  <Label htmlFor="search">{t("Admin.Users.Dialog.LogHistory.Filters.Label.Service")}</Label>
                  <ValidatedSelect className="w-100" options={microserviceSelectOptions} validation={validation} field="service" value={validation.values.service} errorStyle="solid" isClearable />
                </Col>

                <Col xs={12} sm={4} className="align-content-end mt-sm-3">
                  <Button id="submit" type="submit" color="secondary" className="me-0" disabled={loading.filter}>
                    {loading.listLogHistory ? <Spinner size="sm" className="me-2 align-middle"></Spinner> : <i className="ri-equalizer-fill me-1 align-bottom"></i>}
                    {t("Admin.Users.Dialog.LogHistory.Filters.Button.Apply")}
                  </Button>
                </Col>
              </Row>
            </Row>
          </Form>
        </div>
      </PlaceholderGlow>
    </>
  );
};

export default LogHistoryFilters;
