import {APIClient} from "helpers/api_helper";
import {UserStore} from "models/user_stores";
import {UserInventoryQuery, FilterOrderQuery, GetStatisticsQuery, GetUserOrderDetailsQuery} from "api/query";
import {ArchiveUserInventoryCommand, DeleteUserStoreCommand, DisconnectUserStoreCommand, DownloadInventoryCommand, ReassignUserOrderShipmentCommand, SetUserStoreNameCommand, UpdateInventoryCommand, UpdateUserInventoryCostCommand, UpdateUserOrderCostCommand} from "api/command";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "slices";
import {ApplicationUser} from "models/application_user";
import {PagePermissionItem, Permissions} from "helpers/permission_helper";
import * as url from "../helpers/url_helper";
import { ResponseModel } from "models/response_model";

const api = new APIClient();

export const postGetUserStores = () => api.create(url.POST_GET_USER_STORES, []);

export const postSetUserStoreName = (data: SetUserStoreNameCommand) => api.create(url.POST_SET_USER_STORE_NAME, data);

export const postDisconnectUserStore = (data: DisconnectUserStoreCommand) => api.create(url.POST_DISCONNECT_USER_STORE, data);

export const postDeleteUserStore = (data: DeleteUserStoreCommand) => api.create(url.POST_DELETE_USER_STORE, data);

export const postGetUserInventories = (data: UserInventoryQuery) => api.create(url.POST_GET_USER_INVENTORIES, data);

export const postArchiveInventories = (data: ArchiveUserInventoryCommand) => api.create(url.POST_ARCHIVE_INVENTORIES, data);

export const postUnarchiveInventories = (data: ArchiveUserInventoryCommand) => api.create(url.POST_UNARCHIVE_INVENTORIES, data);

export const postUpdateInventoryCost = (data: UpdateUserInventoryCostCommand) => api.create(url.POST_UPDATE_INVENTORY_COST, data);

export const postUpdateInventory = (data: UpdateInventoryCommand) => api.create(url.POST_UPDATE_INVENTORY, data);

export const postDownloadInventory = (data: DownloadInventoryCommand) => api.create(url.POST_DOWNLOAD_INVENTORY, data);

export const postGetUserOrders = (data: FilterOrderQuery) => api.create(url.POST_GET_USER_ORDERS, data);

export const postGetUserOrderDetails = (data: GetUserOrderDetailsQuery) => api.create(url.POST_GET_USER_ORDER_DETAILS, data);

export const postUpdateUserOrderCost = (data: UpdateUserOrderCostCommand) => api.create(url.POST_UPDATE_USER_ORDER_COST, data);

export const postReassignUserOrderShipment = (data: ReassignUserOrderShipmentCommand) => api.create(url.POST_REASSIGN_USER_ORDER_SHIPMENT, data);

export const postGetDashboardStatistics = (data: GetStatisticsQuery, signal?: any) => api.create(url.POST_GET_DASHBOARD_STATISTICS, data, {
  cancelToken: signal
});

export const fetchUserStores = createAsyncThunk<UserStore[], void, {state: RootState}>("Common/fetchUserStores", async (_, {getState}) => {
  const {Login} = getState();

  const profile: ApplicationUser | undefined = Login.user;
  const pageRoles = Permissions.find((p: PagePermissionItem) => p.page === "stores")?.roles;
  let hasPermission = false;
  if (!pageRoles) {
    hasPermission = false;
  } else {
    hasPermission = pageRoles.some((role) => profile?.roles.includes(role.name));
  }

  if (Login.user && !Login.user?.tokenInfo.isTempToken && hasPermission) {
    const response: ResponseModel = await postGetUserStores();
    const stores: UserStore[] = response.data;
    return stores;
  }
  return [];
});
