import {UserSearchProduct} from "models/user_search_product";
import {useTranslation} from "react-i18next";
import ProfitCalculator from "Components/Common/Calculator/_ProfitCalculator";
import {ActiveMarketplaces, AmazonMarketplace} from "helpers/marketplace_helper";
import NoAvailableOffer from "./Components/NoAvailableOffer";
import {useCallback, useEffect, useState} from "react";

interface ProfitAnalysisProps {
  item: UserSearchProduct;
}
const ProfitAnalysis = ({item}: ProfitAnalysisProps) => {
  const {t} = useTranslation();

  const [sourceMarketplace, setSourceMarketplace] = useState<AmazonMarketplace>();
  const [destinationMarketplace, setDestinationMarketplace] = useState<AmazonMarketplace>();

  const onLoad = useCallback(() => {
    let source = ActiveMarketplaces.find((amazonMarketplaceInfo) => amazonMarketplaceInfo.currency === item.currencyCodeSource);
    let destination = ActiveMarketplaces.find((amazonMarketplaceInfo) => amazonMarketplaceInfo.currency === item.currencyCodeTarget);
    setSourceMarketplace(source);
    setDestinationMarketplace(destination);
  }, []); // eslint-disable-line

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return item.summary?.decisionReasons?.includes("NoAvailableOfferAtSource") || item.summary?.decisionReasons?.includes("NoAvailableOfferAtTarget") ? (
    <NoAvailableOffer item={item} />
  ) : (
    <ProfitCalculator
      title={t("SearchResults.Analyzes.ProfitAnalysis.Title")}
      amazonBusinessModel={item.amazonBusinessModel}
      itemProfitAnalysis={item.profitAnalysis}
      buyBoxType={item.competitorAnalysis?.buyBoxType}
      offerCount={item.competitorAnalysis?.offerCount}
      userSearchProductId={item.userSearchProductId}
      sourceMarketplace={sourceMarketplace}
      destinationMarketplace={destinationMarketplace}
    />
  );
};

export default ProfitAnalysis;
