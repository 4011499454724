import {useMemo, useState} from "react";
import {ApexOptions} from "apexcharts";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import i18n from "i18n";
import NoData from "Components/Common/NoData";
import {Badge, CardHeader, Col, Row} from "reactstrap";
import DisplayNumber from "Components/Common/DisplayNumber";

interface SellerHistoryData {
  Date: string;
  IsAmazonExist: boolean;
  FBASellerCount: number;
  FBMSellerCount: number;
}

interface SellerHistoryChartProps {
  sellerHistoryJSON?: string;
}

const SellerHistoryChart = ({sellerHistoryJSON}: SellerHistoryChartProps) => {
  const [historyDay, setHistoryDay] = useState<number>(0);
  // Memoize data parsing
  const sellerHistoryData = useMemo(() => {
    if (!sellerHistoryJSON) {
      return [];
    }

    try {
      const parsedData: SellerHistoryData[] = JSON.parse(sellerHistoryJSON);

      const sortedData = parsedData.sort((a, b) => new Date(a.Date).getTime() - new Date(b.Date).getTime());

      let dayDifference = 0;
      if (sortedData.length >= 2) {
        const minDate = new Date(sortedData[0].Date);
        const maxDate = new Date(sortedData[sortedData.length - 1].Date);
        const diffTime = Math.abs(maxDate.getTime() - minDate.getTime());
        dayDifference = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      }

      setHistoryDay(dayDifference);

      return sortedData;
    } catch (error) {
      console.error("Error parsing seller history data:", error);
      return [];
    }
  }, [sellerHistoryJSON]);

  const formatDateForXAxis = (value: number) => {
    moment.locale(i18n.language);
    return moment(value).format("MMM D");
  };

  const formatDateTimeForTooltip = (value: number) => {
    return moment(value).format("DD MMM YYYY HH:mm");
  };

  const amazonExists = () => {
    return sellerHistoryData.some((item) => item.IsAmazonExist === true);
  };

  const seriesArray = [
    {
      name: i18n.t("SearchResults.Analyzes.SellerHistory.FbaSellers"),
      type: "line",
      data: sellerHistoryData.map((item) => [
        new Date(item.Date).getTime(), // x: timestamp
        item.FBASellerCount, // y: FBA count
      ]),
    },
    {
      name: i18n.t("SearchResults.Analyzes.SellerHistory.FbmSellers"),
      type: "line",
      data: sellerHistoryData.map((item) => [
        new Date(item.Date).getTime(), // x: timestamp
        item.FBMSellerCount, // y: FBM count
      ]),
    },
  ];

  if (amazonExists()) {
    seriesArray.push({
      name: i18n.t("SearchResults.Analyzes.SellerHistory.IsAmazonExist"),
      type: "area",
      data: sellerHistoryData.map((item) => {
        if (item.IsAmazonExist === true) {
          return [
            new Date(item.Date).getTime(), // x: timestamp
            // Use the higher value between FBA and FBM for that date
            Math.max(item.FBASellerCount, item.FBMSellerCount),
          ];
        } else {
          return [
            new Date(item.Date).getTime(), // x: timestamp
            0,
          ];
        }
      }),
    });
  }

  const options: ApexOptions = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: false,
      },
      width: "100%",
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: [2, 2, 2], // Line widths for FBA, FBM, Amazon
    },
    colors: ["#f7b94d", "#21baa5", "#ff0000"], // FBA, FBM, Amazon
    fill: {
      type: ["solid", "solid", "solid"],
      opacity: [1, 1, 0.2], // Make Amazon area semi-transparent
    },
    xaxis: {
      type: "datetime",
      labels: {
        formatter: (value: string) => formatDateForXAxis(Number(value)),
        rotate: 0,
        rotateAlways: false,
        hideOverlappingLabels: true,
        style: {
          fontSize: "10px",
        },
      },
      axisBorder: {
        show: true,
        strokeWidth: 1,
        color: "#78909C",
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "#78909C",
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
    },
    yaxis: {
      min: 0,
      forceNiceScale: true,
      decimalsInFloat: 0,
      floating: false,
      labels: {
        formatter: (value) => {
          // Always show integer values for y-axis
          return Math.round(value).toString();
        },
        style: {
          fontSize: "10px",
        },
        offsetY: 0,
        rotate: 0,
        show: true,
      },
      axisBorder: {
        show: true,
        color: "#78909C",
        width: 1,
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: true,
        color: "#78909C",
        width: 6,
        offsetX: 0,
        offsetY: 0,
      },
    },
    tooltip: {
      enabled: true,
      followCursor: true,
      intersect: false,
      shared: true,
      x: {
        formatter: function(value) {
          // Return full formatted date+time for the datapoint
          return "<strong>" + formatDateTimeForTooltip(value) + "</strong>";
        },
      },
      y: {
        formatter: (value, {seriesIndex, dataPointIndex, w}) => {
          // Special handling for Amazon series
          if (seriesIndex === 2) {
            if (value > 0) return i18n.t("Yes");
            else {
              return i18n.t("No");
            }
          }
          // Format count with integer for other series
          return Math.round(value).toString();
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      size: 0, // Set marker size to 0 to hide the data points
      hover: {
        size: 5, // Show markers on hover
        sizeOffset: 3,
      },
    },
  };

  if (sellerHistoryData.length === 0) {
    return <NoData icon="mdi mdi-chart-line" style={{minHeight: 100}} />;
  }

  return (
    <>
      <CardHeader className="p-0">
        <Row className="mb-2">
          <Col>
            <h5>
              {i18n.t("SearchResults.Analyzes.SellerHistory.Title")}
              <span className="text-muted fs-12 align-middle ms-1">
                <DisplayNumber
                  value={historyDay}
                  prefix="("
                  suffix={`
                    ${historyDay > 1 ? i18n.t("days") + ")" : i18n.t("day") + ")"}`}
                />
              </span>
            </h5>
          </Col>
          <Col xs="auto">
            <Badge className="bg-warning-subtle text-warning me-2">FBA</Badge>
            <Badge className="bg-success-subtle text-success me-2">FBM</Badge>
            <Badge className="bg-danger-subtle text-danger">Amazon</Badge>
          </Col>
        </Row>
      </CardHeader>
      <div className="chart-container">
        <ReactApexChart options={options} series={seriesArray} type="line" height={125} width={"100%"} />
      </div>
    </>
  );
};

export default SellerHistoryChart;

SellerHistoryChart.whyDidYouRender = true;
