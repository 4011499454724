import DisplayNumber from "Components/Common/DisplayNumber";
import {AmazonBusinessModel} from "models/enums/user_search_type";
import {UserSearchProduct} from "models/user_search_product";
import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Card, CardBody} from "reactstrap";

interface VariationAnalysisProps {
  item: UserSearchProduct;
}
const VariationAnalysis = ({item}: VariationAnalysisProps) => {
  const {t} = useTranslation();
  const [variationClassName, setVariationColorClassName] = useState<string>();
  const onLoad = useCallback(() => {
    let level = null;
    if (item.amazonBusinessModel === AmazonBusinessModel.DROPSHIPPING) {
      level = item.variationAnalysis.variationLevelDS;
    } else {
      level = item.variationAnalysis.variationLevel;
    }

    const colorClass = level === "Low" ? "badge rounded-pill bg-success h-25 fs-11" : level === "High" ? "badge rounded-pill bg-warning h-25 fs-11" : "";

    setVariationColorClassName(colorClass);
  }, [item]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const fbaSellerExistClassName = item.variationAnalysis.isHasFBASeller != null ? (item.variationAnalysis.isHasFBASeller === true ? "text-danger" : "text-success") : "";

  const fbSellersOtherVariationsClassName = item.variationAnalysis.othersHasFBASeller != null ? (item.variationAnalysis.othersHasFBASeller === true ? "text-danger" : "text-success") : "";
  return (
    <Card className="border card-animate mb-3">
      <CardBody>
        <h5>{t("SearchResults.Analyzes.VariationAnalysis.Title")}</h5>
        <ul className="list-group d-flex flex-group gap-1">
          <li className="d-flex justify-content-between">
            <div>
              <span>{t("SearchResults.Analyzes.VariationAnalysis.Count")}</span>
            </div>
            <DisplayNumber value={item.variationAnalysis.variationCount} className={`${variationClassName}`} renderOnEmpty={<>{t("NA")}</>} />
          </li>
          <li className="d-flex justify-content-between">
            <span>{t("SearchResults.Analyzes.VariationAnalysis.PriceDiffOtherVariations")}</span>
            <DisplayNumber value={item.variationAnalysis.priceDiffRate} prefix="%" renderOnEmpty={<>{t("NA")}</>} />
          </li>
          <li className="d-flex justify-content-between">
            <span>{t("SearchResults.Analyzes.VariationAnalysis.FBASellerExist")}</span>
            {item.variationAnalysis?.isHasFBASeller != null ? <span className={fbaSellerExistClassName}>{item.variationAnalysis.isHasFBASeller ? t("Yes") : t("No")}</span> : <span>{t("NA")}</span>}
          </li>
          <li className="d-flex justify-content-between">
            <span>{t("SearchResults.Analyzes.VariationAnalysis.FBASellersOtherVariations")}</span>
            {item.variationAnalysis?.othersHasFBASeller != null ? (
              <span className={fbSellersOtherVariationsClassName}>{item.variationAnalysis.othersHasFBASeller ? t("Yes") : t("No")}</span>
            ) : (
              <span>{t("NA")}</span>
            )}
          </li>
        </ul>
      </CardBody>
    </Card>
  );
};
export default VariationAnalysis;
